import { ChangeEvent, JSX } from 'react'

interface PageSizeSelectorProps {
  pageSizes: number[]; //25, 50, 100
  onNewPageSize: (event: PageSizeEvent) => void
  initialSize: number;
}

export interface PageSizeEvent {
  nextSize: number;
}

function PageSizeSelector({ pageSizes, onNewPageSize, initialSize }: PageSizeSelectorProps): JSX.Element {
  function onSelect(e: ChangeEvent<HTMLSelectElement>) {
    onNewPageSize({ nextSize: +e.target.value })
  }

  const options = pageSizes.map(ps => {
    return <option key={ps} value={ps}>{ps}</option>
  })

  return <select
    className='w-16 h-8 text-gray-600 hover:bg-gray-100 border-transparent text-center text-xs focus:outline-gray-200'
    value={initialSize} onChange={onSelect}>
    {options}
  </select>
}

export default PageSizeSelector