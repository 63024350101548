import React from 'react'
import { JetSportBox } from '../BoxView/JetSportBox'
import { BoxContent } from '../BoxView/BoxContent'
import LoadingElement from '../loading-element/loading-element'
import { useWhiteLabelContext } from '../../../WhiteLabelContext'

export function LoadingView(): React.ReactElement {
  const {whiteLabel: {colorHex}} = useWhiteLabelContext()
  return (
    <JetSportBox className={'!p-0'}>
      <BoxContent className={'justify-center items-center relative rounded !p-0'}>
        <LoadingElement className={'rounded'} colorHex={colorHex} />
      </BoxContent>
    </JetSportBox>
  )
}
