import React from 'react'
import { ErrorView } from '../../components/error-view/ErrorView'
import { LoadingView } from '../../components/loading-view/LoadingView'
import { useRepositoriesContext } from '../../../contexts/RepositoriesContext'
import { ComponentState, useComponentStateMachine } from '../../shared/hooks/useComponentStateMachine'
import { useSearchParams } from 'react-router-dom'
import { OrderDetailsWrapper } from './OrderDetailsWrapper'

export function OrderDetailsScreen(): React.ReactElement {
  const { state, dataTransition } = useComponentStateMachine()
  const { orderRepository } = useRepositoriesContext()

  const [searchParams] = useSearchParams()
  const orderId = searchParams.get('orderId')
  const organizationId = searchParams.get('orgId')

  const {
    data: order,
    isSuccess: isOrderSuccess,
    isLoading: isOrderLoading,
    isError: isOrderError
  } = orderRepository.useOrder(+organizationId!, +orderId!)

  const isLoading = isOrderLoading
  const isSuccess = isOrderSuccess
  const isError = isOrderError
  dataTransition(isLoading, isSuccess, isError)

  switch (state) {
    case ComponentState.Succeed:
      return <OrderDetailsWrapper order={order!} />
    case ComponentState.Failed:
      return <OrderDetailsError />
    case ComponentState.Loading:
      return <OrderDetailsLoading />
    default:
      return <OrderDetailsLoading />
  }
}

function OrderDetailsError() {
  return <ErrorView />
}

function OrderDetailsLoading() {
  return <LoadingView />
}