import React, { createContext, useContext, useState } from 'react'
import { LogoOptions } from '../../../../core/models/entities/LogoOptions'
import { BaseLogo } from '../../../../core/models/entities/BaseLogo'

export interface LogoProviderContext {
  options: LogoOptions
  logoToEdit: BaseLogo | null
  setLogoToEdit: (logo: BaseLogo | null) => void
}

const LogoContext = createContext<LogoProviderContext | undefined>(undefined)

interface LogoProviderProps {
  children: React.ReactNode
  options: LogoOptions
}

function LogoProvider({
                        children,
                        options: o
                      }: LogoProviderProps): React.ReactElement {
  const [options] = useState<LogoOptions>(o)
  const [logoToEdit, setLogoToEdit] = useState<BaseLogo | null>(null)


  return (
    <LogoContext.Provider
      value={{ options, logoToEdit, setLogoToEdit }}>
      {children}
    </LogoContext.Provider>
  )
}

function useLogoContext() {
  const context = useContext(LogoContext)
  if (!context) {
    throw new Error('useRepositoriesContext must be used within a RepositoriesProvider')
  }

  return context
}


export { LogoProvider, useLogoContext, LogoContext }