import React from 'react'
import { Autocomplete, Box, createFilterOptions } from '@mui/material'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons'
import ColorBlock from './ColorBlock'
import { Color } from '../../../../core/models/entities/LogoOptions'

function ColorAutoComplete({ colors, disabled, onColorPicked, value }: {
  colors: Color[],
  disabled: boolean,
  onColorPicked: (color: Color) => void
  value: string | undefined
}) {
  const filterOptions = createFilterOptions<Color>({
    limit: 200
  })

  const picked = colors.find(x => x.id === value) ?? null

  function handleColorPicked(color: Color) {
    onColorPicked(color)
  }

  return <Autocomplete
    className={'!text-xs'}
    disabled={disabled}
    options={colors}
    value={picked}
    sx={{ width: 272 }}
    filterOptions={filterOptions}
    getOptionLabel={(option) => option.name}
    renderOption={(props, option) => {
      // @ts-ignore
      const { key, ...otherProps } = props;
      return (
        <Box
          key={key}
          component='li'
          {...otherProps}
        >
          <div className={'flex text-xs items-center space-x-2'}>
            <ColorBlock color={option} />
            <p>{option.name}</p>
          </div>
        </Box>
      )
    }}
    renderInput={(params) =>
    (
      <div ref={params.InputProps.ref}
        className='border rounded-md border-gray-200 border-solid h-9 flex space-x-2 items-center pl-2 bg-white focus-within:border-gray-600'>
        <span>
          <FontAwesomeIcon icon={faMagnifyingGlass} />
        </span>
        <input {...params.inputProps} className='border-none text-xs font-medium outline-none'
          placeholder={'search for color...'} />
      </div>
    )}
    onChange={(x, v, r) => {
      if (v) {
        handleColorPicked(v)
      }
    }}
  />
}


export default ColorAutoComplete