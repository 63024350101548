import Tooltip from '@mui/material/Tooltip'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'
import React from 'react'
import ColorBlock from './ColorBlock'
import { Color } from '../../../../core/models/entities/LogoOptions'

function ColorSwitch({ switchToColor, baseColor, isSelected, onClick }: {
  baseColor: Color, switchToColor?: Color, isSelected: boolean, onClick: (color: Color) => void
}) {
  function LabelText({ color, hasTooltip }: { color: Color, hasTooltip: boolean }) {

    const p = <p className={'whitespace-nowrap overflow-hidden overflow-ellipsis'}>{color.name}</p>

    return !hasTooltip
      ? p
      : <Tooltip placement={'top'} title={color.name}>{p}</Tooltip>
  }

  return (
    <div
      onClick={() => onClick(baseColor)}
      className={`m-0.5 cursor-pointer p-1 border rounded border-gray-200 w-[94px] h-[24px] flex items-center space-x-1.5 hover:border-gray-600 ${isSelected ? 'border-gray-600' : ''}`}>
      <ColorBlock color={baseColor} />
      {!!switchToColor
        ? <>
          <FontAwesomeIcon size={'sm'} icon={faArrowRight} />
          <ColorBlock color={switchToColor} />
          <LabelText color={switchToColor} hasTooltip={switchToColor.name.length > 5} />
        </>
        : <LabelText color={baseColor} hasTooltip={false} />
      }
    </div>
  )
}


export default ColorSwitch