import { TextLogoPreview } from './text-logo-preview.component'
import { PrimaryButton } from '../../../../components/Buttons/PrimaryButton'
import { SecondaryButton } from '../../../../components/Buttons/SecondaryButton'
import { Color, Font, LogoOptions } from '../../../../../core/models/entities/LogoOptions'
import { FontAndColorEditor } from './font-and-color-editor.component'
import LoadingElement from '../../../../components/loading-element/loading-element'

export interface TextLogoCreatePanel_VM {
  textValue: string
  font: Font | undefined
  color: Color | undefined
  onColorChange: (value: (((prevState: (Color | undefined)) => (Color | undefined)) | Color | undefined)) => void
  onFontChange: (value: (((prevState: (Font | undefined)) => (Font | undefined)) | Font | undefined)) => void
  options: LogoOptions
  heightMM: number
  onHeightMMChange: (value: (((prevState: number) => number) | number)) => void
  onTextValueChange: (value: (((prevState: string) => string) | string)) => void
  onAddLogo: () => void
  onCancel: () => void
  canAdd: boolean
  preview?: string
  getPreview: (value: string, color: Color, heightMM: number, font: Font) => Promise<string>
  isLoading: boolean
}

export function TextLogoCreatePanel(
  {
    textValue, 
    onTextValueChange, 
    onHeightMMChange, 
    heightMM,
    font, onFontChange, onColorChange,
    options,
    color,
    onAddLogo,
    onCancel,
    canAdd,
    getPreview,
    isLoading
  }: TextLogoCreatePanel_VM) {

  return <div className={'flex flex-col h-full space-y-4 py-4'}>
    {isLoading && <LoadingElement transparent />}
    <TextLogoPreview value={textValue} font={font} color={color} heightMM={heightMM} isValid={canAdd} getPreview={getPreview} />
    <div className={'flex-1'}>
      <FontAndColorEditor color={color}
        onSelectColor={onColorChange}
        font={font}
        onSelectFont={onFontChange}
        options={options}
        fontSize={heightMM}
        onSelectFontSize={onHeightMMChange}
        value={textValue}
        onChangeValue={onTextValueChange}
        showValueField={true}
      />
    </div>
    <PrimaryButton width={'full'} onClick={onAddLogo} disabled={!canAdd}>Add Text</PrimaryButton>
    <SecondaryButton width={'full'} onClick={onCancel}>Cancel</SecondaryButton>
  </div>
}