import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons'
import { DesignSize, CartItemDesignInstance } from '../models/CartItem';
import { useState } from 'react';

export interface DesignInstanceViewModelProps {
  orderlineDesign: CartItemDesignInstance
  index: number,
  updateLabel: (value: string) => void
  handleInstanceSizeChange: (size: DesignSize) => void
  handleInstanceQuantity: (quantity: number) => void
  handleLogoText: (logoId: number, value: string | number) => void
}

export function DesignInstanceComponent({ index, orderlineDesign, updateLabel: handleOverall, handleInstanceSizeChange: handleSizeChange, handleInstanceQuantity: handleQuantity, handleLogoText: handleRowText }: DesignInstanceViewModelProps) {

  const [expanded, setExpanded] = useState<boolean>(true)

  const sizes = Object.keys(DesignSize).filter(key => isNaN(Number(key)))
  const sizeOptions = sizes.map(size => <option key={size} value={DesignSize[size as keyof typeof DesignSize]}> {DesignSize[size as keyof typeof DesignSize]}</option>)

  const designInstanceLogoRows = orderlineDesign.logos.filter(x => x.logoVariant !== 'static').map((logo, logoIndex) =>
    <div key={logoIndex} className={'text-xs flex space-x-1 p-1'}>
      <div className={'flex items-center'}>
        <p className={'w-[270px] pl-4 uppercase mr-1'}>{logo.locationIdentifier}</p>
        <div className={'w-[94px]'}>
          {logo.logoVariant === 'number' && <input className={'order-logo-design-input-child'} placeholder={'enter number...'} type='number' value={logo.value} onChange={e => handleRowText(logo.logoId, e.target.value)} />}
          {logo.logoVariant === 'name' && <input className={'order-logo-design-input-child'} placeholder={'enter text...'} type='text' value={logo.value} onChange={e => handleRowText(logo.logoId, e.target.value)} />}
        </div>
      </div>
    </div>
  )

  const expand = <>
    <div className={'h-4 w-4 flex items-center justify-center'} onClick={() => setExpanded(x => !x)}>
      {expanded ? <FontAwesomeIcon icon={faChevronUp} /> : <FontAwesomeIcon icon={faChevronDown} />}
    </div>
  </>

  const logoTypes = orderlineDesign.logos.map((type) => type.logoVariant);

  return <div id={'row'}>
    <div id={'logo-parent'} className={'text-xs flex justify-between p-1'}>
      <div className={'flex space-x-1'}>
        <div className={'min-w-[270px] w-[270px]'}>
          {logoTypes.includes('name') ?
            <input className={'order-logo-design-input'} placeholder={'enter label...'} value={orderlineDesign.label} onChange={e => handleOverall(e.target.value)} />
            : <p className="order-logo-design-input">{index + 1}.</p>}
        </div>
        <div className={'min-w-[90px] w-[90px]'}>
          <select className={"order-logo-design-input"} value={orderlineDesign.size} onChange={(e) => handleSizeChange(e.target.value as DesignSize)}>
            {sizeOptions}
          </select>
        </div>
        <div className={'min-w-[90px] w-[90px] flex'}>
          <input className={'order-logo-design-input'} type={'number'} placeholder={'quantity..'} value={orderlineDesign.toDelivery} onChange={(e) => handleQuantity(+e.target.value)} />
        </div>
      </div>
      <div className={'flex items-center'}>
        {orderlineDesign.logos?.filter(x => x.logoVariant === 'name' || x.logoVariant === 'number').length > 0 && expand}
      </div>
    </div>
    {expanded && <div id={'logo-children'} className={'w-full bg-white'}>
      {designInstanceLogoRows}
    </div>}
  </div>
}