import { InputWrapper } from '../../../components/Input/InputWrapper'
import { InputLabel } from '../../../components/Input/InputLabel'
import { CustomInput } from '../../../components/Input/CustomInput'
import { SecondaryButton } from '../../../components/Buttons/SecondaryButton'
import { InputError } from '../InputError'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPalette } from '@fortawesome/free-solid-svg-icons'
import React from 'react'
import { CustomInputChangeEvent } from '../../../../utils/models/events/CustomInputChangeEvent'
import { nameOf } from '../../../../utils/functions/ReflectionUtil'
import { WhiteLabelConfigurationsForm } from './white-label-editor.components'

export interface ColorPickerEditorViewModel {
  onFormChange: (event: React.ChangeEvent<HTMLInputElement> | CustomInputChangeEvent) => void
  disabled: boolean
  form: WhiteLabelConfigurationsForm
  errorMsg: string[] | undefined
}

export function ColorPickerEditor({ disabled, onFormChange, form, errorMsg }: ColorPickerEditorViewModel) {  
  const inputRef = React.useRef<HTMLInputElement>(null)
  function handelUploadClick() {
    inputRef.current?.click()
  }
  return <div>
    <InputWrapper>
      <InputLabel>Primary Color</InputLabel>
      <div className={'flex space-x-4'}>
        <div className={'relative flex-1'}>
          <CustomInput
            name={nameOf<WhiteLabelConfigurationsForm>(lf => lf.colorHex)}
            className={'mr-4'}
            value={form.colorHex}
            onChange={x => onFormChange(x)}
            disabled={disabled}
          />
        </div>
        <SecondaryButton width={'icon'} className={'ml-4'} onClick={handelUploadClick} disabled={disabled}>
        <input ref={inputRef} name={nameOf<WhiteLabelConfigurationsForm>(lf => lf.colorHex)} style={{ visibility: "hidden", position: "absolute" }} type="color" value={form.colorHex} onChange={x => onFormChange(x)} />
          <FontAwesomeIcon size={'lg'} icon={faPalette} style={{ color: form.colorHex }} />
        </SecondaryButton>
      </div>
      <InputError errorMessage={errorMsg} />
    </InputWrapper>
  </div>
}