import React, { useEffect, useState } from 'react'
import { ErrorView } from '../../components/error-view/ErrorView'
import { LoadingView } from '../../components/loading-view/LoadingView'
import { ContentModal } from '../../components/content-modal/ContentModal'
import { usePageQuery } from '../../shared/hooks/usePageQuery'
import {
  PagedComponentState,
  usePagedComponentStateMachine
} from '../../shared/hooks/usePageComponentStateMachine'
import { useRepositoriesContext } from '../../../contexts/RepositoriesContext'
import { useCustomerAndVendors } from '../../shared/hooks/useCustomerAndVendors'
import { Design } from '../../../core/models/entities/Design'
import { DesignListWrapper } from './wrappers/design-list.wrapper'
import { DesignDetailsWrapper } from './wrappers/design-details.wrapper'
import { getAllQuery } from '../logo-editor/logo-editor.screen'
import { RejectionNoteDialog } from '../../components/Dialogs/rejection-note-popup.component'
import { DesignTitleHeader } from '../../components/Logo/LogoTitleHeader'
import { useProfileContext } from '../../../contexts/ProfileContext'
import { useDialog } from '../../components/Dialogs/dialog-base'
import { useOpenDesign } from '../logo-list/vmHooks/useOpenDesign'
import { DesignModalIntent } from './components/design-details.component'

type DesignSelection = {
  design: Design
  intent: DesignModalIntent
}

export function DesignListScreen(): React.ReactElement {
  const [query, queryChanger] = usePageQuery()
  const { state, dataTransition } = usePagedComponentStateMachine()
  const {
    designRepository,
    organizationRepository,
    styleRepository,
    logoRepository,
    manufacturerRepository
  } = useRepositoriesContext()
  const [lastPage, setLastPage] = useState<number>(1)
  const [designSelected, setDesignSelected] = useState<DesignSelection | null>(null)
  const [designRejection, setDesignRejection] = useState<Design | null>(null)
  const { role } = useProfileContext()
  const rejectionNoteDialog = useDialog()
  const { result: openResult, clearOpen } = useOpenDesign()

  const {
    data: page,
    isSuccess: isDesignsSuccess,
    isLoading: isDesignsLoading,
    isError: isDesignsError
  } = designRepository.useAllDesigns(query)

  const {
    data: optionsPage,
    isSuccess: isOptionsSuccess,
    isLoading: isOptionsLoading,
    isError: isOptionsError
  } = logoRepository.useOptions()

  const {
    data: stylesPage,
    isSuccess: isStylesSuccess,
    isLoading: isStylesLoading,
    isError: isStylesError
  } = styleRepository.useAllStyles(getAllQuery)

  const {
    data: manufactuerersPage,
    isSuccess: isManufactererSuccess,
    isLoading: isManufactererLoading,
    isError: isManufactererError
  } = manufacturerRepository.useManufacturers()

  const {
    customers,
    vendors,
    isError: isOrganizationsError,
    isSuccess: isOrganizationsSuccess,
    isLoading: isOrganizationsLoading
  } = useCustomerAndVendors(organizationRepository)

  if (page && page.pageCount !== lastPage) {
    setLastPage(page.pageCount)
  }

  function showRejectionNote(design: Design) {
    setDesignRejection(design)
    rejectionNoteDialog.openDialog()
  }

  // useEffect(() => {
  //   if (openResult) {
  //     setDesignSelected({ design: openResult, intent: 'view' })
  //   }
  // }, [openResult])

  const isLoading =
    isDesignsLoading ||
    isOrganizationsLoading ||
    isManufactererLoading ||
    isOptionsLoading ||
    isStylesLoading
  const isSuccess =
    isDesignsSuccess &&
    isOrganizationsSuccess &&
    isManufactererSuccess &&
    isOptionsSuccess &&
    isStylesSuccess
  const isError =
    isDesignsError || isOrganizationsError || isManufactererError || isOptionsError || isStylesError

  dataTransition(isLoading, isSuccess, isError)

  return state === PagedComponentState.Succeed || state === PagedComponentState.PageLoading ? (
    <>
      {designRejection && (
        <RejectionNoteDialog
          title={<DesignTitleHeader design={designRejection!} />}
          dialogOptions={rejectionNoteDialog}
          approval={designRejection.approval!}
          exclude={role!}
        />
      )}
      <DesignListWrapper
        query={query}
        queryChanger={queryChanger}
        isPageLoading={state === PagedComponentState.PageLoading}
        lastPage={lastPage}
        onSelectDesign={(design) => {
          setDesignSelected({ design, intent: 'view' })
        }}
        designs={page?.results}
        customers={customers!}
        vendors={vendors}
        styles={stylesPage!.results}
        methods={optionsPage!.transferMethods}
        manufacturers={manufactuerersPage!}
        onShowRejectionNote={showRejectionNote}
        onApprove={(design) => setDesignSelected({ design, intent: 'approve' })}
        onReject={(design) => setDesignSelected({ design, intent: 'reject' })}
      />
      <ContentModal
        open={designSelected !== null}
        onClose={() => {
          setDesignSelected(null)
          clearOpen()
        }}>
        {designSelected !== null ? (
          <DesignDetailsWrapper
            design={designSelected!.design}
            onClose={() => setDesignSelected(null)}
            onShowRejectionNote={showRejectionNote}
            closeDialog={() => setDesignSelected(null)}
            intent={designSelected!.intent}
          />
        ) : (
          <></>
        )}
      </ContentModal>
    </>
  ) : state === PagedComponentState.Failed ? (
    <ErrorView />
  ) : (
    <LoadingView />
  )
}
