import { useEffect, useState } from 'react'
import { useWhiteLabelContext } from '../../../../WhiteLabelContext'
import { useRepositoriesContext } from '../../../../contexts/RepositoriesContext'
import { toast } from 'react-toastify'
import { useProfileRepository } from '../../../../data/repositories/useProfileRepository'
import { DetailsForm } from '../../customer-create/components/organization-details-form.component'
import { EmailPreferenceForm } from '../subcomponents/email-preference-editor.component'
import { MyProfileViewModel } from '../my-profile.component'
import { Organization } from '../../../../core/models/entities/Organization'
import { Profile } from '../../../../core/models/entities/Profile'
import { t } from 'i18next'

interface Props {
  profile: Profile
  organization: Organization
}

interface CustomerForm {
  name: string
  contactEmail: string
  email: string
  contactPerson: string
  contactPhone: string
  phone: string
  postCode: string
  address: string
  city: string
  country: string
  cvr: string
  eori?: string
  shippingName?: string
  shippingAddress?: string
  shippingPostCode?: string
  shippingCity?: string
  shippingCountry?: string
  shippingContactPerson?: string
  shippingContactPhone?: string
  shippingContactEmail?: string

  emailPreferenceNewDesign: boolean
  emailPreferenceNewLogos: boolean
  emailPreferenceNewOrder: boolean
  emailPreferencePendingOrder: boolean
  emailPreferenceOrder: boolean
}

export function useMyCustomerProfile({ profile: customerProfile, organization: customerOrganization }: Props): MyProfileViewModel {

  function mapToDetailsForm(profile: Profile, organization: Organization) {
    return {
      address: organization?.address ?? "",
      city: organization?.city ?? "",
      postCode: organization?.postCode ?? "",
      country: organization?.country ?? "",
      contactEmail: organization?.contactEmail ?? "",
      contactPerson: organization?.contactPerson ?? "",
      contactPhone: organization?.contactPhone ?? "",
      cvr: organization?.cvr ?? "",
      eori: organization?.eori ?? "",
      email: organization?.email ?? "",
      name: organization?.name ?? "",
      phone: organization?.phone ?? "",
  
      shippingName: organization?.shippingName ?? "",
      shippingAddress: organization?.shippingAddress ?? "",
      shippingPostCode: organization?.shippingPostCode ?? "",
      shippingCity: organization?.shippingCity ?? "",
      shippingCountry: organization?.shippingCountry ?? "",
      shippingContactPerson: organization?.shippingContactPerson ?? "",
      shippingContactPhone: organization?.shippingContactPhone ?? "",
      shippingContactEmail: organization?.shippingContactEmail ?? "",

      emailPreferenceNewDesign: profile.emailPreferences?.emailPreferenceNewDesign ?? false,
      emailPreferenceNewLogos: profile?.emailPreferences?.emailPreferenceNewLogos ?? false,
      emailPreferenceNewOrder: profile?.emailPreferences?.emailPreferenceNewOrder ?? false,
      emailPreferencePendingOrder: profile?.emailPreferences?.emailPreferencePendingOrder ?? false,
      emailPreferenceOrder: profile?.emailPreferences?.emailPreferenceOrder ?? false,
    }
  }

  function mapToProfile(profile: Profile, detailsForm: CustomerForm): Profile {
    return {
      ...profile,
      emailPreferences: {
        emailPreferenceNewDesign: detailsForm.emailPreferenceNewDesign ?? false,
        emailPreferenceNewLogos: detailsForm?.emailPreferenceNewLogos ?? false,
        emailPreferenceNewOrder: detailsForm?.emailPreferenceNewOrder ?? false,
        emailPreferencePendingOrder: detailsForm?.emailPreferencePendingOrder ?? false,
        emailPreferenceOrder: detailsForm?.emailPreferenceOrder ?? false,
      }
    }
  }

  // Initialize form
  const [detailsForm, setDetailsForm] = useState<CustomerForm>(mapToDetailsForm(customerProfile, customerOrganization))
  const [ profileConfigurations, setProfileConfigurations] = useState<Profile>({...customerProfile});

  const { organizationRepository } = useRepositoriesContext()
  const [ loading, setLoading] = useState<boolean>(false);
  const profileRepository = useProfileRepository()
  const { refetch: refetchProfile } = profileRepository.useProfile()
  const { reset: resetWhiteLabel, updateWhiteLabelContext } = useWhiteLabelContext();

  useEffect(() => {
    setDetailsForm(mapToDetailsForm(customerProfile, customerOrganization))
  }, [customerProfile, customerOrganization])

  useEffect(() => {
    updateWhiteLabelContext({...profileConfigurations?.whiteLabelConfigurations!});
  }, [profileConfigurations]);

  const { refetch: refetchOrganization, data } = organizationRepository.useCustomer(customerProfile!.userOrganizationInformations.organizationId);

  let sections = {
    details: { show: true, canEdit: true },
    emailPreferences: { show: true, canEdit: true },
    invoices: { show: true, canEdit: true },
  }

  function updateDetails(form: DetailsForm) {
    setDetailsForm({
      ...detailsForm,
        contactEmail: form.contactEmail,
        contactPerson: form.contactPerson,
        contactPhone: form.contactPhone,
        postCode: form.postCode,
        address: form.address,
        city: form.city,
        country: form.country,
        email: form.email,
        name: form.name,
        cvr: form.cvr,
        eori: form.eori,
        phone: form.phone,
        shippingName: form.shippingName,
        shippingAddress: form.shippingAddress,
        shippingPostCode: form.shippingPostCode,
        shippingCity: form.shippingCity,
        shippingCountry: form.shippingCountry,
        shippingContactPerson: form.shippingContactPerson,
        shippingContactPhone: form.shippingContactPhone,
        shippingContactEmail: form.shippingContactEmail,
    });
  }

  function updateEmailPreferences(form: EmailPreferenceForm) {
    setDetailsForm({
      ...detailsForm,
        emailPreferenceNewDesign: form.emailPreferenceNewDesign,
        emailPreferenceNewLogos: form.emailPreferenceNewLogos,
        emailPreferenceNewOrder: form.emailPreferenceNewOrder,
        emailPreferencePendingOrder: form.emailPreferencePendingOrder,
        emailPreferenceOrder: form.emailPreferenceOrder
      });
  }

  function resetProfile() {
    setDetailsForm(mapToDetailsForm(customerProfile, customerOrganization))
    resetWhiteLabel();
  }

  function saveProfileHandle() {
    async function transaction() {
      await organizationRepository.updateOrganization({
        id: customerOrganization.id,
        name: detailsForm.name,
        address: detailsForm.address,
        country: detailsForm.country,
        city: detailsForm.city,
        contactEmail: detailsForm.contactEmail,
        contactPerson: detailsForm.contactPerson,
        contactPhone: detailsForm.contactPhone,
        email: detailsForm.email,
        phone: detailsForm.phone,
        cvr: detailsForm.cvr,
        eori: detailsForm.eori,
        postCode: detailsForm.postCode,
        excludedManufacturers: customerOrganization.excludedManufacturers,
        shippingAddress: detailsForm.shippingAddress,
        shippingCity: detailsForm.shippingCity,
        shippingCountry: detailsForm.shippingCountry,
        shippingName: detailsForm.shippingName,
        shippingPostCode: detailsForm.shippingPostCode,
        shippingContactEmail: detailsForm.shippingContactEmail,
        shippingContactPerson: detailsForm.shippingContactPerson,
        shippingContactPhone: detailsForm.shippingContactPhone,
      })
      await profileRepository.updateProfile(mapToProfile(customerProfile, detailsForm))

      const refetchedProfile = await refetchProfile()
      const refetchedOrganization = await refetchOrganization();

      return {
        refetchedProfile, refetchedOrganization
      }
    }

    setLoading(true)
    return transaction()
    .then(({ refetchedProfile, refetchedOrganization }) => {
        setDetailsForm(mapToDetailsForm(refetchedProfile.data!, refetchedOrganization.data!))
        toast.success(t('toasts.profile_update_success'));
      })
      .catch(() => toast.error(t('toasts.profile_update_failed')))
      .finally(() => {
        setLoading(false)
      })
    }

  const initialDetailsForm: DetailsForm = {
    ...detailsForm
  }

  const initialEmailPreferenceform: EmailPreferenceForm = {
    ...detailsForm
  }

  return {
    resetProfile: resetProfile,
    saveProfileChanges: saveProfileHandle,
    updateEmailPreferences: updateEmailPreferences,
    updateLogoHandle: () => {},
    updateOrganizationDetails: updateDetails,
    updateWhiteLabel: () => {},
    updateOrderOptions: () => {},
    sections: sections,
    profile: profileConfigurations!,
    detailsForm: initialDetailsForm,
    emailPreferenceForm: initialEmailPreferenceform,
    loading: loading,
    transition: loading,
    isVendor: false,
    vendorId: customerOrganization?.parentId,
    isAdmin: false,
    logoOptions: undefined,
    updateCommission: undefined,
    commissionProfile: undefined
  }
}
