import ListHeader, { FilterChangeEvent, FilterRecipe } from './SubComponents/TableHeader/ListHeader'
import ListFooter from './SubComponents/TableFooter/TableFooter'
import './GenericTable.css'
import { ColumnField } from './SubComponents/Column'
import SortByFieldEvent from './Models/sortByFieldEvent'
import { PageChangeEvent } from './Models/pageChangeEvent'
import { SearchQueryEvent } from './Models/searchQueryEvent'
import { JSX } from 'react'
import { PageSizeEvent } from './SubComponents/TableFooter/PageSizeSelector'
import { MultiSelectEvent } from './Models/multiSelectEvent'
import { PageQuery } from '../../../utils/models/pageQuery'
import { JetSportBox } from '../BoxView/JetSportBox'
import { BoxFooter } from '../BoxView/BoxFooter'
import { BoxHeader2 } from '../BoxView/BoxHeader2'
import { BoxContent } from '../BoxView/BoxContent'
import { InvertedPanel } from '../BoxView/InvertedPanel'
import { ColumnSettings, ListFrame } from './SubComponents/ListFrame'
import ColumnHeader from './SubComponents/ColumnHeader'
import LoadingElement from '../loading-element/loading-element'
import { IdLookUps } from '../../../utils/models/idLookUp'

export interface TableData<TData extends object> {
  tableTitle: string
  columns: ColumnField[]
  onNewSort?: (sortByFiled: SortByFieldEvent) => void
  onNewPage?: (nextPageEvent: PageChangeEvent) => void
  onSearchQueryChange?: (nextSearchQuery: SearchQueryEvent) => void
  onRowSelected?: (event: MultiSelectEvent<TData>) => void
  onPageSizeChange?: (nextPageEvent: PageSizeEvent) => void
  center?: JSX.Element[] | JSX.Element
  right?: JSX.Element[] | JSX.Element
  multiSelect?: boolean
  selectedData?: IdLookUps<TData>
  isLoading: boolean
  lastPage: number
  query: PageQuery
  renderRow?: (columns: ColumnSettings, data: TData) => JSX.Element
  getKey?: (data: TData) => string | number
  data?: TData[]
  onRowClick?: (data: TData) => void
  getRowColor?: ((data: TData) => string) | string
  filters?: FilterRecipe<unknown>[]
  additionalFilters?: FilterRecipe<unknown>[]
  onFilterChange?: (event: FilterChangeEvent) => void
}

function FunctionalList<T extends object>({
  tableTitle,
  onRowClick = () => {},
  columns,
  onNewSort = () => {},
  onSearchQueryChange = () => {},
  onNewPage = () => {},
  multiSelect = false,
  center = <></>,
  right = <></>,
  selectedData = {},
  onRowSelected = () => {},
  onPageSizeChange = () => {},
  isLoading,
  lastPage,
  query,
  renderRow,
  data,
  getKey,
  getRowColor,
  filters,
  additionalFilters,
  onFilterChange
}: TableData<T>) {
  return (
    <>
      <JetSportBox>
        <BoxHeader2>
          <ListHeader
            query={query}
            tableTitle={tableTitle}
            center={center}
            right={right}
            onSearchQueryChange={onSearchQueryChange}
            filters={filters}
            additionalFilters={additionalFilters}
            onFilterChange={onFilterChange}
          />
        </BoxHeader2>
        <BoxContent className={'space-y-0'}>
          <InvertedPanel className={`pr-0 ${multiSelect ? 'pl-16' : 'pl-2'}`}>
            <ColumnHeader
              initialQuery={query}
              columns={columns}
              onNewSort={onNewSort}
              multiSelect={multiSelect}></ColumnHeader>
          </InvertedPanel>
          <div className={'mx-[-2rem] relative flex-1'}>
            {isLoading ? (
              <LoadingElement></LoadingElement>
            ) : (
              <ListFrame<T>
                onClick={onRowClick}
                columns={columns}
                data={data!}
                renderRow={renderRow!}
                selectedData={selectedData}
                onRowSelected={onRowSelected}
                multiSelect={multiSelect}
                isLoading={isLoading}
                getKey={getKey}
                rowColor={getRowColor}
              />
            )}
          </div>
        </BoxContent>
        <BoxFooter className={'justify-end'}>
          <ListFooter
            initialQuery={query}
            lastPage={lastPage}
            onPageSizeChange={onPageSizeChange}
            onNewPage={onNewPage}></ListFooter>
        </BoxFooter>
      </JetSportBox>
    </>
  )
}

export default FunctionalList
