import { FilterBy, PageQuery } from '../../../utils/models/pageQuery'
import { useSearchParams } from 'react-router-dom'
import { getInitialQuery } from '../functions/getInitialQuery.function'
import { useEffect, useState } from 'react'
import { setQueryParameters } from '../functions/setQueryParameters.function'
import SortByFieldEvent from '../../components/GenericTable/Models/sortByFieldEvent'
import { SearchQueryEvent } from '../../components/GenericTable/Models/searchQueryEvent'
import { PageSizeEvent } from '../../components/GenericTable/SubComponents/TableFooter/PageSizeSelector'
import { PageChangeEvent } from '../../components/GenericTable/Models/pageChangeEvent'
import { FilterChangeEvent } from '../../components/GenericTable/SubComponents/TableHeader/ListHeader'

export function usePageQuery(): [query: PageQuery, setQuery: QueryChanger] {
  const [queryParams, setQParams] = useSearchParams()
  const initialQuery = getInitialQuery(queryParams) ?? { page: { size: 25, index: 1 } }
  const [currentPageQuery, setPageQuery] = useState<PageQuery>(initialQuery)

  useEffect(() => {
    setQueryParameters(setQParams, currentPageQuery)
  }, [currentPageQuery])

  function handleSortByField(event: SortByFieldEvent) {
    setPageQuery(x => ({ ...x, sortBy: { field: event.value, sortDirection: event.sortDirection } }))
  }

  function handleSearchQueryChange(event: SearchQueryEvent) {
    setPageQuery(x => ({ ...x, searchTerm: event.searchTerm }))
  }

  function handlePageSizeChange(event: PageSizeEvent) {
    setPageQuery(x => ({ ...x, page: { ...x.page, size: event.nextSize } }))
  }

  function handleNewPage(event: PageChangeEvent) {
    setPageQuery(x => ({ ...x, page: { ...x.page, index: +event.value } }))
  }

  function handleFilterChange(event: FilterChangeEvent) {
    setPageQuery(x => {
      const newFilters: FilterBy[] = event.activeFilters.map(x => ({ field: x.key, value: '' + x.value }))

      return { ...x, filterBy: newFilters }
    })
  }

  const [queryChanger] = useState({
    handleSortByField,
    handleNewPage,
    handlePageSizeChange,
    handleSearchQueryChange,
    handleFilterChange,
    setPageQuery
  })

  return [currentPageQuery, queryChanger]
}

export interface QueryChanger {
  handleSortByField: (event: SortByFieldEvent) => void
  handleSearchQueryChange: (event: SearchQueryEvent) => void
  handlePageSizeChange: (event: PageSizeEvent) => void
  handleNewPage: (event: PageChangeEvent) => void
  handleFilterChange: (event: FilterChangeEvent) => void
  setPageQuery: (query: PageQuery) => void
}