import React, {  } from 'react'
import { Section } from '../../../components/StructureElements/Section'
import { SectionHeaderTypography } from '../../../components/Typographies/SectionHeaderTypography'
import { nameOf } from '../../../../utils/functions/ReflectionUtil'
import { CustomInputChangeEvent } from '../../../../utils/models/events/CustomInputChangeEvent'
import { SectionContent } from '../SectionContent'
import { SectionHeader } from '../SectionHeader'
import { CheckboxOptions } from '../../../components/Input/CheckboxOptions'
import { StrucCol } from '../../../components/StructureElements/StrucCol'

export interface EmailPreferenceForm {
  emailPreferenceNewDesign: boolean;
  emailPreferenceNewLogos: boolean;
  emailPreferenceNewOrder: boolean;
  emailPreferencePendingOrder: boolean;
  emailPreferenceOrder: boolean;
}

export interface EmailPreferenceTranslations {
  emailPreferenceNewDesignTranslation?: string;
  emailPreferenceNewLogosTranslation?: string;
  emailPreferenceNewOrderTranslation?: string;
  emailPreferencePendingOrderTranslation?: string;
  emailPreferenceOrderTranslation?: string;
}

export interface EmailPreferenceEditorViewModel {
  onFormChange: (event: React.ChangeEvent<HTMLInputElement> | CustomInputChangeEvent) => void
  disabled: boolean
  form: EmailPreferenceForm
  translations: EmailPreferenceTranslations
  initialState: boolean
  onToggle: () => void
}

export function EmailPreferenceEditor({disabled, onFormChange, initialState, form, translations, onToggle}: EmailPreferenceEditorViewModel) {
  return (
    <Section>
    <SectionHeader isCloseable initialState={initialState} onToggle={onToggle}>
      <SectionHeaderTypography>Email Preferences</SectionHeaderTypography></SectionHeader>
    <SectionContent close={!initialState}>
      <StrucCol size={3.5}>
        <CheckboxOptions
          header={'New Designs'}
          name={nameOf<EmailPreferenceForm>(x => x.emailPreferenceNewDesign)}
          checked={form.emailPreferenceNewDesign}
          onChange={x => onFormChange(x)}
          explain={translations.emailPreferenceNewDesignTranslation!}
          disabled={disabled}
        ></CheckboxOptions>
        <CheckboxOptions
          header={'New Logos'}
          name={nameOf<EmailPreferenceForm>(x => x.emailPreferenceNewLogos)}
          checked={form.emailPreferenceNewLogos}
          onChange={x => onFormChange(x)}
          explain={translations.emailPreferenceNewLogosTranslation!}
          disabled={disabled}
        ></CheckboxOptions>
        <CheckboxOptions
          header={'New Order'}
          name={nameOf<EmailPreferenceForm>(x => x.emailPreferenceNewOrder)}
          checked={form.emailPreferenceNewOrder}
          onChange={x => onFormChange(x)}
          explain={translations.emailPreferenceNewOrderTranslation!}
          disabled={disabled}
        ></CheckboxOptions>
        <CheckboxOptions
          header={'Pending Order'}
          name={nameOf<EmailPreferenceForm>(x => x.emailPreferencePendingOrder)}
          checked={form.emailPreferencePendingOrder}
          onChange={x => onFormChange(x)}
          explain={translations.emailPreferencePendingOrderTranslation!}
          disabled={disabled}
        ></CheckboxOptions>
        <CheckboxOptions
          header={'Order'}
          name={nameOf<EmailPreferenceForm>(x => x.emailPreferenceOrder)}
          checked={form.emailPreferenceOrder}
          onChange={x => onFormChange(x)}
          explain={translations.emailPreferenceOrderTranslation!}
          disabled={disabled}
        ></CheckboxOptions>
      </StrucCol>
    </SectionContent>
  </Section>)
}
