import { useEffect, useState } from 'react'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import { useApiClient } from '../../../../data/clients/ApiClient'
import { Design } from '../../../../core/models/entities/Design'
import { mapToDesign } from '../../../../data/models/mappers/mapDesign'
import { DesignModalIntent } from '../../design-list/components/design-details.component'

export function useOpenDesign(): {
  result: Design | undefined
  intent?: DesignModalIntent
  clearOpen: () => void
} {
  const navigate = useNavigate()
  const location = useLocation()
  const apiClient = useApiClient()
  const [searchParams] = useSearchParams()
  const id = searchParams.get('open')
  const orgId = searchParams.get('orgId')
  const [result, setResult] = useState<Design | undefined>()

  useEffect(() => {
    if (id && orgId) {
      apiClient
        .getDesign(Number(orgId), Number(id))
        .then(mapToDesign)
        .then((res) => {
          setResult(res)
        })
        .catch(() => {
          setResult(undefined)
        })
    } else {
      setResult(undefined)
      clearOpen()
    }
  }, [id, orgId])

  function clearOpen() {
    const queryParams = new URLSearchParams(location.search)
    queryParams.delete('open')
    queryParams.delete('orgId')
    navigate(`${location.pathname}?${queryParams}`)
  }

  if (!id || !orgId) {
    return {
      result: undefined,
      clearOpen
    }
  }

  return {
    result,
    clearOpen
  }
}
