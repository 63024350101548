import { VendorType } from '../../../core/models/entities/enums/OrganizationType'
import { VendorTypeResponse } from '../responses/OrganizationTypeResponse'

export function mapToVendorType(dto: VendorTypeResponse): VendorType | undefined {
  switch (dto) {
    case VendorTypeResponse.MainBranch:
      return VendorType.MainBranch
    case VendorTypeResponse.Branch:
      return VendorType.Branch
    default:
      return undefined
  }
}
