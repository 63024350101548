import { PrimaryButton } from '../../../../components/Buttons/PrimaryButton'
import { SecondaryButton } from '../../../../components/Buttons/SecondaryButton'
import LoadingElement from '../../../../components/loading-element/loading-element'
import { NumberPackage } from '../../../../../core/models/entities/NumberPackage'
import { NumberLogoPreview } from './number-logo-preview.component'
import { NumberPackageEditor } from './number-package-editor.component'

export interface NumberPlaceholderLogoCreate_VM {
  numberPackage?: NumberPackage
  onNumberPackage: (value: (((prevState: (NumberPackage | undefined)) => (NumberPackage | undefined)) | NumberPackage | undefined)) => void,
  numberPackages: NumberPackage[],
  onAddLogo: () => void
  onCancel: () => void
  canAdd: boolean
  getPreview: (numberPackage: NumberPackage) => Promise<string>
  isLoading: boolean
}

export function NumberPlaceholderLogoCreate(
  {
    numberPackage,
    onNumberPackage,
    numberPackages,
    onAddLogo,
    onCancel,
    canAdd,
    getPreview,
    isLoading
  }: NumberPlaceholderLogoCreate_VM) {

  return <div className={'flex flex-col h-full space-y-4 py-4'}>
    {isLoading && <LoadingElement transparent />}
    <NumberLogoPreview numberPackage={numberPackage} isValid={canAdd} getPreview={getPreview} />
    <div className={'flex-1'}>
      <NumberPackageEditor
        numberPackage={numberPackage}
        onNumberPackage={onNumberPackage}
        numberPackages={numberPackages}
      />
    </div>
    <PrimaryButton width={'full'} onClick={onAddLogo} disabled={!canAdd}>Add Number</PrimaryButton>
    <SecondaryButton width={'full'} onClick={onCancel}>Cancel</SecondaryButton>
  </div>
}