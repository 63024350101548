import { BaseLogo } from '../../../core/models/entities/BaseLogo'
import { BoxHeaderTypography } from '../Typographies/BoxHeaderTypography'
import React from 'react'
import { Design } from '../../../core/models/entities/Design'
import { Order } from '../../../core/models/entities/Order'

export function LogoTitleHeader({ logo }: { logo: BaseLogo }) {
  return <BoxHeaderTypography>
    Logo - <span className={'font-medium normal-case'}>{logo.product?.sku ?? 'no sku'} ({logo.title})</span>
  </BoxHeaderTypography>
}

export function DesignTitleHeader({ design }: { design: Design }) {
  return <BoxHeaderTypography>
    Design - <span className={'font-medium normal-case'}>{design.product?.mul ?? 'No MUL'} ({design.title})</span>
  </BoxHeaderTypography>
}

export function OrderTitleHeader({ order }: { order: Order }) {
  return <BoxHeaderTypography>
    Order - <span className={'font-medium normal-case'}>{order.id ?? 'No ID'} ({order.id})</span>
  </BoxHeaderTypography>
}