import { toRGBColorString } from '../../../utils/functions/ColorUtil'

//TODO: MaskColors should be fetched from backend instead of being defined in the frontend
export enum MaskColor {
  Background,
  Base,
  Zipper,
  Sewing,
  Button,
  Pocket,
  Collar,
  Crease,
  Reflector
}

export namespace MaskColor {
  export const maskColors = [
    MaskColor.Background,
    MaskColor.Base,
    MaskColor.Button,
    MaskColor.Collar,
    MaskColor.Pocket,
    MaskColor.Sewing,
    MaskColor.Zipper,
    MaskColor.Crease,
    MaskColor.Reflector
  ]

  export function toRGBColor(color: MaskColor): number[] {
    switch (color) {
      case MaskColor.Background: {
        return [53, 125, 34]
      }
      case MaskColor.Base: {
        return [0, 11, 123]
      }
      case MaskColor.Pocket: {
        return [212, 20, 4]
      }
      case MaskColor.Zipper: {
        return [255, 159, 15]
      }
      case MaskColor.Sewing: {
        return [54, 199, 148]
      }
      case MaskColor.Collar: {
        return [120, 69, 125]
      }
      case MaskColor.Button: {
        return [129, 127, 38]
      }
      case MaskColor.Crease: {
        return [38, 157, 255]
      }
      case MaskColor.Reflector: {
        return [227, 227, 227]
      }
    }
  }

  export function parse(rgb: number[]): MaskColor | undefined {
    maskColors.forEach((maskColor) => {
      if (toRGBString(maskColor) === toRGBColorString(rgb)) {
        return maskColor
      }
    })
    return undefined
  }

  export function toString(color: MaskColor): string {
    return MaskColor[color]
  }

  export function toRGBString(color: MaskColor): string {
    return toRGBColorString(toRGBColor(color))
  }
}
