import { useQuery, useQueryClient } from '@tanstack/react-query'
import { Profile } from '../../core/models/entities/Profile'
import { Resource } from '../../core/models/wrappers/Resource'
import { ProfileRepository } from '../../core/repositories/ProfileRepository'
import { useApiClient } from '../clients/ApiClient'
import { mapProfileToUpdateProfileDto, mapToProfile } from '../models/mappers/mapProfile'

function useProfileRepository(): ProfileRepository {
  const apiClient = useApiClient()
  const queryClient = useQueryClient()

  async function invalidateQueries() {
    await queryClient.refetchQueries({ queryKey: ['profile'], type: 'all', refetchPage: () => true })
  }

  function useProfile(orgId?: number): Resource<Profile> {
    return useQuery(['profile'], () => apiClient.getProfile(orgId).then((data) => mapToProfile(data)), {
      staleTime: 600000
    })
  }

  async function updateProfile(profile: Profile): Promise<Profile> {
    let updateProfile = mapProfileToUpdateProfileDto(profile);
    const result = await apiClient.updateProfile(updateProfile)
    await invalidateQueries()
    return mapToProfile(result)
  }

  async function updateProfileById(orgId: number, profile: Profile): Promise<Profile> {
    let updateProfile = mapProfileToUpdateProfileDto(profile);
    const result = await apiClient.updateProfile(updateProfile, orgId)
    await invalidateQueries()
    return mapToProfile(result)
  }

  async function clearProfile() {
    await invalidateQueries()
  }

  return {
    useProfile,
    updateProfile,
    updateProfileById,
    clearProfile
  }
}

export { useProfileRepository }
