import React from 'react'

export function VStack({ children, className, ...props }: React.HTMLProps<HTMLDivElement>) {
  return (
    <div className={`flex flex-col gap-1 ${className}`} {...props}>
      {children}
    </div>
  )
}

export function HStack({ children, className, ...props }: React.HTMLProps<HTMLDivElement>) {
  return (
    <div className={`flex flex-row gap-1 items-center ${className}`} {...props}>
      {children}
    </div>
  )
}
