import React, { JSX } from 'react'
import { Style } from '../../../../core/models/entities/Style'
import { nameOf } from '../../../../utils/functions/ReflectionUtil'
import { formatDate } from '../../../../utils/functions/DateUtil'
import { ColumnSettings } from '../../../components/GenericTable/SubComponents/ListFrame'
import { StrucCol } from '../../../components/StructureElements/StrucCol'
import { StrucRow } from '../../../components/StructureElements/StrucRow'
import { StyleStatusView } from '../../../components/Style/StyleStatus'
import { Tooltip } from '@mui/material'

export interface StyleListItem_VM {
  style: Style;
  columns: ColumnSettings;
  actionButton: JSX.Element | JSX.Element[]
}


export function StyleListItem({ style, columns, actionButton }: StyleListItem_VM) {
  return <StrucRow className={'h-20 py-4'}>
    <StrucCol size={columns[nameOf<Style>(x => x.thumbnailUrl)].weight}>
      <img alt={style.name} src={style.thumbnailUrl} className='max-h-9 max-w-[36px] h-auto w-auto' />
    </StrucCol>
    <StrucCol size={columns[nameOf<Style>(x => x.name)].weight}>
      <Tooltip title={`${style.name} - ${style.model}`} placement={'top-start'}>
        <span className={'whitespace-nowrap overflow-x-hidden overflow-ellipsis'}>{`${style.name} - ${style.model}`}</span>
      </Tooltip>
    </StrucCol>
    <StrucCol size={columns['manufacturer.id'].weight}>
      <Tooltip title={style.manufacturer.name} placement={'top-start'}>
        <span className={'whitespace-nowrap overflow-x-hidden overflow-ellipsis'}>{style.manufacturer.name}</span>
      </Tooltip>
    </StrucCol>
    <StrucCol size={columns[nameOf<Style>(x => x.status)].weight}>
      <StyleStatusView style={style} />
    </StrucCol>
    <StrucCol size={columns[nameOf<Style>(x => x.updatedAt)].weight}>
      {style.updatedAt ? formatDate(style.updatedAt) : '-'}
    </StrucCol>
    <StrucCol size={columns['actions'].weight} className={'my-[-0.5rem]'}>
      {actionButton}
    </StrucCol>
  </StrucRow>
}