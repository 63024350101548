import { Organization } from '../../../../core/models/entities/Organization'
import { PageQuery } from '../../../../utils/models/pageQuery'
import FunctionalList from '../../../components/GenericTable/FunctionalList'
import React from 'react'
import { QueryChanger } from '../../../shared/hooks/usePageQuery'
import { useFunctionalListOrganizationViewModel } from '../vmHooks/useFunctionalListOrganizationViewModel'

interface WrapperProps {
  organizations?: Organization[],
  isPageLoading: boolean,
  query: PageQuery
  queryChanger: QueryChanger,
  lastPage: number
}

export function CustomerListWrapper({
                                      organizations, isPageLoading, query, queryChanger, lastPage
                                    }: WrapperProps) {

  const vm = useFunctionalListOrganizationViewModel(isPageLoading, query, queryChanger, lastPage, organizations)
  return <FunctionalList<Organization>
    {...vm}
  />
}

