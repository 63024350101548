import React, { JSX, useState } from 'react'
import { ErrorView } from '../../components/error-view/ErrorView'
import { LoadingView } from '../../components/loading-view/LoadingView'
import { usePageQuery } from '../../shared/hooks/usePageQuery'
import { useRepositoriesContext } from '../../../contexts/RepositoriesContext'
import { PagedComponentState, usePagedComponentStateMachine } from '../../shared/hooks/usePageComponentStateMachine'
import { StyleListWrapper } from './subcomponents/style-list.wrapper'


export function StyleListScreen(): JSX.Element {
  const [query, queryChanger] = usePageQuery()
  const { state, dataTransition } = usePagedComponentStateMachine()
  const { styleRepository, manufacturerRepository } = useRepositoriesContext()
  const resStyle = styleRepository.useAllStyles(query)
  const [lastPage, setLastPage] = useState<number>(1)

  if (resStyle.data && resStyle.data.pageCount !== lastPage) {
    setLastPage(resStyle?.data?.pageCount ?? 1)
  }

  const {
    data: manufacturers,
    isSuccess: isManufacturersSuccess,
    isLoading: isManufacturersLoading,
    isError: isManufacturersError
  } = manufacturerRepository.useManufacturers()

  const isLoading = resStyle.isLoading || isManufacturersLoading
  const isSuccess = isManufacturersSuccess && resStyle.isSuccess
  const isError = isManufacturersError || resStyle.isError
  dataTransition(isLoading, isSuccess, isError)


  return state === PagedComponentState.Succeed || state === PagedComponentState.PageLoading ? <StyleListWrapper
      styles={resStyle.data?.results}
      query={query}
      queryChanger={queryChanger}
      isPageLoading={state === PagedComponentState.PageLoading}
      manufacturers={manufacturers!}
      lastPage={lastPage}
    />
    : state === PagedComponentState.Failed ?
      <ErrorView /> :
      <LoadingView />
}
