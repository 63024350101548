import { Style } from '../../../../core/models/entities/Style'
import { StrucRow } from '../../../components/StructureElements/StrucRow'
import { StrucCol } from '../../../components/StructureElements/StrucCol'
import { imageFallback } from '../../../shared/const/imageFallback'
import React from 'react'

export function StyleCard({ style, onClick, isSelected }: {
  style: Style,
  onClick: (style: Style) => void,
  isSelected: boolean
}) {
  return <div className={`cursor-pointer border h-20 rounded py-2 ${isSelected ? 'bg-gray-100' : ''}`}
              onClick={() => onClick(style)}>
    <StrucRow>
      <StrucCol size={3}>
        <img className={'object-contain h-16'} src={style.thumbnailUrl ?? imageFallback} />
      </StrucCol>
      <StrucCol className={'space-y-1'}>
        <div>
          <p><b>Model </b>{style.modelAndName}</p>
        </div>
        <div>
          <p><b>Manufacturer </b>{style.manufacturer.name}</p>
        </div>
      </StrucCol>
    </StrucRow>
  </div>
}