import React from 'react'
import { ButtonProp } from './ButtonProp'
import TouchRipple, {
  TouchRippleActions,
  TouchRippleProps
} from '@mui/material/ButtonBase/TouchRipple'

export const SecondaryButton = React.forwardRef<HTMLButtonElement, ButtonProp>((props, ref) => {
  const { className, children, ...rest } = props
  return (
    <ButtonRoot className={`button-secondary ${className ?? ''}`} ref={ref} {...rest}>
      {children}
    </ButtonRoot>
  )
})

export const WarnButton = React.forwardRef<HTMLButtonElement, ButtonProp>((props, ref) => {
  const { className, children, ...rest } = props
  return (
    <ButtonRoot className={`button-warn ${className ?? ''}`} ref={ref} {...rest}>
      {children}
    </ButtonRoot>
  )
})

export const SuccessButton = React.forwardRef<HTMLButtonElement, ButtonProp>((props, ref) => {
  const { className, children, ...rest } = props
  return (
    <ButtonRoot className={`button-success ${className ?? ''}`} ref={ref} {...rest}>
      {children}
    </ButtonRoot>
  )
})

export const ButtonRoot = React.forwardRef<HTMLButtonElement, ButtonProp>((props, ref) => {
  const { className, width, children, ...rest } = props

  let sizeClass
  switch (width) {
    case 'icon':
      sizeClass = 'button-icon'
      break
    case 'xs':
      sizeClass = 'button-xs'
      break
    case 'sm':
      sizeClass = 'but-32'
      break
    case 'md':
      sizeClass = 'but-48'
      break
    case 'full':
      sizeClass = 'w-full'
      break
    default:
      sizeClass = 'but-32'
      break
  }

  const rippleRef =
    React.useRef<React.ForwardRefRenderFunction<TouchRippleActions, TouchRippleProps>>(null)
  const onRippleStart = (e: React.MouseEvent<HTMLButtonElement>) => {
    // @ts-ignore
    rippleRef.current?.start(e)
  }
  const onRippleStop = (e: React.MouseEvent<HTMLButtonElement>) => {
    // @ts-ignore
    rippleRef.current?.stop(e)
  }

  return (
    <button
      ref={ref}
      onMouseDown={onRippleStart}
      onMouseUp={onRippleStop}
      className={`relative button-root ${sizeClass} ${className}`}
      {...rest}>
      {children}
      <TouchRipple ref={rippleRef} center={true} />
    </button>
  )
})
