import { useDesignContext } from '../contexts/design.context'
import { LogoPlacerHeaderBar } from '../components/logo-placer/logo-placer-header.bar'
import React from 'react'

export function LogoPlacerHeaderBarWrapper() {
  const { selectedLogo, lockedLogos, lockLogo, changeLogoWidth, changeLogoHeight, rotateLogo } = useDesignContext()
  const locked = selectedLogo ? lockedLogos[selectedLogo.inDesignId] : false
  const disabled = locked || !selectedLogo

  return <LogoPlacerHeaderBar
    disabled={disabled}
    locked={locked}
    onLockClick={(lock) => lockLogo(selectedLogo!, lock)}
    heightMM={selectedLogo?.heightMm ?? 0}
    widthMM={selectedLogo?.widthMm ?? 0}
    onWidthMMChange={v => changeLogoWidth(selectedLogo!, v)}
    onHeightMMChange={v => changeLogoHeight(selectedLogo!, v)}
    onRotate={d => rotateLogo(selectedLogo!, d)}
  />
}