import { Style } from '../../../../core/models/entities/Style'
import React, { useEffect, useState } from 'react'
import { StyleView } from '../../../../core/models/entities/StyleView'
import { ViewSelector } from '../../style-edit/subcomponents/style-edit-form/ViewSelector'

export function StyleViewer({ style }: { style: Style }) {
  const views = style.styleViews
  const [currentView, setCurrentView] = useState<StyleView>(views[0])

  useEffect(() => {
    setCurrentView(views[0])
  }, [style])

  return <>
    <div className='flex flex-1 w-full justify-center items-center py-8'>
      <div
        className='w-full h-full p-5 bg-contain bg-no-repeat bg-center'
        style={{ backgroundImage: `url(${currentView?.imageUrl})` }}
      />
    </div>
    <ViewSelector
      views={views}
      onSelectView={x => setCurrentView(x)}
      currentView={currentView}
    />
  </>
}