import { Manufacturer } from '../../../../core/models/entities/Manufacturer'
import { Organization } from '../../../../core/models/entities/Organization'
import { DesignCreateForm_VM } from '../components/design-create-form.component'
import { useTranslation } from 'react-i18next'
import { useErrorMessage } from '../../../hooks/useErrorMessage'
import { useProfileContext } from '../../../../contexts/ProfileContext'
import { useRepositoriesContext } from '../../../../contexts/RepositoriesContext'
import { useDesignActionHandler } from '../../design-list/hooks/useDesignActionHandler'
import { useState } from 'react'
import { PageQuery } from '../../../../utils/models/pageQuery'
import { CreateDesignForm } from '../models/CreateDesignForm'
import { Style } from '../../../../core/models/entities/Style'
import { toast } from 'react-toastify'
import { isEmpty } from '../../../../utils/functions/StringUtil'
import { Role } from '../../../../core/models/entities/Role'
import { isAllowed } from '../../../shared/functions/isAllowed'
import { Design } from '../../../../core/models/entities/Design'

export function useDesignCreateFormViewModel(manufacturers: Manufacturer[], customers: Organization[]): DesignCreateForm_VM {

  const { t } = useTranslation()
  const { tError } = useErrorMessage()
  const { role, organizationId } = useProfileContext()
  const { styleRepository } = useRepositoriesContext()
  const { handleCreateDesign, isLoading, handleGoToUpdateDesign, handleGoToDesignList } = useDesignActionHandler()
  const [pageQuery, setPageQuery] = useState<PageQuery>({ page: { index: 1, size: 50 } } as PageQuery)
  const [form, setForm] = useState<CreateDesignForm>({
    organization: role === Role.EndUser ? customers[0] : undefined,
    title: ''
  } as CreateDesignForm)

  const { data, fetchNextPage, isLoading: isStylesLoading } = styleRepository.useAllStyleAsInfinityQuery(pageQuery)
  let styles: Style[] = []
  if (data) {
    if (!form.style) {
      setForm(f => ({ ...f, style: data.pages[0].results[0] }))
    }
    for (const datum of data.pages) {
      styles = styles.concat(datum.results)
    }
  }

  function handleNexPage() {
    fetchNextPage().then()
  }

  function handleFilterOnManufacturer(manufacturer: Manufacturer | null) {
    setPageQuery(x => ({
      ...x,
      filterBy: manufacturer ? [{
        field: 'manufacturer.id',
        value: '' + manufacturer.id
      }] : []
    }))
  }

  function handleSearchQueryChange(query: string) {
    setPageQuery(x => ({
      ...x,
      searchTerm: query
    }))
  }

  function handleSelectCustomer(customer: Organization) {
    setForm(f => ({ ...f, organization: customer }))
  }

  function handleSelectStyle(style: Style) {
    setForm(f => ({ ...f, style: style }))
  }

  function handleChangeTitle(title: string) {
    setForm(x => ({ ...x, title: title }))
  }

  function handleSubmitForm() {

    if (!form) {
      return
    }

    if (!form.style) {
      toast.warn(t('toasts.no_styles_selected'))
      return
    }

    if (isEmpty(form.title)) {
      toast.warn('toasts.no_design_title')
      return
    }

    handleCreateDesign({
      title: form.title,
      customerId: form?.organization?.id ?? organizationId,
      styleId: form.style.id
    })
      .then((design) => {
        // @ts-ignore
        handleGoToUpdateDesign(design as Design)
      })
      .catch((e) => tError(e, 'Failed to create'))
  }

  function handleCancel() {
    handleGoToDesignList()
  }

  return {
    translate: t,
    styles,
    isStylesLoading,
    onLoadMoreStyles: handleNexPage,
    manufacturers: manufacturers,
    onFilterByManufacturer: handleFilterOnManufacturer,
    searchQuery: pageQuery.searchTerm ?? '',
    onSearchQueryChange: handleSearchQueryChange,
    selectedStyle: form.style,
    onSelectStyle: handleSelectStyle,
    showCustomerInfo: isAllowed(role!, Role.Vendor),
    customers,
    title: form.title,
    selectedCustomer: form.organization!,
    onChangeTitle: handleChangeTitle,
    onSelectCustomer: handleSelectCustomer,
    onSubmit: handleSubmitForm,
    disableSubmit: !validateForm(form),
    onCancel: handleCancel,
    isLoading: isLoading,
    role: role!
  }
}

function validateForm(form: CreateDesignForm): boolean {
  return !!form.style && !!form.title
}