import React, { useEffect, useState } from 'react'
import { ContentModal } from '../../components/content-modal/ContentModal'
import { LoadingView } from '../../components/loading-view/LoadingView'
import { ErrorView } from '../../components/error-view/ErrorView'
import { BaseLogo, ImageLogo } from '../../../core/models/entities/BaseLogo'
import { usePageQuery } from '../../shared/hooks/usePageQuery'
import { PagedComponentState, usePagedComponentStateMachine } from '../../shared/hooks/usePageComponentStateMachine'
import { useRepositoriesContext } from '../../../contexts/RepositoriesContext'
import { LogoListWrapper } from './subcomponents/logo-list.wrapper'
import { useProfileContext } from '../../../contexts/ProfileContext'
import { LogoDetailsWrapper } from './subcomponents/logo-details.wrapper'
import { useCustomerAndVendors } from '../../shared/hooks/useCustomerAndVendors'
import { RejectionNoteDialog } from '../../components/Dialogs/rejection-note-popup.component'
import { useDialog } from '../../components/Dialogs/dialog-base'
import { LogoTitleHeader } from '../../components/Logo/LogoTitleHeader'
import { useOpenLogo } from './vmHooks/useOpenLogo'

export function LogoListScreen(): React.ReactElement {
  const [query, queryChanger] = usePageQuery()
  const { state, dataTransition } = usePagedComponentStateMachine()
  const { logoRepository, organizationRepository } = useRepositoriesContext()
  const [lastPage, setLastPage] = useState<number>(1)
  const [logoSelected, setLogoSelected] = useState<BaseLogo | null>(null)
  const [logoRejection, setLogoRejection] = useState<BaseLogo | null>(null)
  const { profile, role } = useProfileContext()
  const rejectionNoteDialog = useDialog()
  const { result: openResult, clearOpen } = useOpenLogo()

  function showRejectionNote(logo: BaseLogo) {
    setLogoRejection(logo)
    rejectionNoteDialog.openDialog();
  }

  const {
    data: page,
    isSuccess: isLogosSuccess,
    isLoading: isLogosLoading,
    isError: isLogosError
  } = logoRepository.useAllLogos(query)
  
  const {
    data: options,
    isSuccess: isOptionsSuccess,
    isLoading: isOptionsLoading,
    isError: isOptionsError
  } = logoRepository.useOptions()

  const {
    customers,
    vendors,
    isError: isOrganizationsError,
    isSuccess: isOrganizationsSuccess,
    isLoading: isOrganizationsLoading
  } = useCustomerAndVendors(organizationRepository)


  if (page && page.pageCount !== lastPage) {
    setLastPage(page.pageCount)
  }

  useEffect(() => {
    if (openResult) {
      setLogoSelected(openResult)
    }
  }, [openResult])

  const isLoading = isOptionsLoading || isLogosLoading || isOrganizationsLoading
  const isSuccess = isLogosSuccess && isOptionsSuccess && isOrganizationsSuccess
  const isError = isOptionsError || isLogosError || isOrganizationsError
  dataTransition(isLoading, isSuccess, isError)

  return state === PagedComponentState.Succeed || state === PagedComponentState.PageLoading
    ? <>
      <LogoListWrapper
        query={query}
        queryChanger={queryChanger}
        isPageLoading={state === PagedComponentState.PageLoading}
        lastPage={lastPage}
        onSelectLogo={setLogoSelected}
        logos={page?.results}
        profile={profile!}
        customers={customers!}
        vendors={vendors}
        options={options!}
        onViewRejectNote={showRejectionNote}
      />
      {logoRejection && <RejectionNoteDialog
        title={
          <LogoTitleHeader logo={logoRejection!} />
        }
        dialogOptions={rejectionNoteDialog}
        approval={(logoRejection as ImageLogo).approval!}
        exclude={role!}
      />}
      <ContentModal open={!!logoSelected} onClose={() => {
        setLogoSelected(null)
        clearOpen()
      }}>
        <LogoDetailsWrapper logo={logoSelected!} onViewRejectNote={showRejectionNote} closeDialog={() => setLogoSelected(null)} />
      </ContentModal>
    </>
    : state === PagedComponentState.Failed ?
      <ErrorView /> :
      <LoadingView />
}


