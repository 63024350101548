import { useEffect, useState } from 'react'
import { useWhiteLabelContext } from '../../../../WhiteLabelContext'
import { useRepositoriesContext } from '../../../../contexts/RepositoriesContext'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'
import { useProfileRepository } from '../../../../data/repositories/useProfileRepository'
import { DetailsForm } from '../../customer-create/components/organization-details-form.component'
import { EmailPreferenceForm } from '../subcomponents/email-preference-editor.component'
import { MyProfileViewModel } from '../my-profile.component'
import { OrderOptionsForm } from '../subcomponents/order-options-editor.component'
import { WhiteLabelConfigurationsForm } from '../subcomponents/white-label-editor.components'
import { Organization } from '../../../../core/models/entities/Organization'
import { Profile } from '../../../../core/models/entities/Profile'
import { useLogoOptionsContext } from '../../../../contexts/LogoOptionsContext'
import { Commission, CommissionProfile } from '../../../../core/models/entities/Commission'
import { VendorType } from '../../../../core/models/entities/enums/OrganizationType'
import { dissmissToast, useErrorToast, useLoadingToast, useSuccessToast } from '../../../components/Toast/Toast'

interface Props {
  profile: Profile
  organization: Organization
}

export function useMyVendorProfile({ profile, organization }: Props): MyProfileViewModel {

  const { organizationRepository } = useRepositoriesContext()
  const [profileConfigurations, setProfileConfigurations] = useState<Profile>({ ...profile })
  // const [organization, setOrganization] = useState<Organization | undefined>(undefined)
  const [loading, setLoading] = useState<boolean>(true)
  const profileRepository = useProfileRepository()
  const { refetch: refetchProfile } = profileRepository.useProfile()
  const { t } = useTranslation()
  const { reset: resetWhiteLabel, updateWhiteLabelContext } = useWhiteLabelContext()
  const [transition, setTransition] = useState(false)
  const logoOptions = useLogoOptionsContext()
  const [commissionProfile, setCommissionProfile] = useState<CommissionProfile>(mapCommissionProfile(organization))

  useEffect(() => {
    setProfileConfigurations({ ...profile })
    setCommissionProfile(mapCommissionProfile(organization))
  }, [organization])

  function mapCommissionProfile(organization: Organization): CommissionProfile {
    return organization?.commission ?? {
      typeOnly: [] as Commission[],
      methods: [] as Commission[]
    } as CommissionProfile
  }

  useEffect(() => {
    updateWhiteLabelContext({ ...profileConfigurations?.whiteLabelConfigurations! })
  }, [profileConfigurations])

  useEffect(() => {
    organizationRepository.getVendorById(profile!.userOrganizationInformations.organizationId)
      .then(() => {
        // setOrganization(x)
        setLoading(false)
      }).catch(() => {
      toast.success(t('toasts.profile_load_failed'))
    })
  }, [])

  let sections = profile.userOrganizationInformations.vendorType === VendorType.MainBranch
    ? {
      details: { show: true, canEdit: false },
      whiteLabelling: { show: true, canEdit: true },
      emailPreferences: { show: true, canEdit: true },
      commissions: { show: true, canEdit: true }
    }
    : {
      details: { show: true, canEdit: false },
      whiteLabelling: { show: true, canEdit: false },
      emailPreferences: { show: true, canEdit: true },
      commissions: { show: true, canEdit: true }
    }

  const initialDetailsForm: DetailsForm = {
    postCode: organization?.postCode ?? '',
    address: organization?.address ?? '',
    city: organization?.country ?? '',
    country: organization?.country ?? '',
    contactEmail: organization?.contactEmail ?? '',
    contactPerson: organization?.contactPerson ?? '',
    contactPhone: organization?.contactPhone ?? '',
    cvr: organization?.cvr ?? '',
    email: organization?.email ?? '',
    name: organization?.name ?? '',
    phone: organization?.phone ?? ''
  }

  const initialEmailPreferenceform: EmailPreferenceForm = {
    emailPreferenceNewDesign: profileConfigurations?.emailPreferences?.emailPreferenceNewDesign ?? false,
    emailPreferenceNewLogos: profileConfigurations?.emailPreferences?.emailPreferenceNewLogos ?? false,
    emailPreferenceNewOrder: profileConfigurations?.emailPreferences?.emailPreferenceNewOrder ?? false,
    emailPreferencePendingOrder: profileConfigurations?.emailPreferences?.emailPreferencePendingOrder ?? false,
    emailPreferenceOrder: profileConfigurations?.emailPreferences?.emailPreferenceOrder ?? false,
  }

  function updateLogo(profile: Profile) {
    setProfileConfigurations({
      ...profileConfigurations!, whiteLabelConfigurations: {
        ...profileConfigurations!.whiteLabelConfigurations,
        logoUrl: profile.whiteLabelConfigurations.logoUrl
      }
    })
  }

  function updateOrganizationLogoHandle(file: File) {
    const toast = useLoadingToast(`Uploading ${file.name}`)
    organizationRepository.updateOrganizationLogo(profile!.userOrganizationInformations.organizationId, file).then(() => {
      refetchProfile().then(p => {
        updateLogo(p.data!)
        useSuccessToast(t('toasts.logo_uploaded_successfully'))
      })
    }).catch((() => {
      useErrorToast(t('toasts.logo_uploaded_unsuccessfully'))
    })).finally(() => dissmissToast(toast))
    
  }

  function updateWhiteLabel(form: WhiteLabelConfigurationsForm) {
    setProfileConfigurations({
      ...profileConfigurations!, whiteLabelConfigurations: {
        ...profileConfigurations!.whiteLabelConfigurations,
        colorHex: form.colorHex,
        whiteLabelContactEmail: form.whiteLabelContactEmail,
        whiteLabelContactPhone: form.whiteLabelContactPhone,
        whiteLabelCopyrightName: form.whiteLabelCopyrightName,
        whiteLabelVendorName: form.whiteLabelVendorName,
        whiteLabelWebsiteLink: form.whiteLabelWebsiteLink
      }
    })
  }

  function updateDetails(form: DetailsForm) {
    setProfileConfigurations({
      ...profileConfigurations!, detailedConfigurations: {
        ...profileConfigurations!.detailedConfigurations,
        contactEmail: form.contactEmail,
        contactPerson: form.contactPerson,
        contactPhone: form.contactPhone,
        organizationAddress: form.address,
        organizationCountry: form.country,
        organizationEmail: form.email,
        organizationCVR: form.cvr,
        organizationPhone: form.phone
      },
      userOrganizationInformations: {
        ...profileConfigurations!.userOrganizationInformations,
        organizationName: form.name
      }
    })
  }

  function updateOrderOptions(form: OrderOptionsForm) {
    setProfileConfigurations({
      ...profileConfigurations!, orderOptions: {
        orderOptionsTermsOfDelivery: form.orderOptionsTermsOfDelivery,
        orderOptionsTermsOfStandardPackaging: form.orderOptionsTermsOfStandardPackaging,
        orderOptionsTermsOfSpecificPackaging: form.orderOptionsTermsOfSpecificPackaging,
        orderOptionsDeliveryCost: form.orderOptionsDeliveryCost ?? 0
      }
    })
  }

  function updateEmailPreferences(form: EmailPreferenceForm) {
    setProfileConfigurations({
      ...profileConfigurations!, emailPreferences: {
        emailPreferenceNewDesign: form.emailPreferenceNewDesign,
        emailPreferenceNewLogos: form.emailPreferenceNewLogos,
        emailPreferenceNewOrder: form.emailPreferenceNewOrder,
        emailPreferencePendingOrder: form.emailPreferencePendingOrder,
        emailPreferenceOrder: form.emailPreferenceOrder
      }
    })
  }

  function resetProfile() {
    setProfileConfigurations(profile)
    resetWhiteLabel()
  }

  function saveProfileHandle() {
    setTransition(true)
    const toast = useLoadingToast(`Saving`)
    profileRepository.updateProfile(profileConfigurations!).then(() => {
      organizationRepository.setCommissionProfile(profile.userOrganizationInformations.organizationId, commissionProfile!).then((_) =>{
        refetchProfile().then(() => {
          
          useSuccessToast(t('toasts.profile_update_success'))
        }).catch(() =>{
          useErrorToast(t('toasts.profile_update_failed'))
        })
      })
    }).catch(() => {
      useErrorToast(t('toasts.commission_update_failed'))
    }).finally(() => {
      dissmissToast(toast)
      setTransition(false)
  })
  }

  return {
    resetProfile: resetProfile,
    saveProfileChanges: saveProfileHandle,
    updateEmailPreferences: updateEmailPreferences,
    updateLogoHandle: updateOrganizationLogoHandle,
    updateOrganizationDetails: updateDetails,
    updateWhiteLabel: updateWhiteLabel,
    updateOrderOptions: updateOrderOptions,
    sections: sections,
    profile: profileConfigurations!,
    detailsForm: initialDetailsForm,
    emailPreferenceForm: initialEmailPreferenceform,
    loading: loading,
    transition: transition,
    isVendor: true,
    vendorId: organization?.id,
    isAdmin: false,
    logoOptions: logoOptions,
    updateCommission: setCommissionProfile,
    commissionProfile: commissionProfile
  }
}
