import { InputWrapper } from '../../../../components/Input/InputWrapper'
import { InputLabel } from '../../../../components/Input/InputLabel'
import { KitMakerAutoComplete, KitMakerAutoCompleteItem } from '../../../../components/Input/KitMakerAutoComplete'
import { NumberPackage } from '../../../../../core/models/entities/NumberPackage'

interface NumberPackageEditor_VM {
  numberPackages: NumberPackage[]
  numberPackage?: NumberPackage
  className?: string
  onNumberPackage: (numberPackage: NumberPackage) => void
}

export function NumberPackageEditor(
  {
    className,
    numberPackages, numberPackage, onNumberPackage
  }: NumberPackageEditor_VM) {

  return <div className={'space-y-4 ' + className}>
    <InputWrapper>
      <InputLabel>NUMBER PACKAGE</InputLabel>
      <NumberPackageAutoComplete numberPackages={numberPackages} onNumberPackageChange={onNumberPackage} value={numberPackage} disabled={false} />
    </InputWrapper>
  </div>
}


function NumberPackageAutoComplete({ numberPackages, disabled, onNumberPackageChange, value }: {
  numberPackages: NumberPackage[],
  onNumberPackageChange: (numberPackage: NumberPackage) => void
  disabled: boolean
  value: NumberPackage | undefined
}) {
  const sortedList = numberPackages.sort((a, b) => a.height - b.height)
  const mappedList = sortedList.map(x => ({ key: x.serial, name: x.name }))
  return <>
    <KitMakerAutoComplete
      subject={"Number Package"}
      items={mappedList}
      disabled={disabled}
      value={value ? { key: value.serial, name: value.name } : undefined}
      onItemChange={(item: KitMakerAutoCompleteItem) => { onNumberPackageChange(numberPackages.find(x => x.serial === item.key)!) }}
    />
  </>
}
