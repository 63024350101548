import { createContext, useContext, useEffect, useState } from "react"
import { Organization } from "../core/models/entities/Organization"
import { useRepositoriesContext } from "./RepositoriesContext"

interface OrganizationImpersonation {
  vendor?: Organization
  organizationId?: number
  setVendor: (vendorId?: number) => void,
  isLoading: boolean
}

const OrganizationImpersonationContext = createContext<OrganizationImpersonation | undefined>(undefined)

interface Props {
  children: React.ReactNode
}

const OrganizationImpersionationProvider = ({ children }: Props): React.ReactElement => {
  const [vendorId, setVendorId] = useState<number | undefined>(undefined)
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const { organizationRepository } = useRepositoriesContext()
  const { data: vendor, refetch: refetchVendor } = organizationRepository.useVendor(vendorId)

  useEffect(() => {
    setIsLoading(true)
    refetchVendor()
      .finally(() => {
        setIsLoading(false)
      })
  }, [vendorId])

  function setVendor(vendorId: number | undefined) {
    setVendorId(vendorId)
  }

  return (
    <OrganizationImpersonationContext.Provider
      value={{
        vendor,
        organizationId: vendorId,
        setVendor,
        isLoading
      }}
    >
      {children}
    </OrganizationImpersonationContext.Provider>
  );
};

function useOrganizationImpersonationContext(): OrganizationImpersonation {
  const context = useContext(OrganizationImpersonationContext)
  if (context === undefined) {
    throw new Error('useOrganizationImpersonationContext must be used within a AdminImpersionationProvider')
  }
  return context
}

export { OrganizationImpersionationProvider, useOrganizationImpersonationContext } 