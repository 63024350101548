import { useState } from 'react'
import { Profile } from '../../../../core/models/entities/Profile'
import { WhiteLabelConfigurationsForm, WhiteLabelEditor, WhiteLabelEditorViewModel } from './white-label-editor.components'
import { CustomInputChangeEvent } from '../../../../utils/models/events/CustomInputChangeEvent'

interface WrapperProps {
  profile: Profile
  onFormChange: (form: WhiteLabelConfigurationsForm) => void
  onLogoChange: (file: File) => void
  disabled: boolean
  initialState: boolean
}

export function WhiteLabelEditorWrapper({ profile, onFormChange: onFormChange, disabled, initialState, onLogoChange }: WrapperProps) {

  const vm = useViewModel(profile, onFormChange, onLogoChange, disabled, initialState)
  return <WhiteLabelEditor {...vm} />;
}

export function useViewModel(profile: Profile,
  onFormChange: (form: WhiteLabelConfigurationsForm) => void,
  onLogoChange: (file: File) => void,
  disabled: boolean,
  initialState: boolean): WhiteLabelEditorViewModel {

  const [toggleState, setToggleState] = useState(initialState);

  const form = {
    colorHex: profile.whiteLabelConfigurations?.colorHex ?? "#000000",
    whiteLabelCopyrightName: profile.whiteLabelConfigurations?.whiteLabelCopyrightName ?? '',
    whiteLabelContactEmail: profile.whiteLabelConfigurations?.whiteLabelContactEmail ?? '',
    whiteLabelContactPhone: profile.whiteLabelConfigurations?.whiteLabelContactPhone ?? '',
    whiteLabelVendorName: profile.whiteLabelConfigurations?.whiteLabelVendorName ?? '',
    whiteLabelWebsiteLink: profile.whiteLabelConfigurations?.whiteLabelWebsiteLink ?? ''
  };

  const initialLogo = profile.whiteLabelConfigurations?.logoUrl;

  function handleFormChange(event: React.ChangeEvent<HTMLInputElement> | CustomInputChangeEvent) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    //@ts-ignore
    form[name] = value;

    onFormChange(form);
  }

  const toggle = () => { setToggleState(!toggleState) }

  function uploadFile(file: File) {
    onLogoChange(file);
  }

  return {
    disabled,
    form,
    onFormColorHexChange: onFormChange,
    onFormChange: handleFormChange,
    initialState: toggleState,
    onToggle: toggle,
    onLogoChange: uploadFile,
    initialLogo: initialLogo!
  };
}