import { InteractionStatus } from '@azure/msal-browser'
import { useIsAuthenticated, useMsal } from '@azure/msal-react'
import { useEffect, useState } from 'react'
import { loginRequest } from '../../../../AuthConfig'
import { useProfileContext } from '../../../../contexts/ProfileContext'
import { Role } from '../../../../core/models/entities/Role'
import { useProfileRepository } from '../../../../data/repositories/useProfileRepository'
import { useCartContext } from '../../../../contexts/CartContext'

export interface NavigationBarViewModel {
  login: () => void
  logout: () => void
  userRole?: Role
  companyLogo?: string
}

export function useNavigationBarViewModel(): NavigationBarViewModel {
  const { instance, inProgress } = useMsal()
  const isAuthenticated = useIsAuthenticated()
  const { profile } = useProfileContext()
  const { clearProfile } = useProfileRepository()
  const { clearItems } = useCartContext()
  const [role, setRole] = useState<Role>()
  const [companyLogo] = useState<string>()

  useEffect(() => {
    if (isAuthenticated && profile) {
      setRole(profile.userOrganizationInformations.role)
    }
  }, [isAuthenticated, profile])

  function login() {
    if (!isAuthenticated && inProgress === InteractionStatus.None) {
      instance.loginRedirect(loginRequest)
    }
  }

  function logout() {
    if (isAuthenticated && inProgress === InteractionStatus.None) {
      instance.logout()
      clearProfile()
      clearItems()
    }
  }

  return {
    login,
    logout,
    userRole: role,
    companyLogo,
    // customer
  }
}
