import { Tooltip } from '@mui/material';
import { Organization } from '../../../core/models/entities/Organization';

export default function CustomerTooltip({ customer: { name, navNumber } }: { customer: Organization }) {
    return (
        <Tooltip
            title={`${name} (${navNumber})`}
            placement={'top-start'}>
            <span className={'whitespace-nowrap overflow-x-hidden overflow-ellipsis'}>
                {name}
            </span>
        </Tooltip>
    );
}