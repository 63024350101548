import { useEffect, useRef, useState } from 'react'
import { useDebounce } from 'use-debounce'
import interact from 'interactjs'
import { ActionName, ActionProps, Interactable, InteractEvent } from '@interactjs/types'
import { DesignLogo } from '../../../../core/models/entities/Design'
import { Marker } from '../design-view/DesignView.vm'
import { LogoType } from '../../../../core/models/entities/BaseLogo'

export interface MarkerInfo {
  x: number,
  y: number,
  height: number,
  width: number,
  angleRad: number,
  xRect: number,
  yRect: number
}

export interface LogoMarker_VM {
  marker: Marker,
  isLocked: boolean
  markerRef: React.RefObject<HTMLDivElement>
  logoUrl: string
  logoType: LogoType
  isActive: boolean,
  onSelect: () => void,
  angle: number,
}



export function useLogoMarkerViewModel(
  marker: Marker,
  isLocked: boolean,
  onLogoManipulation: (
    marker: Marker,
    x: number,
    y: number,
    logoWidth: number,
    logoHeight: number,
    rotation: number,
    xRect: number,
    yRect: number
  ) => void,
  isActive: boolean,
  onSelect: (logo: DesignLogo['inDesignId']) => void,
  viewCords: { x: number, y: number }
): LogoMarker_VM {
  const markerRef = useRef<HTMLDivElement>(null)
  const markerInfo = useRef<MarkerInfo>({
    x: marker.x,
    angleRad: marker.angleRad,
    y: marker.y,
    height: marker.heightPx,
    width: marker.widthPx,
    xRect: 0, yRect: 0
  })

  const [refreshLogoValue, setRefreshLogoValue] = useState<number>(0)
  const [refreshLogo] = useDebounce(refreshLogoValue, 200)
  const [angle, setAngle] = useState<number>(marker.angleRad)

  setupInteractJs()

  useEffect(() => {
    if (markerRef.current) {
      manipulateLogo(marker.x, marker.y, marker.widthPx, marker.heightPx, marker.angleRad)
    }
  }, [markerRef, marker.angleRad])

  useEffect(() => {
    if (refreshLogo) {
      if (markerRef.current !== null) {
        const { x, y, width, height, angleRad, yRect, xRect } = markerInfo.current
        onLogoManipulation(marker, x, y, width, height, angleRad, xRect, yRect)
      }
    }
  }, [refreshLogo])

  function manipulateLogo(x: number, y: number, width: number, height: number, angle: number) {
    const target = markerRef.current
    if (!target) {
      return
    }
    const rect = target.getBoundingClientRect()
    const scaledX = rect.x - viewCords.x
    const scaledY = rect.y - viewCords.y

    Object.assign(target.style, {
      width: `${width}px`,
      height: `${height}px`,
      transform: `translate(${x}px, ${y}px) rotate(${angle}rad)`
    })
    Object.assign(target.dataset, { x: x, y: y })

    markerInfo.current.height = height
    markerInfo.current.width = width
    markerInfo.current.angleRad = angle
    markerInfo.current.x = scaledX
    markerInfo.current.y = scaledY
  }

  function parseDataset(dataset: DOMStringMap) {
    const { x, y } = dataset

    return {
      x: parseFloat(x || '0'),
      y: parseFloat(y || '0')
    }
  }

  function setupInteractJs() {
    const interactor = interact(`#box-${marker.id}`)

    const enabled = !isLocked
    interactor.draggable({
      inertia: false,
      modifiers: [
        interact.modifiers.restrictRect({
          restriction: 'parent',
          enabled: true
        })
      ],
      listeners: {
        move: (event: InteractEvent) => {
          if (!isActive) {
            onSelect(marker.id)
            return
          }
          onDragListener(event)
          onLogoManipulationEnd()
        }
      },
      enabled
    })

    /*if (logoComp.designLogo.logoType === LogoType.NORMAL) {
      interactor.resizable({
        edges: {
          bottom: `#item_resizer-${logoComp.designLogo.inDesignId}`,
          right: `#item_resizer-${logoComp.designLogo.inDesignId}`
        },
        listeners: {
          move: function(event: ResizeEvent) {
            onResizeListener(event)
            onLogoManipulationEnd()
          }
        },
        modifiers: [
          interact.modifiers.aspectRatio({
            ratio: width / height,
            // also restrict the size by nesting another modifier
            modifiers: [interact.modifiers.restrictSize({ max: 'parent' })]
          })
        ]
      })
    }*/

    interact(`#item_rotater-${marker.id}`).draggable({
      cursorChecker: (
        action: ActionProps<ActionName>,
        interactable: Interactable,
        element: Element,
        interacting: boolean
      ) => {
        if (interacting) {
          return 'grabbing'
        } else {
          return 'grab'
        }
      },
      onstart: (event: InteractEvent) => onRotateStart(event),
      /*onmove: (event: InteractEvent) => onRotateListener(event),*/
      onend: () => onLogoManipulationEnd()
    })
  }

  /*function onResizeListener(event: ResizeEvent) {
    const { x, y } = parseDataset(event.target.dataset)

    if (event.rect.width > minWidth && event.rect.height > minHeight) {
      manipulateLogo(x, y, Math.round(event.rect.width), Math.round(event.rect.height), marker.angleRad)
    }
  }*/

  function onDragListener(event: InteractEvent) {
    const { x, y } = parseDataset(event.target.dataset)
    manipulateLogo(x + event.dx, y + event.dy, marker.widthPx, marker.heightPx, marker.angleRad)
  }

  function onRotateStart(event: InteractEvent) {
    const parent = event.target.parentElement

    if (!parent) {
      return
    }

    const rect = parent.getBoundingClientRect()

    // store the center as the element has css `transform-origin: center center`
    parent.setAttribute('data-center-x', `${rect.left + rect.width / 2}`)
    parent.setAttribute('data-center-y', `${rect.top + rect.height / 2}`)
    // get the angle of the element when the drag starts
    parent.setAttribute('data-angle', `${getDragAngle(event)}`)
  }

  /*function onRotateListener(event: InteractEvent) {
    const parent = event.target.parentElement

    if (!parent) {
      return
    }

    const { x, y } = parseDataset(parent.dataset)

    const angle = getDragAngle(event)

    if (angle) {
      const fixedAngle = angle + startingRotation
      manipulateLogo(x, y, width, height, fixedAngle)
      setRotation(fixedAngle)
    }
  }*/

  function getDragAngle(event: InteractEvent): number | null {
    var parent = event.target.parentElement

    if (!parent) {
      return null
    }

    const dataAngle = parent.getAttribute('data-angle')
    const dataCenterX = parent.getAttribute('data-center-x')
    const dataCenterY = parent.getAttribute('data-center-y')

    if (!dataAngle || !dataCenterX || !dataCenterY) {
      return null
    }

    const startAngle = parseFloat(dataAngle) || 0
    const centerX = parseFloat(dataCenterX) || 0
    const centerY = parseFloat(dataCenterY) || 0

    var angle = Math.atan2(centerY - event.clientY, centerX - event.clientX)
    return angle - startAngle
  }

  function onLogoManipulationEnd() {
    if (markerRef.current !== null) {
      setRefreshLogoValue(refreshLogoValue + 1)
    }
  }

  function handleSelect() {
    onSelect(marker.id)
  }

  return {
    marker,
    markerRef,
    isLocked,
    logoUrl: marker.logo.displayImage.lowResUrl,
    logoType: LogoType.ImageLogo,
    onSelect: handleSelect,
    isActive,
    angle
  }
}

