export function calculateActualPpcm(
  baseLength: number,
  newLength: number,
  basePpcm: number
): number {
  const sizeDiff = newLength / baseLength
  return basePpcm * sizeDiff
}

export function calculateSize(length: number, ppcm: number): number {
  return Math.round((length / ppcm) * 10)
}
