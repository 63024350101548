import React from 'react'
import { useParams } from 'react-router-dom'
import { NotFoundPage } from '../not-found/NotFoundPage'
import { MaskEditorPage } from './MaskEditorPage'
import { useMaskEditorViewModel } from './useMaskEditorViewModel'

export function MaskEditorScreen(): React.ReactElement {
  const { styleId } = useParams()

  if (styleId === undefined || isNaN(+styleId)) {
    return <NotFoundPage />
  } else {
    const viewModel = useMaskEditorViewModel(Number(styleId))
    return <MaskEditorPage viewModel={viewModel} />
  }
}
