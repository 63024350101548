import { Manufacturer } from '../../../core/models/entities/Manufacturer'
import { ManufacturerDto } from '../responses/ManufacturerDto'

export function mapToManufacturer(manufacturerDto: ManufacturerDto): Manufacturer {
  return {
    id: manufacturerDto.id,
    guid: manufacturerDto.guid,
    name: manufacturerDto.name
  }
}
