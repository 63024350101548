import { useNumberPlaceholderLogoCreateViewModel } from '../vmHooks/useNumberPlaceholderLogoCreateViewModel'
import { NumberPlaceholderLogoCreate } from '../components/panel/number-placeholder-logo-create.component'
import { useDesignContext } from '../contexts/design.context'
import { useLogoRepository } from '../../../../data/repositories/useLogoRepository'
import { NumberPackage } from '../../../../core/models/entities/NumberPackage'
import { ComponentState, useComponentStateMachine } from '../../../shared/hooks/useComponentStateMachine'
import { LoadingView } from '../../../components/loading-view/LoadingView'
import { ErrorView } from '../../../components/error-view/ErrorView'

export function NumberPlaceholderLogoCreateWrapper() {
  const { useNumberPackages } = useLogoRepository()
  const { design } = useDesignContext()
  const { data, isLoading, isError, isSuccess } = useNumberPackages(design.customerId)

  const { state, dataTransition } = useComponentStateMachine()
  dataTransition(isLoading, isSuccess, isError)

  return state === ComponentState.Succeed
    ? <RenderNumberPlaceholderLogoCreate numberPackages={data!} />
    : state === ComponentState.Loading
      ? <LoadingView />
      : <ErrorView />
}

function RenderNumberPlaceholderLogoCreate({ numberPackages }: { numberPackages: NumberPackage[] }) {
  const vm = useNumberPlaceholderLogoCreateViewModel(numberPackages)
  return <NumberPlaceholderLogoCreate {...vm} />
}