import React, { ReactElement } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faX } from '@fortawesome/free-solid-svg-icons'
import { useWhiteLabelContext } from '../../../WhiteLabelContext';

interface ModalProps {
  open: boolean;
  onClose: () => void
  children: ReactElement<any, any> // eslint-disable-line
}

type ContentModalProps = React.HTMLProps<HTMLDivElement>
const Modal = React.forwardRef<HTMLDivElement, ContentModalProps>((modalProps, ref) => {
  return (
    <div
      ref={ref}
      className='absolute z-30'
      aria-labelledby='modal-title'
      role='dialog'
      aria-modal='true'>
      <div className='fixed inset-0 bg-gray-300 bg-opacity-75 transition-opacity'></div>

      <div className='fixed inset-0 z-10 overflow-y-auto'>
        <div className='flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0'>
          <div
            className='relative overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 inline-block'>
            {modalProps.children}
          </div>
        </div>
      </div>
    </div>
  )
})

export function ContentModal({ children, onClose, open }: ModalProps) {
  const { whiteLabel } = useWhiteLabelContext()
  
  return (
    open
      ? (
        <Modal>
          {/* @ts-ignore */}
          <div className={'flex flex-col relative'} style={{ '--primary': whiteLabel.colorHex, '--primary-bg': `${whiteLabel.colorHex}10` }}>
            <div className={'absolute z-10 right-0'}>
              <button onClick={onClose} className={'!h-8 !w-8 z-50'}>
                <FontAwesomeIcon className={'text-sm'} style={{ fontSize: 10, lineHeight: 10 }} icon={faX} />
              </button>
            </div>
            {children}
          </div>
        </Modal>
      )
      : <></>
  )
}