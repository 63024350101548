import { BaseLogo, ImageLogo, LogoEditStatus, LogoType } from '../../../core/models/entities/BaseLogo'
import { ApprovalDisplay } from '../Approval/ApprovalDisplay'

export function LogoStatusElement({ logo }: { logo: BaseLogo }) {

  if (logo.logoType !== LogoType.ImageLogo) {
    return <p className={`mb-1 font-semibold text-xs text-status-success`}>APPROVED</p>
  }
  const imageLogo = logo as ImageLogo
  switch (imageLogo.status) {
    case LogoEditStatus.Unknown:
      return <p className={`mb-1 font-semibold text-xs text-gray-600`}>UNKNOWN</p>
    case LogoEditStatus.Draft:
      return <p className={`mb-1 font-semibold text-xs text-orange-400`}>DRAFT</p>
    case LogoEditStatus.Rejected:
      return <ApprovalDisplay approval={imageLogo.approval!} />
    case LogoEditStatus.Validating:
      return <ApprovalDisplay approval={imageLogo.approval!} />
    case LogoEditStatus.Done:
      return <p className={`mb-1 font-semibold text-xs text-status-success`}>APPROVED</p>
  }
}


