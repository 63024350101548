import { Section } from '../../../components/StructureElements/Section'
import { SectionHeaderTypography } from '../../../components/Typographies/SectionHeaderTypography'
import React from 'react'
import { nameOf } from '../../../../utils/functions/ReflectionUtil'
import { InputWrapper } from '../../../components/Input/InputWrapper'
import { SectionContent } from '../SectionContent'
import { SectionHeader } from '../SectionHeader'
import { CustomInputChangeEvent } from '../../../../utils/models/events/CustomInputChangeEvent'
import { InputLabel } from '../../../components/Input/InputLabel'
import { StrucRow } from '../../../components/StructureElements/StrucRow'
import { StrucCol } from '../../../components/StructureElements/StrucCol'
import { InputOrderOptionsEditor } from './input-order-options-editor.component'
import { Stepper } from '../../../components/Buttons/Stepper'

export interface OrderOptionsForm {
  orderOptionsTermsOfDelivery: string
  orderOptionsTermsOfStandardPackaging: string
  orderOptionsTermsOfSpecificPackaging: string
  orderOptionsDeliveryCost?: number
}

export interface OrderOptionsEditorViewModel {
  onFormChange: (event: React.ChangeEvent<HTMLInputElement> | CustomInputChangeEvent) => void
  onDeliveryCostChange: (v: number) => void
  disabled: boolean
  form: OrderOptionsForm
  initialState: boolean
  onToggle: () => void
}

export function OrderOptionsEditor({ disabled, onFormChange, initialState, form, onToggle, onDeliveryCostChange }: OrderOptionsEditorViewModel) {
  return (
    <Section>
      <SectionHeader isCloseable initialState={initialState} onToggle={onToggle}>
        <SectionHeaderTypography>Order Options</SectionHeaderTypography></SectionHeader>
      <SectionContent close={!initialState}>
        <StrucRow>
          <StrucCol size={4.5}>
            <InputOrderOptionsEditor
              disabled={disabled}
              initialState={false}
              inputLabel='Terms of "Delivery before 12:00 PM with GLS"'
              nameOfOption={nameOf<OrderOptionsForm>(lf => lf.orderOptionsTermsOfDelivery)}
              onFormChange={onFormChange}
              value={form.orderOptionsTermsOfDelivery} />
            <InputOrderOptionsEditor
              disabled={disabled}
              initialState={false}
              inputLabel='Terms of "Standard bag packaging"'
              nameOfOption={nameOf<OrderOptionsForm>(lf => lf.orderOptionsTermsOfStandardPackaging)}
              onFormChange={onFormChange}
              value={form.orderOptionsTermsOfStandardPackaging} />
            <InputOrderOptionsEditor
              disabled={disabled}
              initialState={false}
              inputLabel='Terms of "Specific packaging"'
              nameOfOption={nameOf<OrderOptionsForm>(lf => lf.orderOptionsTermsOfSpecificPackaging)}
              onFormChange={onFormChange}
              value={form.orderOptionsTermsOfSpecificPackaging} />
          </StrucCol>
          <StrucCol size={3} offset={0.5}>
            <InputWrapper>
              <InputLabel>Cost of "Delivery before 12:00 PM with GLS"</InputLabel>
              <Stepper onValueChange={onDeliveryCostChange} min={0} value={form.orderOptionsDeliveryCost ?? 0} disabled={disabled} />
            </InputWrapper>
          </StrucCol>
        </StrucRow>
      </SectionContent>
    </Section>)
}
