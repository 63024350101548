import React, { forwardRef } from 'react'

interface InputWithSuffix extends React.InputHTMLAttributes<HTMLInputElement> {
  suffix: string,
  textLeft?: boolean
}

export const InputWithSuffix = forwardRef<HTMLInputElement, InputWithSuffix>((props, ref) => {
  const { type, className, name, value, suffix, onChange, disabled, textLeft, ...rest } = props
  return (
    <div
      className={`border rounded-md border-gray-200 border-solid h-7 flex space-x-2 items-center pl-2 bg-white input focus-within:border-gray-600 ${className ?? ''} ${disabled ? '!bg-[#fafafa]' : ''}`}>
      <input ref={ref}
             type={type}
             name={name}
             value={value}
             className={`box-border w-full border-none text-xs font-medium outline-none disabled:!bg-[#fafafa] ${textLeft ? 'text-left' : 'text-right' }`}
             onChange={onChange}
             disabled={disabled}
             {...rest}
      />
      <span>
        {suffix}
      </span>
    </div>
  )
})