import React, { ChangeEvent } from 'react'
import { SelectEvent } from '../Models/multiSelectEvent'

interface ListItemSelectorProps {
  index: number,
  onRowSelectChange: (event: SelectEvent) => void
  initialValue: boolean
}

export function ListItemSelector({ index, onRowSelectChange, initialValue }: ListItemSelectorProps) {

  function onChange(e: ChangeEvent<HTMLInputElement>) {
    onRowSelectChange({ index, checked: e.target.checked })
  }

  return (
    <div className='w-14 flex justify-center items-center' onClick={e => e.stopPropagation()}>
      <div>
        <label className='container'>
          <input checked={!!initialValue} type='checkbox' onChange={(e) => onChange(e)} />
          <span className='checkmark'></span>
        </label>
      </div>
    </div>
  )
}