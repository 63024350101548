import { OrderLine } from '../../../../core/models/entities/Order'

export function BaseOrderLine({ no, orderline }: { no: string, orderline: OrderLine }): JSX.Element {
  return (
    <tr className='min-h-36 h-14'>
      <td className='text-left'>{no}</td>
      <td className='text-left'></td>
      <td className='text-left'>{orderline.product.productSku}</td>
      <td className='text-left'>{orderline.product.productName}</td>
      <td className='text-left'></td>
      <td className='text-right'></td>
      <td className='text-right'></td>
      <td className='text-right'>DKK {orderline.adjustedPrice}</td>
      <td className='text-right'>DKK {orderline.adjustedPrice * orderline.quantity}</td>
    </tr>
  )
}
