import { AuthenticatedTemplate, UnauthenticatedTemplate, useIsAuthenticated, useMsal } from '@azure/msal-react'
import React, { PropsWithChildren, useEffect } from 'react'
import MainHeader from './presentation/screens/header/MainHeader'
import { Outlet, useNavigate } from 'react-router'
import { NotFoundPage } from './presentation/screens/not-found/NotFoundPage'
import MainFooter from './presentation/screens/footer/MainFooter'
import { useStyleRepository } from './data/repositories/useStyleRepository'
import { useDesignRepository } from './data/repositories/useDesignRepository'
import { useLogoRepository } from './data/repositories/useLogoRepository'
import { useProfileRepository } from './data/repositories/useProfileRepository'
import { useOrderRepository } from './data/repositories/useOrderRepository'
import { useManufacturerRepository } from './data/repositories/useManufacturerRepository'
import { useUserRepository } from './data/repositories/useUserRepository'
import { useWhiteLabelContext, WhiteLabelProvider } from './WhiteLabelContext'
import { ProfileProvider } from './contexts/ProfileContext'
import { RepositoriesProvider, useRepositoriesContext } from './contexts/RepositoriesContext'
import LoadingElement from './presentation/components/loading-element/loading-element'
import { useOrganizationRepository } from './data/repositories/useOrganizationRepository'
import { LogoOptionsProvider } from './contexts/LogoOptionsContext'
import { CartProvider } from './contexts/CartContext'
import { useCountryRepository } from './data/repositories/useCountryRepository'
import { CountryProvider } from './presentation/screens/design-editor2/contexts/country.context'
import { PageQuery } from './utils/models/pageQuery'
import { InteractionStatus } from '@azure/msal-browser'
import { loginRequest } from './AuthConfig'
import { OrganizationImpersionationProvider } from './contexts/OrganizationImpersonationContext'

interface Props {
  notfound?: boolean
}

const Screen = React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement>>(({
  children,
  className,
  ...rest
}, ref) => {
  return <div ref={ref} {...rest}
    className={'app w-screen min-h-screen h-screen overflow-auto ' + className ?? ''}>{children}</div>
})

export function App({ notfound = false }: Props): React.ReactElement {

  function MainView() {

    function AuthenticatedView() {
      const { whiteLabel } = useWhiteLabelContext()
      // @ts-ignore
      return <Screen style={{ '--primary': whiteLabel.colorHex, '--primary-bg': `${whiteLabel.colorHex}10` }}>
        <div className={'w-full fixed z-20 h-16 top-0'}>
          <MainHeader />
        </div>
        <div className={'relative'}>
          <div style={{
            minHeight: '100vh',
            paddingTop: '6.75rem',
            paddingBottom: '5rem',
            marginBottom: '-4.05rem'
          }} className={'w-full table  h-full bg-background'}>
            <div className='max-w-[90rem] w-full max-h-full h-full mx-auto'>
              {notfound ? <NotFoundPage /> : <Outlet />}
            </div>
          </div>
          <div className={'w-full h-16 absolute z-20 bottom-[1px]'}>
            <MainFooter />
          </div>
        </div>
      </Screen>
    }

    return (
      <>
        <AuthenticatedTemplate>
          <PreLoader>
            <AuthenticatedView />
          </PreLoader>
        </AuthenticatedTemplate>
        <UnauthenticatedTemplate>
          <UnauthenticatedView />
        </UnauthenticatedTemplate>
      </>
    )
  }

  return (
    <MainView />
  )
}

export function PublicView() {
  // @ts-ignore
  return <Screen style={{ '--primary': '#7BBB58', '--primary-bg': `#7BBB5810` }}>
    <div className={'relative'}>
      <div style={{
        minHeight: '100vh',
        paddingTop: '6rem',
        paddingBottom: '5rem',
        marginBottom: '-4.05rem'
      }} className={'w-full table  h-full bg-background'}>
        <div className='max-w-[90rem] w-full max-h-full h-full mx-auto'>
          {false ? <NotFoundPage /> : <Outlet />}
        </div>
      </div>
    </div>
  </Screen>
}

function AuthenticatedView() {
  const { whiteLabel } = useWhiteLabelContext()
  // @ts-ignore
  return <Screen style={{ '--primary': whiteLabel.colorHex, '--primary-bg': `${whiteLabel.colorHex}10` }}>
    <div className={'w-full fixed z-20 h-16 top-0'}>
      <MainHeader />
    </div>
    <div className={'relative'}>
      <div style={{
        minHeight: '100vh',
        paddingTop: '6.75rem',
        paddingBottom: '5rem',
        marginBottom: '-4.05rem'
      }} className={'w-full table  h-full bg-background'}>
        <div className='max-w-[90rem] w-full max-h-full h-full mx-auto'>
          {false ? <NotFoundPage /> : <Outlet />}
        </div>
      </div>
      <div className={'w-full h-16 absolute z-20 bottom-[1px]'}>
        <MainFooter />
      </div>
    </div>
  </Screen>
}

export function PrivateView() {
  return <>
    <AuthenticatedTemplate>
      <PreLoader>
        <AuthenticatedView />
      </PreLoader>
    </AuthenticatedTemplate>
    <UnauthenticatedTemplate>
      <UnauthenticatedView />
    </UnauthenticatedTemplate>
  </>
}

function UnauthenticatedView() {

  const { instance, inProgress } = useMsal()
  const isAuthenticated = useIsAuthenticated()

  if (!isAuthenticated && inProgress === InteractionStatus.None) {
    sessionStorage.setItem("redirectOnLogin", window.location.pathname + window.location.search)
    instance.loginRedirect(loginRequest)
  }

  return <Screen>
    <LoadingElement />
  </Screen>
}

export function PreLoader(props: PropsWithChildren) {
  const styleRepository = useStyleRepository()
  const designRepository = useDesignRepository()
  const logoRepository = useLogoRepository()
  const organizationRepository = useOrganizationRepository()
  const orderRepository = useOrderRepository()
  const manufacturerRepository = useManufacturerRepository()
  const userRepository = useUserRepository()
  const countryRepository = useCountryRepository()

  const repositories = {
    styleRepository,
    designRepository,
    logoRepository,
    organizationRepository,
    orderRepository,
    manufacturerRepository,
    userRepository,
    countryRepository,
  }

  const { useProfile } = useProfileRepository()
  const { isLoading, refetch } = useProfile()
  const { accounts } = useMsal()
  const id = accounts[0]?.homeAccountId

  useEffect(() => {
    refetch()
  }, [id])

  const redirectOnLogin = sessionStorage.getItem('redirectOnLogin');
  const navigate = useNavigate()

  useEffect(() => {
    if (redirectOnLogin) {
      sessionStorage.removeItem('redirectOnLogin');
      navigate(redirectOnLogin);
    }
  }, [redirectOnLogin])

  return (!accounts[0] || isLoading
    ? <LoadingElement />
    : <RepositoriesProvider repositories={repositories}>
      <OrganizationImpersionationProvider>
        <ProfileProvider>
          <WhiteLabelProvider>
            <CartProvider>
              <PreloadData>{props.children}</PreloadData>
            </CartProvider>
          </WhiteLabelProvider>
        </ProfileProvider>
      </OrganizationImpersionationProvider>
    </RepositoriesProvider>
  )
}

function PreloadData(props: PropsWithChildren) {
  const { logoRepository, countryRepository } = useRepositoriesContext()
  const { useAllCountries } = countryRepository
  const { data, isLoading } = logoRepository.useOptions()
  const { data: countries, isLoading: isCountriesLoading } = useAllCountries({ page: { index: 0, size: 0 } } as PageQuery);

  return (isLoading || isCountriesLoading
    ? <LoadingElement />
    : <CountryProvider countries={countries!.results.flatMap(x => x)}>
      <LogoOptionsProvider options={data!}>
        {props.children}
      </LogoOptionsProvider>
    </CountryProvider>
  )
}
