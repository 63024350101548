import React, { forwardRef } from 'react'
import './LoadingElement.css'
import { LinearProgress } from '@mui/material'

interface LoadingElementProps extends React.HTMLAttributes<HTMLDivElement> {
  colorHex?: string
  transparent?: boolean
  showProgressBar?: boolean
  progressBarValue?: number
}

const LoadingElement = forwardRef<HTMLDivElement, LoadingElementProps>(({
  className,
  transparent,
  showProgressBar,
  progressBarValue,
  colorHex,
  ...rest
}, ref) => {

  return (
    <div ref={ref} id='preloader' {...rest}
      className={`!mt-0 h-full w-full flex justify-center items-center flex-col flex-1 ${transparent ? 'bg-loadingTransparent' : 'bg-loading'} z-10 ${className ?? ''}`}>
      <div className={'bg-white rounded-lg h-[80px] w-[80px] flex s'}>
        <div className={'flex w-full p-[14px]'}>
          <div className={`h-full w-3 mr-2 ${colorHex ? colorHex : 'bg-gray-600'}`} style={{ backgroundColor: colorHex }} />
          <div className={'flex-1'}>
            <div className={`h-3 mb-2 w-full ${colorHex ? colorHex : 'bg-gray-600'}`} style={{ backgroundColor: colorHex }} />
            <div className={`h-3 mb-2 w-full ${colorHex ? colorHex : 'bg-gray-600'}`} style={{ backgroundColor: colorHex }} />
            <div className={`h-3 w-full ${colorHex ? colorHex : 'bg-gray-600'}`} style={{ backgroundColor: colorHex }} />
          </div>
        </div>
      </div>
      {
        showProgressBar &&
        <LinearProgress variant={progressBarValue ? 'determinate' : 'indeterminate'} value={progressBarValue}
          sx={{ width: 100, marginTop: '24px' }}></LinearProgress>
      }
    </div>
  )
})

export default LoadingElement