import { useNavigate } from 'react-router-dom'
import { useRepositoriesContext } from '../../../../contexts/RepositoriesContext'
import { useState } from 'react'
import { Organization } from '../../../../core/models/entities/Organization'

interface CustomerActionHandler {
  isLoading: boolean
  handleCreate: (customer: Organization) => Promise<Organization>
  handleDelete: (customer: Organization) => Promise<void>
  handleUpdate: (customer: Organization) => Promise<Organization>
  handleGoToCustomerCreate: () => void
  handleGoToCustomerUpdate: (customer: Organization) => void
  handleGoToCustomerList: () => void
}

export function useCustomerActionHandler() {

  const navigate = useNavigate()
  const { organizationRepository } = useRepositoriesContext()
  const [isLoading, setIsLoading] = useState(false)
  const startLoading = () => setIsLoading(true)
  const stopLoading = () => setIsLoading(false)

  function handleGoToCustomerList() {
    navigate('/customer')
  }

  function handleGoToCustomerCreate() {
    navigate('/customer/add')
  }

  function handleGoToCustomerUpdate(customer: Organization) {
    navigate(`/customer/${customer.id}/edit`)
  }

  function handleCreate(customer: Organization): Promise<Organization> {
    startLoading()
    return organizationRepository.createCustomer(customer)
      .then()
      .finally(stopLoading)
  }

  function handleUpdate(customer: Organization): Promise<Organization> {
    startLoading()
    return organizationRepository.updateCustomer(customer)
      .then()
      .finally(stopLoading)
  }

  function handleDelete(customer: Organization) {
    startLoading()
    return organizationRepository.deleteCustomer(customer)
      .then()
      .finally(stopLoading)
  }

  return {
    isLoading,
    handleCreate,
    handleDelete,
    handleUpdate,
    handleGoToCustomerCreate,
    handleGoToCustomerUpdate,
    handleGoToCustomerList
  } as CustomerActionHandler
}