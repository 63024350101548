import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { UpdateStyleDto } from '../../../../../core/models/dtos/UpdateStyleDto'
import { Style } from '../../../../../core/models/entities/Style'
import { copy } from '../../../../../utils/functions/ObjectUtil'
import { isEmpty } from '../../../../../utils/functions/StringUtil'
import { StyleEditFormViewModel, StyleViewForm } from './StyleEditFormViewModel'
import { toast } from 'react-toastify'
import { useErrorMessage } from '../../../../hooks/useErrorMessage'

export function useStyleEditFormViewModel(
  style: Style,
  updateStyle: (dto: UpdateStyleDto) => Promise<Style>
): StyleEditFormViewModel {
  const [name, setName] = useState(style.name)
  const [model, setModel] = useState(style.model)
  const [views, setViews] = useState<StyleViewForm[]>([])
  const [submitting, setSubmitting] = useState(false)
  const { tError } = useErrorMessage()

  const navigate = useNavigate()

  useEffect(() => {
    setViews(
      style.styleViews.map((v) => {
        return {
          id: v.id,
          view: v.view,
          ppcm: String(v.ppcm),
          imageUrl: v.imageUrl
        }
      })
    )
  }, [style])

  function onPpcmChange(viewId: number, ppcm: number) {
    const c_views = copy(views)

    const view = c_views.find((v) => v.id === viewId)

    if (view) {
      view.ppcm = '' + ppcm
    }

    setViews(c_views)
  }

  function submit() {
    if (isEmpty(name)) {
      return
    }

    if (isEmpty(model)) {
      return
    }

    if (views.find((v) => Number.isNaN(+v.ppcm))) {
      console.warn('was nan')
      return
    }

    setSubmitting(true)
    updateStyle({
      styleId: style.id,
      name: name,
      model: model,
      views: views.map((v) => {
        return {
          viewId: v.id,
          ppcm: Number(v.ppcm)
        }
      })
    })
      .then()
      .catch((e) => {
        toast.warn(tError(e, 'toasts.style_update_failed'))
      })
      .finally(() => setSubmitting(false))
  }

  function handleAnnotate() {
    navigate(`/style/${style.id}/annotate`)
  }

  return {
    name,
    model,
    views,
    submitting,
    onPpcmChange,
    onNameChange: setName,
    onModelChange: setModel,
    submit,
    handleAnnotate
  }
}
