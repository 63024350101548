import {
  Design,
  DesignLogo,
  DesignState,
  getDesignState
} from '../../../../core/models/entities/Design'
import React, { forwardRef, useState } from 'react'
import { BoxHeader2 } from '../../../components/BoxView/BoxHeader2'
import { BoxHeaderTypography } from '../../../components/Typographies/BoxHeaderTypography'
import { StrucRow } from '../../../components/StructureElements/StrucRow'
import { StrucCol } from '../../../components/StructureElements/StrucCol'
import { formatDate } from '../../../../utils/functions/DateUtil'
import {
  SecondaryButton,
  SuccessButton,
  WarnButton
} from '../../../components/Buttons/SecondaryButton'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFile } from '@fortawesome/free-solid-svg-icons'
import { useDesignActionHandler } from '../hooks/useDesignActionHandler'
import IconButton from '../../../components/LogoButton/IconButton'
import LoadingElement from '../../../components/loading-element/loading-element'
import { LogoDimensions } from '../../../components/Logo/LogoDimensions'
import { DesignStatusElement } from '../../../components/Design/DesignStatusElement'
import { PriceLine } from '../../../components/Logo/LogoPrices'
import { Role } from '../../../../core/models/entities/Role'
import { useProfileContext } from '../../../../contexts/ProfileContext'
import { BoxModalContent } from '../../../components/BoxView/BoxModalContent'
import { Stepper } from '../../../components/Buttons/Stepper'
import {
  AddToCartIcon,
  CloneIcon,
  EditIcon,
  TrashcanCrossIcon
} from '../../../../resources/icons/Icons'
import { CustomInput, CustomTextArea } from '../../../components/Input/CustomInput'
import { RadioButton } from '../../../shared/components/RadioButton'
import { VStack } from '../../../shared/components/VStack'
import { isDesignApprovedByMe } from './design-list-item.component'
import { getColorsElements } from '../../order-create/subcomponents/ProductDetailsLogo'
import { LogoTransferMethod } from '../../../components/Logo/LogoTransferMethod'
import { LogoImage } from '../../../components/Logo/LogoImage'
import { InputLabel, TextField } from '@mui/material'

const LogoList = forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement>>((props, ref) => {
  return (
    <div ref={ref} className={'my-4 max-h-[710px]'}>
      <div className={'h-full overflow-y-scroll mr-[-0.75rem]'}>
        <div className={'mr-[0.48rem] space-y-2'}>{props.children}</div>
      </div>
    </div>
  )
})

export type DesignModalIntent = 'view' | 'reject' | 'approve'
type RejectOption = 'remark' | 'delete'

type ButtonAction =
  | 'cancel'
  | 'delete'
  | 'edit'
  | 'approve'
  | 'clone'
  | 'copy'
  | 'reject'
  | 'skip'
  | 'space'
  | 'add_to_order'

function resolveInitialRemark(design: Design) {
  return (
    design.approval?.customer.rejectionNote ||
    design.approval?.external?.rejectionNote ||
    design.approval?.vendor?.rejectionNote ||
    ''
  )
}

type DefaultOnClick = () => void
interface DefaultActionButtonProps {
  onClick: DefaultOnClick
  disabled?: boolean
}

export function DesignDetails({
  design,
  intent = 'view',
  onClose
}: {
  design: Design
  intent?: DesignModalIntent
  onShowRejectionNote: (design: Design) => void
  onClose: () => void
  closeDialog: () => void
}) {
  const {
    handleDeleteDesign,
    handleRejectAndDelete,
    handlePlaceVerdict,
    handleCopyDesign,
    handleGoToUpdateDesign,
    handleApprovalSkip,
    handleAddToCart,
    handleChangeTitle,
    isLoading
  } = useDesignActionHandler()

  const { role } = useProfileContext()

  const [quantityForOrder, setQuantityForOrder] = useState<number>(10)
  const [rejectOption, setRejectOption] = useState<RejectOption>('delete')
  const [remark, setRemark] = useState(() => resolveInitialRemark(design))

  const isAdminOrVendor = role == (Role.Admin || Role.Vendor)
  const cards = design.designLogos.map((dl) => <LogoCard key={dl.inDesignId} designLogo={dl} />)
  const designState = getDesignState(design)
  const thumbnailSrc = design.displayImage?.lowResUrl ?? design.style.thumbnailUrl

  if (role == null || role == undefined) {
    return <label>'Role not set'</label>
  }

  function onApprove() {
    handlePlaceVerdict(design, { approve: true })
    onClose()
  }

  function onReject(deleteOnRejection: boolean, remark: string) {
    if (deleteOnRejection) {
      handleRejectAndDelete(design, remark)
    } else {
      handlePlaceVerdict(design, { approve: false, rejectionNode: remark })
    }
    onClose()
  }

  function getBottomActionsForIntent(intent: DesignModalIntent, role: Role): ButtonAction[] {
    switch (intent) {
      case 'view':
        const designSate = getDesignState(design)
        return getBottomActionsForDesignState(designSate, role)
      case 'reject':
        return ['cancel', 'space', 'reject']
      case 'approve':
        return ['cancel', 'space', 'approve']
    }
  }

  function getBottomActionsForDesignState(designState: DesignState, role: Role): ButtonAction[] {
    switch (designState) {
      case DesignState.Draft:
      case DesignState.Rejected:
        return ['cancel', 'delete', 'edit']
      case DesignState.Pending:
      case DesignState.Approval:
        if (isDesignApprovedByMe(design, role)) {
          return ['cancel', 'delete', 'add_to_order']
        }
        return ['cancel', 'space', 'delete']
      case DesignState.Done:
        return ['cancel', 'delete', 'add_to_order']
    }
  }

  function ActionButton({ action }: { action: ButtonAction }) {
    switch (action) {
      case 'cancel':
        return <ActionButtons.Cancel onClick={onClose} />
      case 'delete':
        return <ActionButtons.Delete onClick={() => handleDeleteDesign(design)} />
      case 'edit':
        return <ActionButtons.Edit onClick={() => handleGoToUpdateDesign(design)} />
      case 'approve':
        return <ActionButtons.Approve onClick={onApprove} />
      case 'clone':
        return <ActionButtons.Clone onClick={handleClone} />
      case 'copy':
        return <ActionButtons.Copy onClick={handleClone} />
      case 'reject':
        return (
          <ActionButtons.Reject
            onClick={() => {
              switch (rejectOption) {
                case 'remark':
                  onReject(false, remark)
                  break
                case 'delete':
                  onReject(true, remark)
                  break
              }
            }}
          />
        )
      case 'skip':
        return isAdminOrVendor ? (
          <ActionButtons.Skip onClick={() => handleApprovalSkip(design)} />
        ) : null
      case 'space':
        return <></>
      case 'add_to_order':
        return (
          <ActionButtons.AddToOrder
            disabled={quantityForOrder === 0}
            onClick={() => handleAddToCart(design, quantityForOrder)}
          />
        )
    }
  }

  function handleClone() {
    handleCopyDesign(design).then((d) => handleGoToUpdateDesign(d))
  }

  function handleTitle(title: string) {
    handleChangeTitle(design.customerId, design.id, title)
  }

  const BottomButtonBar = ({ role }: { role: Role }) => {
    const actions = getBottomActionsForIntent(intent, role)

    return (
      <StrucRow className="gap-x-[17px]">
        {actions.map((action) => (
          <StrucCol key={`col-${action}`}>
            {<ActionButton key={`button-${action}`} action={action} />}
          </StrucCol>
        ))}
      </StrucRow>
    )
  }

  return (
    <>
      {isLoading && <LoadingElement transparent />}
      <BoxHeader2>
        <DesignTitleHeader design={design} />
      </BoxHeader2>
      <BoxModalContent className={'w-[1200px] min-h-0 pb-8'}>
        <div className="grid grid-cols-12 gap-x-[29px]">
          <div className="col-span-8 grid grid-flow-row gap-y-[17px]">
            <StrucRow>
              <div className={'h-full w-full'}>
                <img
                  className={'m-auto object-contain h-128'}
                  src={thumbnailSrc}
                  alt={design.title}
                />
              </div>
            </StrucRow>
            <div className="grid grid-cols-3 gap-x-[17px]">
              <DesignInfo design={design} onTitleChange={handleTitle} />
              <DesignPrice design={design} />
              <StatusColumn
                role={role}
                design={design}
                intent={intent}
                designState={designState}
                remark={remark}
                rejectOption={rejectOption}
                setRejectOption={setRejectOption}
                setRemark={setRemark}
                quantity={quantityForOrder}
                setQuantity={(value) => setQuantityForOrder(value)}
                onClone={handleClone}
              />
            </div>
            <BottomButtonBar role={role} />
          </div>
          <div className="col-span-4">
            <p className={'font-bold'}>Logos in Design</p>
            {cards?.length > 0 ? <LogoList>{cards}</LogoList> : 'No logos'}
          </div>
        </div>
      </BoxModalContent>
    </>
  )
}

function LogoCard({ designLogo }: { designLogo: DesignLogo }) {
  const logo = designLogo.logo

  const colors = getColorsElements(logo)

  return (
    <div className={`border rounded bg-gray-100`}>
      <StrucRow className={'pl-1 pr-2 py-1 border-b'}>
        <StrucCol className={'!justify-between !flex-row items-center'}>
          <div className={'text-[#1B76DF] flex items-center space-x-2'}>
            <FontAwesomeIcon className={'text-xs px-1.5 py-1 rounded border'} icon={faFile} />
            <span className={'max-w-[200px] overflow-x-hidden overflow-ellipsis whitespace-nowrap'}>
              {logo.title}
            </span>
          </div>
        </StrucCol>
      </StrucRow>
      <StrucRow>
        <StrucCol size={3.5} className={'bg-white p-3'}>
          <div className={'h-screen flex items-center max-h-[55px] w-fit'}>
            <LogoImage
              logo={logo}
              useHighRes={true}
              className={
                'bg-white rounded hover:border hover:p-2 max-h-[74px] hover:scale-[3] hover:ease-out transition-all duration-150 ease-out'
              }
            />
          </div>
        </StrucCol>
        <StrucCol size={5} className={'pl-2 pt-1 text-xs space-y-1'}>
          <div className="flex flex-row gap-x-1 -mb-1">
            <span>
              <b>COLORS</b>
            </span>
            <div>{colors}</div>
          </div>
          <span>
            <b>Placement</b> {designLogo.viewName}
          </span>
          <LogoDimensions logo={logo} />
          <span className={'font-bold'}>{LogoTransferMethod({ logo })}</span>
        </StrucCol>
      </StrucRow>
    </div>
  )
}

function DesignTitleHeader({ design }: { design: Design }) {
  return (
    <div className={'flex items-center space-x-4'}>
      <BoxHeaderTypography>
        Design -{' '}
        <span className={'font-medium normal-case'}>{design.product?.mul ?? design.title}</span>
      </BoxHeaderTypography>
    </div>
  )
}

function DesignPrice({ design }: { design: Design }) {
  if (design.product?.totalPrices === undefined || design.product?.totalPrices.length == 0) {
    return <p className={'font-bold'}>No available prices</p>
  }

  const productPrices = design.product?.totalPrices.map((p) => {
    const key = crypto.randomUUID()
    return (
      <span key={key} className="pt-1 text-xs capitalize inline pr-1">
        <PriceLine price={p} />
      </span>
    )
  })

  return (
    <div>
      <p className={'font-bold'}>
        Design Price
        <span className={'italic text-xxs font-normal'}>(ex moms og påvarmning)</span>
      </p>
      <div className={'grid grid-rows-5 grid-flow-col gap-0'}>{productPrices}</div>
    </div>
  )
}

function DesignInfo({ design, onTitleChange = () => { } }: { design: Design, onTitleChange: (title: string) => void }) {
  const [title, setTitle] = useState(design?.title ?? '')
  const [editable, setEditable] = useState(false)

  const toggle = () => {
    if (editable) onTitleChange(title)
    setEditable(!editable)
  }
  
  return (
    <div className={'space-y-2'}>
      <p className="editable-title-field">
        <b className="whitespace-nowrap">Design Name</b> {editable ?
          <input
            className="editable-title-field__input"
            type="text"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
          : <span className="editable-title-field__label">{design.title}</span>}
        <EditIcon onClick={toggle} />
      </p>
      <p>
        <b>Manufacturer</b> {design.style.manufacturer.name}
      </p>
      <p>
        <b>Model</b> {design.style.modelAndName}
      </p>
      <p>
        <b>Customer</b> {design.customer.name}
      </p>
      <p>
        <b>Creation Date</b> {formatDate(design.createdAtUtc)}
      </p>
    </div>
  )
}

export const ActionButtons = {
  Cancel: ({ onClick }: DefaultActionButtonProps) => (
    <SecondaryButton onClick={onClick} className="w-full">
      CANCEL
    </SecondaryButton>
  ),
  Delete: ({ onClick }: DefaultActionButtonProps) => (
    <IconButton
      variant="error"
      icon={<TrashcanCrossIcon />}
      onClick={onClick}
      className="w-full !h-9">
      DELETE DESIGN
    </IconButton>
  ),
  Approve: ({ onClick }: DefaultActionButtonProps) => (
    <SuccessButton onClick={onClick} className="w-full">
      APPROVE
    </SuccessButton>
  ),
  Reject: ({ onClick }: DefaultActionButtonProps) => (
    <WarnButton onClick={onClick} className="w-full">
      REJECT
    </WarnButton>
  ),
  Clone: ({ onClick }: DefaultActionButtonProps) => (
    <IconButton
      variant="dim"
      key="clone-button"
      icon={<CloneIcon />}
      onClick={onClick}
      className="w-full !h-9">
      CLONE DESIGN
    </IconButton>
  ),
  Copy: ({ onClick }: DefaultActionButtonProps) => (
    <SecondaryButton className="w-full" onClick={onClick}>
      COPY DESIGN
    </SecondaryButton>
  ),
  Skip: ({ onClick }: DefaultActionButtonProps) => (
    <SecondaryButton className="w-full" onClick={onClick}>
      SKIP APPROVAL
    </SecondaryButton>
  ),
  Edit: ({ onClick }: DefaultActionButtonProps) => (
    <IconButton icon={<EditIcon />} variant="dim" className="w-full !h-9" onClick={onClick}>
      EDIT DESIGN
    </IconButton>
  ),
  AddToOrder: ({ onClick, disabled }: DefaultActionButtonProps) => (
    <IconButton
      variant="success"
      className={'w-full !h-9'}
      disabled={disabled}
      onClick={onClick}
      icon={<AddToCartIcon />}>
      Add to Order
    </IconButton>
  )
}

interface StatusProps {
  intent: DesignModalIntent
  design: Design
  role: Role
  designState: DesignState
  rejectOption: RejectOption
  setRejectOption: (option: RejectOption) => void
  remark: string
  setRemark: (value: string) => void
  quantity: number
  setQuantity: (value: number) => void
  onClone: () => void
}
function StatusColumn({
  role,
  intent,
  design,
  designState,
  rejectOption,
  setRejectOption,
  remark,
  setRemark,
  quantity,
  setQuantity,
  onClone
}: StatusProps) {
  const approvedByMe = isDesignApprovedByMe(design, role)

  switch (intent) {
    case 'view':
      break
    case 'reject':
      return (
        <div className="flex flex-col">
          <div>
            <RadioButton
              checked={rejectOption == 'delete'}
              label="Delete this product"
              onClick={() => setRejectOption('delete')}
            />
          </div>
          <div>
            <RadioButton
              checked={rejectOption == 'remark'}
              label="Own remark"
              onClick={() => setRejectOption('remark')}
            />
          </div>
          <CustomTextArea
            placeholder="Remark..."
            disabled={rejectOption !== 'remark'}
            value={remark}
            onChange={(e) => setRemark(e.target.value)}
          />
        </div>
      )
    case 'approve':
      return <div />
  }

  switch (designState) {
    case DesignState.Draft:
      return null
    case DesignState.Rejected:
      return (
        <VStack>
          <DesignStatusElement design={design} />
          <label className="font-semibold pt-3">Rejection reason</label>
          <CustomTextArea disabled={true} value={remark} />
        </VStack>
      )
    case DesignState.Pending:
    case DesignState.Approval:
      return (
        <div className="h-full flex flex-col justify-between gap-y-5">
          <div>
            <DesignStatusElement design={design} />
          </div>

          {approvedByMe && (
            <Stepper value={quantity} onValueChange={setQuantity} className="w-full h-9" />
          )}
        </div>
      )
    case DesignState.Done:
      return (
        <div className="h-full flex flex-col justify-between gap-y-5">
          <ActionButtons.Clone onClick={onClone} />
          <Stepper value={quantity} onValueChange={setQuantity} className="w-full h-9" />
        </div>
      )
  }
}
