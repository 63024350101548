import { Design } from '../../../../core/models/entities/Design'
import { PageQuery } from '../../../../utils/models/pageQuery'
import { Organization } from '../../../../core/models/entities/Organization'
import { QueryChanger } from '../../../shared/hooks/usePageQuery'
import { TableData } from '../../../components/GenericTable/FunctionalList'
import { useProfileContext } from '../../../../contexts/ProfileContext'
import { useNavigate } from 'react-router-dom'
import { PrimaryButton } from '../../../components/Buttons/PrimaryButton'
import { designColumnsConfig } from '../functions/designColumnsConfig'
import { DesignRowItem } from '../components/design-list-item.component'
import { Profile } from '../../../../core/models/entities/Profile'
import { FilterRecipe } from '../../../components/GenericTable/SubComponents/TableHeader/ListHeader'
import { Role } from '../../../../core/models/entities/Role'
import { organizationFilterField } from '../../../shared/const/organizationFilterField'
import { Manufacturer } from '../../../../core/models/entities/Manufacturer'
import { Style } from '../../../../core/models/entities/Style'
import { TransferLogoType, TransferMethod } from '../../../../core/models/entities/LogoOptions'
import { GetOverallApprovalStatus, LogoState } from '../../../../core/models/entities/BaseLogo'
import { ApprovalStatus } from '../../../../core/models/entities/Approval'
import { DesignStatus } from '../../../../core/models/entities/enums/DesignStatus'
import { useDesignActionHandler } from '../hooks/useDesignActionHandler'
import { useLogoOptionsContext } from '../../../../contexts/LogoOptionsContext'
import { useMultiSelectStateMachine } from '../../../shared/hooks/useMultiSelectStateMachine'

export interface DesignRow extends Design {
  checked: boolean | undefined
}

export type DesignClickCallback = (design: Design) => void

export function useFunctionalListDesignViewModel(
  onSelectDesign: DesignClickCallback,
  onApprove: DesignClickCallback,
  onReject: DesignClickCallback,
  lastPage: number,
  query: PageQuery,
  customers: Organization[],
  isPageLoading: boolean,
  vendors: Organization[] | undefined,
  queryChanger: QueryChanger,
  manufacturers: Manufacturer[],
  styles: Style[],
  methods: TransferMethod[],
  onShowRejectionNote: DesignClickCallback,
  // openInitApproval: (design: Design) => void,
  designs?: Design[]
): TableData<Design> {
  const { profile, role } = useProfileContext()
  const {
    handleAddToCart,
    handleAddItemsToCart,
    handleDeleteDesign,
    handlePlaceVerdict,
    handleGoToUpdateDesign,
    handleRejectAndDelete
  } = useDesignActionHandler()

  const navigate = useNavigate()

  function onToCreateDesign() {
    navigate('/design/create')
  }

  const { selectedData, onRowChecked, onMultiSelectRow } = useMultiSelectStateMachine<Design>({
    onSelect: onSelectDesign
  })

  function addToCart() {
    handleAddItemsToCart(Object.values(selectedData), 10)
  }

  const center = (
    <PrimaryButton key={'design-create'} width={'md'} onClick={onToCreateDesign}>
      Create new Design
    </PrimaryButton>
  )

  const right = (role !== Role.Admin && (
    <PrimaryButton width={'md'} onClick={addToCart}>
      Add to Order ({Object.values(selectedData)?.length})
    </PrimaryButton>
  )) || <></>

  return {
    tableTitle: 'Designs',
    columns: designColumnsConfig(),
    onRowClick: onMultiSelectRow,
    lastPage: lastPage,
    query: query,
    isLoading: isPageLoading,
    onNewPage: queryChanger.handleNewPage,
    onNewSort: queryChanger.handleSortByField,
    onFilterChange: queryChanger.handleFilterChange,
    onSearchQueryChange: queryChanger.handleSearchQueryChange,
    onPageSizeChange: queryChanger.handlePageSizeChange,
    renderRow: (columns, data) => (
      <DesignRowItem
        design={data}
        columns={columns}
        onSeeRejectNote={() => onShowRejectionNote(data)}
        onInitiateApproval={() => {}}
        onAddToCart={handleAddToCart}
        onPlaceVerdict={handlePlaceVerdict}
        onDeleteDesign={handleDeleteDesign}
        onEditDesign={handleGoToUpdateDesign}
        onRejectAndDelete={handleRejectAndDelete}
        onApprove={onApprove}
        onReject={onReject}
      />
    ),
    getKey: getKey,
    center: center,
    right: right,
    data: designs,
    getRowColor: logoRowBgColor,
    filters: buildDesignFilterRecipes(profile!, methods, manufacturers, styles, customers, vendors),
    multiSelect: true,
    selectedData: selectedData,
    onRowSelected: onRowChecked
  }
}

function logoRowBgColor(design: Design) {
  if (design.status === DesignStatus.Draft) {
    return 'bg-row-draft'
  }

  switch (GetOverallApprovalStatus(design.approval!)) {
    case ApprovalStatus.Rejected:
      return 'bg-row-warn'
    case ApprovalStatus.Approved:
      return 'bg-row-standard'
    case ApprovalStatus.Pending:
      return 'bg-row-pending'
  }
}

function buildDesignFilterRecipes(
  profile: Profile,
  transferMethods: TransferMethod[],
  manufacturers: Manufacturer[],
  style: Style[],
  customers?: Organization[],
  vendor?: Organization[]
) {
  const logoOption = useLogoOptionsContext()
  const filters: FilterRecipe<any>[] = []

  if (profile.userOrganizationInformations.role >= Role.Admin && vendor) {
    /*filters.push({
      options: vendor,
      key: 'customer.parent',
      getOptionValue: (org => org.id),
      getOptionDisplay: (org => org.name),
      defaultTitle: 'Vendors'
    } as FilterRecipe<Organization>)*/
  }

  if (profile.userOrganizationInformations.role >= Role.Vendor && customers) {
    /*filters.push({
      options: [
        'N/A'
      ],
      key: 'ownership',
      getOptionValue: (o => o),
      getOptionDisplay: (o => o),
      defaultTitle: 'Ownership'
    } as FilterRecipe<string>)*/

    filters.push({
      options: customers,
      key: organizationFilterField,
      getOptionValue: (org) => org.id,
      getOptionDisplay: (org) => org.name,
      defaultTitle: 'Customer'
    } as FilterRecipe<Organization>)
  }

  filters.push({
    options: [LogoState.Approved, LogoState.Pending, LogoState.Rejected, LogoState.Draft],
    key: 'stateFilter',
    getOptionValue: (org) => org as string,
    getOptionDisplay: (org) => org as string,
    defaultTitle: 'Status'
  } as FilterRecipe<LogoState>)

  filters.push({
    options: logoOption.transferLogoTypes,
    key: 'typeFilter',
    getOptionValue: (o) => o.typeKey,
    getOptionDisplay: (o) => o.nickname,
    defaultTitle: 'Type'
  } as FilterRecipe<TransferLogoType>)

  filters.push({
    options: transferMethods,
    key: 'methodFilter',
    getOptionValue: (org) => ':' + org.key + ':',
    getOptionDisplay: (org) => org.method1 + (org.method2 ? `, ${org.method2}` : ''),
    defaultTitle: 'Method'
  } as FilterRecipe<TransferMethod>)

  filters.push({
    options: manufacturers,
    key: 'style.manufacturerId',
    getOptionValue: (org) => org.id,
    getOptionDisplay: (org) => org.name,
    defaultTitle: 'Manufacturer'
  } as FilterRecipe<Manufacturer>)

  filters.push({
    options: style,
    key: 'style.id',
    getOptionValue: (o) => o.id,
    getOptionDisplay: (o) => o.name + ' - ' + o.model,
    defaultTitle: 'Model'
  } as FilterRecipe<Style>)

  return filters
}

const getKey = (data: Design) => data.id
