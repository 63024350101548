import { CartItem } from '../models/CartItem'

interface DesignDetailsDesignProps {
  orderline: CartItem
  size?: 'sm' | 'lg'
}

export function ProductPreviewDesign({ orderline, size = 'sm' }: DesignDetailsDesignProps) {
  const frontView = {
    src: orderline.displayImage?.highResUrl, //'https://placehold.co/600x400/eb4034/FFFFFF.png',
    alt: 'logoname - frontView'
  }
  const dimension: {
    height: number,
    width: number
  } = { height: 0, width: 0 }

  switch (size) {
    case 'sm':
      dimension.height = 60
      dimension.width = 60
      break
    case 'lg':
      dimension.height = 280
      dimension.width = 280
      break
  }

  return <div className={'justify-center items-center flex'}>
    <img style={{
      height: dimension.height,
      width: dimension.width
    }} className={'object-contain'} {...frontView} />
  </div>

}