import { useState } from 'react'
import LoadingElement from '../loading-element/loading-element'
import { InputWrapper } from '../Input/InputWrapper'
import { CustomInput } from '../Input/CustomInput'
import { InputError } from '../../screens/my-profile/InputError'
import { SecondaryButton } from '../Buttons/SecondaryButton'
import { Dialog_VM, DialogFrame, useDialog } from './dialog-base'
import { ApprovalDialog } from './useApprovalDialogByRole'
import { PrimaryButton } from '../Buttons/PrimaryButton'

interface Props<T> {
  subject: string
  onSkip: (entity: T) => Promise<void>
  onOrder: (entity: T) => void
  onInitApproval: (entity: T, addExternal: boolean, email1: string | undefined) => Promise<void>
}

export function useApprovalDialogCustomer<T>({ onInitApproval, subject }: Props<T>): ApprovalDialog<T> {
  const addExternalDialog = useDialog()
  const [loading, setLoading] = useState(false)
  const [entity, setEntity] = useState<T>()

  function handleApproveWithExternal(addExternal: boolean, email?: string) {
    setLoading(true)
    onInitApproval(entity!, addExternal, email)
      .finally(() => {
        setLoading(false)
        addExternalDialog.closeDialog()
      })
  }

  return {
    component: <>
      <AddExternalApprover dialogVM={addExternalDialog} onDone={handleApproveWithExternal} loading={loading} subject={subject} />
    </>,
    start: (entity) => {
      setEntity(entity)
      addExternalDialog.openDialog()
    }
  }
}

interface AddExternalApproverProps {
  subject: string
  dialogVM: Dialog_VM
  loading: boolean
  onDone: (addExternal: boolean, email?: string) => void
}

function AddExternalApprover({ onDone, loading, dialogVM, subject }: AddExternalApproverProps) {
  const [email, setEmail] = useState<string>()
  const [showError, setShowError] = useState<boolean>(false)
  const emailReg = new RegExp('^\\S+@\\S+$')

  function handleYes() {
    if (!email) {
      setShowError(true)
      return
    }

    onDone(true, email)
  }

  function handleInput(input: string) {
    setShowError(!emailReg.test(input))
    setEmail(input)
  }

  return <DialogFrame dialogOption={dialogVM}>
    {loading && <LoadingElement transparent />}
    <div className={'flex flex-col gap-4 p-4'}>
      <p className='capitalize text-left text-base font-bold'>{subject} Approval</p>
      <p>Type the Email you wish to send the approval to.</p>
      <InputWrapper>
        <CustomInput className='bg-white' onChange={e => handleInput(e.target.value)} />
        {showError &&
          <InputError errorMessage={'Must be a valid email'}></InputError>}
      </InputWrapper>
      <div className={'flex justify-center space-x-4'}>
        <SecondaryButton className='flex-1' onClick={() => onDone(false)}>Cancel</SecondaryButton>
        <PrimaryButton className='flex-1' disabled={showError || loading} onClick={handleYes}>Send Approval</PrimaryButton>
      </div>
    </div>
  </DialogFrame>
}
