import { useState } from 'react'
import { BaseLogo, LogoType } from '../../../../core/models/entities/BaseLogo'
import { copy } from '../../../../utils/functions/ObjectUtil'
import { Design, DesignLogo } from '../../../../core/models/entities/Design'
import { StyleView } from '../../../../core/models/entities/StyleView'
import { IdLookUps } from '../../../../utils/models/idLookUp'
import { LogoPlacementPosition } from '../design-style-editor/DesignStyleEditor.vm'
import { NotImplemented } from '../../../shared/functions/not-implemented.function'

export interface DesignEditorTool_VM {
  design: Design
  designLogos: Design['designLogos']
  logos: BaseLogo[]
  handleLogoChange: (logo: DesignLogo) => void
  handleSaveDesign: (locked?: boolean) => Promise<void>
  currentView?: StyleView
  handleSelectView: (view: StyleView) => void
  handleRemoveLogo: (logo: DesignLogo) => void
  handleAddLogo: (logo: DesignLogo) => void,
  setInvalidPlacement: (id: DesignLogo['inDesignId'], placement: LogoPlacementPosition) => void
  invalidPlacement: IdLookUps<LogoPlacementPosition>
  locked: boolean,
  handleLockClick: (lock: boolean) => void,
  handleRotateLogo: (logo: DesignLogo, direction: Direction) => void,
  currentLogo?: DesignLogo
  handleSelectLogo: (logo: DesignLogo['inDesignId']) => void,
  reprepareLogos: number
}

export enum Direction {
  Left, Right
}

export function useDesignEditorToolViewModel(
  design: Design,
  logos: BaseLogo[],
  updateDesign: (design: Design) => Promise<void>
): DesignEditorTool_VM {

  const [currentDesign, setCurrentDesign] = useState<Design>(design)
  const [designLogos, setDesignLogos] = useState<DesignLogo[]>(design.designLogos)
  const [currentView, setCurrentView] = useState<StyleView>()
  const [currentLogoId, setCurrentLogoId] = useState<DesignLogo['inDesignId']>()
  const [locked, setLocked] = useState<boolean>(false)
  const [invalidPlacement, setInvalid] = useState<IdLookUps<LogoPlacementPosition>>({})
  const [reprepareLogos, setReprepareLogos] = useState<number>(0)

  const setInvalidPlacement = (id: DesignLogo['inDesignId'], placement: LogoPlacementPosition) => setInvalid(x => ({
    ...x,
    [id]: placement
  }))

  function handleLockClick(locked: boolean) {
    setLocked(locked)
  }

  function handleSelectView(view: StyleView) {
    setCurrentView(view)
  }

  function handleRotateLogo(logo: DesignLogo, direction: Direction) {
    const mod = direction === Direction.Left ? -0.05 : 0.05
    const max = 6.28318
    let s = logo.angleRads + mod
    if (s < 0) {
      s = max + s
    }
    logo.angleRads = (s) % max
    setReprepareLogos(x => x + 1)
    handleLogoChange(logo)
  }

  function handleAddLogo(logo: DesignLogo): void {
    if (!currentDesign) {
      return
    }
    const newLogo = copy(logo)

    // Calculate the logos initial width and height before placing on the designer
    const logoImg = new Image()

    logoImg.src = newLogo.logo.displayImage.highResUrl


    if (newLogo.logo.logoType === LogoType.ImageLogo) {
      logoImg.onload = () => {
        const logoAspectRatio = logoImg.width / logoImg.height

        if (logoImg.height > logoImg.width) {
          newLogo.heightMm = logo.heightMm
          newLogo.widthMm = newLogo.heightMm * logoAspectRatio
        } else {
          newLogo.widthMm = logo.widthMm
          newLogo.heightMm = newLogo.widthMm / logoAspectRatio
        }

        setDesignLogos(x => [...x, { ...newLogo }])
        setReprepareLogos(x => x + 1)
      }
    } else {
      /*updatedDesignSnapshot.logo.push(newLogo)
      setCurrentDesign(updatedDesignSnapshot)
      setCurrentStyle(style)
      setCurrentView(view)*/
    }
  }

  function handleLogoChange(logo: DesignLogo) {
    setDesignLogos(x => {
      const newList = x.filter(y => logo.inDesignId !== y.inDesignId)
      return [...newList, logo]
    })
  }

  function handleRemoveLogo(logo: DesignLogo) {
    if (!designLogos) {
      return
    }

    const logos = designLogos.filter(x => x.inDesignId !== logo.inDesignId)
    setDesignLogos(logos)
    setReprepareLogos(x => x + 1)
  }

  async function handleSaveDesign(locked?: boolean): Promise<void> {
    const designToUpdate = copy(currentDesign)
    if (locked) {
      // ask if user want customer/vendor approval
      // todo commit design
      NotImplemented('commit design')
      return
    }

    return updateDesign(designToUpdate).then()
  }

  const currentLogo = designLogos.find(dl => dl.inDesignId === currentLogoId)
  return {
    design: currentDesign,
    designLogos: designLogos,
    logos,
    currentView,
    handleSaveDesign,
    handleLogoChange,
    handleSelectView,
    handleRemoveLogo,
    handleAddLogo,
    setInvalidPlacement,
    invalidPlacement,
    locked,
    handleRotateLogo,
    currentLogo,
    handleLockClick,
    handleSelectLogo: setCurrentLogoId,
    reprepareLogos
  }
}