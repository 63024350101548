import { useEffect, useRef, useState } from 'react'
import { copy } from '../../../../utils/functions/ObjectUtil'
import { calculateActualPpcm } from '../../../../utils/functions/SizeUtil'
import {
  getLogoMaskCoverage,
  getLogoPlacementPosition,
  LogoWithLogoPlacementStatus,
  PlacementCalModel,
  ViewWithDimensions
} from '../design-style-editor/DesignStyleEditor.vm'
import { DesignLogo } from '../../../../core/models/entities/Design'
import { BaseLogo } from '../../../../core/models/entities/BaseLogo'
import { getOriginalTopLeft } from '../../../../utils/functions/MathUtil'
import { IdLookUps } from '../../../../utils/models/idLookUp'
import { LogoPlacementPosition } from '../models/Placement'


export interface Marker {
  angleRad: number
  id: string
  heightPx: number
  widthPx: number
  x: number
  y: number
  logo: BaseLogo,
  placementStatus: LogoPlacementPosition
}

export function useDesignViewViewModel(
  locked: boolean,
  currentView: ViewWithDimensions,
  logosOfCurrentView: LogoWithLogoPlacementStatus[],
  onLogoChange: (logo: LogoWithLogoPlacementStatus) => void,
  onSelectLogo: (logo: DesignLogo['inDesignId']) => void,
  onInvalid: (id: DesignLogo['inDesignId'], placement: LogoPlacementPosition) => void,
  invalidPlacement: IdLookUps<LogoPlacementPosition>,
  currentLogo?: DesignLogo
): DesignView_VM {
  const containerRef = useRef<HTMLDivElement>(null)
  const viewRef = useRef<HTMLDivElement>(null)

  const [view, setView] = useState<ViewWithDimensions>(currentView)
  const [viewSettings, setViewSettings] = useState<ScaledViewInfo>()
  const [viewCords, setViewCords] = useState<{ x: number, y: number }>()
  const [markers, setMarkers] = useState<Marker[]>([])

  useEffect(() => {
    if (viewSettings && logosOfCurrentView) {
      const markers = logosOfCurrentView.map(l => {
        const s = toMaker(l, viewSettings)
        s.placementStatus = getLogoPlacementPosition(toPlacementCalModel(l, viewSettings.ppcm, viewSettings.styleToViewSizeDelta), logosOfCurrentView.map(ly => toPlacementCalModel(ly, viewSettings.ppcm, viewSettings.styleToViewSizeDelta)))

        return s
      })
      setMarkers(markers)
    }
  }, [viewSettings, logosOfCurrentView])

  useEffect(() => {
    if (!markers) {
      return
    }

    // fix for rerender loop
    for (const marker of markers) {
      const isSame = invalidPlacement[marker.id] === marker.placementStatus

      if (!isSame) {
        onInvalid(marker.id, marker.placementStatus)
      }
    }


  }, [markers])

  useEffect(() => {
    if (viewRef.current) {
      const rect = viewRef.current.getBoundingClientRect()
      setViewCords({ x: rect.x, y: rect.y })

    }
  }, [viewRef.current])

  function onRefSet() {
    if (viewRef.current) {
      const rect = viewRef.current.getBoundingClientRect()
      setViewCords({ x: rect.x, y: rect.y })
    }
  }

  function buildErrorMsg(): string {
    let errorMsg = ''
    let hasInvalid = false

    for (const key in invalidPlacement) {
      switch (invalidPlacement[key]) {
        case LogoPlacementPosition.VALID:
          break
        case LogoPlacementPosition.INVALID:
          hasInvalid = true
          break
        case LogoPlacementPosition.OVERLAPPING:
          hasInvalid = true
          break
        case LogoPlacementPosition.UNKNOWN:
          break

      }
    }

    if (hasInvalid) {
      errorMsg = 'A logo in your design can’t be placed at its current placement.'
    }

    return errorMsg
  }

  const errorMsg = buildErrorMsg()

  function toMaker(logoComp: LogoWithLogoPlacementStatus, viewSettings: ScaledViewInfo): Marker {
    return {
      x: logoComp.designLogo.logoX * viewSettings.styleToViewSizeDelta,
      y: logoComp.designLogo.logoY * viewSettings.styleToViewSizeDelta,
      heightPx: logoComp.designLogo.heightMm * (viewSettings.ppcm / 10),
      widthPx: logoComp.designLogo.widthMm * (viewSettings.ppcm / 10),
      logo: logoComp.designLogo.logo,
      id: logoComp.designLogo.inDesignId,
      placementStatus: logoComp.position,
      angleRad: logoComp.designLogo.angleRads
    }
  }

  useEffect(() => {
    if (containerRef.current) {
      const currentContainerWidth = containerRef.current.offsetWidth
      const currentContainerHeight = containerRef.current.offsetHeight
      const view = copy(currentView)
      const settings = getViewSettings(view, currentContainerHeight, currentContainerWidth)
      setViewSettings(settings)
    }
  }, [containerRef])

  function modifyDimension(logo: LogoWithLogoPlacementStatus, sizeDelta: number) {
    logo.designLogo.widthMm *= sizeDelta
    logo.designLogo.heightMm *= sizeDelta
    logo.designLogo.logoX *= sizeDelta
    logo.designLogo.logoY *= sizeDelta
  }

  function radians_to_degrees(radians: number) {
    var pi = Math.PI
    return radians * (180 / pi)
  }

  function onLogoManipulation(
    marker: Marker,
    x: number,
    y: number,
    logoWidthPx: number,
    logoHeightPx: number,
    rotation: number,
    xRect: number,
    yRect: number
  ) {
    if (!viewSettings || !viewSettings.maskImage || !viewSettings.maskCanvas || !viewRef.current) {
      return
    }

    const ctx = viewSettings.maskCanvas.getContext('2d', { willReadFrequently: true })
    if (!ctx) {
      return
    }

    const logo = logosOfCurrentView.find(x => x.designLogo.inDesignId === marker.id)

    const sizeDiff = viewSettings.styleToViewSizeDelta
    const ppcm = viewSettings.ppcm

    const scaledHeight = logoHeightPx /*/ sizeDiff*/
    const scaledWidth = logoWidthPx /*/ sizeDiff*/
    const scaledViewHeight = viewSettings.height /*/ sizeDiff*/
    const scaledViewWidth = viewSettings.width /*/ sizeDiff*/

    const coverage = getLogoMaskCoverage(x, y, scaledHeight, scaledWidth, rotation, {
      maskCanvasCtx: ctx,
      maskImage: viewSettings.maskImage,
      width: scaledViewWidth,
      height: scaledViewHeight
    })
    /*console.log('size', sizeDiff)*/
    const { x: ox, y: oy } = getOriginalTopLeft(x, y, logoWidthPx, logoHeightPx, rotation)
    const updatedLogo = copy(logo!)
    updatedLogo.coverage = coverage
    updatedLogo.designLogo.angleRads = rotation
    updatedLogo.designLogo.logoX = ox / sizeDiff
    updatedLogo.designLogo.logoY = oy / sizeDiff
    updatedLogo.designLogo.heightMm = logoHeightPx / (ppcm / 10)
    updatedLogo.designLogo.widthMm = logoWidthPx / (ppcm / 10)
    updatedLogo.position = getLogoPlacementPosition(toPlacementCalModel(updatedLogo, ppcm, sizeDiff), logosOfCurrentView.map(l => toPlacementCalModel(l, ppcm, sizeDiff)))
    onLogoChange(updatedLogo)
  }

  return {
    containerRef,
    makers: markers,
    viewRef: viewRef,
    currentView: view,
    viewSettings: viewSettings,
    onLogoManipulation,
    currentLogo,
    onSelectLogo,
    errorMsg,
    locked,
    viewCords,
    onRefSet
  }
}


export interface DesignView_VM {
  containerRef: React.RefObject<HTMLDivElement>
  makers: Marker[]
  viewRef: React.RefObject<HTMLDivElement>
  currentView: ViewWithDimensions
  viewSettings?: ScaledViewInfo,
  onLogoManipulation: (
    marker: Marker,
    x: number,
    y: number,
    logoWidth: number,
    logoHeight: number,
    rotation: number,
    h: number,
    w: number
  ) => void,
  onSelectLogo: (logo: DesignLogo['inDesignId']) => void,
  currentLogo?: DesignLogo,
  errorMsg?: string;
  locked: boolean,
  viewCords?: { x: number, y: number },
  onRefSet: () => void
}

export function toPlacementCalModel(logo: LogoWithLogoPlacementStatus, ppcm: number, sizeDiff: number): PlacementCalModel {
  return {
    x: logo.designLogo.logoX,
    y: logo.designLogo.logoY,
    rotationRadian: logo.designLogo.angleRads,
    heightPx: (logo.designLogo.heightMm / sizeDiff * (ppcm / 10)),
    widthPx: (logo.designLogo.widthMm / sizeDiff * (ppcm / 10)),
    id: logo.designLogo.inDesignId,
    coverage: logo.coverage
  }
}


function getViewSettings(
  view: ViewWithDimensions,
  containerHeight: number,
  containerWidth: number
): ScaledViewInfo {

  let newCanvasWidth = 0
  let newCanvasHeight = 0
  const viewAspectRatio = view.width / view.height
  const margin = 0.9

  newCanvasHeight = containerHeight * margin
  newCanvasWidth = newCanvasHeight * viewAspectRatio
  if (newCanvasWidth > containerWidth) {
    newCanvasWidth = containerWidth * margin
    newCanvasHeight = newCanvasWidth * viewAspectRatio
  }
  const newPpcm = calculateActualPpcm(view.width, newCanvasWidth, view.view.ppcm)
  const styleImage = new Image()
  styleImage.src = view.view.updatedMaskUrl ?? view.view.maskUrl
  styleImage.crossOrigin = 'anonymous'

  const styleCanvas = document.createElement('canvas')
  styleCanvas.width = newCanvasWidth
  styleCanvas.height = newCanvasHeight

  return {
    viewId: 0,
    width: newCanvasWidth,
    height: newCanvasHeight,
    ppcm: newPpcm,
    maskImage: styleImage,
    maskCanvas: styleCanvas,
    styleToViewSizeDelta: newCanvasWidth / view.width
  }
}

export interface ViewInfo {
  viewId: number
  height: number,
  width: number,
  ppcm: number,
  styleToViewSizeDelta: number,
  maskImage: HTMLImageElement,
  maskCanvas: HTMLCanvasElement,

}

export interface ScaledViewInfo extends ViewInfo {
  styleToViewSizeDelta: number,

}