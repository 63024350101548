import { IdLookUps } from '../../../../utils/models/idLookUp'

export function getUnique<TObject>(array: TObject[], getKey: (obj: TObject) => string) {
  const map: IdLookUps<TObject> = {}

  for (const obj of array) {
    map[getKey(obj)] = obj
  }

  const result: TObject[] = []

  for (const mapKey in map) {
    result.push(map[mapKey])
  }

  return result
}