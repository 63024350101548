export interface ApprovalGroup {
  vendor: Approval;
  customer: Approval;
  external: Approval | null;
  publicKey: string | null;
  externalEmail: string | null;
}

export interface Approval {
  approver: string | null;
  status: ApprovalStatus;
  approvedAtUtc: Date;
  rejectionNote: string | null;
}

export enum ApprovalStatus {
  Pending,
  Approved,
  Rejected
}