import React, { useState } from 'react'
import { EmailPreferenceEditor, EmailPreferenceEditorViewModel, EmailPreferenceForm, EmailPreferenceTranslations } from './email-preference-editor.component'
import { CustomInputChangeEvent } from '../../../../utils/models/events/CustomInputChangeEvent'
import { t } from 'i18next'
import { Role } from '../../../../core/models/entities/Role'
import { useProfileContext } from '../../../../contexts/ProfileContext'

interface WrapperProps {
  initialForm: EmailPreferenceForm
  onFormChange: (form: EmailPreferenceForm) => void
  disabled: boolean,
  initialState: boolean
}

export function EmailPreferenceEditorWrapper({ initialForm, disabled, onFormChange, initialState }: WrapperProps) {
  const vm = useViewModel(initialForm, onFormChange, disabled, initialState)
  return <EmailPreferenceEditor {...vm} />;
}

function useViewModel(
  initialForm: EmailPreferenceForm,
  onFormChange: (form: EmailPreferenceForm) => void,
  disabled: boolean,
  initialState: boolean): EmailPreferenceEditorViewModel {

  const { role } = useProfileContext()

  const [toggleState, setToggleState] = useState(initialState);

  const form = {
    ...initialForm
  };

  function getTranslationByRole(role: Role): EmailPreferenceTranslations {
    switch (role) {
      case Role.Admin: {
        return {
          emailPreferenceNewDesignTranslation: t('editorInputFields.email_preference_newDesign_vendor')!,
          emailPreferenceNewLogosTranslation: t('editorInputFields.email_preference_newLogos_vendor')!,
          emailPreferenceNewOrderTranslation: t('editorInputFields.email_preference_newOrder_vendor')!,
          emailPreferencePendingOrderTranslation: t('editorInputFields.email_preference_pendingOrder_vendor')!,
          emailPreferenceOrderTranslation: t('editorInputFields.email_preference_order_vendor')!
        }
      }
      case Role.Vendor: {
        return {
          emailPreferenceNewDesignTranslation: t('editorInputFields.email_preference_newDesign_vendor')!,
          emailPreferenceNewLogosTranslation: t('editorInputFields.email_preference_newLogos_vendor')!,
          emailPreferenceNewOrderTranslation: t('editorInputFields.email_preference_newOrder_vendor')!,
          emailPreferencePendingOrderTranslation: t('editorInputFields.email_preference_pendingOrder_vendor')!,
          emailPreferenceOrderTranslation: t('editorInputFields.email_preference_order_vendor')!
        }
      }
      case Role.EndUser: {
        return {
          emailPreferenceNewDesignTranslation: t('editorInputFields.email_preference_newDesign_customer')!,
          emailPreferenceNewLogosTranslation: t('editorInputFields.email_preference_newLogos_customer')!,
          emailPreferenceNewOrderTranslation: t('editorInputFields.email_preference_newOrder_customer')!,
          emailPreferencePendingOrderTranslation: t('editorInputFields.email_preference_pendingOrder_customer')!,
          emailPreferenceOrderTranslation: t('editorInputFields.email_preference_order_customer')!,
        }
      }
    }
  }

  const translations: EmailPreferenceTranslations = getTranslationByRole(role!)

  function handleFormChange(event: React.ChangeEvent<HTMLInputElement> | CustomInputChangeEvent) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    //@ts-ignore
    form[name] = value;

    onFormChange(form);
  }

  const toggle = () => { setToggleState(!toggleState) }

  return {
    disabled,
    form,
    translations,
    onFormChange: handleFormChange,
    initialState: toggleState,
    onToggle: toggle
  };
}