import { MenuItem, Select, SelectChangeEvent } from '@mui/material'
import { InputLabel } from '../Input/InputLabel'
import { Commission, CommissionUnit } from '../../../core/models/entities/Commission'
import { copy } from '../../../utils/functions/ObjectUtil'
import './MaterialOverride.css'
import { Stepper } from '../Buttons/Stepper'

interface CommissionPickerProps {
  labelText?: string
  disabled?: boolean
  commission: Commission,
  onChange: (commission: Commission) => void
}

export function CommissionPicker({
  disabled,
  onChange,
  commission,
  labelText,
}: CommissionPickerProps) {
  const copied = copy(commission)
  const handleSelectChange = (event: SelectChangeEvent) => {
    copied.unit = event.target.value as CommissionUnit
    onChange(copied);
  }

  function valueChange(v: number) {
    copied.value = v
    onChange(copied);
  }

  return <div className={'pt-1'}>
    <div className={`flex items-center justify-between ${disabled ? 'text-gray-300' : ''}`}>
      {labelText?.length! > 0 && <InputLabel className='text-base'>{labelText}</InputLabel>}
      {copied.maxDimensions && <span className={'text-xxs'}>{copied.maxDimensions}</span>}
    </div>
    <div className={'h-7 flex space-x-1'}>
      <div className={'flex'}>
        <Select disabled={disabled}
          sx={{
            width: 50
          }}
          style={{ padding: '0px' }}
          value={copied.unit}
          onChange={handleSelectChange}
          className={`select-pad-override bg-gray-100`}
          SelectDisplayProps={{}}
        >
          <MenuItem value={'DKK'}>DKK</MenuItem>
          <MenuItem value={'%'}>%</MenuItem>
        </Select>
      </div>
      <Stepper onValueChange={valueChange} min={0} value={copied.value} />
    </div>
  </div>
}

export function sanitizeValue(value: number, min?: number, max?: number) {
  value = Number.parseFloat(value.toFixed(2))

  if (min !== undefined) {
    if (value < min) {
      return min
    }
  }
  if (max !== undefined) {
    if (value > max) {
      return max
    }
  }

  return value
}
