import { nameOf } from '../../../../utils/functions/ReflectionUtil'
import { Order } from '../../../../core/models/entities/Order'

export function orderColumnsConfig() {
  return [
    {
      value: nameOf<Order>(x => x.orderNumber),
      canSortBy: true,
      displayValue: 'Order no.',
      weight: 1
    },
    {
      value: nameOf<Order>(x => x.requisitionNumber),
      canSortBy: true,
      displayValue: 'Requisition number',
      weight: 1.5
    },
    {
      value: nameOf<Order>(x => x.organization),
      canSortBy: true,
      displayValue: 'Customer',
      weight: 2
    },
    {
      value: nameOf<Order>(x => x.organization.contactPerson),
      canSortBy: true,
      displayValue: 'Contact person',
      weight: 0
    },
    {
      value: nameOf<Order>(x => x.status),
      canSortBy: true,
      displayValue: 'Status',
      weight: 1.5
    },
    {
      value: nameOf<Order>(x => x.placedAtUtc),
      canSortBy: true,
      displayValue: 'Order date',
      weight: 1
    },
    {
      value: 'actions',
      canSortBy: false,
      displayValue: 'Action',
      weight: 1.2
    }
  ]
}