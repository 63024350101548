import React from 'react'
import { Section } from '../../../components/StructureElements/Section'
import { SectionHeaderTypography } from '../../../components/Typographies/SectionHeaderTypography'
import { InputWrapper } from '../../../components/Input/InputWrapper'
import { InputLabel } from '../../../components/Input/InputLabel'
import { CustomInput } from '../../../components/Input/CustomInput'
import { nameOf } from '../../../../utils/functions/ReflectionUtil'
import { CustomInputChangeEvent } from '../../../../utils/models/events/CustomInputChangeEvent'
import { CustomPhoneInput } from '../../../components/Input/CustomPhoneInput'
import { StrucRow } from '../../../components/StructureElements/StrucRow'
import { StrucCol } from '../../../components/StructureElements/StrucCol'
import { CountryAutoComplete, CountryAutoCompleteItem } from '../../../components/Input/CountryAutoComplete'

export interface DetailsForm {
  name: string
  contactEmail: string
  email: string
  contactPerson: string
  contactPhone: string
  phone: string
  postCode: string
  address: string
  city: string
  country: string
  cvr: string
  eori?: string,
  shippingName?: string
  shippingAddress?: string
  shippingPostCode?: string
  shippingCity?: string
  shippingCountry?: string
  shippingContactPerson?: string
  shippingContactPhone?: string
  shippingContactEmail?: string
}

export interface OrganizationDetailForm_VM {
  onFormChange: (event: React.ChangeEvent<HTMLInputElement> | CustomInputChangeEvent) => void
  onCountryChange: (country: CountryAutoCompleteItem) => void
  onShippingCountryChange: (country: CountryAutoCompleteItem) => void
  disabled: boolean
  form: DetailsForm
}

export function OrganizationDetailForm({ disabled, form, onFormChange, onCountryChange, onShippingCountryChange }: OrganizationDetailForm_VM) {
  return (
    <Section className={'space-y-4'}>
      <SectionHeaderTypography>Invoice Details</SectionHeaderTypography>
      <StrucRow>
        <StrucCol size={3.5}>
          <InputWrapper>
            <InputLabel>company</InputLabel>
            <CustomInput
              name={nameOf<DetailsForm>(lf => lf.name)}
              value={form.name}
              onChange={x => onFormChange(x)}
              disabled={disabled}
            />
          </InputWrapper>
          <InputWrapper>
            <InputLabel>Company Email</InputLabel>
            <CustomInput
              type={'email'}
              name={nameOf<DetailsForm>(lf => lf.email)}
              value={form.email}
              onChange={x => onFormChange(x)}
              disabled={disabled}
            />
          </InputWrapper>
          <InputWrapper>
            <InputLabel>Company Phone</InputLabel>
            <CustomPhoneInput
              name={nameOf<DetailsForm>(lf => lf.phone)}
              value={form.phone}
              onChange={x => onFormChange(x)}
              disabled={disabled}
            />
          </InputWrapper>
          <InputWrapper>
            <InputLabel>CVR</InputLabel>
            <CustomInput
              name={nameOf<DetailsForm>(lf => lf.cvr)}
              value={form.cvr}
              onChange={x => onFormChange(x)}
              disabled={disabled}
            />
          </InputWrapper>
        </StrucCol>
        <StrucCol size={3.5} offset={0.5}>
          <InputWrapper>
            <InputLabel>Contact Person</InputLabel>
            <CustomInput
              type={'email'}
              name={nameOf<DetailsForm>(lf => lf.contactPerson)}
              value={form.contactPerson}
              onChange={x => onFormChange(x)}
              disabled={disabled}
            />
          </InputWrapper>
          <InputWrapper>
            <InputLabel>Contact Email</InputLabel>
            <CustomInput
              name={nameOf<DetailsForm>(lf => lf.contactEmail)}
              value={form.contactEmail}
              onChange={x => onFormChange(x)}
              disabled={disabled}
            />
          </InputWrapper>
          <InputWrapper>
            <InputLabel>Contact Phone</InputLabel>
            <CustomPhoneInput
              name={nameOf<DetailsForm>(lf => lf.contactPhone)}
              value={form.contactPhone}
              onChange={x => onFormChange(x)}
              disabled={disabled}
            />
          </InputWrapper>
          <InputWrapper>
            <InputLabel>EORI</InputLabel>
            <CustomInput
              name={nameOf<DetailsForm>(lf => lf.eori)}
              value={form.eori}
              onChange={x => onFormChange(x)}
              disabled={disabled}
            />
          </InputWrapper>
        </StrucCol>
        <StrucCol size={3.5} offset={0.5}>
          <InputWrapper>
            <InputLabel>Address</InputLabel>
            <CustomInput
              name={nameOf<DetailsForm>(lf => lf.address)}
              value={form.address}
              onChange={x => onFormChange(x)}
              disabled={disabled}
            />
          </InputWrapper>
          <InputWrapper>
            <InputLabel>City</InputLabel>
            <CustomInput
              name={nameOf<DetailsForm>(lf => lf.city)}
              value={form.city}
              onChange={x => onFormChange(x)}
              disabled={disabled}
            />
          </InputWrapper>
          <InputWrapper>
            <InputLabel>PostCode</InputLabel>
            <CustomInput
              name={nameOf<DetailsForm>(lf => lf.postCode)}
              value={form.postCode}
              onChange={x => onFormChange(x)}
              disabled={disabled}
            />
          </InputWrapper>
          <InputWrapper>
            <InputLabel>Country</InputLabel>
            <CountryAutoComplete disabled={disabled ?? false} selectedCode={form.country} onCountryChange={onCountryChange} />
          </InputWrapper>          
        </StrucCol>
      </StrucRow>

      <SectionHeaderTypography>Shipping</SectionHeaderTypography>
      <StrucRow>
        <StrucCol size={3.5}>
          <InputWrapper>
            <InputLabel>Shipping Name</InputLabel>
            <CustomInput
              name={nameOf<DetailsForm>(lf => lf.shippingName)}
              value={form.shippingName}
              onChange={x => onFormChange(x)}
              disabled={disabled}
            />
          </InputWrapper>
          <InputWrapper>
            <InputLabel>Shipping Address</InputLabel>
            <CustomInput
              name={nameOf<DetailsForm>(lf => lf.shippingAddress)}
              value={form.shippingAddress}
              onChange={x => onFormChange(x)}
              disabled={disabled}
            />
          </InputWrapper>
          <InputWrapper>
            <InputLabel>Shipping PostCode</InputLabel>
            <CustomInput
              name={nameOf<DetailsForm>(lf => lf.shippingPostCode)}
              value={form.shippingPostCode}
              onChange={x => onFormChange(x)}
              disabled={disabled}
            />
          </InputWrapper>
        </StrucCol>
        <StrucCol size={3.5} offset={0.5}>
          <InputWrapper>
            <InputLabel>Shipping City</InputLabel>
            <CustomInput
              name={nameOf<DetailsForm>(lf => lf.shippingCity)}
              value={form.shippingCity}
              onChange={x => onFormChange(x)}
              disabled={disabled}
            />
          </InputWrapper>
          <InputWrapper>
            <InputLabel>Shipping Country</InputLabel>
            <CountryAutoComplete disabled={disabled ?? false} selectedCode={form.shippingCountry} onCountryChange={onShippingCountryChange} />
          </InputWrapper>
          <InputWrapper>
            <InputLabel>Shipping Contact Person</InputLabel>
            <CustomInput
              name={nameOf<DetailsForm>(lf => lf.shippingContactPerson)}
              value={form.shippingContactPerson}
              onChange={x => onFormChange(x)}
              disabled={disabled}
            />
          </InputWrapper>
        </StrucCol>
        <StrucCol size={3.5} offset={0.5}>
          <InputWrapper>
            <InputLabel>Shipping Contact Phone</InputLabel>
            <CustomPhoneInput
              name={nameOf<DetailsForm>(lf => lf.shippingContactPhone)}
              value={form.shippingContactPhone || ''}
              onChange={x => onFormChange(x)}
              disabled={disabled}
            />
          </InputWrapper>
          <InputWrapper>
            <InputLabel>Shipping Contact Email</InputLabel>
            <CustomInput
            type={'email'}
              name={nameOf<DetailsForm>(lf => lf.shippingContactEmail)}
              value={form.shippingContactEmail}
              onChange={x => onFormChange(x)}
              disabled={disabled}
            />
          </InputWrapper>
        </StrucCol>
      </StrucRow>
    </Section>)
}
