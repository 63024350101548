import { Style } from '../../../../core/models/entities/Style'
import { ColumnSettings } from '../../../components/GenericTable/SubComponents/ListFrame'
import { StyleListItem_VM } from '../components/style-list-item.component'
import React, { JSX } from 'react'
import IconButton from '../../../components/LogoButton/IconButton'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPenToSquare } from '@fortawesome/free-regular-svg-icons'
import { useNavigate } from 'react-router-dom'

export function useStyleListItemViewModel(
  style: Style,
  columns: ColumnSettings
): StyleListItem_VM {
  const navigate = useNavigate()

  function handleEditStyle(style: Style) {
    navigate(`/style/${style.id}/edit`)
  }

  function handleEditClick(e: React.MouseEvent<HTMLElement>, o: Style) {
    e.stopPropagation()
    handleEditStyle(o)
  }

  let action: JSX.Element = <>
    <IconButton icon={<FontAwesomeIcon icon={faPenToSquare} />}
                onClick={(e) => handleEditClick(e, style)}>Edit</IconButton>
  </>

  return {
    columns: columns,
    style: style,
    actionButton: action
  }
}