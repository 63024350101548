import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faRightFromBracket, faUser } from '@fortawesome/free-solid-svg-icons'
import { ListItemIcon, ListItemText, MenuItem } from '@mui/material'
import { Link } from 'react-router-dom'
import { NavigationBarButton } from './NavigationBarButton'
import { useProfileContext } from '../../../../contexts/ProfileContext'
import { Role } from '../../../../core/models/entities/Role'
import { BaseMenu } from '../../../components/Cart/Cart-Icon/BaseMenu'
import { useAdminImpersonationDialog } from '../hooks/useAdminImpersonationDialog'

interface LoggedInMenu {
  logout: () => void
}

export function LoggedInMenu({ logout }: LoggedInMenu) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  const { profile, role } = useProfileContext();
  function handleClick(event: React.MouseEvent<HTMLButtonElement>) {
    setAnchorEl(event.currentTarget)
  }

  const { isImpersonatingAdmin } = useProfileContext()

  const { component, open: openImpersonation } = useAdminImpersonationDialog({
    onContinue: async () => { }
  })

  function handleClose() {
    setAnchorEl(null)
  }

  function handleLogOut() {
    handleClose()
    logout()
  }

  function getUserInfo() {
    switch (role!) {
      case Role.Admin: return "JetSport Admin"
      case Role.Vendor: return profile?.userOrganizationInformations.organizationName ?? "Profile"
      case Role.EndUser: return profile?.userOrganizationInformations.organizationName ?? "Profile"
    }
  }

  return (
    <div>
      {component}
      <NavigationBarButton className={'!w-[60px]'} onClick={handleClick}>{<FontAwesomeIcon size={'lg'}
        icon={faUser} />}</NavigationBarButton>
      <BaseMenu
        id='basic-menu'
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button'
        }}
      >
        <Link to={'/account/me'}>
          <MenuItem onClick={handleClose}>
            <ListItemIcon>
              <FontAwesomeIcon icon={faUser} />
            </ListItemIcon>
            <ListItemText>{getUserInfo()}</ListItemText>
          </MenuItem>
        </Link>
        {/* {isImpersonatingAdmin && <MenuItem onClick={() => openImpersonation()}>
          <ListItemIcon>
            <FontAwesomeIcon icon={faUser} />
          </ListItemIcon>
          <ListItemText>Impersonation</ListItemText>
        </MenuItem>} */}
        <MenuItem onClick={handleLogOut}>
          <ListItemIcon>
            <FontAwesomeIcon icon={faRightFromBracket} />
          </ListItemIcon>
          <ListItemText>Logout</ListItemText>
        </MenuItem>
      </BaseMenu>
    </div >
  )
}