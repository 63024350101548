import React, { JSX } from 'react'
import { Dialog_VM, DialogFrame, useDialog } from './dialog-base'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons'
import { PrimaryButton } from '../Buttons/PrimaryButton'
import { SecondaryButton } from '../Buttons/SecondaryButton'

export function useConfirmationDialog(
  text: string,
  onAccept: () => void,
  onCancel?: () => void

): { component: JSX.Element, open: () => void } {
  const dialog = useDialog()

  function handleAccept() {
    onAccept()
    dialog.closeDialog()
  }
  function handleCancel() {
    if (onCancel) onCancel()
    dialog.closeDialog()
  }

  return {
    component: <ConfirmationDialog2 dialogVm={dialog} onAccept={handleAccept} onCancel={handleCancel} text={text} />,
    open: dialog.openDialog
  }
}


export function ConfirmationDialog2({
                                      onAccept, text, onCancel, dialogVm
                                    }: {
  dialogVm: Dialog_VM
  onAccept: (e: React.MouseEvent<HTMLElement>) => void,
  onCancel: (e: React.MouseEvent<HTMLElement>) => void,
  text: string,
}) {

  return <DialogFrame dialogOption={dialogVm}>
    <div className={'flex flex-col space-y-4'}>
      <div className={'flex justify-center'}>
        <FontAwesomeIcon size={'3x'} icon={faTriangleExclamation} />
      </div>
      <p className={'text-center'}>{text}</p>
      <div className={'flex justify-center space-x-4'}>
        <PrimaryButton width={'sm'} onClick={onAccept}>Yes</PrimaryButton>
        <SecondaryButton width={'sm'} className={'button-secondary w-32'} onClick={onCancel}>No</SecondaryButton>
      </div>
    </div>
  </DialogFrame>
}
