import React from 'react';
import { Checkbox } from '@mui/material';
import { useWhiteLabelContext } from '../../../WhiteLabelContext';

export function CheckboxOptions({ header, explain, checked, onChange, name, checkboxPosition, disabled = false }: {
  name: string
  header?: string
  explain?: string
  checked: boolean
  disabled?: boolean
  checkboxPosition?: "left" | "right"
  onChange: (x: React.ChangeEvent<HTMLInputElement>) => void
}) {

  const { whiteLabel } = useWhiteLabelContext()
  const checkbox = <div>
    <Checkbox
      checked={checked}
      name={name}
      onChange={onChange} 
      disabled={disabled}
      style={ checked ? { 'color': whiteLabel.colorHex } : {} } />
    </div>


  return <div className={'flex items-center'}>
    {checkboxPosition === 'left' ? checkbox: <></>}
    <div className={'space-y-0.5 w-full'}>
      {header && <Typo1>{header}</Typo1>}
      {explain && <Typo2>{explain}</Typo2>}
    </div>
    {(checkboxPosition === 'right' || checkboxPosition === undefined) ? checkbox : <></>}
  </div>;
}

export const Typo1 = React.forwardRef<HTMLParagraphElement, React.HTMLAttributes<HTMLParagraphElement>>(
  (props, ref) => {
    const { className, children, ...rest } = props;
    return <p ref={ref}
      className={`font-semibold capitalize text-sm ${className ? className : ''}`}
      {...rest}
    >
      {children}
    </p>;
  });

export const Typo2 = React.forwardRef<HTMLParagraphElement, React.HTMLAttributes<HTMLParagraphElement>>(
  (props, ref) => {
    const { className, children, ...rest } = props;
    return <p ref={ref}
      className={`font-normal italic capitalize text-sm ${className ? className : ''}`}
      {...rest}
    >
      {children}
    </p>;
  });
