import React, { createContext, useContext, useState } from 'react'
import { Country } from '../../../../core/models/entities/Country'

export interface CountryProviderContext {
  countries: Country[]
}

const CountryContext = createContext<CountryProviderContext | undefined>(undefined)

interface CountryProviderProps {
  children: React.ReactNode
  countries: Country[]
}

function CountryProvider({
  children,
  countries: o
}: CountryProviderProps): React.ReactElement {
  const [countries] = useState<Country[]>(o)

  return (
    <CountryContext.Provider
      value={{ countries }}>
      {children}
    </CountryContext.Provider>
  )
}

function useCountryContext() {
  const context = useContext(CountryContext)
  if (!context) {
    throw new Error('useCountryContext must be used within a RepositoriesProvider')
  }

  return context
}


export { CountryProvider, useCountryContext, CountryContext }