import React, { useState } from 'react'
import { nameOf } from '../../../../utils/functions/ReflectionUtil'
import { CustomTextArea } from '../../../components/Input/CustomInput'
import { InputWrapper } from '../../../components/Input/InputWrapper'
import { CustomInputChangeEvent } from '../../../../utils/models/events/CustomInputChangeEvent'
import { InputLabel } from '../../../components/Input/InputLabel'
import { SecondaryButton } from '../../../components/Buttons/SecondaryButton'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPenToSquare } from '@fortawesome/free-solid-svg-icons'

interface Props {
  onFormChange: (event: React.ChangeEvent<HTMLInputElement> | CustomInputChangeEvent) => void
  disabled: boolean
  initialState: boolean
  value: string
  nameOfOption: string;
  inputLabel: string
}

export function InputOrderOptionsEditor({ disabled, onFormChange, initialState = false, nameOfOption, value, inputLabel }: Props) {
  const [ toggle, setToggle ] = useState<boolean>(true);

  const disableStyle = toggle ? { background: "#F3F3F3"} : {};

  return (
    <InputWrapper>
      <InputLabel>{inputLabel}</InputLabel>
      <div className={'flex space-x-4'}>
        <div className={'relative flex-1'}>
        <CustomTextArea
          name={nameOfOption}
          value={value}
          onChange={x => onFormChange(x)}
          disabled={toggle}
          style={disableStyle}
        />
        </div>
        <SecondaryButton width={'icon'} className={'ml-4'} onClick={() => setToggle(!toggle)} disabled={disabled}>
          <FontAwesomeIcon size={'lg'} icon={faPenToSquare}/>
        </SecondaryButton>
      </div>
    </InputWrapper>)
}
