import { JSX, useState } from 'react'
import { Dialog_VM, DialogFrame, useDialog } from './dialog-base'
import { PrimaryButton } from '../Buttons/PrimaryButton'
import { DesignLogo } from '../../../core/models/entities/Design'
import { getColorsElements } from '../../screens/order-create/subcomponents/ProductDetailsLogo'
import { useOrderActionHandler } from '../../screens/order-create/hooks/useOrderActionsHandler'
import { useCartContext } from '../../../contexts/CartContext'
import { BaseLogo } from '../../../core/models/entities/BaseLogo'
import { SecondaryButton } from '../Buttons/SecondaryButton'
import { Stepper } from '../Buttons/Stepper'

interface useAddLogoToOrderDialogProps {
  component: JSX.Element
  open: () => void
}

export function useAddLogoToOrderDialog(
  designLogo: DesignLogo,
  onAccept?: () => void,
  onCancel?: () => void
): useAddLogoToOrderDialogProps {
  const dialog = useDialog()

  function handleAccept() {
    if (onAccept) onAccept()
    dialog.closeDialog()
  }

  function handleCancel() {
    if (onCancel) onCancel()
    dialog.closeDialog()
  }

  return {
    component: <AddLogoToDesignOrderDialog dialogVm={dialog}
      baseLogo={designLogo.logo!}
      onAccept={handleAccept}
      onCancel={handleCancel} />,
    open: dialog.openDialog
  }
}

interface AddLogoToDesignOrderDialogProps {
  dialogVm: Dialog_VM
  baseLogo: BaseLogo
  onAccept: () => void
  onCancel?: () => void
}

export function AddLogoToDesignOrderDialog({ baseLogo, onAccept, onCancel, dialogVm }: AddLogoToDesignOrderDialogProps) {

  const { handleLogoSetQuantities } = useOrderActionHandler();

  const { logos } = useCartContext();
  const logo = logos.find(x => x.id === '' + baseLogo.id)

  const [logosToStorage, setLogosToStorage] = useState<number>(logo?.toStorage ?? 0);
  const [logosToDelivery, setLogosToDelivery] = useState<number>(logo?.toDelivery ?? 0);

  function handleOnAccept() {
    handleLogoSetQuantities(baseLogo, logosToDelivery, logosToStorage)
    onAccept();
  }

  function handleLogosToDelivery(v: number) {
    setLogosToDelivery(v)
  }

  function handleLogosToStorage(v: number) {
    setLogosToStorage(v)
  }

  const number = baseLogo.id
  const size = baseLogo.transferMethod.size
  const storage = baseLogo.inStorage ?? 0
  const colors = getColorsElements(baseLogo);

  const alt = `Logo - ${baseLogo.title}`
  const src = baseLogo.displayImage.lowResUrl

  return (
    <DialogFrame dialogOption={dialogVm}>
      <div className={'flex flex-col space-y-4 p-4'}>
        <p className={'text-left text-base font-bold'}>Add to Order ({baseLogo.title})</p>
        <div className={'flex flex-row justify-between space-x-4'}>
          <div>
            <p className='uppercase'>No.</p>
            <p>{number}</p>
          </div>
          <div>
            <p className='uppercase'>Image</p>
            <img className={'w-[70px] h-[45px] object-contain'} src={src} alt={alt} />
          </div>
          <div>
            <p className='uppercase'>In Storage</p>
            <p>{storage}</p>
          </div>
          <div>
            <p className='uppercase'>Size</p>
            <p>{size}</p>
          </div>
          <div>
            <p className='uppercase'>Qty.</p>
            <span className='block pt-1 text-xs'>
              <p className={'uppercase inline pr-1'}>Logos to storage</p>
            </span>
            <Stepper onValueChange={handleLogosToStorage} min={0} value={logosToStorage} />
            <span className='block pt-1 text-xs'>
              <p className={'uppercase inline pr-1'}>Logos to delivery</p>
            </span>
            <Stepper onValueChange={handleLogosToDelivery} min={0} value={logosToDelivery} />
          </div>
        </div>
        <div className={'flex flex-row justify-between space-x-4'}>
          <div>
            <p className='uppercase'>Colors</p>
            {colors}
          </div>
        </div>
        <div className={'flex flex-row justify-between space-x-4'}>
          <SecondaryButton width={'full'} className={'bg-[#F8FAFD]'} onClick={onCancel}>
            <span className={'flex-1'}>Cancel</span>
          </SecondaryButton>
          <PrimaryButton width={'full'} className={'bg-[#F8FAFD]'} onClick={handleOnAccept}>
            <span className={'flex-1'}>Confirm</span>
          </PrimaryButton>
        </div>
      </div>
    </DialogFrame>
  )
}
