import { ReactElement } from 'react'
import { useProfileContext } from '../contexts/ProfileContext'
import { Role } from '../core/models/entities/Role'
import { NotFoundPage } from '../presentation/screens/not-found/NotFoundPage'

interface Props {
  requiredRole: Role
  element: JSX.Element
}

export default function AuthRoute({ requiredRole, element }: Props): ReactElement {
  const { profile } = useProfileContext()

  if (profile && profile.userOrganizationInformations.role >= requiredRole) {
    return element
  } else {
    return <NotFoundPage />
  }
}
