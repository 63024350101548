import React from 'react'
import { ComponentState, useComponentStateMachine } from '../../shared/hooks/useComponentStateMachine'
import { useRepositoriesContext } from '../../../contexts/RepositoriesContext'
import { LoadingView } from '../../components/loading-view/LoadingView'
import { ErrorView } from '../../components/error-view/ErrorView'
import { getAllQuery } from '../logo-editor/logo-editor.screen'
import { CreateDesignFormWrapper } from './wrappers/create-design-form.wrapper'

export function CreateDesignScreen(): React.ReactElement {
  const { state, dataTransition } = useComponentStateMachine()
  const { manufacturerRepository, organizationRepository } = useRepositoriesContext()

  const {
    isLoading: isOrganizationLoading,
    isSuccess: isOrganizationSuccess,
    isError: isOrganizationError,
    data: customerPage
  } = organizationRepository.useAllCustomers(getAllQuery)

  const {
    data: manufacturers,
    isLoading: isManufacturersLoading,
    isError: isManufacturersError,
    isSuccess: isManufacturersSuccess
  } = manufacturerRepository.useManufacturers()


  const isLoading = isOrganizationLoading || isManufacturersLoading
  const isError = isOrganizationError || isManufacturersError
  const isSuccess = isOrganizationSuccess && isManufacturersSuccess
  dataTransition(isLoading, isSuccess, isError)
  return state === ComponentState.Succeed
    ? <CreateDesignFormWrapper customers={customerPage!.results} manufacturers={manufacturers!} />
    : state === ComponentState.Loading
      ? <LoadingView />
      : <ErrorView />
}








