import { useWhiteLabelContext } from '../../../WhiteLabelContext'
import { useProfileContext } from '../../../contexts/ProfileContext'
import Brand from '../../components/customer-brand/Brand'

function MainFooter() {
  const { profile } = useProfileContext()
  const { whiteLabel } = useWhiteLabelContext()
  const year = new Date().getFullYear()

  return <footer className='w-full bg-white border-t border-gray-200 px-8'>
    <div className='max-w-[90rem] h-16 w-full mx-auto flex items-center gap-x-10 justify-between'>
      <Brand profile={profile!} />
      <p className={'font-[400] color-[#8D8D8D]'}>© {year} {whiteLabel?.whiteLabelCopyrightName}. All rights
        reserved.</p>
    </div>
  </footer>
}


export default MainFooter