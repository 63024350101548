import { useQuery, useQueryClient } from '@tanstack/react-query'
import { Resource } from '../../core/models/wrappers/Resource'
import { OrderRepository } from '../../core/repositories/OrderRepository'
import { useApiClient } from '../clients/ApiClient'
import { PageQuery, toQueryParam } from '../../utils/models/pageQuery'
import { PagedResponse } from '../models/responses/pagedResponse'
import { DeliveryType, LogoPrice, Order, OrderDeliveryPreferences, Packaging } from '../../core/models/entities/Order'
import { CartItem, CartItemDesign } from '../../presentation/screens/order-create/models/CartItem'
import { mapToCreateOrder } from '../models/mappers/mapCartOrders'
import { mapLogoPrice, mapToCreateOrderDeliveryPreferences, mapToOrder } from '../models/mappers/mapOrder'
import { Verdict } from '../../presentation/components/ApprovalVoting/approval-voting'
import { DesignTemplate } from '../../core/models/entities/DesignTemplate'
import { mapToDesignTemplate } from '../models/mappers/mapDesignTemplate'
import { dissmissToast, useLoadingToast } from '../../presentation/components/Toast/Toast'

function useOrderRepository(): OrderRepository {
  const apiClient = useApiClient()

  const queryClient = useQueryClient()
  async function invalidateQueries() {
    await queryClient.refetchQueries({ queryKey: ['orders'], type: 'all', refetchPage: () => true })
  }

  function useAllOrders(query: PageQuery): Resource<PagedResponse<Order>> {
    return useQuery(['orders', toQueryParam(query)], () => apiClient.getAllOrders(query), {
      select: (data) => ({ ...data, results: data.results.map(mapToOrder) })
    })
  }

  function useOrder(orgId: number, orderId: number): Resource<Order> {
    return useQuery(['orders', orderId], () => apiClient.getOrder(orgId, orderId), {
      staleTime: 1,
      select: (data) => mapToOrder(data)
    })
  }

  async function createOrder(orgId: number, orderlines: CartItem[]): Promise<Order> {
    const createOrderDto = mapToCreateOrder(orderlines);
    return await apiClient.createOrder(orgId, createOrderDto).then(res => {
      invalidateQueries().then()
      return mapToOrder(res)
    })
  }

  function useOrderPrices(orgId: number | undefined, orderlines: CartItem[]): Resource<LogoPrice[]> {
    const createOrderDto = mapToCreateOrder(orderlines);
    return useQuery(['orders', orgId], () => apiClient.getOrderPrices(orgId, createOrderDto), {
      staleTime: 1,
      select: (data) => data?.map(x => mapLogoPrice(x)) ?? []
    })
  }

  async function commitOrder(orgId: number, orderId: number, orderDeliveryPreferences: OrderDeliveryPreferences): Promise<Order> {
    const createOrderDeliveryPreferencesDto = mapToCreateOrderDeliveryPreferences(orderDeliveryPreferences);
    return await apiClient.commitOrder(orgId, orderId, createOrderDeliveryPreferencesDto).then((res) => {
      invalidateQueries().then()
      return mapToOrder(res)
    })
  }

  async function placeVerdict(order: Order, verdict: Verdict): Promise<Order> {
    return await apiClient.placeVerdictOrder(order, verdict).then(res => {
      invalidateQueries().then()
      return res
    })
  }

  async function setPackagingAndDelivery(order: Order, deliveryType: DeliveryType, packaging: Packaging): Promise<Order> {
    return await apiClient.setPackagingAndDelivery(order, deliveryType, packaging).then(res => {
      invalidateQueries().then()
      return res
    })
  }

  async function downloadTemplate(orgId: number, designs: CartItemDesign[]): Promise<Blob> {
    const toast = useLoadingToast("Downloading design template")
    return await apiClient.downloadTemplate(orgId, designs).then(res => {
      invalidateQueries().then()
      return res
    }).finally(() => dissmissToast(toast))
  }

  async function uploadTemplate(orgId: number, spreadsheet: File): Promise<DesignTemplate> {
    const toast = useLoadingToast("Uploading design template")
    return await apiClient.uploadTemplate(orgId, spreadsheet).then(res => {
      return mapToDesignTemplate(res)
    }).finally(() => dissmissToast(toast))
  }

  async function reorder(orgId: number, orderId: number): Promise<Order> {
    return await apiClient.reorder(orgId, orderId).then(res => {
      invalidateQueries().then()
      return mapToOrder(res)
    })
  }

  async function updateOrder(orgId: number, orderId: number, orderlines: CartItem[]): Promise<Order> {
    const updateOrderDto = mapToCreateOrder(orderlines);
    return await apiClient.updateOrder(orgId, orderId, updateOrderDto).then(res => {
      invalidateQueries().then()
      return mapToOrder(res)
    })
  }

  return {
    useAllOrders,
    useOrder,
    createOrder,
    commitOrder,
    placeVerdict,
    setPackagingAndDelivery,
    downloadTemplate,
    uploadTemplate,
    reorder,
    useOrderPrices,
    updateOrder
  }
}

export { useOrderRepository }