import React from 'react'

export const BoxModalContent = React.forwardRef<HTMLDivElement, React.HTMLProps<HTMLDivElement>>(
  (props, ref) => {
    const { className, ...rest } = props
    return (
      <div
        ref={ref}
        className={`px-8 flex flex-col flex-1 ${className ? className : ''}`}
        {...rest}>
        {props.children}
      </div>
    )
  }
)
