import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'
import React from 'react'

function ArrowSeperator() {
  return <div className={'my-auto'}>
    <div
      className={'mx-2 w-9 h-9 rounded-full border border-gray-200 text-gray-600 flex items-center justify-center'}>
      <FontAwesomeIcon size={'lg'} icon={faArrowRight} />
    </div>
  </div>
}

export default ArrowSeperator