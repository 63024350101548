import { Section } from '../../../components/StructureElements/Section'
import { SectionHeader } from '../SectionHeader'
import { SectionHeaderTypography } from '../../../components/Typographies/SectionHeaderTypography'
import { SectionContent } from '../SectionContent'
import { StrucRow } from '../../../components/StructureElements/StrucRow'
import { OrganizationLogoPicker } from './organization-logo-picker'
import { ColorPickerEditorWrapper } from './color-picker-editor.wrapper'
import { InputWrapper } from '../../../components/Input/InputWrapper'
import { InputLabel } from '../../../components/Input/InputLabel'
import { CustomInput } from '../../../components/Input/CustomInput'
import { nameOf } from '../../../../utils/functions/ReflectionUtil'
import { CustomPhoneInput } from '../../../components/Input/CustomPhoneInput'
import { CustomInputChangeEvent } from '../../../../utils/models/events/CustomInputChangeEvent'
import { StrucCol } from '../../../components/StructureElements/StrucCol'

export interface WhiteLabelConfigurationsForm {
  colorHex: string;
  whiteLabelCopyrightName: string
  whiteLabelContactEmail: string
  whiteLabelContactPhone: string
  whiteLabelVendorName: string
  whiteLabelWebsiteLink: string
}

export interface WhiteLabelEditorViewModel {
  onFormColorHexChange: (form: WhiteLabelConfigurationsForm) => void
  onFormChange: (event: React.ChangeEvent<HTMLInputElement> | CustomInputChangeEvent) => void
  onLogoChange: (file: File) => void
  disabled: boolean
  form: WhiteLabelConfigurationsForm
  initialState: boolean
  onToggle: () => void
  initialLogo: string
}

export function WhiteLabelEditor({disabled, onFormColorHexChange, onFormChange, initialState, form, onToggle, onLogoChange, initialLogo}: WhiteLabelEditorViewModel) {
  return <Section className={'!py-0'}>
    <SectionHeader isCloseable initialState={initialState} onToggle={onToggle}>
      <SectionHeaderTypography>White Label Options</SectionHeaderTypography>
    </SectionHeader>
    <SectionContent close={!initialState}>
      <StrucRow>
        <StrucCol size={3.5}>
          <InputWrapper>
            <InputLabel>Company Display Name</InputLabel>
            <CustomInput
              name={nameOf<WhiteLabelConfigurationsForm>(lf => lf.whiteLabelVendorName)}
              value={form.whiteLabelVendorName}
              onChange={x => onFormChange(x)}
              disabled={disabled}
            />
          </InputWrapper>
          <ColorPickerEditorWrapper form={form} onFormChange={onFormColorHexChange} disabled={disabled}/>
          <InputWrapper>
            <InputLabel>Website Link</InputLabel>
            <CustomInput
              name={nameOf<WhiteLabelConfigurationsForm>(lf => lf.whiteLabelWebsiteLink)}
              value={form.whiteLabelWebsiteLink}
              onChange={x => onFormChange(x)}
              disabled={disabled}
            />
          </InputWrapper>
        </StrucCol>
        <StrucCol size={3.5} offset={0.5}>
          <InputWrapper>
            <InputLabel>Email</InputLabel>
            <CustomInput
              name={nameOf<WhiteLabelConfigurationsForm>(lf => lf.whiteLabelContactEmail)}
              value={form.whiteLabelContactEmail}
              onChange={x => onFormChange(x)}
              disabled={disabled}
            />
          </InputWrapper>
          <InputWrapper>
            <InputLabel>Phone</InputLabel>
            <CustomPhoneInput
              name={nameOf<WhiteLabelConfigurationsForm>(lf => lf.whiteLabelContactPhone)}
              value={form.whiteLabelContactPhone}
              onChange={x => onFormChange(x)}
              disabled={disabled}
            />
          </InputWrapper>
          <InputWrapper>
            <InputLabel>Copyright Name</InputLabel>
            <CustomInput
              name={nameOf<WhiteLabelConfigurationsForm>(lf => lf.whiteLabelCopyrightName)}
              value={form.whiteLabelCopyrightName}
              onChange={x => onFormChange(x)}
              disabled={disabled}
            />
          </InputWrapper>
        </StrucCol>
        <StrucCol size={3.5} offset={0.5}>
          <OrganizationLogoPicker value={initialLogo} onLogoChange={onLogoChange} disabled={disabled} />
        </StrucCol>
      </StrucRow>
    </SectionContent>
  </Section>
}