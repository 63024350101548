import { CreateUserDto } from '../../core/models/dtos/CreateUserDto'
import { UserRepository } from '../../core/repositories/UserRepository'
import { useApiClient } from '../clients/ApiClient'
import { mapFromCreateUserDto } from '../models/mappers/mapUser'
import { User } from '../../core/models/entities/User'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { Resource } from '../../core/models/wrappers/Resource'

export function useUserRepository(): UserRepository {
  const apiClient = useApiClient()
  const queryClient = useQueryClient()

  async function invalidateQueries(orgId: number) {
    await queryClient.refetchQueries({ queryKey: ['users', orgId], type: 'all', refetchPage: () => true})
  }

  async function createUser(dto: CreateUserDto): Promise<void> {
    await apiClient.createUser(mapFromCreateUserDto(dto))
    invalidateQueries(dto.organizationId).then()
  }

  function useUsersOfOrganization(orgId: number): Resource<User[]> {
    return useQuery(['users', orgId], () => apiClient.getUsersOfOrganization(orgId).then())
  }

  return {
    createUser,
    useUsersOfOrganization
  }
}
