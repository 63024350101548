import { useTranslation } from 'react-i18next'
import StyledLabel from '../../../../../../components/StyledLabel'
import CustomSlider from '../../../../../../components/slider/CustomSlider'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleHalfStroke } from '@fortawesome/free-solid-svg-icons'

interface Props {
  opacity: number
  onOpacityChange: (val: number) => void
}

export default function OpacitySlider({ opacity, onOpacityChange }: Props): JSX.Element {
  const { t } = useTranslation()
  return (
    <div className="w-full">
      <div className={'flex items-center mb-[-4px] text-xs space-x-2 pl-1'}>
        <FontAwesomeIcon icon={faCircleHalfStroke} />
        <StyledLabel>{t('change_opacity')}</StyledLabel>
      </div>
      <CustomSlider value={opacity} onValueChange={onOpacityChange} />
    </div>
  )
}
