import { getProductPrice, getTypeText } from '../../../../core/models/entities/BaseLogo'
import { PriceLine, PurePriceLine } from '../../../components/Logo/LogoPrices'
import { OrderLineHideColumns } from './OrderLineList'
import { LogoPrice, OrderLineLogo, OrderLineProduct } from '../../../../core/models/entities/Order'

interface Props {
  no: string
  orderline: OrderLineLogo
  hideColumns?: OrderLineHideColumns
  getQuantity: (product: OrderLineProduct) => number
  logoPrices: LogoPrice[]
}

export function LogoOrderLine({ no, orderline, hideColumns, logoPrices }: Props): JSX.Element {
  const prices = orderline.baseLogo.product?.prices ?? []

  const quantity = orderline.quantity
  const pricePerPience = getProductPrice(prices, 1)

  const logoPrice = logoPrices.find(x => x.logoId === orderline.baseLogo.id)
  const adjustedPrice = logoPrice?.adjustedPrice ?? 0
  const qtyPrice = adjustedPrice
  const total = quantity * qtyPrice;

  const additionalFees = orderline.additionalFees.map((fee, i) => {
    return (
      <tr className='min-h-36 h-14' key={`${fee.itemNo}_${i}`}>
        {(hideColumns?.no) ?? <td className='text-left'>{no}-{i + 1}</td>}
        {(hideColumns?.image) ?? <td className='text-left'>
        </td>}
        {(hideColumns?.productId) ?? <td className='text-left'>{fee.itemNo}</td>}
        {(hideColumns?.title) ?? <td className='text-left'>{fee.name}</td>}
        {(hideColumns?.type) ?? <td className='text-left'></td>}
        {(hideColumns?.pricePerPiece) ?? <td className='text-right'>{PurePriceLine({ price: fee.unitPrice, excludeQuantity: true })}</td>}
        {(hideColumns?.qty) ?? <td className='text-right'>{fee.quantity}</td>}
        {(hideColumns?.qtyPrice) ?? <td className='text-right'>{PurePriceLine({ price: fee.unitPrice, excludeQuantity: true })}</td>}
        {(hideColumns?.totalPrice) ?? <td className='text-right'>{PurePriceLine({ price: fee.price, excludeQuantity: true })}</td>}
      </tr>)
  })

  return <>
    <tr className='min-h-36 h-14'>
      {(hideColumns?.no) ?? <td className='text-left'>{no}</td>}
      {(hideColumns?.image) ?? <td className='text-left'>
        <img
          src={orderline.displayImage}
          style={{
            maxHeight: 60,
            maxWidth: 60
          }}
        />
      </td>}
      {(hideColumns?.productId) ?? <td className='text-left'>{orderline.product?.productNo}</td>}
      {(hideColumns?.title) ?? <td className='text-left'>{orderline.title}</td>}
      {(hideColumns?.type) ?? <td className='text-left'>{getTypeText(orderline.baseLogo, orderline.deliveryType)}</td>}
      {(hideColumns?.pricePerPiece) ?? <td className='text-right'>{PriceLine({ price: pricePerPience, excludeQuantity: true })}</td>}
      {(hideColumns?.qty) ?? <td className='text-right'>{quantity || ''}</td>}
      {(hideColumns?.qtyPrice) ?? <td className='text-right'>{PurePriceLine({ price: qtyPrice, excludeQuantity: true })}</td>}
      {(hideColumns?.totalPrice) ?? <td className='text-right'>{PurePriceLine({ price: total, excludeQuantity: true })}</td>}
    </tr>
    {additionalFees}
  </>
}
