import { CartItem } from '../models/CartItem'
import { OrderLineBlock } from './OrderLineBlock'
import { ProductPreviewDesign } from './ProductPreviewDesign'

interface OrderLinesListItemDesignProps {
  orderLine: CartItem
  onClick: (orderLine: CartItem) => void
}

export function OrderLinesListItemDesign({ orderLine, onClick }: OrderLinesListItemDesignProps) {
  return <OrderLineBlock onClick={() => onClick(orderLine)}>
    <ProductPreviewDesign orderline={orderLine}></ProductPreviewDesign>
  </OrderLineBlock>
}