import { canvasToBlob } from 'blob-util'
import { useEffect, useRef, useState } from 'react'
import CanvasDraw from 'react-canvas-draw'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { MaskColor } from '../../../../../core/models/entities/MaskColor'
import { Style } from '../../../../../core/models/entities/Style'
import { StyleView } from '../../../../../core/models/entities/StyleView'
import { loadImageAsync } from '../../../../../utils/functions/ImageUtil'
import { copy } from '../../../../../utils/functions/ObjectUtil'
import { isEmpty } from '../../../../../utils/functions/StringUtil'
import { ViewData, MaskEditorToolViewModel } from './MaskEditorToolViewModel'

export function useMaskEditorToolViewModel(
  style: Style,
  getOriginalMask: (styleId: number, styleViewId: number) => Promise<string>,
  updateMask: (styleId: number, styleViewId: number, file: Blob) => Promise<void>
): MaskEditorToolViewModel {
  const canvasRef = useRef<CanvasDraw>(null)
  const canvasContainerRef = useRef<HTMLDivElement>(null)

  const [canvasWidth, setCanvasWidth] = useState(0)
  const [canvasHeight, setCanvasHeight] = useState(0)
  const [brushSize, setBrushSize] = useState(5)
  const [opacity, setOpacity] = useState(50)
  const [penColor, setPenColor] = useState(MaskColor.Background)
  const [views, setViews] = useState<StyleView[]>([])
  const [currentView, setCurrentView] = useState<StyleView>()
  const [colors] = useState<MaskColor[]>(MaskColor.maskColors)
  const [saveDatas, setSaveDatas] = useState<ViewData[]>([])
  const [saving, setSaving] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const { t } = useTranslation()
  const navigate = useNavigate()

  useEffect(() => {
    if (canvasContainerRef.current) {
      mapViewsToViewData(canvasContainerRef.current).then((datas) => {
        setSaveDatas(datas)
        setViews(style.styleViews)
        setCurrentView(style.styleViews[0])
      })
    }
  }, [style, canvasContainerRef])

  useEffect(() => {
    if (!currentView) {
      return
    }

    const data = saveDatas.find((d) => d.viewId === currentView.id)
    if (data) {
      setCanvasHeight(data.height)
      setCanvasWidth(data.width)
    }
  }, [currentView])

  async function mapViewsToViewData(div: HTMLDivElement): Promise<ViewData[]> {
    const datas: ViewData[] = []
    setIsLoading(true)
    for (const view of style.styleViews) {
      const image = await loadImageAsync(view.imageUrl)
      // const mask = await loadImageAsync(view.updatedMaskUrl ?? view.maskUrl)

      let width = 0
      let height = 0
      const aspectRatio = image.width / image.height
      const margin = 0.9

      height = div.offsetHeight * margin
      width = height * aspectRatio
      if (width > div.offsetWidth) {
        width = div.offsetWidth * margin
        height = width / aspectRatio
      }

      datas.push({
        viewId: view.id,
        data: '',
        dataUrl: '',
        width: width,
        height: height
      })
    }
    setIsLoading(false)
    return datas
  }

  function onResetClick(): void {
    const canvas = canvasRef.current
    if (currentView && canvas) {
      getOriginalMask(style.id, currentView.id).then((res) => {
        const cSaves = copy(saveDatas)
        const cViews = copy(views)

        const view = cViews.find((cv) => cv.id === currentView.id)
        const data = cSaves.find((cs) => cs.viewId === currentView.id)

        if (view && data) {
          view.maskUrl = res
          setViews(cViews)
          setCurrentView(view)

          data.data = ''
          data.dataUrl = ''
          setSaveDatas(cSaves)

          canvas.clear()
        }
      })
    }
  }

  /**
   * With the way the drawing library we use to draw over a mask work, we need to
   * overlap what the user draws on the views mask. So this is how its done:
   *  - Make in memory canvas
   *  - Draw original mask on canvas
   *  - Draw user input on canvas
   * We then creates and saves a mask that has what the user has drawn on it.
   */
  async function saveAllMasks(): Promise<void> {
    for (const data of saveDatas) {
      const view = views.find((v) => v.id === data.viewId)

      if (!view) {
        return
      }

      const originalImg = await loadImageAsync(view.imageUrl)

      // Create in memory canvas and let it have size of original image.
      const memMaskCanvas = document.createElement('canvas')
      memMaskCanvas.width = originalImg.width
      memMaskCanvas.height = originalImg.height

      const ctx = memMaskCanvas.getContext('2d', { willReadFrequently: true })

      if (!ctx) {
        return
      }

      // Put mask on in memory canvas and upscale it to original size
      const maskImage = await loadImageAsync(view.updatedMaskUrl ?? view.maskUrl)
      ctx.drawImage(maskImage, 0, 0, originalImg.width, originalImg.height)

      const dataUrl = tryGetDataUrl(data)

      // If the drawing canvas has data on it we draw that on top of the canvas and upscale it.
      // What the user has drawn is then overlapping the original mask
      if (!isEmpty(dataUrl)) {
        const dataImage = await loadImageAsync(dataUrl)
        ctx.drawImage(dataImage, 0, 0, originalImg.width, originalImg.height)
      }

      // Convert canvas to png blob
      const blob = await canvasToBlob(memMaskCanvas, 'image/png')

      // Update mask
      await updateMask(style.id, data.viewId, blob)

      // Clear canvas
      ctx.clearRect(0, 0, originalImg.width, originalImg.height)
    }
  }

  /**
   * Gets the data url from either the view data or the canvas if the viewdata belongs to the current view.
   * The reason for this is that the current view might not have been saved yet so we get its dataurl from the canvas just in case.
   */
  function tryGetDataUrl(data: ViewData): string {
    if (data.viewId === currentView?.id && canvasRef.current) {
      // @ts-expect-error: Unreachable code error
      return canvasRef.current.getDataURL('png', true, '')
    } else {
      return data.dataUrl
    }
  }

  async function onSaveClick(): Promise<void> {
    setSaving(true)
    saveAllMasks()
      .then(() => {
        toast.success(t('toasts.mask_update_success'))
        navigate('/style')
      })
      .catch(() => {
        toast.warn(t('toasts.mask_update_failed'))
      })
      .finally(() => setSaving(false))
  }

  function onUndoClick(): void {
    const canvas = canvasRef.current
    if (canvas !== null && canvas !== undefined) {
      canvas.undo()
    }
  }

  function onViewSelect(v: StyleView) {
    const current = canvasRef.current
    if (!current) {
      return
    }

    const cDatas = copy(saveDatas)

    // Get current and new selected view save data
    const currentViewData = cDatas.find((sd) => sd.viewId === currentView?.id)
    const newViewData = cDatas.find((sd) => sd.viewId === v.id)

    if (currentViewData && newViewData) {
      // Save the current views canvas data
      currentViewData.data = current.getSaveData()

      // @ts-expect-error: Unreachable code error
      currentViewData.dataUrl = current.getDataURL('png', true, '')

      setSaveDatas(cDatas)

      // Set the new views save data on the canvas
      if (isEmpty(newViewData.data)) {
        current.clear()
      } else {
        current.loadSaveData(newViewData.data, true)
      }
    }

    setCurrentView(v)
  }

  function handleBrushSizeChange(v: number) {
    setBrushSize(v)
  }

  return {
    style,
    views,
    currentView,
    brushSize,
    opacity,
    penColor,
    canvasRef,
    canvasHeight,
    canvasWidth,
    colors,
    canvasContainerRef,
    saving,

    onBrushSizeChange: handleBrushSizeChange,
    onPenColorChange: setPenColor,
    onOpacityChange: setOpacity,
    onViewSelect,

    onUndoClick,
    onResetClick,
    onSaveClick,
    isLoading
  }
}
