import { Organization } from '../../../../core/models/entities/Organization'
import { PageQuery } from '../../../../utils/models/pageQuery'
import { QueryChanger } from '../../../shared/hooks/usePageQuery'
import { TableData } from '../../../components/GenericTable/FunctionalList'
import { useNavigate } from 'react-router-dom'
import { PrimaryButton } from '../../../components/Buttons/PrimaryButton'
import { organizationColumnsConfig } from '../const/organizationColumnsConfig'
import { CustomerListItemComponent } from '../component/customer-list-item.component'
import React from 'react'

export function useFunctionalListOrganizationViewModel(
  isLoading: boolean,
  query: PageQuery,
  queryChanger: QueryChanger,
  lastPage: number,
  organizations?: Organization[]
): TableData<Organization> {
  const navigate = useNavigate()

  function handleRowClick(organization: Organization) {
    navigate(`/customer/${organization.id}`)
  }

  function handleEditRow(organization: Organization) {
    navigate(`/customer/${organization.id}/edit`)
  }

  function handleCreateNewCustomer() {
    navigate(`/customer/add`)
  }

  const center = (
    <PrimaryButton key={'create-customer'} width={'md'} onClick={handleCreateNewCustomer}>
      Create new Customer
    </PrimaryButton>
  )

  return {
    data: organizations,
    query: query,
    lastPage: lastPage,
    isLoading: isLoading,
    tableTitle: 'Customers',
    onSearchQueryChange: queryChanger.handleSearchQueryChange,
    multiSelect: false,
    center: center,
    columns: organizationColumnsConfig,
    onNewSort: queryChanger.handleSortByField,
    onPageSizeChange: queryChanger.handlePageSizeChange,
    onNewPage: queryChanger.handleNewPage,
    renderRow: (columns, data) => (
      <CustomerListItemComponent
        organization={data}
        columns={columns}
        onEditOrganization={handleEditRow}
      />
    ),
    onRowClick: handleRowClick,
    getKey: (d) => d.id
  }
}
