import React, { forwardRef, PropsWithChildren } from 'react'

interface Props extends PropsWithChildren {
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void
}

export const NavigationBarButton = forwardRef<HTMLButtonElement, React.ButtonHTMLAttributes<HTMLButtonElement>>(({
                                                                                                                   children,
                                                                                                                   onClick,
                                                                                                                   className
                                                                                                                 }, ref) => {
  return (
    <button
      onClick={onClick}
      className={`h-full w-[150px] text-gray-600 hover:bg-gray-100 text-sm font-medium ${className ?? ''}`}>
      {children}
    </button>
  )
})
