import { BaseLogo } from '../../../../core/models/entities/BaseLogo'
import { Organization } from '../../../../core/models/entities/Organization'
import { Profile } from '../../../../core/models/entities/Profile'
import { PageQuery } from '../../../../utils/models/pageQuery'
import { QueryChanger } from '../../../shared/hooks/usePageQuery'
import FunctionalList from '../../../components/GenericTable/FunctionalList'
import { useFunctionalListLogosViewModel } from '../vmHooks/useFunctionalListLogosViewModel'
import { LogoOptions } from '../../../../core/models/entities/LogoOptions'
import { useLogoActionHandler } from '../hooks/useLogoActionsHandler'
import { useApprovalDialogByRole } from '../../../components/Dialogs/useApprovalDialogByRole'
import { useCreateLogoDialog } from '../../customer-list/hooks/useCreateLogoDialog'

interface WrapperProps {
  profile: Profile,
  logos?: BaseLogo[],
  isPageLoading: boolean,
  lastPage: number,
  options: LogoOptions
  query: PageQuery,
  queryChanger: QueryChanger,
  customers: Organization[],
  vendors?: Organization[],

  onSelectLogo: (logo: BaseLogo) => void
  onViewRejectNote: (logo: BaseLogo) => void
}

export function LogoListWrapper(
  {
    profile,
    logos,
    isPageLoading,
    lastPage,
    options,
    query,
    queryChanger,
    customers,
    vendors,
    onSelectLogo,
    onViewRejectNote
  }: WrapperProps) {
  const { handleSkipApproval, handleInitiateApproval, handleSkipAddToCart } = useLogoActionHandler()
  const { component, start: openInitApproval } = useApprovalDialogByRole<BaseLogo>({
    role: profile.userOrganizationInformations.role,
    subject: "logo",
    onSkip: (entity) => handleSkipApproval(entity).then(),
    onOrder: (entity) => handleSkipAddToCart(entity, 10).then(),
    onInitApproval: (entity, addExternal, email1) => handleInitiateApproval(entity, { external: addExternal ?? false, externalEmail: email1, vendor: true, customer: true }).then()
  })

  const { component: componentCreateLogoDialog, open } = useCreateLogoDialog({
    onContinue: async () => { },
    onCancel: () => { },
    options: options
  })

  function openCreateLogoDialog() {
    open();
  }

  const vm = useFunctionalListLogosViewModel(profile, isPageLoading, lastPage, options, query, queryChanger, onSelectLogo, onViewRejectNote, openInitApproval, openCreateLogoDialog, customers, vendors, logos)

  return <>
    {componentCreateLogoDialog}
    {component}
    <FunctionalList<BaseLogo>
      {...vm}
    /></>
}

