import { ExternalApprovalFlow, LoadingFrame } from '../external-approval-design/ExternalApprovalFlow'
import { useParams, useSearchParams } from 'react-router-dom'
import { usePublicApiClient } from '../external-approval-design/use-public-api.client'
import { ComponentState, useComponentStateMachine } from '../../../shared/hooks/useComponentStateMachine'
import { ErrorView } from '../../../components/error-view/ErrorView'
import { InvalidRoute } from '../external-approval-design/external-approval-design.screen'
import { LogoDto } from '../../../../data/models/responses/LogoDto'
import { LogoImage } from '../../../components/Logo/LogoImage'
import { BaseLogo } from '../../../../core/models/entities/BaseLogo'
import { mapToLogo } from '../../../../data/models/mappers/mapLogo'


export function ExternalApprovalLogoScreen() {
  const [qp] = useSearchParams()
  const params = useParams()

  const code = qp.get('code')
  const logoId = params?.logoId

  const validRoute = !!code && !!logoId
  return validRoute ? <ExternalApprovalLogoLoader logoId={+logoId!} code={code!} /> : <InvalidRoute />
}

interface ExternalApprovalDesignLoaderProps {
  code: string,
  logoId: number;
}

function ExternalApprovalLogoLoader({ logoId, code }: ExternalApprovalDesignLoaderProps) {
  const { getLogo } = usePublicApiClient()
  const { dataTransition, state } = useComponentStateMachine()
  const { isError, isLoading, isSuccess, data } = getLogo(logoId, code)
  dataTransition(isLoading, isSuccess, isError)

  if (state === ComponentState.Failed) {
    return <ErrorView />
  }

  if (state === ComponentState.Succeed) {
    const logo = mapToLogo(data!)
    return <ExternalApprovalLogo logo={logo} code={code} />
  }

  return <LoadingFrame />
}

interface ExternalApprovalLogoProps {
  logo: BaseLogo;
  code: string;
}

function ExternalApprovalLogo({ logo, code }: ExternalApprovalLogoProps) {

  const { placeVerdictLogo } = usePublicApiClient()

  async function handleVerdict(approval: boolean, note?: string) {
    return placeVerdictLogo(logo.id, approval, note, code)
  }

  return <ExternalApprovalFlow placeVerdict={handleVerdict} subject={'Logo'}>
    <LogoImage
      className={'min-h-[325px] min-w-[450px] max-h-[650px] max-w-[900px] object-scale-down bg-white rounded-bl'}
      logo={logo} useHighRes={true}
    />
  </ExternalApprovalFlow>
}

interface ExternalLogoViewProps {
  logo: LogoDto
}
function ExternalLogoView({ logo }: ExternalLogoViewProps) {
  return <img src={logo.displayImage.highResUrl} />
}


