import { JSX, useState } from 'react'
import { Dialog_VM, DialogFrame, useDialog } from '../../../components/Dialogs/dialog-base'
import { PrimaryButton } from '../../../components/Buttons/PrimaryButton'
import LoadingElement from '../../../components/loading-element/loading-element'
import { Organization } from '../../../../core/models/entities/Organization'
import { useOrganizationRepository } from '../../../../data/repositories/useOrganizationRepository'
import { PageQuery } from '../../../../utils/models/pageQuery'
import { ComponentState, useComponentStateMachine } from '../../../shared/hooks/useComponentStateMachine'
import { InputWrapper } from '../../../components/Input/InputWrapper'
import { InputLabel } from '../../../components/Input/InputLabel'
import { CustomAutoComplete } from '../../../components/Input/CustomAutoComplete'
import { useOrganizationImpersonationContext } from '../../../../contexts/OrganizationImpersonationContext'
import { SecondaryButton } from '../../../components/Buttons/SecondaryButton'

interface Props {
  component: JSX.Element
  open: () => void
}

export function useAdminImpersonationDialog({
  onContinue,
  onCancel
}: { onContinue: () => Promise<void>, onCancel?: () => void }): Props {

  const { setVendor, vendor, isLoading: isImpersonationLoading } = useOrganizationImpersonationContext()
  const { useAllVendors } = useOrganizationRepository()
  const { data: vendors, isError, isLoading, isSuccess } = useAllVendors({ page: { index: 0, size: 0 } } as PageQuery)

  const { state, dataTransition } = useComponentStateMachine()
  dataTransition(isLoading || isImpersonationLoading, isSuccess, isError)

  const [loading, setLoading] = useState<boolean>(false)

  const dialog = useDialog()

  function handleSubmit(organization: Organization) {
    setLoading(true);
    onContinue().finally(() => {
      setLoading(false)
      setVendor(organization.id)
      dialog.closeDialog()
    })
  }

  function handleClear() {
    setVendor(undefined)
    dialog.closeDialog()
  }

  function handleCancel() {
    if (onCancel) onCancel()
    dialog.closeDialog()
  }

  return {
    component: state === ComponentState.Loading
      ? <DialogFrame dialogOption={dialog}>
        <div className='h-80'>
          <LoadingElement transparent />
        </div>
      </DialogFrame>
      : <DialogFrame dialogOption={dialog}>
        {loading && <LoadingElement transparent />}
        <CreateAdminImpersionationDialogComponent
          dialog={dialog}
          onSubmit={handleSubmit}
          onClear={handleClear}
          organization={vendor}
          organizations={vendors?.results!}
        />
      </DialogFrame>,
    open: dialog.openDialog
  }
}

interface CreateDialogComponentProps {
  dialog: Dialog_VM
  onSubmit: (organization: Organization) => void
  onClear: () => void
  organization?: Organization
  organizations: Organization[]
}

export function CreateAdminImpersionationDialogComponent(
  {
    onSubmit,
    onClear,
    organization,
    organizations
  }: CreateDialogComponentProps) {

  const [selected, setSelected] = useState<Organization | null>(organization ?? null)
  const isValid = selected !== null

  return <div className={'flex flex-col space-y-4 p-4'}>
    <p className={'text-left text-base font-bold'}>Impersonate Organization</p>
    <div className={'flex flex-col justify-center space-y-4'}>
      <InputWrapper>
        <InputLabel>Vendor</InputLabel>
        <CustomAutoComplete<Organization>
          options={organizations}
          getOptionKey={(org) => org.id}
          displayOption={(org) => `${org.name} (${org.navNumber})`}
          key={selected?.id}
          onChange={(org) => setSelected(org)}
          value={selected}
          placeholder='search for vendors'
        />
      </InputWrapper>
      <PrimaryButton width={'full'} onClick={() => onSubmit(selected!)} disabled={!isValid}>Impersonate Organization</PrimaryButton>
      <SecondaryButton width={'full'} onClick={onClear}>Reset to Admin</SecondaryButton>
    </div>
  </div>
}
