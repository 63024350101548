import React from 'react'
import { LogoEditViewUpdateDesignWrapper } from '../wrappers/logo-edit-view-update-design.wrapper'
import { MainViewStates } from '../models/enums/main-view-states.enum'
import { LogoPlacer } from './logo-placer/logo-placer.component'

export function StateBasedView({ state }: {
  state: MainViewStates
}) {
  switch (state) {
    case MainViewStates.LogoPlacer:
      return <LogoPlacer />
    case MainViewStates.LogoEditor:
      return <LogoEditViewUpdateDesignWrapper />
  }
}

