import { LogoCoverage } from '../../design-editor/design-style-editor/DesignStyleEditor.vm'

export function isMarkerPlacementOnMaskValid(coverage: LogoCoverage): boolean {
  const validPercentage = 0.98

  return (
    coverage.base >= validPercentage ||
    coverage.pocket >= validPercentage ||
    coverage.collar >= validPercentage
  )
}