import { Commission, CommissionProfile } from '../../../core/models/entities/Commission'
import { CommissionDto, CommissionProfileDto } from '../responses/CommissionProfileDto'

export function mapToCommissionProfile(dto: CommissionProfileDto): CommissionProfile {
  return {
    ...dto,
    overAll: mapToCommission(dto.overAll),
    typeOnly: dto.typeOnly.map(mapToCommission),
    methods: dto.methods.map(mapToCommission),
    createdAtUtc: new Date(dto.createdAtUtc),
    updatedAtUtc: new Date(dto.updatedAtUtc)
  }
}

export function mapToCommission(dto: CommissionDto): Commission {
  return {
    ...dto
  }
}