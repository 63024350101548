import { ErrorStatus } from '../models/entities/ErrorStatus'

export class DataError extends Error {
  errorStatus?: ErrorStatus
  constructor(message: string, errorStatus: ErrorStatus) {
    super(message)
    this.name = 'DataError'
    this.errorStatus = errorStatus
  }
}
