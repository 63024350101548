import { useMsal } from '@azure/msal-react'
import { loginRequest } from '../../AuthConfig'
import { DataError } from '../../core/errors/DataError'
import { NoCurrentAccountError } from '../../core/errors/NoCurrentAccountError'
import { UnauthorizedError } from '../../core/errors/UnauthorizedError'
import { mapToErrorStatus } from '../models/mappers/mapError'

interface AuthorizedFetch {
  authFetch(input: RequestInfo | URL, init?: RequestInit): Promise<Response>
}

export function useAuthorizedFetch(): AuthorizedFetch {
  const { instance, accounts } = useMsal()

  async function authFetch(input: RequestInfo | URL, init?: RequestInit): Promise<Response> {
    const currentAcc = accounts[0]

    if (!currentAcc) {
      throw new NoCurrentAccountError('Cannot authorize request if no account is present')
    }

    const request = {
      ...loginRequest,
      account: currentAcc
    }

    let accessToken = (await instance.acquireTokenSilent(request)).accessToken

    if (!accessToken || accessToken === '') {
      accessToken = (await instance.acquireTokenPopup(request)).accessToken
      // If token is still not available we can't authenticate the user
      if (!accessToken || accessToken === '') {
        throw new UnauthorizedError('Cannot authenticate request')
      }
    }

    let requestInit: RequestInit = {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    }

    if (init !== undefined) {
      requestInit = { ...requestInit, ...init }
    }

    const response = await fetch(input, requestInit)

    // If bad response throw DataError containing custom error code from backend if any
    if (!response.ok) {
      const errorStatus = mapToErrorStatus(await response.json())
      throw new DataError(errorStatus.message, errorStatus)
    }

    return response
  }

  return {
    authFetch
  }
}
