import { useTranslation } from 'react-i18next'
import StyledLabel from '../../../../../../components/StyledLabel'
import CustomSlider from '../../../../../../components/slider/CustomSlider'
import { faBrush } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

interface BrushSliderProps {
  onBrushSizeChange: (size: number) => void
  brushSize: number
}

export default function BrushSlider({
  onBrushSizeChange,
  brushSize
}: BrushSliderProps): React.ReactElement {
  const { t } = useTranslation()
  // Render
  return (
    <div className="w-full">
      <div className={'flex items-center mb-[-4px] text-xs space-x-2 pl-1'}>
        <FontAwesomeIcon icon={faBrush} />
        <StyledLabel>{t('change_brush_size')}</StyledLabel>
      </div>
      <CustomSlider value={brushSize} onValueChange={onBrushSizeChange} min={3} max={13} />
    </div>
  )
}
