import FunctionalList from '../../../components/GenericTable/FunctionalList'
import { Order } from '../../../../core/models/entities/Order'
import { PageQuery } from '../../../../utils/models/pageQuery'
import { Organization } from '../../../../core/models/entities/Organization'
import { QueryChanger } from '../../../shared/hooks/usePageQuery'
import { useFunctionalListOrdersViewModel } from '../vmHooks/useFunctionalListOrdersViewModel'
import { Profile } from '../../../../core/models/entities/Profile'

interface WrapperProps {
  profile: Profile,
  onSelectOrder: (order: Order) => void,
  lastPage: number,
  query: PageQuery,
  customers: Organization[],
  isPageLoading: boolean,
  orders?: Order[],
  vendors: Organization[] | undefined,
  queryChanger: QueryChanger
  onRejectionNote: (order: Order) => void
}

export function OrderListWrapper({ profile, lastPage, query, customers, isPageLoading, orders, vendors, queryChanger, onRejectionNote: onRejectionNote, onSelectOrder }: WrapperProps) {
  const vm = useFunctionalListOrdersViewModel(profile, lastPage, query, customers, isPageLoading, vendors, queryChanger, onRejectionNote, onSelectOrder, orders)
  return <FunctionalList<Order> {...vm} />
}

