import { LogoEditorTool_VM } from '../../logo-editor/componenets/LogoEditorTool'
import { useState } from 'react'
import { useLogoContext } from '../contexts/logo-options.context'
import { useDesignEditorStateContext } from '../contexts/design-editor-state-provider.context'
import { useDesignContext } from '../contexts/design.context'
import { ColorChange, useLogoActionHandler } from '../../logo-list/hooks/useLogoActionsHandler'
import { DesignEditorStates } from '../models/enums/design-editor-states.enum'
import { BaseLogo, ImageLogo } from '../../../../core/models/entities/BaseLogo'
import { toast } from 'react-toastify'
import { ImageLogoForm } from '../../logo-editor/models/ImageLogoForm'

export function useLogoEditViewUpdateDesignViewModel(): LogoEditorTool_VM {
  const [recoloredImageBase64, setRecoloredImageBase64] = useState<string | null>(null)
  const { logoToEdit, options, setLogoToEdit } = useLogoContext()
  const [colorChangeCode, setColorChangeCode] = useState<string>()
  const { goToState } = useDesignEditorStateContext()
  const { addToDesign, refreshLogoMarkers } = useDesignContext()

  const copy = Object.assign({}, logoToEdit as ImageLogo)
  const [logoForm, setLogoForm] = useState<ImageLogoForm>(toLogoForm(copy))

  const { isLoading, handleUpdateLogoColor, handleSaveThenSkipLogo, handleResizeLogo } = useLogoActionHandler()

  function toLogoForm(logo: ImageLogo): ImageLogoForm {
    return {
      id: logo.id,
      title: logo.title ?? '',
      transferMethod: logo.transferMethod,
      heightMm: logo.heightMm ?? 0,
      widthMm: logo.widthMm ?? 0,
      customer: logo.customer,
      colors: logo.colors
    }
  }

  function handleCancel() {
    // todo: 'are you sure' dialog before leaving
    goToState(DesignEditorStates.Normal)
  }

  function handleSaveCommit() {
    const newLogo: ImageLogo = {
      ...logoToEdit as ImageLogo,
      title: logoForm.title,
      transferMethod: logoForm.transferMethod!,
      heightMm: logoForm.heightMm,
      widthMm: logoForm.widthMm,
      customer: logoForm.customer,
      colors: logoForm.colors
    }

    const trans = async () => {
      return await handleSaveThenSkipLogo(newLogo.customer.id, newLogo)
    }

    trans().then((logoAndProduct) => {
      addToDesign(logoAndProduct)
      setLogoToEdit(null)
      goToState(DesignEditorStates.Normal)
    })
      .catch(() => toast.warn('update failed'))
  }

  function handleUpdateColor(colors: ColorChange[]) {
    handleUpdateLogoColor(logoToEdit?.customer.id!, logoToEdit!.id, { colorChanges: colors })
      .then((s) => {
        toast.success('Colors change success')
        const imageLogo = s as ImageLogo;
        setRecoloredImageBase64(imageLogo.colorChange?.temporaryImageBase64 ?? null)
        setColorChangeCode(imageLogo.colorChange?.colorChangeCode)
        setLogoToEdit(s)
        refreshLogoMarkers(s)
      })
      .catch(() => toast.warn('Color change failed'))
  }

  function updateForm(formLogo: ImageLogoForm) {
    setLogoForm({ ...formLogo })
  }

  function handleResize() {
    const newLogo: ImageLogo = {
      ...logoToEdit as ImageLogo,
      heightMm: logoForm.heightMm,
      widthMm: logoForm.widthMm
    }

    handleResizeLogo(newLogo!.customer.id!, newLogo!).then(resizedLogo => {
      const resizedImageLogo = resizedLogo as ImageLogo
      setLogoToEdit({ ...resizedImageLogo })
      setLogoForm({
        ...logoForm,
        heightMm: resizedImageLogo.heightMm,
        widthMm: resizedImageLogo.widthMm
      })
    })
  }

  return {
    tempColorImage: recoloredImageBase64,
    logo: logoToEdit!,
    options: options,
    isLoading,
    onCancel: handleCancel,
    onDraft: undefined,
    onCommit: handleSaveCommit,
    onUpdateColor: handleUpdateColor,
    showCustomerInput: false,
    customers: [],
    logoForm: logoForm,
    updateForm: updateForm,
    onResize: handleResize
  }
}