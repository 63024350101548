import React from 'react'
import { useOrderConfirmationEditViewModel } from './useOrderConfirmationEditViewModel'
import { OrderConfirmationComponent } from './OrderConfirmationComponent'
import { Order } from '../../../core/models/entities/Order'

interface Props {
  order: Order
}

export function OrderConfirmationWrapper({ order }: Props): React.ReactElement {
  const vm = useOrderConfirmationEditViewModel(order)
  return <OrderConfirmationComponent {...vm} />
}
