import { PageQuery } from '../../../../utils/models/pageQuery'
import React from 'react'
import FunctionalList from '../../../components/GenericTable/FunctionalList'
import { Style } from '../../../../core/models/entities/Style'
import { Manufacturer } from '../../../../core/models/entities/Manufacturer'
import { QueryChanger } from '../../../shared/hooks/usePageQuery'
import { useFunctionalListStyleViewModel } from '../vmHooks/useFunctionalListStyleViewModel'


interface WrapperProps {
  styles?: Style[];
  isPageLoading: boolean;
  manufacturers: Manufacturer[];
  query: PageQuery;
  queryChanger: QueryChanger;
  lastPage: number
}

export function StyleListWrapper(
  {
    styles,
    query,
    queryChanger,
    isPageLoading,
    manufacturers,
    lastPage
  }: WrapperProps) {

  const vm = useFunctionalListStyleViewModel(
    manufacturers,
    query,
    queryChanger,
    lastPage,
    isPageLoading,
    styles
  )

  return <FunctionalList<Style>
    {...vm}
  />
}



