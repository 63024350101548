import { faCheck, faXmark } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useProfileContext } from '../../../contexts/ProfileContext'
import { ApprovalGroup } from '../../../core/models/entities/Approval'
import { Role } from '../../../core/models/entities/Role'
import { ConfirmationGuard } from '../ConfirmationGuard'
import { useRejectLogoDialog } from '../Dialogs/reject-logo-dialog'
import IconButton from '../LogoButton/IconButton'

export function ApprovalVoting({
  approvalGroup,
  placeVerdict,
  rejectDelete
}: {
  approvalGroup: ApprovalGroup
  placeVerdict: (verdict: Verdict) => void
  rejectDelete: (reason?: string) => void
}) {
  const { role } = useProfileContext()

  const rejectionDialog = useRejectLogoDialog(
    '',
    (reason) => placeVerdict({ approve: false, rejectionNode: reason }),
    (reason) => rejectDelete(reason),
    () => {}
  )

  function showButtons(role: Role, approvalGroup: ApprovalGroup) {
    if (role === Role.EndUser && approvalGroup.customer?.approver == null) {
      return true
    }

    if (role === Role.Vendor && approvalGroup.vendor?.approver == null) {
      return true
    }

    return false
  }

  const show = showButtons(role!, approvalGroup)

  const approveButton = (
    <ConfirmationGuard
      anchor={
        <IconButton
          disabled={role === Role.Admin}
          key={'approve'}
          variant="success"
          icon={<FontAwesomeIcon icon={faCheck} />}>
          Approve
        </IconButton>
      }
      text={'Do you want to approve this logo?'}
      onAccept={(e) => {
        placeVerdict({ approve: true })
      }}
    />
  )

  const rejectButton = (
    <ConfirmationGuard
      anchor={
        <IconButton
          disabled={role === Role.Admin}
          key={'reject'}
          variant="error"
          icon={<FontAwesomeIcon icon={faXmark} />}>
          Reject
        </IconButton>
      }
      text={'Do you want to Reject this logo?'}
      onAccept={rejectionDialog.open}
    />
  )

  return (
    <>
      {rejectionDialog.component}
      {show && approveButton}
      {show && rejectButton}
    </>
  )
}

export interface Verdict {
  approve: boolean
  rejectionNode?: string
}
