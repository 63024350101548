import React from 'react'

import { ButtonProp } from './ButtonProp'
import { ButtonRoot } from './SecondaryButton'

export const PrimaryButton = React.forwardRef<HTMLButtonElement, ButtonProp>(
  (props, ref) => {
    const { className, children, ...rest } = props
    return <ButtonRoot  className={`button-primary ${className ?? ''}`}
                ref={ref} {...rest}>
      {children}
    </ButtonRoot>
  }
)