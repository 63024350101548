import { Color } from '../../../../core/models/entities/LogoOptions'

function ColorBlock({ color, colorHex, size = 'md' }: { color?: Color, colorHex?: string, size?: 'sm' | 'md' }) {
  const styleString = color ? `rgb(${color.red}, ${color.green}, ${color.blue})` : colorHex?.charAt(0) === '#' ? colorHex : `#${colorHex!}`

  return <BlockBase size={size} background={styleString} />
}

export function BlockBase({ background, size }: { size: 'sm' | 'md', background: string }) {
  switch (size) {
    case 'sm':
      return <div style={{ background }}
                  className={`inline-block min-h-2 min-w-2 h-3 w-3 m-[1.5px] rounded-[1px]`} />
    case 'md':
      return <div style={{ background }}
                  className={`inline-block min-h-3.5 min-w-3.5 h-3.5 w-3.5 m-[1.5px] rounded`} />
  }
}


export default ColorBlock