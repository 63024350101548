import React from 'react'
import { LogoMarker } from '../components/logo-placer/marker.component'
import { Marker } from '../models/marker.model'
import { useMakerViewModel } from '../vmHooks/useMakerViewModel'

interface WrapperProps {
  marker: Marker
}

export function Marker2Wrapper({ marker }: WrapperProps) {
  const vm = useMakerViewModel(marker)
  return <LogoMarker {...vm} />
}