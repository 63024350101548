import { TextLogoPreview } from './text-logo-preview.component'
import { PrimaryButton } from '../../../../components/Buttons/PrimaryButton'
import { SecondaryButton } from '../../../../components/Buttons/SecondaryButton'
import { Color, Font, LogoOptions } from '../../../../../core/models/entities/LogoOptions'
import { FontAndColorEditor } from './font-and-color-editor.component'
import LoadingElement from '../../../../components/loading-element/loading-element'

export interface NamePlaceholderLogoCreate_VM {
  font: Font | undefined,
  color: Color | undefined,
  onColorChange: (value: (((prevState: (Color | undefined)) => (Color | undefined)) | Color | undefined)) => void,
  onFontChange: (value: (((prevState: (Font | undefined)) => (Font | undefined)) | Font | undefined)) => void,
  options: LogoOptions,
  heightMM: number,
  onHeightMMChange: (value: (((prevState: number) => number) | number)) => void,
  onAddLogo: () => void
  onCancel: () => void
  canAdd: boolean
  getPreview?: (value: string, color: Color, heightMM: number, font: Font) => Promise<string>,
  isLoading: boolean,
  omitActions?: boolean,
}

export function NamePlaceholderLogoCreate(
  {
    onAddLogo,
    onCancel,
    onHeightMMChange,
    heightMM,
    onColorChange,
    options,
    color,
    onFontChange,
    font,
    canAdd,
    getPreview,
    isLoading,
    omitActions
  }: NamePlaceholderLogoCreate_VM) {

  return <div className={'flex flex-col h-full space-y-4'}>
    {isLoading && <LoadingElement transparent />}
    {!!getPreview && <TextLogoPreview value={'Name'} font={font} color={color} heightMM={heightMM} isValid={canAdd} getPreview={getPreview} />}
    <div className={'flex-1'}>
      <FontAndColorEditor color={color}
        onSelectColor={onColorChange}
        font={font}
        onSelectFont={onFontChange}
        options={options}
        fontSize={heightMM}
        onSelectFontSize={onHeightMMChange}
        showValueField={false}
      />
    </div>
    {omitActions !== true && <>
      <PrimaryButton width={'full'} onClick={onAddLogo} disabled={!canAdd}>Add Name</PrimaryButton>
      <SecondaryButton width={'full'} onClick={onCancel}>Cancel</SecondaryButton>
    </>}
  </div>
}