import { Slider, styled } from '@mui/material'

interface Props {
  value: number
  onValueChange: (val: number) => void
  min?: number
  max?: number
}

const PrettoSlider = styled(Slider)({
  color: '#D2DFEE',
  height: 7,
  '& .MuiSlider-track': {
    border: 'none',
  },
  '& .MuiSlider-thumb': {
    height: 15,
    width: 15,
    backgroundColor: '#D2DFEE',
    '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
      boxShadow: 'inherit',
    },
    '&:before': {
      display: 'none',
    },
  },
  '& .MuiSlider-valueLabel': {
    lineHeight: 1.2,
    fontSize: 12,
    background: 'unset',
    padding: 0,
    width: 32,
    height: 32,
    borderRadius: '50% 50% 50% 0',
    backgroundColor: '#52af77',
    transformOrigin: 'bottom left',
    transform: 'translate(50%, -100%) rotate(-45deg) scale(0)',
    '&:before': { display: 'none' },
    '&.MuiSlider-valueLabelOpen': {
      transform: 'translate(50%, -100%) rotate(-45deg) scale(1)',
    },
    '& > *': {
      transform: 'rotate(45deg)',
    },
  },
});

export default function CustomSlider({ value, onValueChange, min = 1, max = 100 }: Props) {
  return <PrettoSlider value={value} min={min} max={max} onChange={(event, value, activeThumb) => onValueChange(Number(value))}/>

  /*return (
    <input
      className="h-5 w-full"
      type="range"
      onChange={(event) => {
        const value = event.target.value
        onValueChange(Number(value))
      }}
      min={min}
      max={max}
      value={value}
    />
  )*/
}
