import { Approval, ApprovalGroup } from '../../../core/models/entities/Approval'
import { ApprovalDto, ApprovalGroupDto } from '../responses/ApprovalDto'

export function mapToApprovalGroup(dto: ApprovalGroupDto): ApprovalGroup {
  return {
    customer: dto.customer ? mapToApproval(dto.customer) : { } as Approval,
    vendor: dto.vendor ? mapToApproval(dto.vendor) : { } as Approval,
    external: dto.external ? mapToApproval(dto.external) : null,
    externalEmail: dto.externalEmail,
    publicKey: dto.publicKey
  }
}

export function mapToApproval(dto: ApprovalDto): Approval {
  return {
    approver: dto.approver,
    status: dto.status,
    approvedAtUtc: new Date(dto.approvedAtUtc),
    rejectionNote: dto.rejectionNote
  }
}
