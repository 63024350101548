import { CartItem, CartItemDesign, CartItemLogo } from '../order-create/models/CartItem'
import { CartListDesignItem } from './subcomponents/CartListDesignItem'
import { CartListLogoItem } from './subcomponents/CartListLogoItem'

export interface CartListViewModel {
  cartItems: CartItem[]
}

export function CartListComponent({ cartItems: orderlines }: CartListViewModel) {

  const listItems = orderlines.map((listitem, i) => {
    const no = (i + 1).toString().padStart(2, '0').toString();

    switch (listitem.type) {
      case 'Design':
        return <CartListDesignItem key={listitem.id} no={no} orderline={listitem as CartItemDesign} />
      case 'Logo':
        return <CartListLogoItem key={listitem.id} no={no} cartItems={listitem as CartItemLogo} />
      default:
        return null
    }
  })

  return <div className={'flex-1'}>
    <table className={"table-auto bg-[#FCFDFE]"}>
      <thead className={'uppercase text-left'}>
        <tr>
          <th className='w-1/12 text-left'>No.</th>
          <th className='w-1/12 text-left'>Image</th>
          <th className='w-1/12 text-left'>Product ID</th>
          <th className='w-2/12 text-left'>Title</th>
          <th className='w-2/12 text-left'>Type</th>
          <th className='w-2/12 text-right'>Price Per Piece</th>
          <th className='w-1/12 text-right'>QTY</th>
          <th className='w-1/12 text-right'>QTY Price</th>
          <th className='w-1/12 text-right'>Total</th>
        </tr>
      </thead>
      <tbody>
        {listItems}
      </tbody>
    </table>
  </div>
}