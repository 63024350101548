import { CartItemDesign } from '../models/CartItem'
import { DesignDetails } from './DesignDetails'
import { LogoTextEditor } from './LogoTextEditor'
import { OrderLineViewDesignPrint } from './OrderLineViewDesignPrint'

interface OrderLineViewDesignProps {
  orderline: CartItemDesign
}

export function OrderLineViewDesign({ orderline }: OrderLineViewDesignProps) {

  const logoards = orderline.design.designLogos.map(designLogo => {
    return <OrderLineViewDesignPrint key={designLogo.id} designLogo={designLogo}  orderline={orderline}/>;
  })

  return (
    <div id={'main-box'} className={'flex-1 flex space-x-10'}>
      <div id={'left-box'} className={'space-y-2 flex-1'}>
        <div className={'flex-1'}>
          <DesignDetails orderline={orderline} />
        </div>
        <div>
          <p className={'text-sm font-semibold uppercase mb-2'}>PRINTS</p>
          <div
            className={'overflow-x-hidden overflow-y-scroll pr-1 h-[370px] grid grid-cols-2 gap-x-3 gap-y-4'}>
            {logoards}
          </div>
        </div>
      </div>
      <div id={'right-box'} className={'flex-1 flex flex-col'}>
        <div className={'flex-1'}>
          <LogoTextEditor orderlineDesign={orderline}/>
        </div>
      </div>
    </div>
  )
}
