import { useRepositoriesContext } from '../../../../contexts/RepositoriesContext'
import { nameOf } from '../../../../utils/functions/ReflectionUtil'
import { BaseLogo } from '../../../../core/models/entities/BaseLogo'
import { SortDirection } from '../../../components/GenericTable/Models/sortDirection'
import { PageQuery } from '../../../../utils/models/pageQuery'
import { useDesignEditorStateContext } from '../contexts/design-editor-state-provider.context'
import { DesignEditorStates } from '../models/enums/design-editor-states.enum'
import { LibrarySidePanel } from '../components/panel/library-side-panel.component'
import { useLogoContext } from '../contexts/logo-options.context'
import { useLogoActionHandler } from '../../logo-list/hooks/useLogoActionsHandler'
import { useDesignContext } from '../contexts/design.context'
import { useState } from 'react'

export function LibrarySidePanelUpdateWrapper() {
  const { goToState, setLoading } = useDesignEditorStateContext()
  const { options, setLogoToEdit } = useLogoContext()
  const { design, addToDesign, deleteLogo } = useDesignContext()
  const { logoRepository } = useRepositoriesContext()
  const { handleCreateLogo } = useLogoActionHandler()
  const [term, setTerm] = useState<string>("")
  const { data, isLoading, fetchNextPage } = logoRepository.useAllLogosForDesignInfinity(design.customerId, {
    sortBy: {
      sortDirection: SortDirection.DESC,
      field: nameOf<BaseLogo>(x => x.createdAtUtc)
    },
    page: {
      index: 1,
      size: 10
    },
    searchTerm: term
  } as PageQuery)

  let logosFull: BaseLogo[] = []
  if (data) {
    for (const datum of data.pages) {
      logosFull = logosFull.concat(datum.results)
    }
  }

  function handleBack() {
    goToState(DesignEditorStates.Normal)
  }

  function handleDeleteLogo(logo: BaseLogo) {
    deleteLogo(logo)
  }

  function handleAddLogo(logo: BaseLogo) {
    addToDesign(logo)
    goToState(DesignEditorStates.Normal)
  }

  function handleUpload(file: File) {
    setLoading(true)
    handleCreateLogo(file, design.customerId, "New Logo")
      .then(x => {
        setLogoToEdit(x)
        goToState(DesignEditorStates.EditLogo)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  function handleFilter(term: string) {
    setTerm(term)
  }

  return <LibrarySidePanel
    logos={logosFull}
    isLoading={isLoading}
    onLoadMore={fetchNextPage}
    onDeleteLogo={handleDeleteLogo}
    onAddLogo={handleAddLogo}
    onBack={handleBack}
    onUpload={handleUpload}
    options={options}
    onFilter={handleFilter}
    term={term}
  />
}