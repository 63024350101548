import React, { createContext, useContext, useEffect, useState } from 'react'
import { WhiteLabelConfigurations } from './core/models/entities/Profile'
import { useProfileContext } from './contexts/ProfileContext';

interface WhiteLabelProviderContext {
  whiteLabel: WhiteLabelConfigurations
  updateWhiteLabelContext: (value: WhiteLabelConfigurations) => void;
  reset: () => void;
}

const WhiteLabelContext = createContext<WhiteLabelProviderContext | undefined>(undefined)

interface RepositoriesProviderProps {
  children: React.ReactNode
}

function WhiteLabelProvider({ children }: RepositoriesProviderProps): React.ReactElement {

  const { profile } = useProfileContext()

  const whiteLabel: WhiteLabelConfigurations = profile?.whiteLabelConfigurations ?? defaultConfigurations()

  useEffect(() => {
    setCurrentWhiteLabel(whiteLabel)
  }, [profile])

  const [currentWhiteLabel, setCurrentWhiteLabel] = useState<WhiteLabelConfigurations>(whiteLabel);

  function defaultConfigurations(): WhiteLabelConfigurations {
    return {
      colorHex: '#2abcab',
      logoUrl: '',
      whiteLabelContactEmail: '',
      whiteLabelContactPhone: '',
      whiteLabelCopyrightName: '',
      whiteLabelVendorName: '',
      whiteLabelWebsiteLink: ''
    }
  }

  function reset() {
    setCurrentWhiteLabel({ ...profile!.whiteLabelConfigurations });
  }

  function updateWhiteLabel(value: WhiteLabelConfigurations) {
    const test = { ...value };
    setCurrentWhiteLabel(test);
  }

  return (
    <WhiteLabelContext.Provider
      value={{ whiteLabel: currentWhiteLabel, updateWhiteLabelContext: updateWhiteLabel, reset: reset }}>
      {children}
    </WhiteLabelContext.Provider>
  )
}

function useWhiteLabelContext(): WhiteLabelProviderContext {
  const context = useContext(WhiteLabelContext)
  if (context === undefined) {
    throw new Error('useRepositoriesContext must be used within a RepositoriesProvider')
  }
  return context
}

export { WhiteLabelProvider, useWhiteLabelContext }