import React, { ChangeEvent, useState } from 'react'
import { InfinityListComponent } from '../../../../shared/components/infinity-list.component'
import { PrimaryButton } from '../../../../components/Buttons/PrimaryButton'
import { SecondaryButton } from '../../../../components/Buttons/SecondaryButton'
import { extensionsToMimeTypesString } from '../../../logo-editor/componenets/ExtensionsToMimeTypesString'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileArrowDown } from '@fortawesome/free-solid-svg-icons'
import { BaseLogo } from '../../../../../core/models/entities/BaseLogo'
import { LogoOptions } from '../../../../../core/models/entities/LogoOptions'
import { LogoCardLibrary } from '../cards/logo-card-library.component'
import TableSearchBar from '../../../../components/GenericTable/SubComponents/TableHeader/TableSearchBar'

export interface LibrarySidePanel_VM {
  onBack: () => void,
  isLoading: boolean,
  onLoadMore: () => void,
  logos: BaseLogo[],
  onUpload: (file: File) => void,
  onAddLogo: (logo: BaseLogo) => void,
  onDeleteLogo: (logo: BaseLogo) => void
  options: LogoOptions
  onFilter: (term: string) => void
  term: string
}

export function LibrarySidePanel(
  {
    onAddLogo,
    onDeleteLogo,
    logos,
    onUpload,
    onLoadMore,
    isLoading,
    onBack,
    options,
    onFilter,
    term
  }: LibrarySidePanel_VM) {


  const [isDragging, setIsDragging] = useState(false)

  function handleDragCatalog(e: React.DragEvent<HTMLDivElement | HTMLFormElement>) {
    e.preventDefault()
    e.stopPropagation()
    if (e.type === 'dragenter' || e.type === 'dragover') {
      setIsDragging(true)
    } else if (e.type === 'dragleave') {
      setIsDragging(false)
    }
  }

  function handleDropCatalog(e: React.DragEvent<HTMLDivElement | HTMLFormElement>) {
    e.preventDefault()
    e.stopPropagation()
    setIsDragging(false)
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      onUpload(e.dataTransfer.files[0])
    }
  }

  function handleSelectCatalog(event: ChangeEvent<HTMLInputElement>) {
    event.stopPropagation()
    event.preventDefault()

    const files = event.target.files

    if (files && files.length > 0) {
      const file = files[0]
      onUpload(file)
    }
  }

  const inputRef = React.useRef<HTMLInputElement>(null)

  function onUploadClick() {
    inputRef.current?.click()
  }

  return <div className={'relative space-y-2 flex flex-col h-full py-4'} onDragEnter={handleDragCatalog}>
    <p className={'text-sm font-semibold'}>Latest Upload</p>
    <TableSearchBar onSearchQueryChange={x => onFilter(x.searchTerm)} searchQuery={term} />
    <InfinityListComponent className={'flex-1 !max-h-[824px]'} isLoading={isLoading} currentItems={logos}
      onLoadMore={onLoadMore}
      renderItem={(logo) => <LogoCardLibrary onAdd={() => onAddLogo(logo)} onDelete={() => onDeleteLogo(logo)} key={logo.id} logo={logo} />} />
    <PrimaryButton width={'full'} onClick={onUploadClick}>Upload new File</PrimaryButton>
    <SecondaryButton width={'full'} onClick={onBack}>Back</SecondaryButton>
    <input ref={inputRef} className={'hidden'} type='file' id='input-file-upload'
      onChange={handleSelectCatalog}
      accept={extensionsToMimeTypesString(options.extensions)} />
    {isDragging &&
      (
        <>
          <div
            className={'z-10 absolute w-full h-full top-0 bottom-0 left-0 right-0'}
            onDragEnter={handleDragCatalog}
            onDragLeave={handleDragCatalog}
            onDragOver={handleDragCatalog}
            onDrop={handleDropCatalog}>
          </div>
          <div id={'drop-overlay'}
            className={'animate-fadeInFast bg-gray-200 absolute w-full h-full top-0 bottom-0 left-0 right-0 flex justify-center items-center'}>
            <div className={'space-y-3 flex flex-col h-5 mt-[-60px]'}>
              <FontAwesomeIcon icon={faFileArrowDown} size={'2x'} className={'mx-auto '} />
              <p className={'italic font-light text-xs'}>Drop image here</p>
            </div>
          </div>
        </>
      )
    }
  </div>
}