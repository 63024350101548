import { useCountryContext } from "../../screens/design-editor2/contexts/country.context"
import { KitMakerAutoCompleteItem, KitMakerAutoComplete } from "./KitMakerAutoComplete"

export interface CountryAutoCompleteItem {
  code: string
  name: string
}

export function CountryAutoComplete({ disabled, onCountryChange, selectedCode }: {
  disabled: boolean,
  onCountryChange: (color: CountryAutoCompleteItem) => void
  selectedCode: string | undefined
}) {
  const { countries } = useCountryContext();
  const value = countries.find(x => x.code === selectedCode)

  return <>
    <KitMakerAutoComplete 
      subject={"Country"} 
      items={countries.map(x => ({key: x.code, name: x.name}))} 
      disabled={disabled} 
      value={value ? {key: value.code, name: value.name} : undefined}
      onItemChange={function (item: KitMakerAutoCompleteItem): void {
        onCountryChange({
          code: item.key,
          name: item.name
        })
      } } 
    />
  </>
}