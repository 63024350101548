import { InputWrapper } from '../../../../components/Input/InputWrapper'
import { InputLabel } from '../../../../components/Input/InputLabel'
import { Color, Font, LogoOptions } from '../../../../../core/models/entities/LogoOptions'
import { Autocomplete, Box, createFilterOptions } from '@mui/material'
import ColorBlock from '../../../logo-editor/componenets/ColorBlock'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons'
import { CustomInput } from '../../../../components/Input/CustomInput'
import { KitMakerAutoComplete, KitMakerAutoCompleteItem } from '../../../../components/Input/KitMakerAutoComplete'

interface FontAndColorEditor_VM {
  options: LogoOptions
  color?: Color
  onSelectColor: (color: Color) => void
  font?: Font
  onSelectFont: (font: Font) => void
  fontSize: number
  onSelectFontSize: (value: number) => void
  value?: string
  onChangeValue?: (value: string) => void
  showValueField: boolean
}

export function FontAndColorEditor(
  {
    options, color, onSelectColor, onSelectFont, font, onSelectFontSize, fontSize, showValueField, value, onChangeValue
  }: FontAndColorEditor_VM) {

  if (showValueField && !onChangeValue) {
    console.warn('showValueField set to true but onChangeValue is undefined')
  }

  return <div className={'space-y-4 py-4'}>
    {
      showValueField && <InputWrapper>
        <InputLabel>VALUE</InputLabel>
        <CustomInput className={'bg-white'} onChange={x => onChangeValue!(x.target.value)} value={value ?? ''} disabled={false} />
      </InputWrapper>
    }
    <InputWrapper>
      <InputLabel>FONT</InputLabel>
      <FontAutoComplete fonts={options.fonts} onFontChange={onSelectFont} value={font} disabled={false} />
    </InputWrapper>

    <InputWrapper>
      <InputLabel>HEIGHT</InputLabel>
      <HeightAutoComplete heights={options.fontSizes} onHeightChange={onSelectFontSize} value={fontSize} disabled={false} />
    </InputWrapper>

    <InputWrapper>
      <InputLabel>COLOR</InputLabel>
      <ColorAutoComplete colors={options.colors} disabled={false} onColorPicked={onSelectColor} value={color?.id} />
    </InputWrapper>
  </div>
}



export function FontAutoComplete({ fonts, disabled, onFontChange, value }: {
  fonts: Font[],
  disabled: boolean,
  onFontChange: (color: Font) => void
  value: Font | undefined
}) {

  return <>
    <KitMakerAutoComplete
      subject={"Font"}
      items={fonts.map(x => ({ key: x.key, name: x.name }))}
      disabled={disabled}
      value={value ? { key: value.key, name: value.name } : undefined}
      onItemChange={(item: KitMakerAutoCompleteItem) => { onFontChange({ key: item.key, name: item.name }) }}
    />
  </>
}

function FontSizeAutoComplete({ fontSizes, disabled, onFontSizeChange, value }: {
  fontSizes: number[],
  disabled: boolean,
  onFontSizeChange: (size: number) => void
  value: number | undefined
}) {
  const filterOptions = createFilterOptions<number>({
    limit: 200
  })

  return <Autocomplete
    className={'!text-xs'}
    disabled={disabled}
    options={fontSizes}
    value={value || null}
    filterOptions={filterOptions}
    getOptionLabel={(option) => option.toString()}
    renderOption={(props, option) => (
      <Box
        component='li'
        {...props}
        key={option}
      >
        <div className={'flex text-xs items-center space-x-2'}>
          <p>{option}</p>
        </div>
      </Box>
    )}
    renderInput={(params) =>
    (
      <div ref={params.InputProps.ref}
        className='border rounded-md border-gray-200 border-solid h-9 flex space-x-2 items-center pl-2 bg-white focus-within:border-gray-600'>
        <span>
          <FontAwesomeIcon icon={faMagnifyingGlass} />
        </span>
        <input {...params.inputProps} className='border-none text-xs font-medium outline-none w-full'
          placeholder={'search for Font...'} />
      </div>
    )}
    onChange={(x, v) => {
      if (v) {
        onFontSizeChange(v)
      }
    }}
  />
}

function HeightAutoComplete({ heights, disabled, onHeightChange, value }: {
  heights: number[],
  disabled: boolean,
  onHeightChange: (value: number) => void
  value: number | undefined
}) {
  const filterOptions = createFilterOptions<number>({
    limit: 200
  })

  return <Autocomplete
    className={'!text-xs'}
    disabled={disabled}
    options={heights}
    value={value || null}
    filterOptions={filterOptions}
    getOptionLabel={(option) => option.toString()}
    renderOption={(props, option) => (
      <Box
        component='li'
        {...props}
        key={option}
      >
        <div className={'flex text-xs items-center space-x-2'}>
          <p>{option}</p>
        </div>
      </Box>
    )}
    renderInput={(params) =>
    (
      <div ref={params.InputProps.ref}
        className='border rounded-md border-gray-200 border-solid h-9 flex space-x-2 items-center pl-2 bg-white focus-within:border-gray-600'>
        <span>
          <FontAwesomeIcon icon={faMagnifyingGlass} />
        </span>
        <input {...params.inputProps} className='border-none text-xs font-medium outline-none w-full'
          placeholder={'search for Height...'} />
      </div>
    )}
    onChange={(x, v) => {
      if (v) {
        onHeightChange(v)
      }
    }}
  />
}

function ColorAutoComplete({ colors, disabled, onColorPicked, value }: {
  colors: Color[],
  disabled: boolean,
  onColorPicked: (color: Color) => void
  value: string | undefined
}) {
  const filterOptions = createFilterOptions<Color>({
    limit: 200
  })

  const picked = colors.find(x => x.id === value) ?? null

  function handleColorPicked(color: Color) {
    onColorPicked(color)
  }

  return <Autocomplete
    className={'!text-xs'}
    disabled={disabled}
    options={colors}
    value={picked}
    filterOptions={filterOptions}
    getOptionLabel={(option) => option.name}
    renderOption={(props, option) => {
      // @ts-ignore
      const { key, ...otherProps } = props;
      return (
        <Box
          key={key}
          component='li'
          {...otherProps}
        >
          <div className={'flex text-xs items-center space-x-2'}>
            <ColorBlock color={option} />
            <p>{option.name}</p>
          </div>
        </Box>
      )
    }}
    renderInput={(params) =>
    (
      <div ref={params.InputProps.ref}
        className='border rounded-md border-gray-200 border-solid h-9 flex space-x-2 items-center pl-2 bg-white focus-within:border-gray-600'>
        <span>
          <FontAwesomeIcon icon={faMagnifyingGlass} />
        </span>
        <input {...params.inputProps} className='border-none text-xs font-medium outline-none w-full'
          placeholder={'search for color...'} />
      </div>
    )}
    onChange={(x, v) => {
      if (v) {
        handleColorPicked(v)
      }
    }}
  />
}
