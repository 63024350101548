import Tooltip from '@mui/material/Tooltip'
import ColorBlock from './ColorBlock'
import { Color } from '../../../../core/models/entities/LogoOptions'

function ColorBlockWithToolTip({ color, onClick, size }: { color: Color, onClick: (color: Color) => void, size?: 'sm' | 'md' }) {
  return (
    <Tooltip title={color.name} placement={'right'}>
      <div onClick={() => onClick(color)}><ColorBlock size={size} color={color} /></div>
    </Tooltip>
  )
}


export default ColorBlockWithToolTip