import React, { SVGProps } from 'react'
import { ReactComponent as NoteIcon } from './note-icon.svg'

interface IconProps extends SVGProps<SVGSVGElement> {}

export { NoteIcon }

export function CloneIcon(props: IconProps) {
  return (
    <svg
      width="10"
      height="10"
      viewBox="0 0 10 10"
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        d="M7.14286 2.14286V0.714286C7.14286 0.524845 7.0676 0.343164 6.93365 0.209209C6.79969 0.0752548 6.61801 0 6.42857 0H0.714286C0.524845 0 0.343164 0.0752548 0.209209 0.209209C0.0752548 0.343164 0 0.524845 0 0.714286V6.42857C0 6.61801 0.0752548 6.79969 0.209209 6.93365C0.343164 7.0676 0.524845 7.14286 0.714286 7.14286H2.14286V2.85714C2.14286 2.6677 2.21811 2.48602 2.35207 2.35207C2.48602 2.21811 2.6677 2.14286 2.85714 2.14286H7.14286Z"
        fill="currentColor"
      />
      <path
        d="M9.2855 2.85742H3.57122C3.38178 2.85742 3.2001 2.93268 3.06614 3.06663C2.93219 3.20059 2.85693 3.38227 2.85693 3.57171V9.28599C2.85693 9.47543 2.93219 9.65711 3.06614 9.79107C3.2001 9.92502 3.38178 10.0003 3.57122 10.0003H9.2855C9.47494 10.0003 9.65663 9.92502 9.79058 9.79107C9.92453 9.65711 9.99979 9.47543 9.99979 9.28599V3.57171C9.99979 3.38227 9.92453 3.20059 9.79058 3.06663C9.65663 2.93268 9.47494 2.85742 9.2855 2.85742ZM8.57122 6.78599H6.7855V8.57171H6.07122V6.78599H4.2855V6.07171H6.07122V4.28599H6.7855V6.07171H8.57122V6.78599Z"
        fill="currentColor"
      />
    </svg>
  )
}

export function AddIcon(props: IconProps) {
  return (
    <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.6 0.8C1.15817 0.8 0.8 1.15817 0.8 1.6V4.8C0.8 5.24184 1.15817 5.6 1.6 5.6H4.8C5.24184 5.6 5.6 5.24184 5.6 4.8V1.6C5.6 1.15817 5.24184 0.8 4.8 0.8H1.6ZM0 1.6C0 0.716346 0.716346 0 1.6 0H4.8C5.68368 0 6.4 0.71635 6.4 1.6V4.8C6.4 5.68367 5.68367 6.4 4.8 6.4H1.6C0.71635 6.4 0 5.68368 0 4.8V1.6Z"
        fill="#596675"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.6001 1.6001C7.82101 1.6001 8.0001 1.77918 8.0001 2.0001V6.4001C8.0001 7.28377 7.28377 8.0001 6.4001 8.0001H2.0001C1.77918 8.0001 1.6001 7.82101 1.6001 7.6001C1.6001 7.37918 1.77918 7.2001 2.0001 7.2001H6.4001C6.84194 7.2001 7.2001 6.84194 7.2001 6.4001V2.0001C7.2001 1.77918 7.37918 1.6001 7.6001 1.6001Z"
        fill="#596675"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.2001 1.6001C3.42101 1.6001 3.6001 1.77918 3.6001 2.0001V2.8001H4.4001C4.62101 2.8001 4.8001 2.97918 4.8001 3.2001C4.8001 3.42101 4.62101 3.6001 4.4001 3.6001H3.6001V4.4001C3.6001 4.62101 3.42101 4.8001 3.2001 4.8001C2.97918 4.8001 2.8001 4.62101 2.8001 4.4001V3.6001H2.0001C1.77918 3.6001 1.6001 3.42101 1.6001 3.2001C1.6001 2.97918 1.77918 2.8001 2.0001 2.8001H2.8001V2.0001C2.8001 1.77918 2.97918 1.6001 3.2001 1.6001Z"
        fill="#596675"
      />
    </svg>
  )
}

export function TrashcanIcon(props: IconProps) {
  return (
    <svg width="6" height="8" viewBox="0 0 6 8" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4.38057 1.01646C4.39464 1.07365 4.45264 1.11993 4.51055 1.11993H5.63162C5.83504 1.11993 6 1.2871 6 1.49333C6 1.66357 5.88756 1.80717 5.73379 1.85212L5.54139 7.21361C5.52568 7.65019 5.16772 8 4.73619 8H1.26372C0.832903 8 0.474232 7.6494 0.458606 7.21361L0.266208 1.85212C0.112445 1.80717 0 1.66358 0 1.49333C0 1.2871 0.164964 1.11993 0.368384 1.11993H1.48936C1.5471 1.11993 1.60545 1.07312 1.61942 1.01646L1.69826 0.696899C1.79567 0.30204 2.177 0 2.57848 0H3.42136C3.8229 0 4.20416 0.30186 4.30164 0.696899L4.38057 1.01646ZM2.73685 2.82661V6.45334C2.73685 6.60062 2.85467 6.72002 3.00001 6.72002C3.14536 6.72002 3.26317 6.60062 3.26317 6.45334V2.82661C3.26317 2.67932 3.14536 2.55993 3.00001 2.55993C2.85467 2.55993 2.73685 2.67932 2.73685 2.82661ZM1.52654 2.83444L1.63177 6.46117C1.63602 6.60837 1.75723 6.72424 1.90258 6.71983C2.04784 6.71552 2.1621 6.5927 2.15783 6.44549L2.0526 2.81876C2.04835 2.67157 1.92706 2.55578 1.78179 2.5601C1.63654 2.56441 1.52218 2.68724 1.52654 2.83444ZM3.94747 2.81878L3.84224 6.44551C3.83789 6.5927 3.95224 6.71552 4.09749 6.71984C4.24274 6.72424 4.36403 6.60837 4.3683 6.46119L4.47353 2.83445C4.47778 2.68726 4.36352 2.56444 4.21828 2.56012C4.07293 2.55581 3.95173 2.67159 3.94747 2.81878ZM2.40576 1.11992H3.59428C3.62302 1.11992 3.64091 1.09696 3.63387 1.06863L3.5869 0.877971C3.57136 0.815239 3.48471 0.746614 3.42142 0.746614H2.57854C2.51533 0.746614 2.4286 0.815329 2.41314 0.877971L2.36609 1.06863C2.35897 1.09766 2.37685 1.11992 2.40576 1.11992Z"
        fill="#596675"
      />
    </svg>
  )
}

export function TrashcanCrossIcon(props: IconProps) {
  return (
    <svg
      width="8"
      height="10"
      viewBox="0 0 8 10"
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <g clipPath="url(#clip0_2367_32706)">
        <path
          d="M5.06636 0H2.93327C2.79203 0 2.65803 0.0543294 2.55662 0.14601C2.45522 0.241087 2.4009 0.366723 2.4009 0.499151V0.998302H0.532179C0.340237 0.998302 0.166403 1.09338 0.068621 1.24958C-0.0291608 1.40577 -0.0255392 1.59593 0.0722426 1.74873C0.166403 1.90492 0.343859 2 0.535801 2H7.46745C7.65939 2 7.83322 1.90492 7.931 1.74873C8.02879 1.59253 8.02516 1.40238 7.931 1.24958C7.83684 1.09677 7.65939 0.998302 7.46745 0.998302H5.60235V0.499151C5.60235 0.366723 5.5444 0.237691 5.44662 0.14601C5.34522 0.0509338 5.21122 0 5.06998 0L5.06636 0Z"
          fill="currentColor"
        />
        <path
          d="M7.73159 9.00157V3.24945C7.73159 3.04911 7.64829 2.85896 7.49619 2.71974C7.34771 2.58052 7.14128 2.49902 6.93123 2.49902H1.06794C0.854269 2.49902 0.651462 2.57712 0.502979 2.71974C0.354495 2.85896 0.267578 3.0525 0.267578 3.24945V9.00157C0.267578 9.26643 0.379846 9.52109 0.579031 9.70785C0.778216 9.89461 1.04983 9.99987 1.33231 9.99987H6.66685C6.94934 9.99987 7.22095 9.89461 7.42014 9.70785C7.61932 9.52109 7.73159 9.26643 7.73159 9.00157ZM5.1784 6.64503C5.2798 6.74011 5.33775 6.86575 5.33775 7.00157C5.33775 7.13739 5.2798 7.26303 5.1784 7.35811C5.07699 7.45318 4.943 7.50751 4.79814 7.50751C4.65327 7.50751 4.51928 7.45318 4.41787 7.35811L3.99777 6.95743L3.57767 7.35811C3.47627 7.45318 3.34227 7.50751 3.19741 7.50751C3.05255 7.50751 2.91855 7.45318 2.81715 7.35811C2.71574 7.26303 2.6578 7.13739 2.6578 7.00157C2.6578 6.86575 2.71574 6.74011 2.81715 6.64503L3.24449 6.25115L2.81715 5.85726C2.68315 5.73162 2.62883 5.54486 2.67953 5.37169C2.73023 5.19851 2.87509 5.06269 3.05979 5.01515C3.24449 4.96761 3.44005 5.01515 3.57767 5.14418L3.99777 5.54486L4.41787 5.14418C4.55187 5.01855 4.75106 4.96761 4.93575 5.01515C5.12045 5.06269 5.26532 5.19851 5.31602 5.37169C5.36672 5.54486 5.31602 5.72823 5.1784 5.85726L4.75106 6.25115L5.1784 6.64503Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_2367_32706">
          <rect width="8" height="10" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

export function AddToCartIcon(props: IconProps) {
  return (
    <svg width="13" height="13" viewBox="0 0 13 13" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M3.96351 9.5127C3.17587 9.5127 2.53662 10.1519 2.53662 10.9396C2.53662 11.7272 3.17587 12.3665 3.96351 12.3665C4.75116 12.3665 5.3904 11.7272 5.3904 10.9396C5.3904 10.1519 4.75116 9.5127 3.96351 9.5127ZM3.96351 11.4152C3.70096 11.4152 3.48788 11.2021 3.48788 10.9396C3.48788 10.677 3.70096 10.464 3.96351 10.464C4.22606 10.464 4.43914 10.677 4.43914 10.9396C4.43914 11.2021 4.22606 11.4152 3.96351 11.4152Z"
        fill="currentColor"
      />
      <path
        d="M9.67103 9.5127C8.88339 9.5127 8.24414 10.1519 8.24414 10.9396C8.24414 11.7272 8.88339 12.3665 9.67103 12.3665C10.4587 12.3665 11.0979 11.7272 11.0979 10.9396C11.0979 10.1519 10.4587 9.5127 9.67103 9.5127ZM9.67103 11.4152C9.40848 11.4152 9.1954 11.2021 9.1954 10.9396C9.1954 10.677 9.40848 10.464 9.67103 10.464C9.93358 10.464 10.1467 10.677 10.1467 10.9396C10.1467 11.2021 9.93358 11.4152 9.67103 11.4152Z"
        fill="currentColor"
      />
      <path
        d="M6.90892 6.65986C7.03829 6.65986 7.16385 6.60659 7.25327 6.51147L9.06447 4.60895C9.24521 4.41869 9.2376 4.1181 9.04735 3.93736C8.8571 3.75662 8.5565 3.76423 8.37576 3.95448L6.90892 5.49552L6.52841 5.09599C6.34767 4.90574 6.04707 4.89813 5.85682 5.07887C5.66657 5.25961 5.65896 5.56021 5.8397 5.75046L6.56456 6.51147C6.65398 6.60659 6.77764 6.65986 6.90892 6.65986Z"
        fill="currentColor"
      />
      <path
        d="M12.7339 2.53122C12.3097 1.97949 11.3109 1.97949 10.0457 1.97949H6.19877C5.93623 1.97949 5.72314 2.19257 5.72314 2.45512C5.72314 2.71767 5.93623 2.93075 6.19877 2.93075H10.0457C10.5479 2.93075 11.0217 2.93075 11.3984 2.9688C11.8626 3.01637 11.971 3.10198 11.9824 3.1134C12.1289 3.30936 12.0148 3.88201 11.8436 4.74576L11.5258 6.283C11.3413 7.17718 11.2519 7.57861 11.0407 7.74984C10.8295 7.92107 10.4186 7.92868 9.50726 7.92868C9.24471 7.92868 9.03163 8.14176 9.03163 8.40431C9.03163 8.66686 9.24471 8.87994 9.50726 8.87994C10.5746 8.87994 11.1606 8.87994 11.6419 8.48802C12.1232 8.0961 12.2412 7.51964 12.4562 6.47515L12.7739 4.93791V4.93411C13.0136 3.73171 13.1468 3.06773 12.7339 2.53122Z"
        fill="currentColor"
      />
      <path
        d="M6.81724 7.92831H6.14375C4.40294 7.92831 3.67998 7.91119 3.28616 7.49644C2.86951 7.05696 2.82765 6.58513 2.82765 4.76252V3.03693C2.82765 3.00078 2.82765 2.96463 2.82765 2.93039H3.64764C3.91019 2.93039 4.12327 2.71731 4.12327 2.45476C4.12327 2.19221 3.91019 1.97913 3.64764 1.97913H2.80292C2.76867 1.60053 2.69067 1.31134 2.50993 1.04689C2.1865 0.573164 1.69375 0.400035 0.801467 0.0861188L0.634045 0.0271407C0.386717 -0.0603753 0.114657 0.0708986 0.0271407 0.318226C-0.0603753 0.565554 0.0708987 0.837615 0.318227 0.925131L0.483746 0.984109C1.25997 1.25617 1.5796 1.37603 1.72229 1.58531C1.86878 1.80029 1.87449 2.1865 1.87449 3.03883V4.76442C1.87449 6.6346 1.90303 7.42224 2.59364 8.15281C3.28426 8.88148 4.2983 8.88148 6.14185 8.88148H6.81724C7.07979 8.88148 7.29287 8.66839 7.29287 8.40585C7.29287 8.1433 7.07979 7.92831 6.81724 7.92831Z"
        fill="currentColor"
      />
    </svg>
  )
}

export function EditIcon(props: IconProps) {
  return (
    <svg width="11" height="11" viewBox="0 0 11 11" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M7.60819 7.61033L8.7622 6.45775V10.4237C8.7622 10.5765 8.70138 10.7231 8.59325 10.8312C8.48503 10.9393 8.33825 11 8.1852 11H0.577007C0.423952 11 0.277168 10.9393 0.168953 10.8312C0.0608233 10.7231 0 10.5765 0 10.4237V2.82496C0 2.67219 0.0608233 2.52559 0.168953 2.41751C0.277178 2.30942 0.423957 2.24867 0.577007 2.24867H4.51086L3.35685 3.40125H1.154V9.84764H7.60816L7.60819 7.61033ZM10.9999 2.38809C11.0006 2.51529 10.9509 2.63764 10.8614 2.72815L6.12317 7.45951C6.06444 7.51874 5.99124 7.56161 5.91081 7.58395L3.27281 8.32158C3.23179 8.3329 3.18952 8.3387 3.14698 8.33889C2.99801 8.33822 2.85781 8.26863 2.76738 8.15037C2.67705 8.03211 2.64669 7.87857 2.68543 7.73494L3.38818 5.09437C3.40979 5.01385 3.45195 4.94046 3.51049 4.88114L8.24773 0.141744C8.33749 0.0514315 8.45952 0.000379658 8.58697 0H8.59973C9.23442 0.000950677 9.84302 0.252497 10.2927 0.699884C10.7423 1.14737 10.9967 1.75416 11 2.38807L10.9999 2.38809ZM5.5265 6.70085C5.48319 6.38761 5.33775 6.09727 5.11274 5.87483C4.88763 5.65228 4.5955 5.51007 4.28141 5.46993L4.00557 6.49912V6.49922C4.22449 6.59476 4.40003 6.76807 4.49835 6.98557L5.5265 6.70085Z"
        fill="currentColor"
      />
    </svg>
  )
}
