import { useQuery, useQueryClient } from '@tanstack/react-query'
import { CreateOrganizationDto } from '../../core/models/dtos/CreateCustomerDto'
import { UpdateOrganizationDto } from '../../core/models/dtos/UpdateCustomerDto'
import { Organization } from '../../core/models/entities/Organization'
import { Resource } from '../../core/models/wrappers/Resource'
import { OrganizationRepository } from '../../core/repositories/OrganizationRepository'
import { useApiClient } from '../clients/ApiClient'
import { mapToOrganization } from '../models/mappers/mapOrganization'
import { PageQuery, toQueryParam } from '../../utils/models/pageQuery'
import { PagedResponse } from '../models/responses/pagedResponse'
import { mapFromCreateOrganizationDto } from '../models/mapFromCreateOrganizationDto'
import { mapFromUpdateOrganizationDto } from '../models/mapFromUpdateOrganizationDto'
import { CommissionProfile } from '../../core/models/entities/Commission'

function useOrganizationRepository(): OrganizationRepository {
  const apiClient = useApiClient()

  const queryClient = useQueryClient()
  async function invalidateCustomer(id: Organization['id']) {
    await queryClient.refetchQueries({ queryKey: ['organization', id], type: 'all', refetchPage: () => true })
    await queryClient.refetchQueries({ queryKey: ['customer', id], type: 'all', refetchPage: () => true })
    await invalidateCustomers()
  }

  async function invalidateCustomers() {
    await queryClient.refetchQueries({ queryKey: ['all-customers'], type: 'all', refetchPage: () => true })
  }

  function useOrganization(id: Organization['id']): Resource<Organization> {
    return useQuery(['organization', id], () => apiClient.getOrganization(id).then((data) => mapToOrganization(data)))
  }

  function useCustomer(id: Organization['id']): Resource<Organization> {
    return useQuery(['customer', id], () => apiClient.getCustomer(id).then((data) => mapToOrganization(data)))
  }

  function useVendor(id?: Organization['id']): Resource<Organization> {
    return useQuery(['vendor', id], () => {
      if (!id) {
        return null
      }
      return apiClient.getVendor(id).then((data) => mapToOrganization(data))
    })
  }

  async function getOrganizationById(id: Organization['id']): Promise<Organization> {
    const response = await apiClient.getOrganization(id)
    return mapToOrganization(response)
  }

  async function getVendorById(id: Organization['id']): Promise<Organization> {
    return apiClient.getVendor(id).then((data) => mapToOrganization(data));
  }

  async function getCustomerById(id: Organization['id']): Promise<Organization> {
    return apiClient.getCustomer(id).then((data) => mapToOrganization(data));
  }

  function useAllCustomers(query: PageQuery): Resource<PagedResponse<Organization>> {
    return useQuery(['all-customers', toQueryParam(query)],
      () => apiClient.getAllCustomers(query).then((data) => ({ ...data, results: data.results.map(mapToOrganization) })))
  }

  function useAllVendors(query: PageQuery): Resource<PagedResponse<Organization>> {
    return useQuery(['all-vendors', toQueryParam(query)],
      () => apiClient.getAllVendors(query).then((data) => ({ ...data, results: data.results.map(mapToOrganization) })))
  }

  async function getAllCustomers(): Promise<Organization[]> {
    const response = await apiClient.getAllCustomers({ page: { size: 0, index: 0 } } as PageQuery)
    return response.results.map(mapToOrganization)
  }

  async function getAllVendors(): Promise<Organization[]> {
    const response = await apiClient.getAllVendors({ page: { size: 0, index: 0 } } as PageQuery)
    return response.results.map(mapToOrganization)
  }

  async function updateOrganization(dto: UpdateOrganizationDto, companyLogo?: File): Promise<void> {
    return await apiClient
      .updateOrganization(dto.id, mapFromUpdateOrganizationDto(dto))
      .then(async (res) => {
        if (res.isVendor && companyLogo) {
          await apiClient.uploadCompanyLogo(res.id, companyLogo)
        }
      })
  }

  async function updateOrganizationLogo(organizationId: Organization['id'], companyLogo: File): Promise<void> {
    return await apiClient.uploadCompanyLogo(organizationId, companyLogo);
  }

  async function updateProviderLogo(companyLogo: File): Promise<void> {
    return await apiClient.uploadProviderLogo(companyLogo);
  }

  function createCustomer(organization: Organization): Promise<Organization> {
    return apiClient.createCustomer(organization).then(mapToOrganization);
  }

  function updateCustomer(organization: Organization): Promise<Organization> {
    return apiClient.updateCustomer(organization).then(mapToOrganization).finally(() => invalidateCustomer(organization.id));
  }

  function deleteCustomer(organization: Organization): Promise<void> {
    return apiClient.deleteCustomer(organization);
  }

  async function createOrganization(dto: CreateOrganizationDto, companyLogo?: File): Promise<void> {
    return await apiClient.createOrganization(mapFromCreateOrganizationDto(dto)).then(async (res) => {
      if (res.isVendor && companyLogo) {
        await apiClient.uploadCompanyLogo(res.id, companyLogo)
      }
    })
  }

  function deleteOrganization(id: number): Promise<void> {
    return apiClient.deleteOrganization(id)
  }

  async function setCommissionProfile(orgId: number, commissionProfile: CommissionProfile): Promise<void> {
    if (!commissionProfile.overAll && commissionProfile.methods.length === 0 && commissionProfile.typeOnly.length === 0) {
      await apiClient.deleteCommissionProfile(orgId)
    }
    else {
      await apiClient.setCommissionProfile(orgId, commissionProfile)
    }
    await invalidateCustomer(orgId)
  }

  return {
    useAllVendors,
    useAllCustomers,
    useOrganization,
    useCustomer,
    useVendor,
    getAllVendors,
    getAllCustomers,
    getOrganizationById,
    getCustomerById,
    getVendorById,
    updateOrganization,
    createOrganization,
    deleteOrganization,
    updateOrganizationLogo,
    updateProviderLogo,
    createCustomer,
    deleteCustomer,
    updateCustomer,
    setCommissionProfile
  }
}

export { useOrganizationRepository }
