import { JSX } from 'react'
import { Organization } from '../../../../core/models/entities/Organization'
import { Profile } from '../../../../core/models/entities/Profile'
import { BaseLogo } from '../../../../core/models/entities/BaseLogo'
import { LogoOptions } from '../../../../core/models/entities/LogoOptions'
import { useLogoEditorViewModel } from '../vmHooks/useLogoEditorViewModel'
import { LogoEditorComponent } from '../componenets/logo-editor.component'

interface WrapperProps {
  profile: Profile;
  customers: Organization[];
  vendors: Organization[];
  selectedCustomerId?: Organization['id']
  options: LogoOptions;
  preselectedLogo?: BaseLogo | undefined
}

export function LogoEditorWrapper({
                                    profile,
                                    customers,
                                    vendors,
                                    options,
                                    selectedCustomerId,
                                    preselectedLogo = undefined
                                  }: WrapperProps): JSX.Element {
  const vm = useLogoEditorViewModel(profile, customers, vendors, options, selectedCustomerId, preselectedLogo)
  return <LogoEditorComponent {...vm} />

}




