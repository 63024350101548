import { ReactElement } from 'react'
import { useParams } from 'react-router-dom'
import { StyleEditForm } from './subcomponents/style-edit-form/StyleEditForm'
import { ErrorView } from '../../components/error-view/ErrorView'
import { LoadingView } from '../../components/loading-view/LoadingView'
import { NotFoundPage } from '../not-found/NotFoundPage'
import { useStyleEditViewModel } from './useStyleEditViewModel'

export default function StyleEditScreen(): ReactElement {
  const { styleId } = useParams()

  if (styleId === undefined || isNaN(+styleId)) {
    return <NotFoundPage />
  } else {
    const { style, isStyleLoading, isStyleSuccess, updateStyle } = useStyleEditViewModel(
      Number(styleId)
    )
    return (
      <>
        {style && isStyleSuccess ? (
          <StyleEditForm style={style} updateStyle={updateStyle} />
        ) : isStyleLoading ? (
          <LoadingView />
        ) : (
          <ErrorView />
        )}
      </>
    )
  }
}
