import { useNavigate } from 'react-router-dom'
import { useState } from 'react'
import { useRepositoriesContext } from '../../../../contexts/RepositoriesContext'
import { Design } from '../../../../core/models/entities/Design'
import { CreateDesignDto } from '../../../../core/models/dtos/CreateDesignDto'
import { InitiateApproval } from '../../logo-list/hooks/useLogoActionsHandler'
import { useOrderActionHandler } from '../../order-create/hooks/useOrderActionsHandler'
import { Verdict } from '../../../components/ApprovalVoting/approval-voting'
import { withToasts } from '../../../components/Toast/Toast'

interface DesignActionHandler {
  isLoading: boolean
  handleCopyDesign: (design: Design) => Promise<Design>
  handleCreateDesign: (design: CreateDesignDto) => Promise<Design>
  handleGoToCreateDesign: (id?: number) => void
  handleGoToDesignList: () => void
  handleUpdateDesign: (design: Design) => Promise<Design>
  handleDeleteDesign: (design: Design) => Promise<void>
  handleGoToUpdateDesign: (design: Design) => void
  handleAddToCart: (design: Design, quantity: number) => Promise<void>
  handleAddItemsToCart: (designs: Design[], quantity: number) => Promise<void>
  handleInitiateApproval: (design: Design, approval: InitiateApproval) => Promise<Design>,
  handlePlaceVerdict: (design: Design, verdict: Verdict) => Promise<Design>,
  handleDeleteLogo: (design: Design) => Promise<void>,
  handleUpdateThenCommit: (design: Design) => Promise<Design>
  handleCommit: (design: Design) => Promise<Design>
  handleApprovalSkip: (design: Design) => Promise<Design>
  handleChangeStyle(orgId: number, designId: number, styleId: number): Promise<Design>
  handleChangeTitle(orgId: number, designId: number, title: string): Promise<Design>
  handleSkipAddToCart(design: Design, quantity: number): Promise<Design>
  handleRejectAndDelete: (design: Design, note?: string) => Promise<void>
}

export function useDesignActionHandler(): DesignActionHandler {
  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState(false)

  const { designRepository } = useRepositoriesContext()
  const { handleDesignAddOrderLine, handleDesignAddOrderLines } = useOrderActionHandler();

  function handleCopyDesign(design: Design) {
    async function transaction() {
      return await designRepository.copyDesign(design.customerId, design.id)
    }

    return withToasts(
      transaction,
      'Copying design',
      'Design copied',
      'Failed to copy design',
      setIsLoading
    )
  }

  function handleCreateDesign(design: CreateDesignDto): Promise<Design> {
    async function transaction() {
      return await designRepository.createDesign({ ...design })
    }

    return withToasts(
      transaction,
      'Creating design',
      'Design created',
      'Failed to create design',
      setIsLoading
    )
  }

  function handleChangeStyle(orgId: number, designId: number, styleId: number): Promise<Design> {
    async function transaction() {
      return await designRepository.changeStyle(orgId, designId, styleId)
    }

    return withToasts(
      transaction,
      'Updating design style',
      'Design style updated',
      'Failed to update design style',
      setIsLoading
    )
  }


  function handleChangeTitle(orgId: number, designId: number, title: string) {
    async function transaction() {
      return await designRepository.changeTitle(orgId, designId, title)
    }

    return withToasts(
      transaction,
      'Updating design title',
      'Design title updated',
      'Failed to update design title',
      setIsLoading
    )
  }


  function handleAddToCart(design: Design, quantity: number): Promise<void> {
    handleDesignAddOrderLine(design, quantity);
    return Promise.resolve()
  }

  function handleAddItemsToCart(designs: Design[], quantity: number): Promise<void> {
    handleDesignAddOrderLines(designs, quantity);
    return Promise.resolve()
  }

  function handleUpdateDesign(design: Design) {
    async function transaction() {
      return await designRepository.updateDesign(design)
    }

    return withToasts(
      transaction,
      'Updating design',
      'Design updated',
      'Failed to update design',
      setIsLoading
    )
  }

  function handleDeleteDesign(design: Design) {
    async function transaction() {
      return await designRepository.deleteDesign(design.customerId, design.id)
    }

    return withToasts(
      transaction,
      'Deleting design',
      'Design deleted',
      'Failed to delete design',
      setIsLoading
    )
  }

  function handleGoToCreateDesign(id?: number) {
    navigate('/design/create' + (id ? `/${id}` : ''))
  }

  function handleGoToDesignList() {
    navigate('/design')
  }

  function handleGoToUpdateDesign(design: Design) {
    navigate(`/design/${design.id}/editor?orgId=${design.customer.id}`)
  }

  function handleUpdateThenCommit(design: Design) {
    async function transaction() {
      await designRepository.updateDesign(design)
      return await designRepository.commit(design)
    }

    return withToasts(
      transaction,
      'Updating and committing design',
      'Design updated and committed',
      'Failed to update and commit design',
      setIsLoading
    )
  }

  function handleCommit(design: Design) {
    async function transaction() {
      return await designRepository.commit(design)
    }

    return withToasts(
      transaction,
      'Committing design',
      'Design committed',
      'Failed to commit design',
      setIsLoading
    )
  }

  function handleDeleteLogo(design: Design) {
    async function transaction() {
      return await designRepository.deleteDesign(design.customer.id, design.id)
    }

    return withToasts(
      transaction,
      'Deleting design',
      'Design deleted',
      'Failed to design logo',
      setIsLoading
    )
  }

  function handlePlaceVerdict(design: Design, verdict: Verdict) {
    async function transaction() {
      return await designRepository.placeVerdict(design, verdict)
    }

    return withToasts(
      transaction,
      'Updating approval',
      'Approval successful',
      'Approval failed',
      setIsLoading
    );
  }

  function handleInitiateApproval(design: Design, approval: InitiateApproval) {
    async function transaction() {
      const approvedDesign = await designRepository.initiateApproval(design, approval)
      return await designRepository.placeVerdict(approvedDesign, { approve: true })
    }

    return withToasts(
      transaction,
      'Updating approval',
      'Approval successful',
      'Approval failed',
      setIsLoading
    )
  }

  function handleApprovalSkip(design: Design): Promise<Design> {
    async function transaction() {
      return await designRepository.initiateApproval(design, {
        skip: true,
        customer: false,
        external: false,
        vendor: false,
        externalEmail: undefined
      })
    }

    return withToasts(
      transaction,
      'Skipping approval',
      'Approval skipped',
      'Skipping approval failed',
      setIsLoading
    )
  }

  function handleRejectAndDelete(design: Design, note?: string) {
    async function transaction() {
      const placedVerdict = await designRepository.placeVerdict(design, { approve: false, rejectionNode: note ?? "" })
      return await designRepository.deleteDesign(placedVerdict.customerId, design.id)
    }

    return withToasts(
      transaction,
      'Deleting design',
      'Deletion successful',
      'Deletion failed',
      setIsLoading
    )
  }

  function handleSkipAddToCart(design: Design, quantity: number) {
    async function transaction() {
      return await designRepository.initiateApproval(design, {
        skip: true,
        vendor: false,
        customer: false,
        external: false,
        externalEmail: undefined
      }).then((updatedDesign) => {
        handleAddToCart(updatedDesign, quantity)
        return updatedDesign
      })
    }

    return withToasts(
      transaction,
      'Skipping approval and adding to cart',
      'Approval skipped and added to cart successful',
      'Approval skip failed',
      setIsLoading
    )
  }

  return {
    isLoading,
    handleCopyDesign,
    handleCreateDesign,
    handleGoToCreateDesign,
    handleChangeTitle,
    handleUpdateDesign,
    handleDeleteDesign,
    handleGoToUpdateDesign,
    handleAddToCart,
    handleAddItemsToCart,
    handleGoToDesignList,
    handleInitiateApproval,
    handlePlaceVerdict,
    handleDeleteLogo,
    handleUpdateThenCommit,
    handleCommit,
    handleApprovalSkip,
    handleChangeStyle,
    handleSkipAddToCart,
    handleRejectAndDelete
  } 
}