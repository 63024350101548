import { useWhiteLabelContext } from '../../../../../WhiteLabelContext'
import { Direction } from '../../../design-editor/design-editor-tool/DesignEditorTool.vm'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faRotateRight } from '@fortawesome/free-solid-svg-icons/faRotateRight'
import { InputWithSuffix } from '../../../logo-editor/componenets/InputWithSuffix'
import { faLock } from '@fortawesome/free-solid-svg-icons/faLock'
import { faLockOpen } from '@fortawesome/free-solid-svg-icons/faLockOpen'
import { faRotateLeft } from '@fortawesome/free-solid-svg-icons/faRotateLeft'
import React from 'react'

export interface LogoPlacerHeaderBar_VM {
  locked: boolean
  onLockClick: (lock: boolean) => void
  onRotate: (direction: Direction) => void
  heightMM: number
  onHeightMMChange: (v: number) => void
  widthMM: number
  onWidthMMChange: (v: number) => void
  disabled: boolean
}

export function LogoPlacerHeaderBar({
                                      locked,
                                      onLockClick,
                                      onRotate,
                                      disabled,
                                      onWidthMMChange,
                                      widthMM,
                                      onHeightMMChange,
                                      heightMM
                                    }: LogoPlacerHeaderBar_VM) {
  const { whiteLabel: { colorHex } } = useWhiteLabelContext()
  return <>
    <IconButton disabled={disabled} onClick={() => onRotate(Direction.Right)}>
      <FontAwesomeIcon icon={faRotateRight} />
    </IconButton>
    <IconButton disabled={disabled} onClick={() => onRotate(Direction.Left)}>
      <FontAwesomeIcon icon={faRotateLeft} />
    </IconButton>
  </>
}

function IconButton(props: React.ButtonHTMLAttributes<HTMLButtonElement>) {
  return <button
    className={'rounded bg-white w-7 flex justify-center items-center border disabled:!bg-[#fafafa]'} {...props}>
    {props.children}
  </button>
}
