import { JSX } from 'react'
import { useProfileContext } from '../../../contexts/ProfileContext'
import { LoadingView } from '../../components/loading-view/LoadingView'
import { useRepositoriesContext } from '../../../contexts/RepositoriesContext'
import { ErrorView } from '../../components/error-view/ErrorView'
import { Profile } from '../../../core/models/entities/Profile'
import { Organization } from '../../../core/models/entities/Organization'
import { useSearchParams } from 'react-router-dom'
import { BaseLogo } from '../../../core/models/entities/BaseLogo'
import { PageQuery } from '../../../utils/models/pageQuery'
import { useCustomerAndVendors } from '../../shared/hooks/useCustomerAndVendors'
import { LogoOptions } from '../../../core/models/entities/LogoOptions'
import { ComponentState, useComponentStateMachine } from '../../shared/hooks/useComponentStateMachine'
import { useLogoOptionsContext } from '../../../contexts/LogoOptionsContext'
import { LogoEditorWrapper } from './wrappers/logo-editor.wrapper'

export const getAllQuery = { page: { index: 1, size: 0 } } as PageQuery

function LogoEditorScreen(): JSX.Element {
  const { profile } = useProfileContext()
  const { state, dataTransition } = useComponentStateMachine()
  const { organizationRepository: organizationRepository } = useRepositoriesContext()
  const options = useLogoOptionsContext()


  const {
    customers,
    vendors,
    isError: isOrganizationError,
    isSuccess: isOrganizationSuccess,
    isLoading: isOrganizationLoading
  } = useCustomerAndVendors(organizationRepository)


  const isLoading = isOrganizationLoading
  const isError = isOrganizationError
  const isSuccess = isOrganizationSuccess
  dataTransition(isLoading, isSuccess, isError)


  const [sp] = useSearchParams()
  const logoId = Number(sp.get('logo'))
  const orgId = Number(sp.get('organization'))

  return state === ComponentState.Succeed
    ? logoId && orgId
      ? <LogoEditorScreenWithPreselectedLogo orgId={orgId} logoId={logoId} options={options!} profile={profile!} vendors={vendors!} customers={customers!} />
      : <LogoEditorWrapper profile={profile!} vendors={vendors!} customers={customers!} options={options!} />

    : state === ComponentState.Loading
      ? <LoadingView />
      : <ErrorView />
}

function LogoEditorScreenWithPreselectedLogo({ logoId, orgId, profile, vendors, customers, options }: {
  logoId: BaseLogo['id'],
  orgId: Organization['id'],
  profile: Profile,
  customers: Organization[];
  vendors: Organization[];
  options: LogoOptions
}) {
  const { logoRepository } = useRepositoriesContext()
  const { data, isLoading } = logoRepository.useLogo(orgId, logoId)

  return isLoading
    ? <LoadingView />
    : <LogoEditorWrapper preselectedLogo={data!} profile={profile!} vendors={vendors} customers={customers} selectedCustomerId={orgId}
      options={options!} />
}

export default LogoEditorScreen