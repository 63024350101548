import { Marker } from '../models/marker.model'
import { areTwoFiguresColliding, getRotatedVertices } from '../../../../utils/functions/MathUtil'

export function isLogoOverlappingAnotherLogo(marker: Marker, allMarkers: Marker[]): boolean {


  // If marker is the only marker on the view there is no need to check for overlap
  if (allMarkers.length < 2) {
    return false
  }

  // Find corners/verteces of marker
  const logoVertices = getRotatedVertices(
    marker.x,
    marker.y,
    marker.widthPx,
    marker.heightPx,
    marker.rotationRad
  )

  for (const l of allMarkers) {
    // Logo will always overlap with itself, so continue loop
    if (l.id === marker.id) {
      continue
    }


    const lVertices = getRotatedVertices(l.x, l.y, l.widthPx, l.heightPx, l.rotationRad)
    if (areTwoFiguresColliding(logoVertices, lVertices)) {
      return true
    }
  }

  return false
}