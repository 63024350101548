import { Heating, HeatingProduct, LogoPrice, OrderLine, OrderLineDesign, OrderLineLogo, OrderLineProduct } from '../../../../core/models/entities/Order'
import { ProductGroup } from '../../order-confirmation/useOrderConfirmationEditViewModel'
import { BaseOrderLine } from './BaseOrderLine'
import { DesignOrderLine } from './DesignOrderLine'
import { LogoOrderLine } from './LogoOrderLine'

export interface OrderLineHideColumns {
  no?: boolean
  image?: boolean
  productId?: boolean
  title?: boolean
  type?: boolean
  pricePerPiece?: boolean
  qty?: boolean
  qtyPrice?: boolean
  totalPrice?: boolean
}

export interface OrderLineProductGroupList {
  productNo: string
  orderlines: OrderLine[]
}

interface Props {
  hideColumns?: OrderLineHideColumns
  orderlines: OrderLine[]
  productGroups: ProductGroup[]
  heating?: Heating[]
  logoPrices: LogoPrice[]
  getQuantity: (product: OrderLineProduct) => number
}

export function OrderLineList({ hideColumns, orderlines, productGroups, getQuantity, heating, logoPrices }: Props) {

  const productLines = orderlines.map((orderline, i) => {
    const index = (i + 1).toString()
    switch (orderline.product.productType) {
      case 'Design':
        return <DesignOrderLine key={i} no={index} orderline={orderline as OrderLineDesign} hideColumns={hideColumns} logoPrices={logoPrices} />
      case 'Logo':
        return <LogoOrderLine key={i} no={index} orderline={orderline as OrderLineLogo} hideColumns={hideColumns} getQuantity={getQuantity} logoPrices={logoPrices} />
      default:
        return <BaseOrderLine key={i} no={index} orderline={orderline} />
    }
  })

  return <table className={"table-fixed border-separate -sm:hidden empty-cells-hidden mx-auto w-full"}>
    <thead className={'uppercase'}>
      <tr>
        {(hideColumns?.no) ?? <th className='w-1/12 text-left'>No.</th>}
        {(hideColumns?.image) ?? <th className='w-1/12 text-left'>Image</th>}
        {(hideColumns?.productId) ?? <th className='w-1/12 text-left'>Product ID</th>}
        {(hideColumns?.title) ?? <th className='w-2/12 text-left'>Title</th>}
        {(hideColumns?.type) ?? <th className='w-1/12 text-left'>Type</th>}
        {(hideColumns?.pricePerPiece) ?? <th className='w-1/12 text-right'>Price Per Piece</th>}
        {(hideColumns?.qty) ?? <th className='w-1/12 text-right'>QTY</th>}
        {(hideColumns?.qtyPrice) ?? <th className='w-1/12 text-right'>QTY Price</th>}
        {(hideColumns?.totalPrice) ?? <th className='w-1/12 text-right'>Total</th>}
      </tr>
    </thead>
    <tbody>
      {productLines}
    </tbody>
  </table>
}