import { JetSportBox } from '../../../components/BoxView/JetSportBox'
import { BoxContent } from '../../../components/BoxView/BoxContent'
import { BoxFooter } from '../../../components/BoxView/BoxFooter'
import { Style } from '../../../../core/models/entities/Style'
import React from 'react'
import { StrucRow } from '../../../components/StructureElements/StrucRow'
import { StrucCol } from '../../../components/StructureElements/StrucCol'
import { BoxHeader2 } from '../../../components/BoxView/BoxHeader2'
import { BoxHeaderTypography } from '../../../components/Typographies/BoxHeaderTypography'
import { WhiteLabelIcon } from '../../logo-editor/componenets/WhiteLabelIcon'
import { faShirt } from '@fortawesome/free-solid-svg-icons'
import TableSearchBar from '../../../components/GenericTable/SubComponents/TableHeader/TableSearchBar'
import { ManufacturerAutoComplete } from '../../../components/autocompletes/manufacturer-auto.complete'
import { Manufacturer } from '../../../../core/models/entities/Manufacturer'
import { InputLabel } from '../../../components/Input/InputLabel'
import { InvertedPanel } from '../../../components/BoxView/InvertedPanel'
import { imageFallback } from '../../../shared/const/imageFallback'
import { InputWrapper } from '../../../components/Input/InputWrapper'
import { CustomInput } from '../../../components/Input/CustomInput'
import { Organization } from '../../../../core/models/entities/Organization'
import { PrimaryButton } from '../../../components/Buttons/PrimaryButton'
import { SecondaryButton } from '../../../components/Buttons/SecondaryButton'
import LoadingElement from '../../../components/loading-element/loading-element'
import { InfinityListComponent } from '../../../shared/components/infinity-list.component'
import { CustomAutoComplete } from '../../../components/Input/CustomAutoComplete'
import { Role } from '../../../../core/models/entities/Role'
import { StyleViewer } from '../../design-create/components/style-viewer.component'
import { StyleCard } from '../../design-create/components/style-card.component'

export interface DesignChangeStyleForm_VM {
  translate: (keyword: string) => string
  styles: Style[],
  isStylesLoading: boolean
  onLoadMoreStyles: () => void
  manufacturers: Manufacturer[]
  onFilterByManufacturer: (manufacturer: Manufacturer | null) => void,
  searchQuery: string
  onSearchQueryChange: (query: string) => void
  selectedStyle?: Style
  onSelectStyle: (style: Style) => void,
  showCustomerInfo: boolean,
  organization: Organization,
  title: string,
  onChangeTitle: (title: string) => void,
  selectedCustomer: Organization,
  onSelectCustomer: (customer: Organization) => void,
  onSubmit: () => void,
  disableSubmit: boolean
  onCancel: () => void
  isLoading: boolean
  role: Role
}


export default function DesignChangeStyleForm(
  {
    styles,
    translate: t,
    isStylesLoading,
    onLoadMoreStyles,
    manufacturers,
    onFilterByManufacturer,
    searchQuery,
    onSearchQueryChange,
    selectedStyle,
    onSelectStyle,
    showCustomerInfo,
    organization,
    onSubmit,
    onChangeTitle,
    title,
    disableSubmit,
    onCancel,
    isLoading,
    role
  }: DesignChangeStyleForm_VM): React.ReactElement {
  return (
    <JetSportBox>
      {isLoading && <LoadingElement transparent className={'rounded'} />}
      <BoxContent className={'!pr-0'}>
        <StrucRow className={'w-full flex-1'}>
          <StrucCol size={4} className={'!justify-start space-y-4 border-r pr-5 pb-5'}>
            <BoxHeader2 className={'flex justify-between !px-0'}>
              <BoxHeaderTypography>Pick a product</BoxHeaderTypography>
              <WhiteLabelIcon icon={faShirt} />
            </BoxHeader2>
            <div className={'space-y-8'}>
              <div>
                <InputLabel>Selected Product</InputLabel>
                {selectedStyle
                  ? <div className={'border h-32 rounded py-2'}>
                    <StrucRow>
                      <StrucCol size={4}>
                        <img className={'object-contain h-28'} src={selectedStyle.thumbnailUrl ?? imageFallback} />
                      </StrucCol>
                      <StrucCol className={'space-y-2'}>
                        <div>
                          <p className={'font-bold capitalize text-xs'}>Model</p>
                          <p>{selectedStyle.modelAndName}</p>
                        </div>
                        <div>
                          <p className={'font-bold capitalize text-xs'}>Manufacturer</p>
                          <p>{selectedStyle.manufacturer.name}</p>
                        </div>
                      </StrucCol>
                    </StrucRow>
                  </div>
                  : <div className={'bg-gray-100 h-32 rounded p-4 flex justify-center items-center'}>
                    No style selected
                  </div>
                }
              </div>
              <div className={'space-y-2'}>
                <div>
                  <InputLabel>Brand</InputLabel>
                  <ManufacturerAutoComplete manufacturers={manufacturers} onSelected={onFilterByManufacturer} />
                </div>
                <div>
                  <InputLabel>Search for Model</InputLabel>
                  <TableSearchBar onSearchQueryChange={x => onSearchQueryChange(x.searchTerm)}
                    searchQuery={searchQuery} />
                </div>
                <InfinityListComponent<Style> className={'!h-[590px] pt-4'} currentItems={styles}
                  onLoadMore={onLoadMoreStyles}
                  isLoading={isStylesLoading}
                  renderItem={style => <StyleCard key={style.id} style={style}
                    onClick={onSelectStyle}
                    isSelected={style.id === selectedStyle?.id} />} />
              </div>
            </div>
          </StrucCol>
          <StrucCol size={8} className={'!justify-start '}>
            <InvertedPanel className={'rounded-tr !mx-0 !px-0 !py-5 flex justify-center'}>
              <BoxHeaderTypography>{selectedStyle?.modelAndName}</BoxHeaderTypography>
            </InvertedPanel>
            <div className={'bg-gray-100 border-t border-b py-2 px-2'}>
              <StrucRow>
                <StrucCol size={6} className={'px-2'}>
                  <InputWrapper>
                    <InputLabel>Title</InputLabel>
                    <CustomInput className={'!bg-white'} value={title} maxLength={30} disabled={true}
                      onChange={e => onChangeTitle(e.target.value)} placeholder='enter design title' />
                  </InputWrapper>
                </StrucCol>
                <StrucCol size={6} className={'px-2'}>
                  <InputWrapper>
                    <InputLabel>Customer</InputLabel>
                    <CustomAutoComplete<Organization>
                      options={[organization]}
                      getOptionKey={(org) => '' + org.id}
                      displayOption={(org) => org.name}
                      key={organization?.id}
                      onChange={(org) => { }}
                      value={organization}
                      placeholder='search for organization'
                      disabled={true}
                    />
                  </InputWrapper>
                </StrucCol>
              </StrucRow>
              {
                showCustomerInfo &&
                <StrucRow>

                  {/* <StrucCol size={6} className={'px-2'}>
                    <InputWrapper>
                      <InputLabel>Customer Branch</InputLabel>
                      <Dropdown
                        options={[]}
                        defaultValue={false}
                        onOptionSelect={(c) => {
                          if (c) {
                            // todo: branches
                          }
                        }}
                        getOptionDisplay={(c) => 'c.name'}
                      />
                    </InputWrapper>
                  </StrucCol>*/}
                  {/*<StrucCol size={6} className={'px-2'}>
                  <InputWrapper>
                    <InputLabel>Contact</InputLabel>
                    <CustomInput className={'bg-white'} value={''}
                                 onChange={e => {
                                 }} />
                  </InputWrapper>
                </StrucCol>*/}
                </StrucRow>
              }
            </div>
            {selectedStyle && <StyleViewer style={selectedStyle} />}
          </StrucCol>
        </StrucRow>
      </BoxContent>
      <BoxFooter className={'border-t justify-between'}>
        <SecondaryButton onClick={onCancel}>
          Cancel
        </SecondaryButton>
        <PrimaryButton disabled={disableSubmit}
          onClick={onSubmit}>
          {t('continue')}
        </PrimaryButton>
      </BoxFooter>
    </JetSportBox>
  )
}



