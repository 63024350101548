import { InfinityListComponent } from '../../../../shared/components/infinity-list.component'
import { useDesignContext } from '../../contexts/design.context'
import { LogoInDesignCard } from '../cards/logo-in-design-card.component'
import { LogoPlacementPosition } from '../../../design-editor/models/Placement'
import React from 'react'

export function LogoInDesignPanel() {
  const { logosOfDesign, selectedView, selectedLogo, logoPlacement, removeFromDesign, editLogo, cloneLogo } = useDesignContext()

  return <div className={'relative space-y-2 flex flex-col h-full'}>
    <InfinityListComponent className={'!max-h-[824px] flex-1'} currentItems={logosOfDesign}
      renderItem={l => {
        return <React.Fragment key={l.inDesignId}>
          <LogoInDesignCard
            placement={logoPlacement[l.inDesignId]?.status === LogoPlacementPosition.VALID}
            logo={l} disable={l.viewId !== selectedView.id}
            isActive={l.inDesignId === selectedLogo?.inDesignId}
            onRemove={() => removeFromDesign(l)}
            onEdit={() => editLogo(l)}
            onClone={() => cloneLogo(l)}
          />
        </React.Fragment>
      }}
    />
  </div>
}


