import { Organization } from '../../../core/models/entities/Organization'
import { CreateOrganizationDto } from '../../../core/models/dtos/CreateCustomerDto'
import { CreateCustomerBody } from '../bodies/CreateCustomerBody'
import { OrganizationDto } from '../responses/OrganizationDto'
import { mapToCommissionProfile } from './mapCommissions'

export function mapToOrganization(dto: OrganizationDto): Organization {
  if (dto === null) {
    throw new Error('mapToOrganization was called with a null dto argument');
  }

  const mainContact = dto?.contacts?.find(x => x.isMain)
  const shippingContact = dto?.contacts?.find(x => x.isShipping)
  return {
    ...dto,
    lastOrder: dto?.lastOrder ? new Date(dto.lastOrder) : undefined,
    contactPerson: mainContact?.name,
    contactPhone: mainContact?.phone,
    contactEmail: mainContact?.email,
    parent: dto?.parent ? mapToOrganization(dto.parent) : undefined,
    children: dto?.children.map(mapToOrganization),
    shippingContactPerson: shippingContact?.name,
    shippingContactPhone: shippingContact?.phone,
    shippingContactEmail: shippingContact?.email,
    commission: dto?.commission ? mapToCommissionProfile(dto.commission) : undefined,
    isVendor: dto?.type === "vendor"
  }
}

export function mapFromCreateCustomerDto(dto: CreateOrganizationDto): CreateCustomerBody {
  return {...dto}
}
