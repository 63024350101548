export interface Props {
  subTotal: number
  vat: number
  totalAmount: number
}

export function PriceTotal({ subTotal, vat, totalAmount }: Props) {
  return <div className={'w-full text-sm space-y-4 mb-5'}>
    <div className={'flex justify-end border-b'}>
      <p className={'w-[140px] capitalize'}>subtotal</p>
      <p>{subTotal.toFixed(2)}</p>
    </div>
    <div className={'flex justify-end border-b'}>
      <p className={'w-[140px] uppercase'}>vat</p>
      <p>{vat.toFixed(2)}</p>
    </div>
    <div className={'flex justify-end font-bold'}>
      <p className={'w-[140px] capitalize'}>total amount</p>
      <p>{totalAmount.toFixed(2)}</p>
    </div>
  </div>
}
