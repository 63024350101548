import React from 'react'
import { Order } from '../../../../core/models/entities/Order'
import { faNoteSticky, faPen } from '@fortawesome/free-solid-svg-icons'
import { nameOf } from '../../../../utils/functions/ReflectionUtil'
import { ColumnSettings } from '../../../components/GenericTable/SubComponents/ListFrame'
import IconButton from '../../../components/LogoButton/IconButton'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { formatDate } from '../../../../utils/functions/DateUtil'
import { StrucCol } from '../../../components/StructureElements/StrucCol'
import { StrucRow } from '../../../components/StructureElements/StrucRow'
import { OrderStatusElement } from './OrderStatusElement'
import { OrderState, getOrderState } from '../vmHooks/useFunctionalListOrdersViewModel'
import { useProfileContext } from '../../../../contexts/ProfileContext'
import { ApprovalVoting, Verdict } from '../../../components/ApprovalVoting/approval-voting'
import { Tooltip } from '@mui/material'
import { useNavigate } from 'react-router'

interface OrderRowItem {
  order: Order;
  columns: ColumnSettings;
  onEditDesign: (order: Order) => void
  onPlaceVerdict: (order: Order, verdict: Verdict) => void
  onSeeRejectNote: (order: Order) => void
}

export function OrderRowItem({ order, columns, onSeeRejectNote, onPlaceVerdict, onEditDesign }: OrderRowItem) {

  const navigate = useNavigate()
  const { profile } = useProfileContext();
  const isNotCustomer = order.organization.id != profile?.userOrganizationInformations.organizationId;

  function EditOrder(e: React.MouseEvent<HTMLElement>) {
    e.stopPropagation()
    onEditDesign(order)
  }

  function SeeRejectNote(e: React.MouseEvent<HTMLElement>) {
    e.stopPropagation()
    onSeeRejectNote(order)
  }

  function ViewOrder(e: React.MouseEvent<HTMLElement>) {
    e.stopPropagation()
    navigate(`/order/details?orderId=${order.id}&orgId=${order.organization.id}`)
  }

  const overallState = getOrderState(order)
  const actions = <>
    {
      overallState === OrderState.Rejected && <>
        <IconButton key={'see-note'} onClick={SeeRejectNote} icon={<FontAwesomeIcon icon={faNoteSticky} />}>
          See Note
        </IconButton>
      </>
    }
    {
      overallState === OrderState.Pending && isNotCustomer && <ApprovalVoting placeVerdict={verdict => onPlaceVerdict(order, verdict)} approvalGroup={order.approval!} rejectDelete={() => { }} />
    }
    {
      overallState === OrderState.Draft && <IconButton key={'approve'} onClick={EditOrder} icon={<FontAwesomeIcon icon={faPen} />}>Edit</IconButton>
    }
    {
      overallState === OrderState.InProduction && <IconButton key={'approve'} onClick={ViewOrder} icon={<FontAwesomeIcon icon={faPen} />}>View</IconButton>
    }
  </>

  return <StrucRow className={'py-2'}>
    <StrucCol size={columns[nameOf<Order>(x => x.orderNumber)].weight}>
      <Tooltip title={order.orderNumber} placement={'top-start'}>
        <span className={'whitespace-nowrap overflow-x-hidden overflow-ellipsis'}>{order.orderNumber}</span>
      </Tooltip>
    </StrucCol>
    <StrucCol size={columns[nameOf<Order>(x => x.requisitionNumber)].weight}>
      <Tooltip title={order.requisitionNumber} placement={'top-start'}>
        <span className={'whitespace-nowrap overflow-x-hidden overflow-ellipsis'}>{order.requisitionNumber}</span>
      </Tooltip>
    </StrucCol>
    <StrucCol size={columns[nameOf<Order>(x => x.organization)].weight}>
      <Tooltip title={order.organization.name} placement={'top-start'}>
        <span className={'whitespace-nowrap overflow-x-hidden overflow-ellipsis'}>{order.organization.name}</span>
      </Tooltip>
    </StrucCol>
    <StrucCol size={columns[nameOf<Order>(x => x.organization.contactPerson)].weight}>
      <Tooltip title={order.organization.contactPerson} placement={'top-start'}>
        <span className={'whitespace-nowrap overflow-x-hidden overflow-ellipsis'}>{order.organization.contactPerson}</span>
      </Tooltip>
    </StrucCol>
    <StrucCol size={columns[nameOf<Order>(x => x.status)].weight}>
      <OrderStatusElement order={order} />
    </StrucCol>
    <StrucCol size={columns[nameOf<Order>(x => x.placedAtUtc)].weight}>
      {formatDate(order.placedAtUtc)}
    </StrucCol>
    <StrucCol size={columns['actions'].weight} className={'my-[-0.5rem]'}>
      {actions}
    </StrucCol>
  </StrucRow>
}