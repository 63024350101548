import React, { JSX } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleXmark } from '@fortawesome/free-solid-svg-icons'

export function Closeable({ children, onClose }: {
  onClose: (x: React.MouseEvent<HTMLDivElement>) => void
  children: JSX.Element
}) {
  return <div
    className={`relative`}>
    <div
      onClick={x => onClose(x)}
      className={'z-10 absolute hover:text-status-failed-1 text-xs cursor-pointer top-[-5px] right-[-5px] rounded-full bg-white leading-none'}>
      <FontAwesomeIcon icon={faCircleXmark} />
    </div>
    {children}
  </div>
}