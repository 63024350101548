import React, { JSX } from 'react'
import { CustomDateInput, CustomInput, CustomTextArea } from './CustomInput'
import { InputLabel } from './InputLabel'
import 'react-phone-number-input/style.css'

import { InputError } from '../../screens/my-profile/InputError'
import { CustomPhoneInput } from './CustomPhoneInput'

interface InputWrapperProps extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: string
}

export const InputWrapper = (props: InputWrapperProps) => {
  // @ts-any-go-brrr
  let label: any = <InputLabel>{props.label}</InputLabel>
  let input
  let rest
  let error

  function isInput(comp: JSX.Element) {
    return comp.type === CustomInput || comp.type === CustomTextArea || comp.type === CustomDateInput || comp.type === CustomPhoneInput
  }

  function isLabel(comp: JSX.Element) {
    return comp.type === InputLabel
  }

  function isError(comp: JSX.Element) {
    return comp.type === InputError
  }

  if (Array.isArray(props.children)) {
    const childArray = props.children as JSX.Element[]
    label = childArray.find(isLabel)
    input = childArray.find(isInput)
    error = childArray.find(isError)
    rest = childArray.filter(x => !isLabel(x) && !isInput(x) && !isError(x))
  }

  else if (props.children instanceof React.Component) {
    const child = props.children as JSX.Element
    label = isLabel(child) ? child : null
    input = isInput(child) ? child : null
    error = isError(child) ? child : null
  }

  else if (typeof props.children === 'object') {
    // Single children.
    input = props.children
  }

  return <div className={'space-y-0.5 pb-3'}>
    {label}
    {input}
    {rest}
    {error}
  </div>
}



