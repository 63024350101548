import { faX } from '@fortawesome/free-solid-svg-icons'
import { BaseLogo } from '../../../core/models/entities/BaseLogo'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export type LogoDimensionFormat = "BWWXHYYMM" | "WWxYYmm"

export function LogoDimensions({ logo, format: format = "BWWXHYYMM" }: { logo: BaseLogo, format?: LogoDimensionFormat }) {

  switch (format) {
    case 'BWWXHYYMM': return <span><b>B</b> {logo.widthMm} x <b>H</b> {logo.heightMm} MM</span>

    case 'WWxYYmm': return <span className='flex items-center gap-0.5'>{logo.widthMm}<FontAwesomeIcon icon={faX} size={'2xs'} className={'mx-auto '} />{logo.heightMm}mm</span>
  }
  
}