import { MaskEditorViewModel } from './MaskEditorViewModel'
import { useRepositoriesContext } from '../../../contexts/RepositoriesContext'

export function useMaskEditorViewModel(styleId: number): MaskEditorViewModel {
  const { styleRepository } = useRepositoriesContext()
  const {
    data: style,
    isLoading: isStyleLoading,
    isError: isStyleError,
    isSuccess: isStyleSuccess
  } = styleRepository.useStyle(styleId)

  return {
    style,
    isLoading: isStyleLoading,
    isError: isStyleError,
    isSuccess: isStyleSuccess,
    getOriginalMask: styleRepository.getOriginalMask,
    updateMask: styleRepository.updateMask
  }
}
