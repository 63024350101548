import { Manufacturer } from '../../../../core/models/entities/Manufacturer'
import { useState } from 'react'
import { useCustomerActionHandler } from '../hooks/useCustomerActionHandler'
import { Organization } from '../../../../core/models/entities/Organization'
import { CustomerCreateForm_VM } from '../components/customer-create-form.component'
import { isCustomerFormInvalid } from '../functions/is-customer-form-valid.function'
import { toast } from 'react-toastify'
import { useErrorMessage } from '../../../hooks/useErrorMessage'
import { useProfileContext } from '../../../../contexts/ProfileContext'
import { Role } from '../../../../core/models/entities/Role'
import { CustomerForm } from '../components/customer.form'
import { LogoOptions } from '../../../../core/models/entities/LogoOptions'
import { Commission, CommissionProfile } from '../../../../core/models/entities/Commission'
import { useRepositoriesContext } from '../../../../contexts/RepositoriesContext'

export function useCustomerCreateFormViewModel(manufacturers: Manufacturer[], vendors: Organization[] | undefined, logoOptions: LogoOptions): CustomerCreateForm_VM {
  const [form, setForm] = useState<CustomerForm>({
    name: '',
    contactEmail: '',
    email: '',
    contactPerson: '',
    contactPhone: '',
    phone: '',
    address: '',
    cvr: '',
    excludedManufacturers: [] as Manufacturer[],
    parentId: undefined,
    eori: '',
    shippingName: '',
    shippingAddress: '',
    shippingPostCode: '',
    shippingCity: '',
    shippingCountry: '',
    shippingContactPerson: '',
    shippingContactPhone: '',
    shippingContactEmail: '',
  } as CustomerForm)
  const [commissionProfile, setCommissionProfile] = useState<CommissionProfile>()
  const { handleGoToCustomerList, handleCreate, isLoading} = useCustomerActionHandler()
  const { organizationRepository} = useRepositoriesContext()
  const { tError } = useErrorMessage()
  const { profile, role } = useProfileContext()

  function handleCreateClick() {
    const formValid = !isCustomerFormInvalid(form)
    if (!formValid) {
      toast.error('Form is invalid')
      return
    }

    if (formValid && !form.parentId && role === Role.Admin) {
      toast.error('Admins must choose a Vendor')
      return
    }

    if (formValid && form.parentId && role !== Role.Admin) {
      toast.error('Illegal action')
      return
    }

    const s = {
      ...form,
      isVendor: false,
      parentId: form.parentId ?? profile!.userOrganizationInformations.organizationId,
      id: 0,
      excludedManufacturers: form.excludedManufacturers?.map(x => x.id),
      commission: undefined
    } as Organization
    
    async function transaction() {
      const org = await handleCreate(s)
      if(commissionProfile) {
        return organizationRepository.setCommissionProfile(org.id, commissionProfile)
      }
      return Promise.resolve()
    }


    transaction().then(() => {
        toast.success('Customer is Created')
        handleGoToCustomerList()
      })
      .catch((e) => tError(e))
  }

  function updateCommission(commission: CommissionProfile): void {
    setCommissionProfile(commission)
  }

  function handleCancel() {
    handleGoToCustomerList()
  }

  return {
    onCreate: handleCreateClick,
    onCancel: handleCancel,
    form: form,
    setForm: setForm,
    manufactures: manufacturers,
    isFormValid: !isCustomerFormInvalid(form),
    isLoading: isLoading,
    showVendorPicker: profile?.userOrganizationInformations.role === Role.Admin,
    vendors: vendors,
    selectedVendor: vendors ? vendors.find(x => x.id === form.parentId) : undefined,
    logoOptions: logoOptions,
    updateCommission: updateCommission,
    commissionProfile: commissionProfile ?? {  typeOnly: [] as Commission[], methods: [] as Commission[]} as CommissionProfile
  }
}