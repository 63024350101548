import { CreateUserDto } from '../../../core/models/dtos/CreateUserDto'
import { CreateUserBody } from '../bodies/CreateUserBody'

export function mapFromCreateUserDto(dto: CreateUserDto): CreateUserBody {
  return {
    firstName: dto.firstname,
    lastName: dto.lastname,
    email: dto.email,
    organizationId: dto.organizationId
  }
}
