import { Organization } from '../../../../core/models/entities/Organization'
import { User } from '../../../../core/models/entities/User'
import { useRepositoriesContext } from '../../../../contexts/RepositoriesContext'
import { CreateUserDto } from '../../../../core/models/dtos/CreateUserDto'
import { OrganizationDetailsView_VM } from '../../../components/Organization/organization-details-view.component'
import { useNavigate } from 'react-router-dom'
import { PageQuery, toInAppQueryParam } from '../../../../utils/models/pageQuery'
import { withToasts } from '../../../components/Toast/Toast'
import { useState } from 'react'

export function useOrganizationDetailsViewVendor(organization: Organization, users: User[]): OrganizationDetailsView_VM {
  const { userRepository } = useRepositoriesContext()
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const navigate = useNavigate()

  function handleCreateUser(user: CreateUserDto) {

    async function transaction() {
      user.organizationId = organization.id
      return await userRepository.createUser(user)
    }

    return withToasts(
      transaction,
      'Creating user',
      'User created',
      'Failed to create user',
      setIsLoading
    )
  }
  function handleBranchClick(organization: Organization) {
    if (organization.isVendor) {
      navigate(`/vendor/${organization.parent}`)
    }
    else {
      navigate(`/customer/${organization.id}`)
    }
  }

  function handleToMainBranch() {
    navigate(`/vendor/${organization.mainBranchId}`)
  }

  function handleToCustomers() {
    const page = {
      page: {
        index: 1,
        size: 25,
      },
      filterBy: [
        {
          value: '' + organization.id,
          field: 'parent.id'
        }
      ]
    } as PageQuery
    const s = toInAppQueryParam(page);
    navigate(`/customer${s}`)
  }

  const subject = 'Vendor'
  return {
    users,
    subject,
    onCreateUser: handleCreateUser,
    organization,
    onBranchClick: handleBranchClick,
    onToMainBranch: handleToMainBranch,
    onToCustomers: handleToCustomers,
  }
}