import { BaseLogo, ProductPrice } from '../../../core/models/entities/BaseLogo'
import { Design } from '../../../core/models/entities/Design'
import { Organization } from '../../../core/models/entities/Organization'

function PricesShort({ prices, skipnLines = 0, nLines = 4 }: { prices: ProductPrice[] | undefined, skipnLines?: number, nLines?: number }) {
  if (!prices || !prices.length) {
    return <>-</>
  }
  const price1 = prices.find(x => x.minQuantity === 1 || x.minQuantity === 0)
  if (price1 && price1.minQuantity === 0) {
    price1.minQuantity = 1
  }
  const rest = prices.slice(skipnLines, nLines)

  function PriceLine({ price }: { price?: ProductPrice }) {
    if (!price) {
      return <></>
    }
    return <p>{price.currency === '' || !price.currency ? 'DKK' : price.currency} {price.adjustedPrice.toFixed(2)} ({price.minQuantity})</p>
  }

  const restComponent = rest.map((r, i) =>
    <PriceLine key={[r.minQuantity, i].toString()} price={r} />)
  return (<div className={'flex flex-col'}>
    {restComponent}
  </div>)
}

export function PriceLine({ price, excludeQuantity }: { price?: ProductPrice, excludeQuantity?: boolean }) {
  return <p>{PriceLineAsString({ price, excludeQuantity })}</p>
}

export function PriceTotalLine({ price, quantity }: { price?: ProductPrice, quantity: number }): JSX.Element {
  if (!price) {
    return <></>
  }
  return <>{`${price.currency === '' || !price.currency ? 'DKK' : price.currency} ${(price.adjustedPrice * quantity).toFixed(2)}`}</>
}

export function PriceLineAsString({ price, excludeQuantity }: { price?: ProductPrice, excludeQuantity?: boolean }) {
  if (!price) {
    return ""
  }
  const quantity = excludeQuantity ? "" : " (" + (price.minQuantity === 0 ? 1 : price.minQuantity) + ")"
  return `${price.currency === '' || !price.currency ? 'DKK' : price.currency} ${price.adjustedPrice.toFixed(2)}${quantity}`
}


export function PurePriceLine({ currency, price, minQuantity, excludeQuantity }: { currency?: string, price?: number, minQuantity?: number, excludeQuantity?: boolean }) {
  return <p>{PurePriceLineAsString({ currency, price, minQuantity, excludeQuantity })}</p>
}

export function PurePriceLineAsString({ currency, price, minQuantity, excludeQuantity }: { currency?: string, price?: number, minQuantity?: number, excludeQuantity?: boolean }): string {
  const quantity = excludeQuantity ? "" : "(" + minQuantity + ")"
  return `${currency === '' || !currency ? 'DKK' : currency} ${(price ?? 0).toFixed(2)} ${quantity}`
}

export function LogoPrices({ logo, nLines = 4 }: { logo: BaseLogo, nLines?: number }) {
  const prices = logo?.product?.prices
  return <>
    <PricesShort prices={prices} nLines={nLines} />
    <CustomerSummary customer={logo.customer} />
  </>
}

export function DesignPrices({ design, nLines = 4 }: { design: Design, nLines?: number }) {
  const prices = design.product?.totalPrices;
  return <>
    <PricesShort prices={prices} nLines={nLines} />
    <CustomerSummary customer={design.customer} />
  </>
}

const CustomerSummary = ({ customer }: { customer: Organization }) => <em>
  {customer.name} ({customer.navNumber})
</em>