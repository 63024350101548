import React from 'react'
import { DrawingContainer } from './subcomponents/drawing-container/DrawingContainer'
import { MaskEditorToolViewModel } from './MaskEditorToolViewModel'
import { SettingsContainer } from './subcomponents/settings-container/SettingsContainer'
import { JetSportBox } from '../../../../components/BoxView/JetSportBox'
import { BoxHeader2 } from '../../../../components/BoxView/BoxHeader2'
import { BoxHeaderTypography } from '../../../../components/Typographies/BoxHeaderTypography'
import { BoxContent } from '../../../../components/BoxView/BoxContent'
import { BoxFooter } from '../../../../components/BoxView/BoxFooter'
import {
  faArrowRotateLeft,
  faArrowsRotate,
  faFloppyDisk,
  faPaintbrush,
  faXmark
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ViewSelector } from '../../../style-edit/subcomponents/style-edit-form/ViewSelector'
import { StyleView } from '../../../../../core/models/entities/StyleView'
import { SecondaryButton, WarnButton } from '../../../../components/Buttons/SecondaryButton'
import { PrimaryButton } from '../../../../components/Buttons/PrimaryButton'
import { useNavigate } from 'react-router-dom'
import LoadingElement from '../../../../components/loading-element/loading-element'
import { WhiteLabelIcon } from '../../../logo-editor/componenets/WhiteLabelIcon'
import { StrucCol } from '../../../../components/StructureElements/StrucCol'
import { StrucRow } from '../../../../components/StructureElements/StrucRow'

interface MaskEditorProps {
  viewModel: MaskEditorToolViewModel
}

export function MaskEditorTool({ viewModel }: MaskEditorProps): React.ReactElement {
  // Render

  const navigate = useNavigate()
  function handleCancel() {
    navigate(`/style/${viewModel.style.id}/edit`)
  }
  return (
    <JetSportBox>
      <StrucRow>
        <StrucCol size={3} className={'border-r'}>
          <BoxHeader2
            className={'justify-between items-center'}><BoxHeaderTypography>Annotate</BoxHeaderTypography><WhiteLabelIcon
            className={'text-primary-100'} icon={faPaintbrush} /></BoxHeader2>
          <BoxContent>
            <SettingsContainer
              penColor={viewModel.penColor}
              onPenColorChange={viewModel.onPenColorChange}
              colors={viewModel.colors}
              brushSize={viewModel.brushSize}
              onBrushSizeChange={viewModel.onBrushSizeChange}
              opacity={viewModel.opacity}
              onOpacityChange={viewModel.onOpacityChange}
            />
          </BoxContent>
          <BoxFooter className={'flex-col space-x-0 space-y-4'}>
            <StrucRow className={'justify-center'}>
              <StrucCol size={5.5}>
                <SecondaryButton width={'full'} className={'px-4'} onClick={viewModel.onUndoClick}>
                  <FontAwesomeIcon icon={faArrowRotateLeft} />
                  <span className={'flex-1'}>Undo</span>
                </SecondaryButton></StrucCol>
              <StrucCol size={5.5} offset={1}>
                <WarnButton width={'full'} className={'px-4'} onClick={viewModel.onResetClick}>
                  <FontAwesomeIcon icon={faArrowsRotate} />
                  <span className={'flex-1'}>Reset</span>
                </WarnButton>
              </StrucCol>
            </StrucRow>
            <StrucRow>
              <StrucCol size={5.5}>
                <SecondaryButton width={'full'} className={'px-4'}><
                  FontAwesomeIcon icon={faXmark} />
                  <span className={'flex-1'} onClick={handleCancel}>Cancel</span>
                </SecondaryButton>
              </StrucCol>
              <StrucCol size={5.5} offset={1}>
                <PrimaryButton width={'full'} className={'px-4'} onClick={viewModel.onSaveClick}>
                  <FontAwesomeIcon icon={faFloppyDisk} />
                  <span className={'flex-1'}>Save</span>
                </PrimaryButton></StrucCol>
            </StrucRow>
          </BoxFooter>
        </StrucCol>
        <StrucCol size={9} className={'relative'}>
          <BoxHeader2 className={'bg-gray-100 rounded-tr'}></BoxHeader2>
          <div
            className='flex-1 w-full h-full flex justify-center items-center overflow-hidden'
            ref={viewModel.canvasContainerRef}>
            {viewModel.currentView && viewModel.canvasWidth > 0 && viewModel.canvasHeight > 0 && (
              <DrawingContainer
                penColor={viewModel.penColor}
                brushSize={viewModel.brushSize}
                opacity={viewModel.opacity}
                canvasWidth={viewModel.canvasWidth}
                canvasHeight={viewModel.canvasHeight}
                canvasRef={viewModel.canvasRef}
                view={viewModel.currentView}
              />
            )}
          </div>
          <ViewSelector
            views={viewModel.views}
            onSelectView={x => viewModel.onViewSelect(x as StyleView)}
            currentView={viewModel.currentView}
          />
          {viewModel.isLoading && <LoadingElement className={'rounded-tr rounded-br'} />}
        </StrucCol>
      </StrucRow>
      {viewModel.saving && <LoadingElement transparent className={'rounded'}/>}
    </JetSportBox>
  )
}
