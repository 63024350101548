export function radiansToDegrees(radians: number): number {
  var pi = Math.PI
  return radians * (180 / pi)
}

export function degreesToRadians(degrees: number) {
  var pi = Math.PI
  return degrees * (pi / 180)
}

/**
 * Get all vertices for a logo.
 * @param bx Top left x coordinate of logos bounding box.
 * @param by Top right y coordinat of logos bounding box.
 * @param width The width of the logo.
 * @param height The height of the logo.
 * @param angle The logo can be potentially rotated. So this is how much the logo rotated around its center.
 * @returns Returns an array of all four corner points of the logo.
 * The order is; top-left, top-right, bottom-left, bottom-right
 */
export function getRotatedVertices(
  bx: number,
  by: number,
  width: number,
  height: number,
  angle: number
): Vertex[] {
  /*console.log(bx, by)*/
  const { x: cx, y: cy } = getCenterOfRotatedLogo(bx, by, width, height, angle)
  /*console.log('center', cx, cy)*/
  const { x: ox, y: oy } = getOriginalTopLeft(bx, by, width, height, angle)

  const p1 = rotatePointAroundAnotherPoint(ox, oy, cx, cy, angle)
  const p2 = rotatePointAroundAnotherPoint(ox + width, oy, cx, cy, angle)
  const p3 = rotatePointAroundAnotherPoint(ox, oy + height, cx, cy, angle)
  const p4 = rotatePointAroundAnotherPoint(ox + width, oy + height, cx, cy, angle)

  // Order: top-left, top-right, bottom-left, bottom-right
  return [p1, p2, p3, p4]
}

/**
 * Gets the original top-left coordinate of a logo before it was rotated around its center.
 *
 * Note: This function still works even if the logo is not rotated.
 * @param bx top-left x-coordinate of the logos bounding box.
 * @param by top-left y-coordinate of the logos bounding box.
 * @param width The width of the logo.
 * @param height The height of the logo.
 * @param angle The logo can be potentially rotated. So this is how much the logo rotated around its center.
 * @returns the (x,y) coordinate of the logos original top-left
 */
export function getOriginalTopLeft(
  bx: number,
  by: number,
  width: number,
  height: number,
  angle: number
): Point {
  const { x: cx, y: cy } = getCenterOfRotatedLogo(bx, by, width, height, angle)
  const ox = cx - width * 0.5
  const oy = cy - height * 0.5

  return { x: ox, y: oy }
}

/**
 * Gets the center coordinates of the logo that is poitentially rotated.
 * @param bx top-left x-coordinate of the logos bounding box.
 * @param by top-left y-coordinate of the logos bounding box.
 * @param width The width of the logo.
 * @param height The height of the logo.
 * @param angle The logo can be potentially rotated. So this is how much the logo rotated around its center.
 * @returns the center coordinates of the logo.
 */
export function getCenterOfRotatedLogo(
  bx: number,
  by: number,
  width: number,
  height: number,
  angle: number
): Point {
  let smallestAngle = (angle % Math.PI) * 2

  if (smallestAngle >= Math.PI) {
    smallestAngle -= Math.PI
  }

  const bw = Math.abs(width * Math.cos(angle)) + Math.abs(height * Math.sin(angle))
  const bh = Math.abs(width * Math.sin(angle)) + Math.abs(height * Math.cos(angle))

  const cx = bx + bw * 0.5
  const cy = by + bh * 0.5

  return { x: cx, y: cy }
}

/**
 * Rotate a point around another point
 * @param x1 x-coordinate of point to rotate.
 * @param y1 y-coordinate of point to rotate.
 * @param x2 x-coordinate of point to rotate around.
 * @param y2 y-coordinate of point to rotate around.
 * @param angle How much point is getting rotated.
 * @returns The coordinates of the point after rotation.
 */
export function rotatePointAroundAnotherPoint(
  x1: number,
  y1: number,
  x2: number,
  y2: number,
  angle: number
): Point {
  const rotatedX = (x1 - x2) * Math.cos(angle) - (y1 - y2) * Math.sin(angle) + x2
  const rotatedY = (x1 - x2) * Math.sin(angle) + (y1 - y2) * Math.cos(angle) + y2

  return { x: rotatedX, y: rotatedY }
}

export function rotate(cx: number, cy: number, x: number, y: number, radians: number) {
 /* const radians = (Math.PI / 180) * radians*/
  const cos = Math.cos(radians)
  const sin = Math.sin(radians)
  const nx = (cos * (x - cx)) - (sin * (y - cy)) + cx
  const ny = (cos * (y - cy)) + (sin * (x - cx)) + cy
  return { x: nx, y: ny }
}

/**
 * Calculates all edges of a figure with its vertices.
 * @param vertices Vertices of the figure.
 * @returns A list of all the figures edges.
 */
function getEdges(vertices: Vertex[]): Edge[] {
  const edges = []

  for (let i = 0; i < vertices.length; i++) {
    let j = (i + 1) % vertices.length
    edges.push({ p1: vertices[i], p2: vertices[j] })
  }

  return edges
}

/**
 * Check if two figures are colliding by using their vertices.
 * @param v1 Vertices of the first figure.
 * @param v2 Vertices of the second figure.
 * @returns true if the two figures are colliding. Otherwise false.
 */
export function areTwoFiguresColliding(v1: Vertex[], v2: Vertex[]): boolean {
  const edges1 = getEdges(v1)
  const edges2 = getEdges(v2)

  for (const e1 of edges1) {
    for (const e2 of edges2) {
      const intersection = intersect(e1, e2)
      if (intersection) {
        return true
      }
    }
  }

  return false
}

/**
 * Calculate if two edges are intersecting.
 * By using edges of a figure we can use a formula for calculating the intersection of two lines.
 * @param e1 Edge of a figure.
 * @param e2 Edge of another figure
 * @returns true if the lines do intersect. Otherwise false.
 */
function intersect(e1: Edge, e2: Edge): boolean {
  const { x: x1, y: y1 } = e1.p1
  const { x: x2, y: y2 } = e1.p2

  const { x: x3, y: y3 } = e2.p1
  const { x: x4, y: y4 } = e2.p2

  const denominator = (y4 - y3) * (x2 - x1) - (x4 - x3) * (y2 - y1)

  if (denominator == 0) {
    return false
  }

  const ua = ((x4 - x3) * (y1 - y3) - (y4 - y3) * (x1 - x3)) / denominator
  const ub = ((x2 - x1) * (y1 - y3) - (y2 - y1) * (x1 - x3)) / denominator

  if (ua < 0 || ua > 1 || ub < 0 || ub > 1) {
    return false
  }

  return true
}

interface Point {
  x: number
  y: number
}

interface Vertex {
  x: number
  y: number
}

interface Edge {
  p1: Point
  p2: Point
}
