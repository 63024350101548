import React from 'react'
import './styles/OrderCreateScreen.css'
import { OrderLoading } from './OrderLoading'
import { OrderError } from './OrderError'
import { useOrderCreateViewModel } from './useOrderCreateViewModel'
import { OrderCreateComponent } from './OrderCreate.Component'
import LoadingElement from '../../components/loading-element/loading-element'

export enum ComponentState {
  Loading, Error, Success
}

export function OrderCreateScreen(): React.ReactElement {

  const vm = useOrderCreateViewModel();
  const componentState: ComponentState = ComponentState.Success as ComponentState

  switch (componentState) {
    case ComponentState.Success:
      return <>
        {vm.isLoading && <LoadingElement transparent />}
        <OrderCreateComponent {...vm} />
      </>
    case ComponentState.Error:
      return <OrderError />
    case ComponentState.Loading:
      return <OrderLoading />
    default:
      return <OrderLoading />
  }
}
