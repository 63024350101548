import { useState } from 'react'

export enum ComponentState {
  Loading,
  Succeed,
  Failed
}

export function useComponentStateMachine() {
  const [current, setState] = useState<ComponentState>(ComponentState.Loading)

  function canTransition(from: ComponentState, to: ComponentState) {
    switch (from) {
      case ComponentState.Loading:
        return to === ComponentState.Succeed || to === ComponentState.Failed
      case ComponentState.Succeed:
        return to === ComponentState.Loading
      case ComponentState.Failed:
        return to === ComponentState.Loading
    }
  }

  function transitionTo(to: ComponentState) {
    if (to === current) {
      return
    }

    if (!canTransition(current, to)) {
      throw new Error('illegal transition')
    }

    setState(to)
  }

  function transitionOrStaySucceeded() {
    transitionTo(ComponentState.Succeed)
  }

  function transitionOrStayFailed() {
    transitionTo(ComponentState.Failed)
  }

  function transitionOrStayLoading() {
    transitionTo(ComponentState.Loading)
  }

  function dataTransition(isLoading: boolean, isSucceeded: boolean, isFailed: boolean) {
    if (isSucceeded) {
      transitionOrStaySucceeded()
    } else if (isFailed) {
      transitionOrStayFailed()
    } else if (isLoading) {
      transitionOrStayLoading()
    }
  }

  return {
    state: current,
    transition: transitionTo,
    dataTransition: dataTransition
  }
}