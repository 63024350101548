import { createSearchParams, useNavigate } from 'react-router-dom'
import { useRepositoriesContext } from '../../../../contexts/RepositoriesContext'
import { useState } from 'react'
import { BaseLogo } from '../../../../core/models/entities/BaseLogo'
import { Organization } from '../../../../core/models/entities/Organization'
import { LogoCommit } from '../../../../data/models/bodies/LogoCommit'
import { useOrderActionHandler } from '../../order-create/hooks/useOrderActionsHandler'
import { Verdict } from '../../../components/ApprovalVoting/approval-voting'
import { withToasts } from '../../../components/Toast/Toast'

export function useLogoActionHandler() {
  const goTo = useNavigate()
  const { logoRepository } = useRepositoriesContext()
  const { handleLogoAddOrderLine, handleLogoAddOrderLines } = useOrderActionHandler();

  const [isLoading, setIsLoading] = useState(false)

  function handleGoToCreateNewLogo() {
    goTo('/logo/create')
  }

  function handleAddToCart(logo: BaseLogo, quantity: number) {
    handleLogoAddOrderLine(logo, quantity, 0)
  }

  function handleAddItemsToCart(logos: BaseLogo[], quantity: number) {
    handleLogoAddOrderLines(logos, quantity, 0)
  }

  function handleGoToEditLogo(logo: BaseLogo) {
    goTo({
      pathname: '/logo/create',
      search: createSearchParams({
        ['logo']: '' + logo.id,
        ['organization']: '' + logo.customer.id
      }).toString()
    })
  }

  function handleUpdateLogoColor(orgId: number, logoId: number, changeToColor: ChangeLogoColors) {
    async function transaction() {
      return await logoRepository.changeColors(orgId, logoId, changeToColor)
    }

    return withToasts(
      transaction,
      'Updating logo color',
      'Logo color updated',
      'Failed to update logo color',
      setIsLoading
    )
  }

  function handleChangeTitle(orgId: number, logoId: number, title: string) {
    async function transaction() {
      return await logoRepository.changeTitle(orgId, logoId, title)
    }

    return withToasts(
      transaction,
      'Updating logo title',
      'Logo title updated',
      'Failed to update logo title',
      setIsLoading
    )
  }

  function handleUpdateLogo(orgId: number, logo: BaseLogo) {
    async function transaction() {
      return await logoRepository.update(orgId, logo)
    }

    return withToasts(
      transaction,
      'Updating logo',
      'Logo updated',
      'Failed to update logo',
      setIsLoading
    )
  }

  function handleResizeLogo(orgId: number, logo: BaseLogo) {
    async function transaction() {
      return await logoRepository.resize(orgId, logo)
    }

    return withToasts(
      transaction,
      'Rezizing logo',
      'Logo resized',
      'Failed to resize logo',
      setIsLoading
    )
  }

  function handleCommitLogo(logo: BaseLogo, commit: LogoCommit) {
    async function transaction() {
      return await logoRepository.commit(logo, commit)
    }

    return withToasts(
      transaction,
      'Committing logo',
      'Logo committed',
      'Failed to commit logo',
      setIsLoading
    )
  }

  function handleSaveThenSkipLogo(orgId: number, logo: BaseLogo) {
    async function transaction() {
      const updatedLogo = await logoRepository.update(orgId, logo)
      return await logoRepository.initiateApproval(updatedLogo, {
        skip: true,
        vendor: false,
        customer: false,
        external: false,
        externalEmail: undefined
      })
    }

    return withToasts(
      transaction,
      'Committing logo',
      'Logo committed',
      'Failed to commit logo',
      setIsLoading
    )
  }

  function handleDeleteLogo(logo: BaseLogo) {
    async function transaction() {
      return await logoRepository.delete(logo.customer.id, logo.id)
    }

    return withToasts(
      transaction,
      'Deleting logo',
      'Logo deleted',
      'Failed to delete logo',
      setIsLoading
    )
  }

  function handleCreateLogo(file: File, customerId: Organization['id'], title: string) {
    async function transaction() {
      const baseLogo = await logoRepository.createBaseLogo(customerId, title)
      return await logoRepository.uploadFileToBaseLogo(file, customerId, baseLogo.id);
    }

    return withToasts(
      transaction,
      'Creating logo',
      'Logo created',
      'Failed to create logo',
      setIsLoading
    )
  }

  function handleCreateTextLogo(value: string, color: string, heightMm: number, font: string, orgId: Organization['id']): Promise<BaseLogo> {
    async function transaction() {
      return await logoRepository.createTextLogo(value, color, heightMm, font, orgId)
    }

    return withToasts(
      transaction,
      'Creating text logo',
      'Text logo created',
      'Failed to create text logo',
      setIsLoading
    )
  }

  function handleCreatePlaceholderNameLogo(color: string, heightMm: number, font: string, orgId: Organization['id']): Promise<BaseLogo> {
    async function transaction() {
      return await logoRepository.createNameLogo(color, heightMm, font, orgId)
    }

    return withToasts(
      transaction,
      'Creating name logo',
      'Name logo created',
      'Failed to create name logo',
      setIsLoading
    )
  }

  function handleCreatePlaceholderNumberLogo(serial: string, orgId: Organization['id']): Promise<BaseLogo> {
    async function transaction() {
      return await logoRepository.createNumberLogo(serial, orgId)
    }

    return withToasts(
      transaction,
      'Creating number logo',
      'Number logo created',
      'Failed to create number logo',
      setIsLoading
    )
  }

  function handlePlaceVerdict(logo: BaseLogo, verdict: Verdict) {
    async function transaction() {
      return await logoRepository.placeVerdict(logo, verdict)
    }

    return withToasts(
      transaction,
      'Updating approval',
      'Approval successful',
      'Approval failed',
      setIsLoading
    )
  }

  function handleRejectAndDelete(logo: BaseLogo, note?: string) {
    async function transaction() {
      const placedVerdict = await logoRepository.placeVerdict(logo, { approve: false, rejectionNode: note ?? "" })
      return await logoRepository.delete(placedVerdict.customer.id, placedVerdict.id)
    }

    return withToasts(
      transaction,
      'Deleting',
      'Deletion successful',
      'Deletion failed',
      setIsLoading
    )
  }

  function handleCopyLogo(logo: BaseLogo) {
    async function transaction() {
      return await logoRepository.copy(logo.customer.id, logo.id)
    }

    return withToasts(
      transaction,
      'Copying logo',
      'Copying successful',
      'Copying failed',
      setIsLoading
    )
  }

  function handleCopyThenGoToLogo(logo: BaseLogo) {
    async function transaction() {
      return await logoRepository.copy(logo.customer.id, logo.id).then((newLogo) => {
        goTo({
          pathname: '/logo/create',
          search: createSearchParams({
            ['logo']: '' + newLogo.id,
            ['organization']: '' + newLogo.customer.id
          }).toString()
        })
      })
    }

    return withToasts(
      transaction,
      'Copying logo',
      'Copying successful',
      'Copying failed',
      setIsLoading
    )
  }

  function handleInitiateApproval(logo: BaseLogo, approval: InitiateApproval) {
    async function transaction() {
      const approvedLogo = await logoRepository.initiateApproval(logo, approval)
      return await logoRepository.placeVerdict(approvedLogo, { approve: true })
    }

    return withToasts(
      transaction,
      'Updating approval',
      'Approval successful',
      'Approval failed',
      setIsLoading
    )
  }

  function handleSkipApproval(logo: BaseLogo) {
    async function transaction() {
      return await logoRepository.initiateApproval(logo, {
        skip: true,
        vendor: false,
        customer: false,
        external: false,
        externalEmail: undefined
      })
    }

    return withToasts(
      transaction,
      'Skipping approval',
      'Approval skip successful',
      'Approval skip failed',
      setIsLoading
    )
  }

  function handleCommitAndInitiateApproval(logo: BaseLogo, commit: LogoCommit, approval: InitiateApproval) {
    async function transaction() {
      const committedLogo = await logoRepository.commit(logo, commit)
      const approvedLogo = await logoRepository.initiateApproval(committedLogo, approval)
      return await logoRepository.placeVerdict(approvedLogo, { approve: true })
    }

    return withToasts(
      transaction,
      'Updating approval',
      'Approval successful',
      'Approval failed',
      setIsLoading
    );
  }

  function handleSkipAddToCart(logo: BaseLogo, quantity: number) {
    async function transaction() {
      return await logoRepository.initiateApproval(logo, {
        skip: true,
        vendor: false,
        customer: false,
        external: false,
        externalEmail: undefined
      }).then((updatedLogo) => {
        handleAddToCart(updatedLogo, quantity)
        return updatedLogo
      })
    }

    return withToasts(
      transaction,
      'Skipping approval and adding to cart',
      'Approval skipped and added to cart successful',
      'Approval skip failed',
      setIsLoading
    )
  }

  return {
    isLoading,
    handleGoToCreateNewLogo,
    handleAddToCart,
    handleAddItemsToCart,
    handleGoToEditLogo,
    handleDeleteLogo,
    handleChangeTitle,
    handlePlaceVerdict,
    handleRejectAndDelete,
    handleCreateLogo,
    handleCreateTextLogo,
    handleCreatePlaceholderNameLogo,
    handleCreatePlaceholderNumberLogo,
    handleUpdateLogoColor,
    handleUpdateLogo,
    handleResizeLogo,
    handleCopyThenGoToLogo,
    handleCopyLogo,
    handleCommitLogo,
    handleSaveThenSkipLogo,
    handleInitiateApproval,
    handleCommitAndInitiateApproval,
    handleSkipApproval,
    handleSkipAddToCart
  }
}

export interface ChangeLogoColors {
  colorChanges: ColorChange[];
}

export interface InitiateApproval {
  skip?: boolean
  vendor: boolean
  customer: boolean
  external: boolean
  externalEmail?: string
}

export interface ColorChange {
  from: string;
  to: string;
}