import { useTranslation } from 'react-i18next'
import { DataError } from '../../core/errors/DataError'

interface ErrorMessage {
  tError(error: Error, defaultMessageKey?: string): string
}

export function useErrorMessage(): ErrorMessage {
  const { t } = useTranslation()

  // translate based on error object type
  function tError(error: Error, defaultTranslationKey?: string): string {

    // DataError
    if (error instanceof DataError) {
      return t([
        `errorCodes.${error.errorStatus?.code}`,  // 1st priority
        `${defaultTranslationKey}`,               // 2nd priority
        `errorCodes.default`                      // etc.
      ])
    }

    // any other Error type
    return t([
      `${defaultTranslationKey}`,
      `errorCodes.default`
    ])
  }

  return {
    tError
  }
}
