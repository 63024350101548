import { NavigationBar } from './subcomponents/NavigationBar'
import { NavigationBarButton } from './subcomponents/NavigationBarButton'
import { useTranslation } from 'react-i18next'
import { InteractionStatus } from '@azure/msal-browser'
import { loginRequest } from '../../../AuthConfig'
import { useIsAuthenticated, useMsal } from '@azure/msal-react'

function MainHeader() {
  return <NavigationBar />
}

export function UnauthenticatedHeader() {
  const { t } = useTranslation()
  const { instance, inProgress } = useMsal()
  const isAuthenticated = useIsAuthenticated()

  function login() {
    if (!isAuthenticated && inProgress === InteractionStatus.None) {
      instance.loginRedirect(loginRequest)
    }
  }

  return <nav className='w-full bg-white border-b border-gray-200 px-8'>
    <div className='max-w-[90rem] h-16 w-full mx-auto flex items-center gap-x-10'>
      <div className='w-full h-full flex justify-end'>
        <NavigationBarButton onClick={login}>{t('login')}</NavigationBarButton>
      </div>
    </div>
  </nav>
}

export default MainHeader