import { Style } from '../../../../core/models/entities/Style'
import { ColumnSettings } from '../../../components/GenericTable/SubComponents/ListFrame'
import React from 'react'
import { StyleListItem } from '../components/style-list-item.component'
import { useStyleListItemViewModel } from '../vmHooks/useStyleListItemViewModel'

interface WrapperProps {
  style: Style;
  columns: ColumnSettings;
}

export function StyleListItemWrapper(
  {
    style,
    columns
  }: WrapperProps) {
  const vm = useStyleListItemViewModel(style, columns)
  return <StyleListItem {...vm} />
}

