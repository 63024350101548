import {
  DesignStyleEditor_VM,
  LogoPlacementPosition,
  useDesignStyleEditorViewModel,
  ViewWithDimensions
} from './DesignStyleEditor.vm'
import React from 'react'
import { DesignViewWrapper } from '../design-view/DesignView.component'
import { ViewSelector } from '../../style-edit/subcomponents/style-edit-form/ViewSelector'
import { StyleView } from '../../../../core/models/entities/StyleView'
import { StyleViewForm } from '../../style-edit/subcomponents/style-edit-form/StyleEditFormViewModel'
import { Design, DesignLogo } from '../../../../core/models/entities/Design'
import { IdLookUps } from '../../../../utils/models/idLookUp'


export interface WrapperProps {
  design: Design,
  onLogoChange: (logo: DesignLogo) => void,
  onViewSelect: (view: StyleView) => void,
  designLogos: Design['designLogos'],
  onLogoInvalid: (id: DesignLogo['inDesignId'], placement: LogoPlacementPosition) => void
  currentLogo?: DesignLogo
  onSelectLogo: (logo: DesignLogo['inDesignId']) => void
  locked: boolean
  reprepareLogos: number
  invalidPlacement: IdLookUps<LogoPlacementPosition>
}

export function DesignLogoEditorWrapper({
                                          design,
                                          onLogoChange,
                                          onViewSelect,
                                          designLogos,
                                          onLogoInvalid,
                                          onSelectLogo,
                                          currentLogo,
                                          locked,
                                          reprepareLogos,
                                          invalidPlacement
                                        }: WrapperProps) {
  const viewModel = useDesignStyleEditorViewModel(design, onLogoChange, onViewSelect, designLogos, onLogoInvalid, onSelectLogo, locked, reprepareLogos, invalidPlacement, currentLogo)
  return <>
    <DesignStyleEditor {...viewModel} />
  </>
}


export function DesignStyleEditor({
                                    currentView,
                                    views,
                                    handleSelectView,
                                    logosOfView,
                                    isLoading,
                                    handleLogoChange,
                                    currentLogo,
                                    onSelectLogo,
                                    locked,
                                    onInvalid,
                                    invalidPlacement
                                  }: DesignStyleEditor_VM) {
  if (views.length && !currentView) {
    handleSelectView(views[0])
  }

  return <div className={'h-full flex flex-col'}>
    <div className={'flex-1'}>
      {currentView ? <DesignViewWrapper
        locked={locked}
        currentLogo={currentLogo}
        currentView={currentView!}
        logosOfCurrentView={logosOfView}
        onLogoChange={handleLogoChange}
        onSelectLogo={onSelectLogo}
        onInvalid={onInvalid}
        invalidPlacement={invalidPlacement}
      /> : <p>no image</p>}
    </div>
    <DesignViewSelector views={views} onSelectView={handleSelectView} currentView={currentView} />
  </div>
}

interface DesignViewSelectorProps {
  views: ViewWithDimensions[];
  onSelectView: (view: ViewWithDimensions) => void,
  currentView?: ViewWithDimensions;
}

function DesignViewSelector({ views, onSelectView, currentView }: DesignViewSelectorProps) {

  const parsedViews = views.map(x => x.view)

  function handleSelectView(view: StyleView | StyleViewForm) {
    const selectedView = views.find(x => x.view.id === view.id)
    onSelectView(selectedView!)
  }

  return <ViewSelector views={parsedViews} onSelectView={handleSelectView} currentView={currentView?.view} />
}