import { NavigateOptions, URLSearchParamsInit } from 'react-router-dom'
import { PageQuery } from '../../../utils/models/pageQuery'

export function setQueryParameters(setQParams: (nextInit?: (URLSearchParamsInit | ((prev: URLSearchParams) => URLSearchParamsInit)), navigateOpts?: NavigateOptions) => void, currentPageQuery: PageQuery) {
  setQParams(() => {
    const qP = {
      searchTerm: currentPageQuery.searchTerm,
      pageSize: '' + currentPageQuery.page?.size,
      pageIndex: '' + currentPageQuery.page?.index,
      sortByField: currentPageQuery.sortBy?.field,
      sortDirection: currentPageQuery.sortBy?.sortDirection ? '' + currentPageQuery.sortBy?.sortDirection : '',
      filterBy: currentPageQuery.filterBy?.map(x => x.field),
      filterOn: currentPageQuery.filterBy?.map(x => x.value),
      open: '' + currentPageQuery.open?.id,
      orgId: '' + currentPageQuery.open?.organizationId
    }


    // removes keys with undefined values from model
    const clone = Object.assign({}, qP)
    for (const key in clone) {
      // @ts-ignore
      if (!clone[key] || clone[key] === 'undefined') {
        // @ts-ignore
        delete qP[key]
      }
    }

    return qP
  })
}