export function capRadianToFullRotation(rad: number): number {
  const max = Math.PI
  const min = -Math.PI

  let v = rad
  if (v < min) { // -3.15 < -3.14
    v = max + (v - min)
  } else if (v > max) { // 3.15 > 3.14
    v = min + (v - max) // -3.14 + (3.15 - 3.14)
  }
  return v
}