import { useParams, useSearchParams } from 'react-router-dom'
import { usePublicApiClient } from './use-public-api.client'
import { ComponentState, useComponentStateMachine } from '../../../shared/hooks/useComponentStateMachine'
import { ErrorView } from '../../../components/error-view/ErrorView'
import { DesignDto } from '../../../../data/models/responses/DesignDto'
import { JetSportBox } from '../../../components/BoxView/JetSportBox'
import { ExternalApprovalFlow, LoadingFrame } from './ExternalApprovalFlow'
import { BoxHeader2 } from '../../../components/BoxView/BoxHeader2'
import { BoxContent } from '../../../components/BoxView/BoxContent'
import { BoxFooter } from '../../../components/BoxView/BoxFooter'

export function ExternalApprovalDesignScreen() {
  const [qp] = useSearchParams()
  const params = useParams()

  const code = qp.get('code')
  const designId = params?.designId

  const validRoute = !!code && !!designId
  return validRoute ? <DesignExternalApprovalLoader designId={+designId!} code={code!} /> : <InvalidRoute />
}

interface DesignExternalApprovalLoaderProps {
  code: string,
  designId: number;
}

function DesignExternalApprovalLoader({ designId, code }: DesignExternalApprovalLoaderProps) {
  const { getDesign } = usePublicApiClient()
  const { dataTransition, state } = useComponentStateMachine()
  const { isError, isLoading, isSuccess, data } = getDesign(designId, code)
  dataTransition(isLoading, isSuccess, isError)

  if (state === ComponentState.Failed) {
    return <ErrorView />
  }

  if (state === ComponentState.Succeed) {
    return <DesignExternalApproval design={data!} code={code} />
  }

  return <LoadingFrame />
}

interface DesignExternalApprovalProps {
  design: DesignDto;
  code: string
}

function DesignExternalApproval({ design, code }: DesignExternalApprovalProps) {
  const { placeVerdictDesign } = usePublicApiClient()

  async function handleVerdict(approval: boolean, note?: string) {
    return placeVerdictDesign(design.id, approval, note, code)
  }

  return <ExternalApprovalFlow placeVerdict={handleVerdict} subject={'Design'}>
    <ExternalViewDesign design={design}/>
  </ExternalApprovalFlow>
}

interface ExternalViewDesignProps {
  design: DesignDto
}

function ExternalViewDesign({design}: ExternalViewDesignProps) {
  return <img src={design.displayImage.highResUrl}/>
}


export function InvalidRoute() {
  return <JetSportBox>
    <BoxHeader2/>
    <BoxContent>Invalid route</BoxContent>
    <BoxFooter/>
  </JetSportBox>
}