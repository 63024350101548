import { useQuery, useQueryClient } from '@tanstack/react-query'
import { Manufacturer } from '../../core/models/entities/Manufacturer'
import { Resource } from '../../core/models/wrappers/Resource'
import { ManufacturerRepository } from '../../core/repositories/ManufacturerRepository'
import { useApiClient } from '../clients/ApiClient'
import { CreateManufacturerBody } from '../models/bodies/CreateManufacturerBody'
import { mapToManufacturer } from '../models/mappers/mapManufacturer'

function useManufacturerRepository(): ManufacturerRepository {
  const apiClient = useApiClient()
  const queryClient = useQueryClient()

  async function invalidateQueries() {
    await queryClient.refetchQueries({ queryKey: ['manufacturers'], type: 'all', refetchPage: () => true})
  }

  async function getManufacturers(orgId?: number): Promise<Manufacturer[]> {
    return apiClient.getAllManufacturers(orgId).then(x=> x.results)
  }

  function useManufacturers(): Resource<Manufacturer[]> {
    const config = { staleTime: 600000 }
    return useQuery(['manufacturers'], () => apiClient.getAllManufacturers()
      .then(data => data.results.map(mapToManufacturer)), config)
  }

  async function createManufacturer(name: string): Promise<Manufacturer> {
    const body: CreateManufacturerBody = {
      name: name
    }
    const result = await apiClient.createManufacturer(body);
    await invalidateQueries()
    return mapToManufacturer(result)
  }

  return {
    getManufacturers,
    useManufacturers,
    createManufacturer
  }
}

export { useManufacturerRepository }
