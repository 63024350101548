import { FilterBy, PageQuery } from '../../../utils/models/pageQuery'

export function getInitialQuery(queryParams: URLSearchParams): PageQuery {
  const filterByArray = queryParams.getAll('filterBy')
  const filterOnArray = queryParams.getAll('filterOn')

  function getFilters(filterOn: string[], filterBy: string[]) {
    const filter: FilterBy[] = []
    for (let i = 0; i < filterBy.length; i++) {
      filter.push({
        value: filterOnArray[i],
        field: filterByArray[i]
      })
    }
    return filter
  }

  const openId = queryParams.get("open")
  const orgId = queryParams.get("orgId")

  return {
    filterBy: filterByArray ? getFilters(filterOnArray, filterByArray) : [],
    sortBy: {
      field: queryParams.get('sortByField'),
      sortDirection: queryParams.get('sortDirection')
    },
    searchTerm: queryParams.get('searchTerm'),
    page: {
      size: queryParams.get('pageSize') ?? 25,
      index: queryParams.get('pageIndex') ?? 1
    },
    open: (openId && orgId) ? {
      id: openId,
      organizationId: orgId
    } : undefined
  } as PageQuery
}