import { Role } from '../../../core/models/entities/Role'
import { RoleResponse } from '../responses/RoleResponse'

export function mapToRole(dto: RoleResponse): Role {
  switch (dto) {
    case RoleResponse.Admin:
      return Role.Admin
    case RoleResponse.Vendor:
      return Role.Vendor
    case RoleResponse.EndUser:
      return Role.EndUser
  }
}
