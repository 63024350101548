import { Menu, MenuProps } from '@mui/material'
import { useWhiteLabelContext } from '../../../../WhiteLabelContext'

export function BaseMenu(props: MenuProps): React.ReactElement {

  const { whiteLabel } = useWhiteLabelContext()

  const mergedStyle = {
    ...props.style,
    '--primary': whiteLabel.colorHex,
    '--primary-bg': `${whiteLabel.colorHex}10`,
    zIndex: 10000,
    position: 'relative'
  }


  return <Menu {...props} >
    {/* @ts-ignore */}
    <div style={mergedStyle}>
      {props.children}
    </div>
  </Menu>
}