import { MenuItem, Select } from '@mui/material'
import { InputLabel } from '../../components/Input/InputLabel'
import { faX } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

interface CommissionDisplayProps {
  labelText: string
  onClick: () => void
}

export function CommissionDisplay({ labelText, onClick }: CommissionDisplayProps) {

  const handleClick = (event: { detail: number }) => {
    if (event.detail === 2) {
      onClick();
    }
  };

  return <div className={'p-1'}>
    <div className={`px-0.5`}><InputLabel>{labelText}</InputLabel></div>
    <div className={'h-7 flex space-x-1'}>
      <div className={'flex'}>
        <Select disabled={true}
                sx={{
                  width: 50
                }}
                style={{ padding: '0px' }}
                value={"~"}
                className={`select-pad-override bg-gray-100 text-gray-400 text-lg font-bold`}
                SelectDisplayProps={{}}
        >
          <MenuItem value={"~"}>{"~"}</MenuItem>
        </Select>
      </div>
      <div className={'w-32 bg-gray-100'}>
        <div className={`flex justify-between border-2 rounded h-7`}>
          <button
            className={`w-4 bg-gray-100 rounded-bl rounded-tl text-gray-300`} disabled={true}>
          </button>
          <div className={'grid content-center text-gray-400 text-lg font-bold'} onClick={handleClick}>
            ~
          </div>
          <button
            onClick={onClick}
            className={`w-4 bg-gray-100 rounded-br rounded-tr text-300 hover`}>
            <FontAwesomeIcon icon={faX} />
          </button >
        </div>
      </div>
    </div>
  </div>
}