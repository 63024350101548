import React from 'react'
import { Dialog_VM, DialogFrame } from './dialog-base'
import { StrucRow } from '../StructureElements/StrucRow'
import { StrucCol } from '../StructureElements/StrucCol'
import { InputWrapper } from '../Input/InputWrapper'
import { InputLabel } from '../Input/InputLabel'
import { CustomTextArea } from '../Input/CustomInput'
import { ApprovalGroup, ApprovalStatus } from '../../../core/models/entities/Approval'
import { Role } from '../../../core/models/entities/Role'

interface Props {
  approval?: ApprovalGroup,
  title: JSX.Element
  dialogOptions: Dialog_VM
  exclude: Role
}

export function RejectionNoteDialog({ approval, title, dialogOptions, exclude }: Props) {
  return <DialogFrame dialogOption={dialogOptions}>
    <div className={'space-y-4'}>
      <StrucRow>
        <StrucCol>
          {title}
        </StrucCol>
      </StrucRow>
      {
        approval?.vendor?.status === ApprovalStatus.Rejected && <StrucRow>
          <StrucCol>
            <InputWrapper>
              <InputLabel>Vendor Note:</InputLabel>
              <CustomTextArea readOnly={true} value={approval.vendor?.rejectionNote ?? 'no reason given'} />
            </InputWrapper>
          </StrucCol>
        </StrucRow>
      }
      {
        approval?.customer?.status === ApprovalStatus.Rejected && <StrucRow>
          <StrucCol>
            <InputWrapper>
              <InputLabel>Customer Note:</InputLabel>
              <CustomTextArea readOnly={true} value={approval.customer?.rejectionNote ?? 'no reason given'} />
            </InputWrapper>
          </StrucCol>
        </StrucRow>
      }
      {
        approval?.external?.status === ApprovalStatus.Rejected && <StrucRow>
          <StrucCol>
            <InputWrapper>
              <InputLabel>External Customer Note:</InputLabel>
              <CustomTextArea readOnly={true} value={approval.external?.rejectionNote ?? 'no reason given'} />
            </InputWrapper>
          </StrucCol>
        </StrucRow>
      }</div>
  </DialogFrame>

}