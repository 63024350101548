import { BaseLogo } from "../../../core/models/entities/BaseLogo"
import { Design } from "../../../core/models/entities/Design"
import { Role } from "../../../core/models/entities/Role"
import { useApprovalDialogAdmin } from "./approval-dialog-admin"
import { useApprovalDialogCustomer } from "./approval-dialog-customer"
import { useApprovalDialogVendor } from "./approval-dialog-vendor"

export interface Props<T> {
  role: Role
  subject: string
  onSkip: (entity: T) => Promise<void>
  onOrder: (entity: T) => Promise<void>
  onInitApproval: (entity: T, addExternal?: boolean, email1?: string | undefined) => Promise<void>
}

export interface ApprovalDialog<T> {
  component: JSX.Element,
  start: (entity: T) => void
}

export function useApprovalDialogByRole<T extends Design | BaseLogo>({ role, subject, onSkip, onOrder, onInitApproval }: Props<T>): ApprovalDialog<T> {
  const title = subject

  switch (role) {
    case Role.Admin:
      return useApprovalDialogAdmin<T>({
        subject: title,
        onSkip,
        onOrder,
        onInitApproval: (entity) => onInitApproval(entity)
      })
    case Role.Vendor:
      return useApprovalDialogVendor<T>({
        subject: title,
        onSkip,
        onOrder,
        onInitApproval: (entity) => onInitApproval(entity)
      })
    case Role.EndUser:
      return useApprovalDialogCustomer<T>({
        subject: title,
        onSkip,
        onOrder,
        onInitApproval: (entity, addExternal, email1) => onInitApproval(entity, addExternal, email1)
      })
  }
}