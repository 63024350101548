import { ApprovalGroup, ApprovalStatus } from '../../../core/models/entities/Approval'
import { GetOverallApprovalStatus } from '../../../core/models/entities/BaseLogo'

export function ApprovalDisplay({ approval }: { approval: ApprovalGroup }) {
  const { Vendor, Customer, External, Header } = buildStatusCSS(approval)
  return (<>
    <ApprovalStatusHeader status={Header.value} />
    {Vendor && <div className={`font-semibold flex items-center space-x-2 ${Vendor.textColor}`}>
      <div className={`rounded-full h-[0.25rem] w-[0.25rem] ${Vendor.dotColor}`} />
      <p>Vendor</p></div>}
    {Customer && <div className={`font-semibold flex items-center space-x-2 ${Customer.textColor}`}>
      <div className={`rounded-full h-[0.25rem] w-[0.25rem] ${Customer.dotColor}`} />
      <p>Customer</p></div>}
    {External && <div className={`font-semibold flex items-center space-x-2 ${External.textColor}`}>
      <div className={`rounded-full h-[0.25rem] w-[0.25rem] ${External.dotColor}`} />
      <p>End Customer</p></div>}
  </>)
}

export function ApprovalStatusHeader({ status }: { status: ApprovalStatus }) {
  return <p className={`font-semibold text-xs uppercase ${statusColor(status)}`}>{headerDisplayText(status)}</p>
}

function headerDisplayText(status: ApprovalStatus) {
  switch (status) {
    case ApprovalStatus.Pending:
      return 'PROOF'
    case ApprovalStatus.Approved:
      return 'APPROVED BY'
    case ApprovalStatus.Rejected:
      return 'APPROVED BY'
  }
}

function statusColor(statusType: ApprovalStatus) {
  switch (statusType) {
    case ApprovalStatus.Approved:
      return 'text-status-success'
    case ApprovalStatus.Pending:
      return 'text-status-pending-1'
    case ApprovalStatus.Rejected:
      return 'text-status-success'
  }
}

function buildStatusCSS(approval: ApprovalGroup
) {


  const overAll = GetOverallApprovalStatus(approval)
  return {
    Header: { value: overAll, textColor: sssHeader(overAll) },
    Vendor: approval.vendor ? paticipantCSS(approval.vendor.status) : null,
    Customer: approval.customer ? paticipantCSS(approval.customer.status) : null,
    External: approval.external ? paticipantCSS(approval.external.status) : null
  }
}

function sssHeader(statusType: ApprovalStatus) {
  switch (statusType) {
    case ApprovalStatus.Approved:
      return 'text-status-success'
    case ApprovalStatus.Pending:
      return 'text-status-pending-1'
    case ApprovalStatus.Rejected:
      return 'text-status-failed-1'
  }
}

function paticipantCSS(logoStatus: ApprovalStatus) {
  switch (logoStatus) {
    case ApprovalStatus.Approved:
      return { textColor: 'text-gray-600', dotColor: 'bg-status-success' }
    case ApprovalStatus.Pending:
      return { textColor: 'text-status-pending-1', dotColor: 'bg-status-pending-1' }
    case ApprovalStatus.Rejected:
      return { textColor: 'text-status-failed-1', dotColor: 'bg-status-failed-1' }
  }
}