import { BrandExclusionForm, OrganizationBrandExclusionForm } from '../components/brand-exclusion-form.component'
import { Manufacturer } from '../../../../core/models/entities/Manufacturer'
import { useBrandExclusionFormCreateViewModel } from '../vmHooks/useBrandExclusionFormCreateViewModel'

interface WrapperProps {
  form: OrganizationBrandExclusionForm,
  onFormChange: (form: OrganizationBrandExclusionForm) => void
  manufacturers: Manufacturer[]
}

export function BrandExclusionFormCreateWrapper({ form, onFormChange, manufacturers }: WrapperProps) {
  const vm = useBrandExclusionFormCreateViewModel(form, onFormChange, manufacturers)
  return <BrandExclusionForm {...vm} />
}

