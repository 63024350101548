import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleCheck, faCircleXmark } from '@fortawesome/free-solid-svg-icons'
import React from 'react'

export function BinaryStatusGoodBad({ verdict, badOption, goodOption }: {
  verdict: boolean,
  goodOption: string,
  badOption: string
}) {
  return <div
    className={`flex font-bold text-xxs items-center ${!verdict ? 'text-status-failed-1' : 'text-status-success'} `}>
    <div className={'mr-1'}>
      {!verdict
        ? <FontAwesomeIcon className={'text-status-failed-1'} icon={faCircleXmark} />
        : <FontAwesomeIcon className={'text-status-success'} icon={faCircleCheck} />}
    </div>
    {!verdict ? badOption : goodOption}
  </div>
}