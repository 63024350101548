import { ComponentState, useComponentStateMachine } from '../../shared/hooks/useComponentStateMachine'
import { useRepositoriesContext } from '../../../contexts/RepositoriesContext'
import { LoadingView } from '../../components/loading-view/LoadingView'
import { ErrorView } from '../../components/error-view/ErrorView'
import React from 'react'
import { useParams, useSearchParams } from 'react-router-dom'
import { NotFoundPage } from '../not-found/NotFoundPage'
import { Organization } from '../../../core/models/entities/Organization'
import { Design } from '../../../core/models/entities/Design'
import { DesignEditorStateProvider } from './contexts/design-editor-state-provider.context'
import { LogoProvider } from './contexts/logo-options.context'
import { DesignProvider } from './contexts/design.context'
import { DesignEditorTool } from './components/design-editor-tool.component'

export function DesignEditor2ScreenWithParams({ orgId, designId }: {
  orgId: Organization['id'],
  designId: Design['id']
}) {
  const { state, dataTransition } = useComponentStateMachine()
  const { designRepository, logoRepository } = useRepositoriesContext()
  const {
    data: design,
    isLoading: isDesignLoading,
    isSuccess: isDesignSuccess,
    isError: isDesignError
  } = designRepository.useDesign(orgId, designId!)

  const {
    data: options,
    isLoading: isOptionLoading,
    isSuccess: isOptionSuccess,
    isError: isOptionError
  } = logoRepository.useOptions()

  const isLoading = isOptionLoading || isDesignLoading
  const isError = isOptionError || isDesignError
  const isSuccess = isOptionSuccess && isDesignSuccess
  dataTransition(isLoading, isSuccess, isError)
  return state === ComponentState.Succeed
    ?
    <LogoProvider options={options!}>
      <DesignEditorStateProvider>
        <DesignProvider design={design!}>
          <DesignEditorTool design={design!} options={options!} />
        </DesignProvider>
      </DesignEditorStateProvider>
    </LogoProvider>
    : state === ComponentState.Loading
      ? <LoadingView />
      : <ErrorView />
}


export function DesignEditor2Screen(): React.ReactElement {
  const { designId } = useParams()
  const [searchParams] = useSearchParams()
  const organizationId = searchParams.get('orgId')

  const validParams = !!designId &&
    !Number.isNaN(designId) &&
    !!organizationId &&
    !Number.isNaN(organizationId)

  return validParams
    ? <DesignEditor2ScreenWithParams designId={+designId} orgId={+organizationId} />
    : <NotFoundPage />
}