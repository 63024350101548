import { ColorDto, FontDto, LogoOptionsDto, TransferMethodDto } from '../responses/logoOptionsDto'
import { Color, Font, LogoOptions, TransferMethod } from '../../../core/models/entities/LogoOptions'

export function mapLogoOptions(dto: LogoOptionsDto): LogoOptions {
  return {
    ...dto,
    transferMethods: dto.transferMethods.map(mapToTransferMethod),
    colors: dto.colors.map(mapToColor),
    transferLogoTypes: dto.logoTypes,
    fonts: dto.fonts.map(mapToFont),
    fontSizes: dto.fontSizes.map(mapToFontSize)
  }
}

function mapToColor(dto: ColorDto): Color {
  return dto
}

function mapToTransferMethod(dto: TransferMethodDto): TransferMethod {
  return dto
}

function mapToFont(dto: FontDto): Font {
  return dto
}

function mapToFontSize(dto: number): number {
  return dto
}