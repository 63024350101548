import React from 'react';
import { CustomInput } from './CustomInput';
import PhoneInput from 'react-phone-number-input/input';
import { CustomInputChangeEvent } from '../../../utils/models/events/CustomInputChangeEvent';

interface CustomPhoneInputProps {
  placeholder?: string;
  name: string;
  onChange: (event: CustomInputChangeEvent) => void;
  value: string;
  disabled: boolean;
}

export function CustomPhoneInput({ onChange, name, value, placeholder, disabled }: CustomPhoneInputProps) {
  function emitEvent(x: unknown) {
    onChange({
      target: {
        name: name,
        type: 'phone-input',
        // @ts-ignore
        value: x ? x.toString() : ''
      }
    });
  }

  return <PhoneInput
    placeholder={placeholder}
    value={value}
    inputComponent={CustomInput}
    onChange={emitEvent}
    smartCaret={true}
    disabled={disabled} />;
}
