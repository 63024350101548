import React from 'react'
import CanvasDraw from 'react-canvas-draw'
import { MaskColor } from '../../../../../../../core/models/entities/MaskColor'
import { StyleView } from '../../../../../../../core/models/entities/StyleView'

interface DrawingContainerProps {
  penColor: MaskColor
  brushSize: number
  opacity: number
  canvasWidth: number
  canvasHeight: number
  canvasRef: React.RefObject<CanvasDraw>
  view: StyleView
}

export function DrawingContainer({
  canvasRef,
  canvasWidth,
  canvasHeight,
  penColor,
  brushSize,
  opacity,
  view
}: DrawingContainerProps): React.ReactElement {
  // Render
  return (
    <div>
      <CanvasDraw
        className="absolute z-10"
        ref={canvasRef}
        imgSrc={view.updatedMaskUrl ?? view.maskUrl}
        canvasWidth={canvasWidth}
        canvasHeight={canvasHeight}
        brushColor={`rgb(${MaskColor.toRGBString(penColor)})`}
        brushRadius={brushSize}
        lazyRadius={0}
        style={{ opacity: opacity / 100 }}
      />
      <CanvasDraw
        className="z-0"
        imgSrc={view.imageUrl}
        canvasWidth={canvasWidth}
        canvasHeight={canvasHeight}
        disabled={true}
      />
    </div>
  )
}
