import { Profile } from '../../../core/models/entities/Profile'
import { useTranslation } from 'react-i18next'
import { Role } from '../../../core/models/entities/Role'
import { Link } from 'react-router-dom'
import { useWhiteLabelContext } from '../../../WhiteLabelContext'

function Brand({ profile }: { profile: Profile }): JSX.Element {
  const { t } = useTranslation()
  return (
    <Link to='/' className='h-full w-28 p-2 flex justify-center items-center'>
      {profile.userOrganizationInformations.role === Role.Admin ? (
        <span className='font-bold text-l uppercase'>
          {
            profile.whiteLabelConfigurations.logoUrl
              ? <BrandInner profile={profile!} /> 
              : t('admin')
          }</span>
      ) : (
        profile.userOrganizationInformations.organizationId
          ? <BrandInner profile={profile!} />
          : <span className='font-bold text-l uppercase'>N/A</span>
      )}
    </Link>
  )
}

function BrandInner({ profile }: { profile: Profile }) {

  const { whiteLabel } = useWhiteLabelContext();

  return <>{profile &&
    <img className='h-auto w-auto max-h-full max-w-full' src={whiteLabel.logoUrl} />}</>
}

export default Brand