export function compareColors(color: number[], color2: number[]) {
  const distR = color[0] - color2[0],
    distG = color[1] - color2[1],
    distB = color[2] - color2[2]

  const threshold = 40
  return distR * distR + distG * distG + distB * distB <= threshold * threshold
}

export function toRGBColorString(color: number[]): string {
  return color.join(',')
}
