import React from 'react'

export const SectionHeaderTypography = React.forwardRef<HTMLParagraphElement, React.HTMLAttributes<HTMLParagraphElement>>(
  (props, ref) => {
    const { className, ...rest } = props
    return <p ref={ref}
              className={`font-semibold capitalize text-sm ${className ?? ''}`} {...rest}>{props.children}</p>
  }
)

export const TypographyR = React.forwardRef<HTMLParagraphElement, React.HTMLAttributes<HTMLParagraphElement>>(
  (props, ref) => {
    const { className, ...rest } = props
    return <p ref={ref}
              className={`font-normal text-xs ${className ?? ''}`} {...rest}>{props.children}</p>
  }
)


export const ColumnTypography = React.forwardRef<HTMLParagraphElement, React.HTMLAttributes<HTMLParagraphElement>>(
  (props, ref) => {
    const { className, ...rest } = props
    return <p ref={ref}
              className={`font-semibold text-sm uppercase ${className ?? ''}`} {...rest}>{props.children}</p>
  }
)

