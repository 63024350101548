import { Manufacturer } from '../../../core/models/entities/Manufacturer'
import { Autocomplete, Box, createFilterOptions } from '@mui/material'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons'

export function ManufacturerAutoComplete({ onSelected, manufacturers }: {
  manufacturers: Manufacturer[],
  onSelected: (manufacturer: (Manufacturer | null)) => void
}) {
  const filterOptions = createFilterOptions<Manufacturer>({
    limit: 200
  })

  return <Autocomplete
    className={'!text-xs'}
    disablePortal
    options={manufacturers}
    filterOptions={filterOptions}
    getOptionLabel={(x) => x.name}
    renderOption={(props, option) => {
      // @ts-ignore
      const { key, ...otherProps } = props;
      return (
        <Box
          key={key}
          component='li'
          {...otherProps}
        >
          <div className={'flex text-xs items-center'}>
            <p>{option.name}</p>
          </div>
        </Box>
      )
    }}
    renderInput={(params) =>
    (

      <div className='text-xs border rounded-md border-solid border-gray-200 h-9 flex space-x-2 items-center pl-2'
        ref={params.InputProps.ref}>
        <span>
          <FontAwesomeIcon icon={faMagnifyingGlass} />
        </span>
        <input placeholder='Search' {...params.inputProps}
          className='w-full border-none text-gray-600 text-xs font-medium outline-none' />
      </div>
    )}
    onChange={(x, v) => onSelected(v)}
  />
}