import React, { forwardRef, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons'

export interface SectionHeaderProps extends React.HTMLAttributes<HTMLDivElement> {
  isCloseable: boolean;
  onToggle?: (open: boolean) => void
  initialState?: boolean;
}
export const SectionHeader = forwardRef<HTMLDivElement, SectionHeaderProps>((props, ref) => {
  const { children, className, initialState = true, isCloseable = false, ...rest } = props

  const [isOpen, setIsOpen] = useState<boolean>(initialState)

  function handleToggle() {
    if (isCloseable) {
      const newVal = !isOpen
      if (rest.onToggle) {
        rest.onToggle(newVal)
      }
      setIsOpen(newVal)
    }
  }

  return <div ref={ref} className={`px-2 flex hover:bg-gray-100 py-3.5 ${className} border-t`} {...rest} onClick={handleToggle}>
    <div className={'flex-1'}>
      {children}
    </div>
    {isCloseable && <div className={'mr-2'}>
      {isOpen
        ? <FontAwesomeIcon icon={faChevronUp} />
        : <FontAwesomeIcon icon={faChevronDown} />}

    </div>}
  </div>
})