import { Role } from '../../../core/models/entities/Role'
import { MyProfile, MyProfileViewModel } from './my-profile.component'
import { useMyAdminProfile } from './useProfile/use-my-admin-profile'
import { useMyVendorProfile } from './useProfile/use-my-vendor-profile'
import { ErrorView } from '../../components/error-view/ErrorView'
import { LoadingView } from '../../components/loading-view/LoadingView'
import { ComponentState, useComponentStateMachine } from '../../shared/hooks/useComponentStateMachine'
import { useRepositoriesContext } from '../../../contexts/RepositoriesContext'
import { useMyCustomerProfile } from './useProfile/use-my-customer-profile'
import { Organization } from '../../../core/models/entities/Organization'
import { useProfileContext } from '../../../contexts/ProfileContext'
import { Profile } from '../../../core/models/entities/Profile'


export function MyProfileScreen(): React.ReactElement {
  const { profile, role } = useProfileContext()

  switch (role!) {
    case Role.Admin: return AdminProfile(profile!)
    case Role.Vendor: return VendorProfile(profile!)
    case Role.EndUser: return CustomerProfile(profile!)
  }
}

function AdminProfile(profile: Profile): React.ReactElement {
  return showProfileComponent(profile, undefined)
}

function VendorProfile(profile: Profile): React.ReactElement {
  const { organizationRepository } = useRepositoriesContext()
  const { data: vendor, isError, isLoading, isSuccess } = organizationRepository.useVendor(profile.userOrganizationInformations.organizationId!)

  const vm = useMyVendorProfile({ profile, organization: vendor! });
  return showProfileComponent(profile, vendor!)
}

function CustomerProfile(profile: Profile): React.ReactElement {
  const { organizationRepository } = useRepositoriesContext()
  const { data: customer, isError, isLoading, isSuccess } = organizationRepository.useCustomer(profile.userOrganizationInformations.organizationId!)

  return showProfileComponent(profile, customer!)
}

function showProfileComponent(profile: Profile, organization?: Organization): React.ReactElement {

  function getVM(profile: Profile, org?: Organization): MyProfileViewModel {
    switch (profile.userOrganizationInformations.role) {
      case Role.Admin: return useMyAdminProfile({ profile: profile });
      case Role.Vendor: return useMyVendorProfile({ profile: profile, organization: org! })
      case Role.EndUser: return useMyCustomerProfile({ profile: profile, organization: org! })
    }
  }

  const vm = getVM(profile, organization)

  const isLoading = vm?.loading === true;
  const isSuccess = vm?.loading === false;
  const isError = vm?.loading === undefined

  const { state, dataTransition } = useComponentStateMachine()
  dataTransition(isLoading, isSuccess, isError)

  return state === ComponentState.Succeed
    ? <MyProfile {...vm!} />
    : state === ComponentState.Loading
      ? <LoadingView />
      : <ErrorView />
}