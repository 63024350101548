import { useEffect, useState } from "react"
import { useLocation, useNavigate, useSearchParams } from "react-router-dom"
import { BaseLogo } from "../../../../core/models/entities/BaseLogo"
import { useApiClient } from "../../../../data/clients/ApiClient"
import { mapToLogo } from "../../../../data/models/mappers/mapLogo"

export function useOpenLogo(): { result: BaseLogo | undefined, clearOpen: () => void } {
    const navigate = useNavigate()
    const location = useLocation()
    const apiClient = useApiClient()
    const [searchParams] = useSearchParams()
    const id = searchParams.get('open')
    const orgId = searchParams.get('orgId')
    const [result, setResult] = useState<BaseLogo | undefined>()

    useEffect(() => {
        if (id && orgId) {
            apiClient.getLogo(Number(orgId), Number(id)).then(mapToLogo).then((res) => {
                setResult(res)
            }).catch(() => {
                setResult(undefined)
            })
        } else {
            setResult(undefined)
            clearOpen()
        }
    }, [id, orgId])

    function clearOpen() {
        const queryParams = new URLSearchParams(location.search)
        queryParams.delete('open')
        queryParams.delete('orgId')
        navigate(`${location.pathname}?${queryParams}`)
    }

    if (!id || !orgId) {
        return {
            result: undefined,
            clearOpen
        };
    }

    return {
        result,
        clearOpen
    }
}