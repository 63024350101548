import { ComponentState, useComponentStateMachine } from '../../shared/hooks/useComponentStateMachine'
import { ErrorView } from '../../components/error-view/ErrorView'
import { LoadingView } from '../../components/loading-view/LoadingView'
import { useRepositoriesContext } from '../../../contexts/RepositoriesContext'
import { CustomerCreateFormWrapper } from './wrappers/customer-create-form.wrapper'
import { getAllQuery } from '../logo-editor/logo-editor.screen'
import { Manufacturer } from '../../../core/models/entities/Manufacturer'
import { Organization } from '../../../core/models/entities/Organization'
import { LogoOptions } from '../../../core/models/entities/LogoOptions'
import { useProfileContext } from '../../../contexts/ProfileContext'
import { Role } from '../../../core/models/entities/Role'

export function CustomerCreateScreen() {

  let vm: () => Data;
  const { role } = useProfileContext()

  switch (role) {
    case Role.Admin: {
      vm = () => useAdminCreateCustomer();
      break;
    }
    case Role.Vendor: {
      vm = () => useVendorCreateCustomer();
      break;
    }
    default: {
      throw new Error("Only Admins and Vendors can create customers")
    }
  }

  const { logoOptions, manufacturers, vendors, state } = vm();

  return state === ComponentState.Succeed
    ? <CustomerCreateFormWrapper manufacturers={manufacturers} vendors={vendors} logoOptions={logoOptions!} />
    : state === ComponentState.Loading
      ? <LoadingView />
      : <ErrorView />
}

interface Data {
  manufacturers: Manufacturer[]
  vendors: Organization[] | undefined
  logoOptions: LogoOptions | undefined
  state: ComponentState
}

function useAdminCreateCustomer(): Data {
  const { state, dataTransition } = useComponentStateMachine()
  const { manufacturerRepository, organizationRepository, logoRepository } = useRepositoriesContext()
  const { data, isError, isLoading, isSuccess } = manufacturerRepository.useManufacturers()
  const { data: logoOptions, isLoading: isLogoOptionsLoading, isError: isLogoOptionsError, isSuccess: isLogoOptionsSuccess } = logoRepository.useOptions();

  const {
    data: vendors,
    isLoading: isVendorLoading,
    isError: isVendorError,
    isSuccess: isVendorSuccess
  } = organizationRepository.useAllVendors(getAllQuery)

  dataTransition(isLoading || isVendorLoading || isLogoOptionsLoading, isSuccess && isVendorSuccess && isLogoOptionsSuccess, isError || isVendorError || isLogoOptionsError)

  return {
    logoOptions: logoOptions,
    manufacturers: data!,
    vendors: vendors?.results,
    state: state
  }
}

function useVendorCreateCustomer(): Data {
  const { state, dataTransition } = useComponentStateMachine()
  const { manufacturerRepository, logoRepository } = useRepositoriesContext()
  const { data, isError, isLoading, isSuccess } = manufacturerRepository.useManufacturers()
  const { data: logoOptions, isLoading: isLogoOptionsLoading, isError: isLogoOptionsError, isSuccess: isLogoOptionsSuccess } = logoRepository.useOptions();

  dataTransition(isLoading || isLogoOptionsLoading, isSuccess && isLogoOptionsSuccess, isError || isLogoOptionsError)

  return {
    logoOptions: logoOptions,
    manufacturers: data!,
    vendors: undefined,
    state: state
  }
}