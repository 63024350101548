import '../GenericTable.css'
import { JSX } from 'react'
import { ColumnField } from './Column'
import { ListItemFrame } from './ListItemFrame'
import { MultiSelectEvent } from '../Models/multiSelectEvent'
import { IdLookUps } from '../../../../utils/models/idLookUp'

interface ListFrameProps<TData extends object> {
  data: TData[],
  columns: ColumnField[],
  renderRow: (columns: ColumnSettings, data: TData) => JSX.Element,
  onRowSelected: (event: MultiSelectEvent<TData>) => void,
  multiSelect: boolean
  selectedData: IdLookUps<TData>
  isLoading: boolean;
  getKey?: (data: TData) => string | number,
  onClick: (data: TData) => void,
  rowColor?: ((data: TData) => string) | string
}

function toColumnSettings(columns: ColumnField[]): ColumnSettings {
  const settings: ColumnSettings = {}
  for (const column of columns) {
    settings[column.value] = column
  }
  return settings
}

export function ListFrame<TData extends object>(
  {
    data,
    renderRow,
    columns,
    onRowSelected,
    multiSelect,
    selectedData,
    getKey,
    onClick,
    rowColor
  }: ListFrameProps<TData>) {

  function isChecked(data: TData): boolean {
    const key = getKey!(data);
    if (key in selectedData) {
      return true;
    }

    return false;
  }

  const columnSettings = toColumnSettings(columns)
  const rows = data.map((d, index) => {
    let bg: string = ''
    if (rowColor) {
      // @ts-ignore
      bg = (rowColor instanceof String ? rowColor : rowColor(d))
    }
    return <ListItemFrame
      bgColor={bg}
      key={getKey!(d)}
      index={index}
      isChecked={isChecked(d)}
      multiSelect={multiSelect}
      rowChecked={({ checked, index }) => onRowSelected({ checked, index, data: d })}
      onClick={() => onClick(d)}
    >
      {renderRow(columnSettings, d)}
    </ListItemFrame>
  }
  )

  return <div>
    {rows}
  </div>
}

export interface ColumnSettings {
  [key: ColumnField['value']]: ColumnField
}