import { UpdateStyleDto } from '../../core/models/dtos/UpdateStyleDto'
import { UpdateStyleBody } from './bodies/UpdateStyleBody'
import { UpdateStyleViewDto } from '../../core/models/dtos/UpdateStyleViewDto'
import { UpdateStyleViewBody } from './bodies/UpdateStyleViewBody'

export function mapToUpdateStyleBody(dto: UpdateStyleDto): UpdateStyleBody {
  return {
    name: dto.name,
    model: dto.model,
    views: dto.views.map(mapToUpdateStyleViewBody)
  }
}

export function mapToUpdateStyleViewBody(dto: UpdateStyleViewDto): UpdateStyleViewBody {
  return {
    id: dto.viewId,
    ppcm: dto.ppcm
  }
}