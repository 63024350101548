import '../../GenericTable.css'
import { PageChangeEvent } from '../../Models/pageChangeEvent'
import { JSX, useState } from 'react'
import PageIndexButton from './PageIndexButton'


interface PaginatorProps {
  onNewPage: (nextPageEvent: PageChangeEvent) => void;
  firstPage?: number;
  lastPage: number;
  initialIndex: number;
}


function Paginator({ onNewPage, lastPage = 1, initialIndex = 1, firstPage = 1 }: PaginatorProps): JSX.Element {
  const [currentPage, setCurrentPage] = useState(Number(initialIndex))
  let indexButtons: JSX.Element[]

  function goToPage(index: number) {
    setCurrentPage(index)
    onNewPage({ value: '' + index })
  }

  function onNextPage() {
    const nextPage = currentPage + 1
    if (nextPage > lastPage) {
      return
    }

    setCurrentPage(nextPage)
    onNewPage({ value: '' + nextPage })
  }

  function onPrevPage() {
    const prevPage = currentPage - 1
    if (prevPage < firstPage) {
      return
    }

    setCurrentPage(prevPage)
    onNewPage({ value: '' + prevPage })
  }

  indexButtons = buildPaginationButtonBar(lastPage, firstPage, currentPage, goToPage)
  const disabled1 = currentPage === firstPage
  const disabled2 = currentPage === lastPage
  return (
    <div className='flex gap-2'>
      <button className={'Paginator--nav-btn ' + (disabled1 ? '' : 'white-btn-hover')} disabled={disabled1}
              onClick={onPrevPage}>Prev
      </button>
      {indexButtons}
      <button className={'Paginator--nav-btn ' + (disabled2 ? '' : 'white-btn-hover')} disabled={disabled2}
              onClick={onNextPage}>Next
      </button>
    </div>
  )
}


function DotSeparator(index: number): JSX.Element {
  return <span className='w-8 tracking-widest mt-1 text-center cursor-default' key={'sep' + index}>...</span>
}

function IndexButton(index: number, currentPage: number, onGoToPage: (index: number) => void): JSX.Element {
  return PageIndexButton({
    pageIndex: index,
    goToPage: () => onGoToPage(index),
    active: currentPage === index
  })
}

function buildPaginationButtonBar(
  lastPage: number, firstPage: number, currentPage: number,
  onGoToPage: (index: number) => void
): JSX.Element[] {
  const dotSepThreshold = 2
  const indexButtons: JSX.Element[] = []

  if (lastPage <= 9) {
    for (let i = firstPage; i <= lastPage; i++) {
      indexButtons.push(IndexButton(i, currentPage, onGoToPage))
    }
    return indexButtons
  }

  // 1. index button
  indexButtons.push(IndexButton(firstPage, currentPage, onGoToPage))

  // 2. index button
  if (currentPage > firstPage + dotSepThreshold + 1) {
    indexButtons.push(
      currentPage === firstPage + 4
        ? IndexButton(2, currentPage, onGoToPage)
        : DotSeparator(2)
    )
  }

  // 3. to 7. index button
  if (currentPage > firstPage + dotSepThreshold + 1) {
    let loadStart = currentPage - dotSepThreshold
    if (loadStart > lastPage - (dotSepThreshold * 2) - 2) {
      loadStart = lastPage - (dotSepThreshold * 2) - 2
    }
    let loadAmount = currentPage + dotSepThreshold
    if (loadAmount > lastPage - 2) {
      loadAmount = lastPage - 2
    }

    for (let i = loadStart; i <= loadAmount; i++) {
      indexButtons.push(IndexButton(i, currentPage, onGoToPage))
    }
  }

  if (currentPage <= firstPage + dotSepThreshold + 1) {
    for (let i = firstPage + 1; i <= 7; i++) {
      indexButtons.push(IndexButton(i, currentPage, onGoToPage))
    }
  }

  // 8. index button
  indexButtons.push(
    currentPage < lastPage - (dotSepThreshold * 2)
      ? DotSeparator(8)
      : IndexButton(lastPage - 1, currentPage, onGoToPage)
  )

  // 9. index button
  indexButtons.push(IndexButton(lastPage, currentPage, onGoToPage))

  return indexButtons
}

export default Paginator