import { nameOf } from '../../../../utils/functions/ReflectionUtil'
import { ColumnField } from '../../../components/GenericTable/SubComponents/Column'
import { Role } from '../../../../core/models/entities/Role'
import { Design } from '../../../../core/models/entities/Design'
import { useProfileContext } from '../../../../contexts/ProfileContext'

export function designColumnsConfig() {
  const { role } = useProfileContext()
  return [
    {
      value: nameOf<Design>((x) => x.displayImage),
      canSortBy: false,
      displayValue: 'Image',
      weight: 1
    },
    {
      value: 'product.mul',
      canSortBy: true,
      displayValue: 'MUL',
      weight: 0.75
    },
    {
      value: nameOf<Design>((x) => x.title),
      canSortBy: true,
      displayValue: 'Title',
      weight: 0
    },
    {
      value: nameOf<Design>((x) => x.customer),
      canSortBy: true,
      displayValue: 'Customer',
      weight: 1.5
    },
    {
      value: 'priceSort',
      canSortBy: true,
      displayValue: 'Design Price',
      weight: 1
    },
    {
      value: nameOf<Design>((x) => x.createdAtUtc),
      canSortBy: true,
      displayValue: 'Creation Date',
      weight: 1.2
    },
    {
      value: nameOf<Design>((x) => x.status),
      canSortBy: true,
      displayValue: 'Status',
      weight: role === Role.EndUser ? 2 : 1.5
    },
    {
      value: 'action',
      canSortBy: false,
      displayValue: 'Action',
      weight: 1.5
    }
  ] as ColumnField[]
}
