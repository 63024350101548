import { CreateOrderDto, DesignInstanceDto, InstanceLogoSpecDto, OrderProductDto } from "../../../core/models/dtos/CreateOrderDto";
import { CartItem, CartItemLogo, CartItemDesign } from "../../../presentation/screens/order-create/models/CartItem";

export function mapToCreateOrder(orderlines: CartItem[]): CreateOrderDto {
    const orderProducts = orderlines.flatMap(line => {
        switch (line.type) {
            case "Logo": return [mapToProductDetailLogoDto(line as CartItemLogo)]
            case "Design": return mapToProductDetailDesignDto(line as CartItemDesign)
        }
    })

    return {
        products: orderProducts
    }
}

function mapToProductDetailLogoDto(orderline: CartItemLogo): OrderProductDto {
    const dto = {
        productNo: orderline.baseLogo.product?.sku!,
        productType: orderline.type,
        toStorage: orderline.toStorage,
        toDelivery: orderline.toDelivery,
        value: orderline.value,
    } as OrderProductDto;

    return dto;
}

function mapToProductDetailDesignDto(orderline: CartItemDesign): OrderProductDto[] {
    const dto = {
        productNo: orderline.design.product?.mul!,
        productType: orderline.type,
        instances: orderline.instances.map(x => {
            return ({
                logos: x.logos.map(l => {
                    return ({
                        isNumeric: l.logoVariant === "number",
                        locationIdentifier: l.locationIdentifier,
                        value: l.value,
                        logoId: l.logoId,
                        deliveryType: l.deliveryType
                    } as InstanceLogoSpecDto)
                }),
                toDelivery: x.toDelivery,
                toStorage: x.toStorage,
                value: x.label,
                size: x.size?.toString()
            } as DesignInstanceDto)
        })
    } as OrderProductDto

    let products = [dto];

    for (let key in orderline.instances) {
        let value = orderline.instances[key];
        const logoDesign = orderline.design.designLogos.find(logo => logo.id === Number(key))!;
        if (logoDesign === undefined) {
            continue
        }

        products.push(({
            productNo: logoDesign!.logo.product?.sku!,
            productType: 'Logo',
            toStorage: value.toStorage,
            toDelivery: value.toDelivery,
            // value:  orderline.value,
            // isNumeric: orderline.isNumeric
        } as OrderProductDto))
    }

    return products;
}
