import React from 'react'

export const CustomInput = React.forwardRef<HTMLInputElement, React.InputHTMLAttributes<HTMLInputElement>>((props, ref) => {
  const { className, ...rest } = props
  return <input ref={ref} className={`input bg-gray-100 border-gray-200 h-9 px-5 ${className ?? ''}`} {...rest} />
})

export const CustomTextArea = React.forwardRef<HTMLTextAreaElement, React.TextareaHTMLAttributes<HTMLTextAreaElement>>((props, ref) => {
  const { className, ...rest } = props
  return <textarea ref={ref} className={`input bg-gray-100 border-gray-200 h-40 px-5 resize-none disabled:bg-gray-50 disabled:border-gray-50   space-x-4 ${className ?? ''}`} {...rest} />
})

export const CustomDateInput = React.forwardRef<HTMLInputElement, React.InputHTMLAttributes<HTMLInputElement>>((props, ref) => {
  const { className, ...rest } = props
  return <input type="date" ref={ref} className={`input bg-gray-100 border-gray-200 h-9 px-5 ${className ?? ''}`} {...rest} />
})