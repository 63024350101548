import React from 'react'

export function InputError({ errorMessage }: { errorMessage?: string | string[] }) {
  let shownMessage = ''
  if (typeof errorMessage === 'string') {
    shownMessage += errorMessage
  }

  // @ts-ignore
  if (errorMessage instanceof Array<String>) {
    for (const error of errorMessage) {
      if (shownMessage) {
        shownMessage += `, ${error}`
        continue
      }
      shownMessage = error
    }
  }
  return errorMessage ? <div className={'relative !m-0'}>
    <span className={'text-xs text-red-100 animate-fadeInFast left-1 top-[-1px] absolute'}>{shownMessage}</span>
  </div> : <></>
}