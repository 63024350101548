import React, { useEffect } from 'react'

type ImageProps = React.HTMLProps<HTMLDivElement> & {
  url: string
  onRefSet?: () => void
}

export const ImageContainer = React.forwardRef<HTMLDivElement, ImageProps>((imageProps, ref) => {
  // Render
  useEffect(() => {
    if(ref && imageProps.onRefSet) {
      imageProps.onRefSet()
    }
  }, [ref])
  return (
    <div
      ref={ref}
      style={{
        backgroundImage: `url(${imageProps.url})`,
        ...imageProps.style
      }}
      className={`bg-contain bg-no-repeat bg-center ${imageProps.className}`}>
      {imageProps.children}
    </div>
  )
})
