import React from 'react'
import { ComponentState, useComponentStateMachine } from '../../shared/hooks/useComponentStateMachine'
import { useRepositoriesContext } from '../../../contexts/RepositoriesContext'
import { LoadingView } from '../../components/loading-view/LoadingView'
import { ErrorView } from '../../components/error-view/ErrorView'
import { useParams } from 'react-router'
import { useSearchParams } from 'react-router-dom'
import { DesignChangeStyleFormWrapper } from './wrappers/design-change-style-form.wrapper'

export function DesignChangeStyleScreen(): React.ReactElement {
  const { state, dataTransition } = useComponentStateMachine()
  const { manufacturerRepository, designRepository } = useRepositoriesContext()

  const { designId } = useParams()
  const [searchParams] = useSearchParams()
  const organizationId = searchParams.get('orgId')

  const {
    data: manufacturers,
    isLoading: isManufacturersLoading,
    isError: isManufacturersError,
    isSuccess: isManufacturersSuccess
  } = manufacturerRepository.useManufacturers()

  const {
    data: design,
    isLoading: isDesignLoading,
    isError: isDesignError,
    isSuccess: isDesignSuccess
  } = designRepository.useDesign(+organizationId!, +designId!)

  const isLoading = isManufacturersLoading || isDesignLoading
  const isError = isManufacturersError || isDesignError
  const isSuccess = isManufacturersSuccess || isDesignSuccess

  dataTransition(isLoading, isSuccess, isError)
  return state === ComponentState.Succeed
    ? <DesignChangeStyleFormWrapper manufacturers={manufacturers!} design={design!} />
    : state === ComponentState.Loading
      ? <LoadingView />
      : <ErrorView />
}








