import { LibrarySidePanelUpdateWrapper } from '../../wrappers/library-side-panel-update.wrapper'

import { SideViewStates } from '../../models/enums/side-view-states.enum'
import { TextLogoCreatePanelWrapper } from '../../wrappers/text-logo-create-panel.wrapper'
import { SymbolLogoCreatePanelWrapper } from '../../wrappers/symbol-logo-create-panel.wrapper'
import { NamePlaceholderLogoCreateWrapper } from '../../wrappers/name-placeholder-logo-create.wrapper'
import { LogoInDesignPanel } from './logo-in-design-panel.component'
import { NumberPlaceholderLogoCreateWrapper } from '../../wrappers/number-placeholder-logo-create.wrapper'

export function StateBasedPanel({ state }: {
  state: SideViewStates
}) {
  switch (state) {
    case SideViewStates.LogoInDesign:
      return <LogoInDesignPanel />
    case SideViewStates.NameLogoCreate:
      return <NamePlaceholderLogoCreateWrapper />
    case SideViewStates.SymbolLogoCreate:
      return <SymbolLogoCreatePanelWrapper />
    case SideViewStates.NumberLogoCreate:
      return <NumberPlaceholderLogoCreateWrapper />
    case SideViewStates.Library:
      return <LibrarySidePanelUpdateWrapper />
    case SideViewStates.TextLogoCreate:
      return <TextLogoCreatePanelWrapper />

  }
}
