import CatalogUpload from './subcomponents/catalog-upload/CatalogUpload'
import { ErrorView } from '../../components/error-view/ErrorView'
import { LoadingView } from '../../components/loading-view/LoadingView'
import { useStyleCatalogUploadViewModel } from './useStyleCatalogUploadViewModel'

export default function StyleCatalogUploadScreen() {
  const { manufacturers, isLoading, isSuccess, uploadCatalog, createManufacturer } =
    useStyleCatalogUploadViewModel()
  return (
    <>
      {manufacturers && isSuccess ? (
        <CatalogUpload
          manufacturers={manufacturers}
          catalogUpload={uploadCatalog}
          createManufacturer={createManufacturer}
        />
      ) : isLoading ? (
        <LoadingView />
      ) : (
        <ErrorView />
      )}
    </>
  )
}
