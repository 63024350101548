import { useTranslation } from 'react-i18next'
import { Manufacturer } from '../../../../../core/models/entities/Manufacturer'
import { JetSportBox } from '../../../../components/BoxView/JetSportBox'
import { BoxHeader2 } from '../../../../components/BoxView/BoxHeader2'
import { BoxHeaderTypography } from '../../../../components/Typographies/BoxHeaderTypography'
import { BoxContent } from '../../../../components/BoxView/BoxContent'
import { BoxFooter } from '../../../../components/BoxView/BoxFooter'
import React, { ChangeEvent, ReactNode, useState } from 'react'
import { PrimaryButton } from '../../../../components/Buttons/PrimaryButton'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faCloudArrowUp, faFileArrowDown } from '@fortawesome/free-solid-svg-icons'
import { CustomInput } from '../../../../components/Input/CustomInput'
import { SecondaryButton } from '../../../../components/Buttons/SecondaryButton'
import { useNavigate } from 'react-router-dom'
import { useErrorMessage } from '../../../../hooks/useErrorMessage'
import { toast } from 'react-toastify'
import { isEmpty } from '../../../../../utils/functions/StringUtil'
import LoadingElement from '../../../../components/loading-element/loading-element'
import { StrucCol } from '../../../../components/StructureElements/StrucCol'
import { StrucRow } from '../../../../components/StructureElements/StrucRow'

interface Props {
  manufacturers: Manufacturer[]
  catalogUpload: (manufacturerId: string, catalog: File) => Promise<void>
  createManufacturer: (name: string) => Promise<Manufacturer>
}

export default function CatalogUpload({
                                        manufacturers,
                                        catalogUpload,
                                        createManufacturer
                                      }: Props): React.ReactElement {
  const [selectedManufacturer, setSelectedManufacturer] = useState<Manufacturer>()
  const [currentCatalog, setCurrentCatalog] = useState<File>()
  const [isDragging, setIsDragging] = useState(false)
  const [name, setName] = useState('')
  const inputRef = React.useRef<HTMLInputElement>(null)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const startLoading = () => setIsLoading(true);
  const stopLoading = () => setIsLoading(false);
  const [filePicked, setFilePicked] = useState<boolean>(false)
  const [manufacturedPicked, setManufacturedPicked] = useState<boolean>(false)
  const [submitted, setSubmitted] = useState<boolean>(false)
  const navigate = useNavigate();

  const { t } = useTranslation()

  const { tError } = useErrorMessage()

  function handleUploadCatalog() {
    if (!selectedManufacturer) {
      toast.warn(t('no_manufacturer_selected'))
      return
    }

    if (!currentCatalog) {
      toast.warn(t('no_catalog_uploaded'))
      return
    }

    setSubmitted(true)
    startLoading()
    catalogUpload(selectedManufacturer.guid, currentCatalog)
      .then(() => {
        toast.success(t('toasts.catalog_upload_success'))
        endUpload()
      })
      .catch((e) => {
        toast.warn(tError(e, 'toasts.catalog_upload_failed'))
      })
      .finally(stopLoading)
  }

  function handleSelectCatalog(event: ChangeEvent<HTMLInputElement>) {
    event.stopPropagation()
    event.preventDefault()

    const files = event.target.files

    if (files && files.length > 0) {
      const file = files[0]
      setFilePicked(true)
      setCurrentCatalog(file)
    }
  }

  function handleDropCatalog(e: React.DragEvent<HTMLDivElement | HTMLFormElement>) {
    e.preventDefault()
    e.stopPropagation()
    setIsDragging(false)
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      // at least one file has been dropped so do something
      setFilePicked(true)
      setCurrentCatalog(e.dataTransfer.files[0])
    }
  }

  function handleDragCatalog(e: React.DragEvent<HTMLDivElement | HTMLFormElement>) {
    e.preventDefault()
    e.stopPropagation()
    if (e.type === 'dragenter' || e.type === 'dragover') {
      setIsDragging(true)
    } else if (e.type === 'dragleave') {
      setIsDragging(false)
    }
  }

  function endUpload() {
    setTimeout(() => {
      navigate('/style')
    }, 3000);
  }

  function onCreateManufacturer() {
    if (isEmpty(name)) {
      toast.warn(t('toasts.name_cannot_be_empty'))
      return
    }

    startLoading()
    createManufacturer(name)
      .then((m) => {
        setName('')
        setSelectedManufacturer(m)
        setManufacturedPicked(true)
        toast.success(t('toasts.create_manufacturer_success'))
      })
      .catch((e) => toast.warn(tError(e, 'toasts.create_manufacturer_failed')))
      .finally(stopLoading)
  }



  function NumberCircle({ children, done }: { children: ReactNode, done?: boolean }) {
    return <div
      className={`border-2 rounded-full h-10 w-10 flex justify-center items-center text-[16px] ${done ? 'border-status-success bg-status-success-bg' : ''}`}>
      {done
        ? <FontAwesomeIcon icon={faCheck} className={'text-status-success'} />
        : <span>{children}</span>
      }
    </div>
  }

  function handleSelectManufacturer(manufacturer: Manufacturer) {
    setManufacturedPicked(true)
    setSelectedManufacturer(manufacturer)
  }

  function ManufacturerListItem({ manufacturer, onClick, isSelected }: {
    manufacturer: Manufacturer,
    onClick: (manufacturer: Manufacturer) => void,
    isSelected: boolean
  }) {
    return <li onClick={() => onClick(manufacturer)}
               className={`cursor-pointer flex items-center h-8 ${isSelected ? 'text-status-success font-bold' : ''}`}>
      <span className={'my-auto'}>{manufacturer.name}</span>
    </li>
  }

  const manuComponents = manufacturers.map(x => <ManufacturerListItem key={x.id}
    isSelected={selectedManufacturer?.id === x.id} onClick={handleSelectManufacturer}
    manufacturer={x} />)

  function onUploadClick() {
    inputRef.current?.click()
  }


  function handleCancel() {
    navigate('/style')
  }

  return (
    <JetSportBox className={'relative'}>
      {isLoading && <LoadingElement transparent className={'rounded'} />}
      <BoxHeader2><BoxHeaderTypography>{t('upload_catalog')}</BoxHeaderTypography></BoxHeader2>
      <BoxContent>
        <StrucRow className={'h-full !justify-start'}>
          <StrucCol size={4} className={'space-y-4 pr-4'}>
            <div className={'flex items-center space-x-2'}>
              <NumberCircle done={filePicked}>1</NumberCircle>
              <span>Upload Catalog File</span>
            </div>
            <div className={`relative flex-1 border rounded ${filePicked ? 'bg-status-success-bg' : ''}`} onDragEnter={handleDragCatalog}>
              <div className={'flex justify-center items-center h-full'}>
                <input ref={inputRef} className={'hidden'} type='file' id='input-file-upload'
                       onChange={handleSelectCatalog}
                       accept='application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel' />
                {filePicked
                  ? <FontAwesomeIcon icon={faCheck} size={'3x'} className={'text-status-success'}/>
                  : <div className={'space-y-3 flex flex-col'}>
                  <FontAwesomeIcon icon={faCloudArrowUp} size={'2x'} className={'mx-auto '} />
                  <p className={'italic font-light text-xs'}>Drag and drop or</p>
                  <button onClick={onUploadClick}
                          className='mx-auto upload-button hover:underline italic font-light text-sm'>Upload logo
                  </button>
                </div>}
              </div>
              {isDragging &&
                (
                  <>
                    <div
                      className={'z-10 absolute w-full h-full top-0 bottom-0 left-0 right-0'}
                      onDragEnter={handleDragCatalog}
                      onDragLeave={handleDragCatalog}
                      onDragOver={handleDragCatalog}
                      onDrop={handleDropCatalog}>
                    </div>
                    <div id={'drop-overlay'}
                         className={'animate-fadeInFast bg-gray-200 absolute w-full h-full top-0 bottom-0 left-0 right-0 flex justify-center items-center'}>
                      <div className={'space-y-3 flex flex-col h-5 mt-[-60px]'}>
                        <FontAwesomeIcon icon={faFileArrowDown} size={'2x'} className={'mx-auto '} />
                        <p className={'italic font-light text-xs'}>Drop image here</p>
                      </div>
                    </div>
                  </>
                )
              }</div>
          </StrucCol>
          <StrucCol size={4} className={'space-y-4 px-4'}>
            <div className={'flex items-center space-x-2'}>
              <NumberCircle done={manufacturedPicked}>2</NumberCircle>
              <span>Choose Manufacturer</span>
              {selectedManufacturer &&
                <span className={'text-status-success font-bold'}>{selectedManufacturer.name}</span>}
            </div>
            <div>
              Add new Manufacturer
            </div>
            <div className={'flex space-x-4'}>
              <CustomInput value={name}
                           onChange={x => setName(x.target.value)} />
              <PrimaryButton width={'sm'} onClick={onCreateManufacturer}>Add</PrimaryButton>
            </div>
            <div>
              Or choose form existing
            </div>
            <div className={'border rounded'}>
              <ul
                className={'flex flex-col w-full flex-1 h-[650px] max-h-[650px] overflow-y-scroll overflow-x-visible px-1 mx-2'}>
                {manuComponents}
              </ul>
            </div>
          </StrucCol>
          <StrucCol size={4} className={'space-y-4 pl-4'}>
            <div className={'flex items-center space-x-2'}>
              <NumberCircle done={submitted}>3</NumberCircle>
              <span>Submit</span>
            </div>
            <span>By click 'Upload Catalog' you agree that the given catalog is associated with the selected manufacturer</span>
            <div className={'flex justify-end pt-4'}>
              <PrimaryButton onClick={handleUploadCatalog} disabled={!currentCatalog || !selectedManufacturer}>Upload Catalog</PrimaryButton>
            </div>
          </StrucCol>
        </StrucRow>
      </BoxContent>
      <BoxFooter>
        <SecondaryButton onClick={handleCancel}>Cancel</SecondaryButton>
      </BoxFooter>
    </JetSportBox>
  )
}
