import { Style, StyleStatus } from '../../../core/models/entities/Style'
import React from 'react'

export function StyleStatusView({style}:{style: Style}) {
  switch (style.status) {
    case StyleStatus.NotAnnotated:
      return <NotAnnotated/>
    case StyleStatus.Annotated:
      return <Annotated/>

  }
}


function NotAnnotated() {
  return <div className={`font-semibold flex items-center space-x-2`}>
    <div className={`rounded-full h-[0.25rem] w-[0.25rem] bg-status-pending-1`} />
    <p>Not Annotated</p>
  </div>
}

function Annotated() {
  return <div className={`font-semibold flex items-center space-x-2`}>
    <div className={`rounded-full h-[0.25rem] w-[0.25rem] bg-status-success`} />
    <p>Annotated</p>
  </div>
}