import React, { forwardRef } from 'react'
import { BaseLogo, ImageLogo, LogoType } from '../../../core/models/entities/BaseLogo'
import { Design } from '../../../core/models/entities/Design'
import { imageFallback } from '../../shared/const/imageFallback'

interface DesignImageProp extends React.ImgHTMLAttributes<HTMLImageElement> {
  design: Design
  useHighRes?: boolean
}
export const DesignImage = forwardRef<HTMLImageElement, DesignImageProp>((props, ref) => {
  const { design, useHighRes, ...rest } = props
  rest.src = (design.displayImage
    ?  useHighRes
      ? design.displayImage.highResUrl
      : design.displayImage.lowResUrl
    : useHighRes
      ? design.style.thumbnailUrl
      : design.style.thumbnailUrlLowResolution) ?? imageFallback
  rest.alt = design.title
  return <img ref={ref} {...rest} />
})