import React from 'react'
import { Manufacturer } from '../../../../core/models/entities/Manufacturer'
import { Organization } from '../../../../core/models/entities/Organization'
import { CustomerUpdateForm } from '../component/customer-update-form.component'
import { useCustomerFormUpdateViewModel } from '../vmHooks/useCustomerFormUpdateViewModel'
import { LogoOptions } from '../../../../core/models/entities/LogoOptions'

interface WrapperProps {
  manufacturers: Manufacturer[]
  customer: Organization
  logoOptions: LogoOptions
}

export function CustomerFormUpdateWrapper({ manufacturers, customer, logoOptions }: WrapperProps) {
  const vm = useCustomerFormUpdateViewModel(manufacturers, customer, logoOptions)
  return <CustomerUpdateForm {...vm} />
}