import { OrderLinesListItemDesign } from './OrderLinesListItemDesign'
import { OrderLinesListItemLogo } from './OrderLinesListItemLogo'
import { CartItem } from '../models/CartItem'

interface OrderLinesListItemProps {
  orderLine: CartItem
  onClick: (orderLine: CartItem) => void
}

export function OrderLinesListItem({ orderLine: orderLine, onClick }: OrderLinesListItemProps) {
  switch (orderLine.type) {
    case "Design":
      return <OrderLinesListItemDesign orderLine={orderLine} onClick={onClick} />
    case "Logo":
      return <OrderLinesListItemLogo orderLine={orderLine} onClick={onClick} />
  }
}