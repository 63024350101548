import { Dispatch, SetStateAction } from "react";
import { Bounce, Id, toast } from "react-toastify";

export function useSuccessToast(title: string, autoClose: number = 5000): Id {
    const id = toast.success(title, {
        position: "top-right",
        autoClose: autoClose,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce
    });

    return id;
}

export function useErrorToast(title: string, autoClose: number = 5000): Id {
    const id = toast.error(title, {
        position: "top-right",
        autoClose: autoClose,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
    });

    return id;
}

export function useLoadingToast(title: string, autoClose: number = 5000): Id {
    const id = toast.loading(title, {
        position: "top-right",
        autoClose: autoClose,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
    });

    return id;
}

export function dissmissToast(id: Id) {
    toast.dismiss(id)
}

export async function withToasts<T>(
    asyncFunction: () => Promise<T>,
    loadingMessage: string,
    successMessage: string,
    errorMessage: string,
    setIsLoading?: Dispatch<SetStateAction<boolean>>
): Promise<T> {
    setIsLoading && setIsLoading(true);
    const toast = useLoadingToast(loadingMessage)

    try {
        const result = await asyncFunction()
        useSuccessToast(successMessage)
        return result;
    } catch (error) {
        useErrorToast(errorMessage)
        throw error
    } finally {
        dissmissToast(toast)
        setIsLoading && setIsLoading(false)
    }
}