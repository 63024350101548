import { DesignLogo } from '../../../../../core/models/entities/Design'
import DigitalColorBlock from '../../../logo-editor/componenets/DigitalColorBlock'
import { StrucRow } from '../../../../components/StructureElements/StrucRow'
import { StrucCol } from '../../../../components/StructureElements/StrucCol'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faFile, faTrash } from '@fortawesome/free-solid-svg-icons'
import { BinaryStatusGoodBad } from './binary-status-good-bad.component'
import IconButton from '../../../../components/LogoButton/IconButton'
import { BaseLogo, ImageLogo, isDigital, LogoEditStatus, LogoType } from '../../../../../core/models/entities/BaseLogo'
import { getColorsElements } from '../../../order-create/subcomponents/ProductDetailsLogo'
import { LogoTransferMethod } from '../../../../components/Logo/LogoTransferMethod'
import { CloneIcon } from '../../../../../resources/icons/Icons'
import { LogoImage } from '../../../../components/Logo/LogoImage'

interface LogoInDesignCard_VM {
  logo: DesignLogo,
  placement?: boolean
  disable?: boolean
  isActive: boolean;
  onRemove: () => void
  onEdit: () => void
  onClone: () => void
}
export function LogoInDesignCard({ logo, placement, disable, isActive, onEdit, onRemove, onClone }: LogoInDesignCard_VM) {

  const isRaster = isDigital(logo.logo)
  const colors = getColorsElements(logo.logo)
  const color = isRaster ? <DigitalColorBlock /> : colors

  function actions(logo: BaseLogo) {
    switch (logo.logoType) {
      case LogoType.Unknown:
        return <>
          <IconButton width={'full'} icon={<FontAwesomeIcon icon={faTrash} />} onClick={onRemove}>Remove</IconButton>
        </>
      case LogoType.TextLogo:
        return <>
          <IconButton width={'full'} icon={<FontAwesomeIcon icon={faTrash} />} onClick={onRemove}>Remove</IconButton>
        </>
      case LogoType.NameLogo:
        return <>
          <IconButton width={'full'} icon={<FontAwesomeIcon icon={faTrash} />} onClick={onRemove}>Remove</IconButton>
        </>
      case LogoType.NumberLogo:
        return <>
          <IconButton width={'full'} icon={<FontAwesomeIcon icon={faTrash} />} onClick={onRemove}>Remove</IconButton>
        </>
      case LogoType.ImageLogo:
        return <>
          {(logo as ImageLogo).status === LogoEditStatus.Draft ? <IconButton width={'full'} key={'edit'} icon={<FontAwesomeIcon icon={faEdit} />} onClick={onEdit}>Edit</IconButton> : <IconButton width={'full'} icon={<CloneIcon />} onClick={onClone}>Clone Logo</IconButton>}
          <IconButton width={'full'} icon={<FontAwesomeIcon icon={faTrash} />} onClick={onRemove}>Remove</IconButton>
        </>
      case LogoType.SymbolLogo:
        return <>
          <IconButton width={'full'} icon={<FontAwesomeIcon icon={faTrash} />} onClick={onRemove}>Remove</IconButton>
        </>
    }
  }

  const buttons = actions(logo.logo)

  return <div className={`border rounded bg-gray-100 ${isActive ? 'border-gray-600' : ''} ${disable ? 'opacity-50' : ''}`}>
    <StrucRow className={'pl-1 pr-2 py-1 border-b'}>
      <StrucCol className={'!justify-between !flex-row items-center'}>
        <div className={'text-[#1B76DF] flex items-center space-x-2'}>
          <FontAwesomeIcon className={'text-xs px-1.5 py-1 rounded border'} icon={faFile} />
          <span
            className={'max-w-[200px] overflow-x-hidden overflow-ellipsis whitespace-nowrap'}>{logo.logo.title}</span>
          <BinaryStatusGoodBad verdict={!isRaster} goodOption={'Vector'} badOption={'Raster'} />
        </div>
      </StrucCol>
      {placement !== undefined && <StrucCol className={'!flex-row !justify-end'} size={3.5}>
        <BinaryStatusGoodBad verdict={placement} goodOption={'Valid Placement'} badOption={'Invalid Placement'} />
      </StrucCol>}
    </StrucRow>
    <StrucRow>
      <StrucCol size={3.5} className={'bg-white p-3'}>
        <div className={'h-screen flex items-center max-h-[55px] w-fit'}>
          <LogoImage
            logo={logo.logo}
            useHighRes={true}
            className={
              'bg-white rounded hover:border hover:p-2 max-h-[74px] origin-top-left hover:scale-[3] hover:ease-out transition-all duration-150 ease-out'
            }
          />
        </div>
      </StrucCol>
      <StrucCol size={5} className={'pl-2 pt-1 text-xs space-y-1'}>
        <div>
          <span className={'uppercase font-bold'}>Colors</span>
          <div>
            {color}
          </div>
        </div>
        <span><b>B</b> {logo.logo.widthMm} x <b>H</b> {logo.logo.heightMm}</span>
        <span className='font-bold'><LogoTransferMethod logo={logo.logo} /></span>
      </StrucCol>
      <StrucCol className={'pr-2 pt-2 space-y-2'} size={3.5}>
        {buttons}
      </StrucCol>
    </StrucRow>
  </div>
}