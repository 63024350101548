import React, { ReactElement } from 'react'
import { ImageContainer } from '../../../components/image/ImageContainer'
import { DesignView_VM, useDesignViewViewModel } from './DesignView.vm'
import {
  LogoPlacementPosition,
  LogoWithLogoPlacementStatus,
  ViewWithDimensions
} from '../design-style-editor/DesignStyleEditor.vm'
import { LogoMarkerWrapper } from '../logo-marker/LogoMarker.component'
import { DesignLogo } from '../../../../core/models/entities/Design'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleExclamation } from '@fortawesome/free-solid-svg-icons'
import { IdLookUps } from '../../../../utils/models/idLookUp'

interface WrapperProps {
  locked: boolean,
  currentView: ViewWithDimensions,
  logosOfCurrentView: LogoWithLogoPlacementStatus[],
  onLogoChange: (logo: LogoWithLogoPlacementStatus) => void,
  currentLogo?: DesignLogo
  onSelectLogo: (logo: DesignLogo['inDesignId']) => void,
  onInvalid: (id: DesignLogo['inDesignId'], placement: LogoPlacementPosition) => void,
  invalidPlacement: IdLookUps<LogoPlacementPosition>
}


export function DesignViewWrapper({
  locked,
  currentView,
  currentLogo,
  logosOfCurrentView,
  onLogoChange,
  onSelectLogo,
  onInvalid,
  invalidPlacement
}: WrapperProps) {
  const viewModel = useDesignViewViewModel(
    locked,
    currentView!,
    logosOfCurrentView,
    onLogoChange,
    onSelectLogo,
    onInvalid,
    invalidPlacement,
    currentLogo
  )
  return <DesignView {...viewModel} />
}


export function DesignView(
  {
    containerRef,
    viewRef,
    currentView,
    viewSettings,
    onLogoManipulation,
    onSelectLogo,
    currentLogo,
    makers,
    errorMsg,
    locked,
    viewCords,
    onRefSet
  }: DesignView_VM): ReactElement {

  return <div
    ref={containerRef}
    className={`w-full h-full flex justify-center items-center relative `}>
    {errorMsg && <div className={'absolute z-10 top-0 left-0 flex p-1.5 space-x-1 pointer-events-none'}>
      <FontAwesomeIcon className={'text-[20px] text-status-failed-1 p-1.5'} icon={faCircleExclamation} />
      <div className={'bg-[#596675] rounded text-xs text-gray-100 px-2 py-1 max-w-[180px] text-center pointer-events-none'}>
        {errorMsg}
      </div>
    </div>}
    {viewSettings && <ImageContainer
      ref={viewRef}
      onRefSet={onRefSet}
      url={currentView.view.imageUrl}
      style={{ width: viewSettings.width, height: viewSettings.height }}
      className='relative bg-cover shrink-0'>
      {viewCords ? makers.map((value) => (
        <LogoMarkerWrapper
          key={`marker_${value.id}`}
          marker={value}
          isActive={currentLogo?.inDesignId === value.id}
          isLocked={locked}
          onLogoManipulation={onLogoManipulation}
          onSelect={x => onSelectLogo(x)}
          viewCords={viewCords}
        />
      )) : <></>}
    </ImageContainer>}
  </div>
}


