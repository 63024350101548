import React, { createContext, useContext, useState } from 'react'
import { DesignEditorStates } from '../models/enums/design-editor-states.enum'
import { NotImplemented } from '../../../shared/functions/not-implemented.function'
import { MainViewStates } from '../models/enums/main-view-states.enum'
import { SideViewStates } from '../models/enums/side-view-states.enum'

export interface DesignEditorStateProviderContext {
  sidePanelState: SideViewStates,
  mainViewState: MainViewStates
  overall: DesignEditorStates
  goToState: (next: DesignEditorStates) => void,
  isLoading: boolean;
  setLoading: (v: boolean) => void
}

const DesignEditorStateContext = createContext<DesignEditorStateProviderContext | undefined>(undefined)

interface DesignEditorStateProviderProps {
  children: React.ReactNode
}

function DesignEditorStateProvider({
                                     children
                                   }: DesignEditorStateProviderProps): React.ReactElement {
  const [componentState, setComponentState] = useState<DesignEditorStates>(DesignEditorStates.Normal)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const { side, main } = SubcomponentStates(componentState)


  return (
    <DesignEditorStateContext.Provider
      value={{
        sidePanelState: side,
        mainViewState: main,
        goToState: setComponentState,
        overall: componentState,
        isLoading: isLoading,
        setLoading: setIsLoading
      }}>
      {children}
    </DesignEditorStateContext.Provider>
  )
}

function useDesignEditorStateContext() {
  const context = useContext(DesignEditorStateContext)
  if (!context) {
    throw new Error('useRepositoriesContext must be used within a RepositoriesProvider')
  }

  return context
}

function SubcomponentStates(state: DesignEditorStates) {
  switch (state) {
    case DesignEditorStates.Normal:
      return {
        side: SideViewStates.LogoInDesign,
        main: MainViewStates.LogoPlacer
      }
    case DesignEditorStates.UploadLogo:
      return {
        side: SideViewStates.Library,
        main: MainViewStates.LogoPlacer
      }
    case DesignEditorStates.EditLogo:
      return {
        side: SideViewStates.LogoInDesign,
        main: MainViewStates.LogoEditor
      }
    case DesignEditorStates.AddText:
      return {
        side: SideViewStates.TextLogoCreate,
        main: MainViewStates.LogoPlacer
      }
    case DesignEditorStates.AddSymbol:
      return {
        side: SideViewStates.SymbolLogoCreate,
        main: MainViewStates.LogoPlacer
      }
    case DesignEditorStates.AddName:
      return {
        side: SideViewStates.NameLogoCreate,
        main: MainViewStates.LogoPlacer
      }
    case DesignEditorStates.AddNumber:
      return {
        side: SideViewStates.NumberLogoCreate,
        main: MainViewStates.LogoPlacer
      }
    case DesignEditorStates.ViewLibrary:
      return {
        side: SideViewStates.Library,
        main: MainViewStates.LogoPlacer
      }
    case DesignEditorStates.ChangeModel:
      NotImplemented('ChangeModel STATE')
      return {
        side: SideViewStates.LogoInDesign,
        main: MainViewStates.LogoPlacer
      }

  }
}

export { DesignEditorStateProvider, useDesignEditorStateContext, DesignEditorStateContext }