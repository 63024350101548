import { CSSProperties, useState } from "react"
import { Commission, CommissionProfile } from "../../../../core/models/entities/Commission"
import { LogoOptions, TransferLogoType } from "../../../../core/models/entities/LogoOptions"
import { Method } from "../../../../core/models/entities/TransferOptions"
import { copy } from "../../../../utils/functions/ObjectUtil"
import { FlexWrapSpacer, Section, SubSection } from "../../../components/StructureElements/Section"
import { SectionHeader } from "../../my-profile/SectionHeader"
import { SectionHeaderTypography } from "../../../components/Typographies/SectionHeaderTypography"
import { SectionContent } from "../../my-profile/SectionContent"
import { CommissionPicker } from "../../../components/commission/commission-picker.component"
import { ExplainTypography } from "../../../components/Typographies/ExplainTypography"
import { SubSectionHeaderTypography } from "../../../components/Typographies/SubSectionHeaderTypography"
import { CommissionPickerGroup } from "../../../components/commission/commission-picker-group.component"
import { StrucRow } from "../../../components/StructureElements/StrucRow"
import { SecondaryButton } from "../../../components/Buttons/SecondaryButton"
import { useWhiteLabelContext } from "../../../../WhiteLabelContext"

export interface CommissionViewModel {
  commissions?: CommissionProfile
  userType: 'vendor' | 'customer',
  options: CommissionOptions
  onCommissionUpdate: (commission: CommissionProfile) => void,
  logoOptions: LogoOptions
  collapsable?: boolean
}

export interface CommissionOptions {
  types: TransferLogoType[]
  transfers: Method[]
}

export interface MethodCommissions {
  base: Commission,
  sizeMethods: Commission[],
}

export function CommissionEditor(
  {
    commissions: s,
    userType,
    onCommissionUpdate,
    logoOptions,
    options,
    collapsable = false
  }: CommissionViewModel) {
  const commissions = copy(s)
  const [openCommission, setOpenCommission] = useState<boolean>(true)
  const [specific, setSpecific] = useState<boolean>(!!commissions?.overAll)
  const [typeSelectedIndex, setTypeSelectedIndex] = useState<number>(0)

  const methods = gropeMethodCommissions(commissions)
  const types = getTypeOnlyCommissions(commissions)
  const overAll = commissions?.overAll ?? {
    type: 'OverAll',
    unit: '%',
    value: 0.
  } as Commission

  function updateOverAll(commission: Commission) {
    if (!commissions) {
      return;
    }
    commissions.overAll = commission.value !== 0 ? commission : undefined
    onCommissionUpdate(commissions)
  }

  function updateTypeOnly(commission: Commission) {
    if (!commissions) {
      return;
    }

    const found = commissions.typeOnly.find(to => to.typeKey === commission.typeKey)
    if (found) {
      commissions.typeOnly = commissions.typeOnly.filter(to => to.typeKey !== found.typeKey)
      if (commission.value !== 0) {
        commissions.typeOnly.push(commission)
      }
    } else {
      if (commission.value === 0) return;
      commissions.typeOnly.push(commission);
    }
    onCommissionUpdate(commissions)
  }

  function updateMethod(commission: MethodCommissions) {
    if (!commissions) {
      return;
    }
    const methodCommissions = [commission.base, ...commission.sizeMethods]

    for (const methodCommission of methodCommissions) {

      const found = commissions.methods.find(to => to.methodKey === methodCommission.methodKey && to.maxDimensions === methodCommission.maxDimensions)
      if (found) {
        commissions.methods = commissions.methods.filter(to => to.methodKey !== found.methodKey || to.maxDimensions !== found.maxDimensions)
        if (methodCommission.value !== 0) {
          commissions.methods.push(methodCommission)
        }
      } else {
        if (methodCommission.value === 0) continue;
        commissions.methods.push(methodCommission);
      }
    }

    onCommissionUpdate(commissions)
  }

  function getTypeOnlyCommissions(commission?: CommissionProfile): Commission[] {
    const types = options.types
    const typeCommissions: Commission[] = []
    for (const type of types) {
      const found = commission?.typeOnly.find(t => t.typeKey === type.typeKey)
      typeCommissions.push(found ?? {
        type: 'TypeOnly',
        typeKey: type.typeKey,
        value: 0,
        unit: '%',
        maxDimensions: null,
        maxSizeCM2: null,
        maxSize: null,
        methodKey: undefined
      })
    }
    return typeCommissions
  }

  function gropeMethodCommissions(commission?: CommissionProfile): MethodCommissions[] {
    const methodCommissions = commission?.methods ?? []

    const methodCommissionViews: MethodCommissions[] = []

    for (const method of options.transfers) {
      const bases = methodCommissions.find(s => s.methodKey === method.methodKey && !s.maxDimensions)
      const base: Commission = bases ?? {
        value: 0,
        maxDimensions: null,
        methodKey: method.methodKey,
        typeKey: method.type,
        type: 'Method',
        unit: '%',
        maxSize: null,
        maxSizeCM2: null
      }

      const sizeMethods: Commission[] = []
      for (const size of method.options) {
        const methodCommission = methodCommissions.find(s => s.methodKey === method.methodKey && s.maxDimensions === size)
        const s: Commission = methodCommission ?? {
          value: 0,
          maxDimensions: size,
          methodKey: method.methodKey,
          typeKey: method.type,
          type: 'Method',
          unit: '%',
          maxSize: null,
          maxSizeCM2: null
        }

        sizeMethods.push(s)
      }

      methodCommissionViews.push({
        base,
        sizeMethods
      })
    }

    return methodCommissionViews
  }

  const { whiteLabel } = useWhiteLabelContext()

  function getSelectedStyle(selected: boolean): CSSProperties {
    return selected ? {
      backgroundColor: whiteLabel.colorHex,
      color: "#F6FAF6"
    } : {}
  }

  function getStyle(types: Commission[], index: number): string {
    const baseClasses = "rounded-2 font-bold bg-gray-100 border-0 h-8"
    const prependClasses = index == 0 ? "border-r-0" : ""
    const appendClasses = index == (types.length - 1) ? "border-l-0" : ""
    return [baseClasses, prependClasses, appendClasses].join(" ")
  }

  const header = <SectionHeaderTypography>Set Standard Commission</SectionHeaderTypography>
  const content = <>
    <StrucRow>
      <SubSection className={'space-y-2'}>
        <CommissionPicker
          disabled={specific}
          labelText={'overall'}
          commission={overAll}
          onChange={updateOverAll} />
        <div className={'flex items-center space-x-1.5 pl-1'}>
          <input type={'checkbox'} onChange={x => setSpecific(x.target.checked)} checked={specific} />
          <ExplainTypography>{userType == 'customer' ? 'Specify commission for this customer' : 'Specify commission for all customers'}</ExplainTypography>
        </div>
      </SubSection>
    </StrucRow>
    {specific &&
      <>
        <StrucRow>
          <SubSection>
            <SubSectionHeaderTypography>Type</SubSectionHeaderTypography>
            <div className={'flex flex-wrap bg-gray-100 rounded-md'}>
              {
                types.map((type, index) =>
                    <SecondaryButton onClick={() => setTypeSelectedIndex(index)} style={getSelectedStyle(typeSelectedIndex === index)} className={getStyle(types, index)}>{type.typeKey}</SecondaryButton>
                )
              }
            </div>
          </SubSection>
        </StrucRow>
        <StrucRow>
          <SubSection>
            <CommissionPicker
              labelText={`Overall - (${types.find(x => x.typeKey === types[typeSelectedIndex].typeKey)!.typeKey})`}
              commission={types.find(x => x.typeKey === types[typeSelectedIndex].typeKey)!}
              onChange={updateTypeOnly} />
          </SubSection>
        </StrucRow>
        <StrucRow>
          <SubSection>
            <SubSectionHeaderTypography>Method</SubSectionHeaderTypography>
            <div className={'flex flex-wrap'}>
              {
                methods.filter(x => x.base.typeKey == types[typeSelectedIndex].typeKey).map(method =>
                  <FlexWrapSpacer key={method.base.typeKey + "_" + method.base.methodKey}>
                    <CommissionPickerGroup
                      labelText={method.base.methodKey!}
                      commissions={method}
                      method={options.transfers.find(x => x.methodKey === method.base.methodKey)!}
                      updateCommission={updateMethod} />
                  </FlexWrapSpacer>
                )
              }
            </div>
          </SubSection>
        </StrucRow>
      </>
    }
  </>

  if (collapsable) {
    return (
      <Section>
        <SectionHeader isCloseable initialState={openCommission} onToggle={(open) => setOpenCommission(open)}>
          {header}
        </SectionHeader>
        <SectionContent close={!openCommission} overflowYHidden={false}>
          {content}
        </SectionContent>
      </Section>
    )
  }

  return (
    <>
      {header}
      {content}
    </>
  )
}