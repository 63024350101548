import { forwardRef } from 'react'
import { ReactI18NextChild } from 'react-i18next'

export interface Props extends React.HTMLAttributes<HTMLParagraphElement> {
  children: ReactI18NextChild | Iterable<ReactI18NextChild>
}

export const InputLabel = forwardRef<HTMLParagraphElement, Props>((props, ref) => {
  const { children, className, ...rest } = props
  return <p className={`font-medium capitalize text-xs ${className ?? ''}`} {...rest}>{children}</p>
})