import { Manufacturer } from '../../../../core/models/entities/Manufacturer'
import { useState } from 'react'
import { Organization } from '../../../../core/models/entities/Organization'
import { useErrorMessage } from '../../../hooks/useErrorMessage'
import { useCustomerActionHandler } from '../../customer-create/hooks/useCustomerActionHandler'
import { toast } from 'react-toastify'
import { CustomerUpdateForm_VM, OrganizationUpdateForm } from '../component/customer-update-form.component'
import { LogoOptions } from '../../../../core/models/entities/LogoOptions'
import { Commission, CommissionProfile } from '../../../../core/models/entities/Commission'
import { useRepositoriesContext } from '../../../../contexts/RepositoriesContext'


export function useCustomerFormUpdateViewModel(manufacturers: Manufacturer[], customer: Organization, logoOptions: LogoOptions): CustomerUpdateForm_VM {
  const currentManu = manufacturers.filter(m => customer.excludedManufacturers.find(emId => m.id === emId))
  const [form, setForm] = useState<OrganizationUpdateForm>({
    id: customer.id,
    postCode: customer.postCode ?? '',
    address: customer.address ?? '',
    city: customer.city ?? '',
    country: customer.country ?? '',
    cvr: customer.cvr ?? '',
    email: customer.email ?? '',
    contactEmail: customer.contactEmail ?? '',
    phone: customer.phone ?? '',
    contactPhone: customer.contactPhone ?? '',
    contactPerson: customer.contactPerson ?? '',
    name: customer.name,
    excludedManufacturers: currentManu,
    eori: customer.eori ?? '',
    shippingName: customer.shippingName ?? '',
    shippingAddress: customer.shippingAddress ?? '',
    shippingPostCode: customer.shippingPostCode ?? '',
    shippingCity: customer.shippingCity ?? '',
    shippingCountry: customer.shippingCountry ?? '',
    shippingContactPerson: customer.shippingContactPerson ?? '',
    shippingContactPhone: customer.shippingContactPhone ?? '',
    shippingContactEmail: customer.shippingContactEmail ?? ''
  })
  const { handleGoToCustomerList, handleUpdate, isLoading } = useCustomerActionHandler()
  const { tError } = useErrorMessage()

  const { logoRepository, organizationRepository } = useRepositoriesContext()

  const { data } = logoRepository.useTransferOptions(customer.parentId)

  const [commission, setCommission] = useState<CommissionProfile | undefined>(customer.commission)

  function handleCreateClick() {
    const formValid = !isCustomerUpdateFormInvalid(form)
    if (!formValid) {
      toast.error('Form is invalid')
      return
    }

    async function transaction() {
      await handleUpdate({
        ...form,
        isVendor: false,
        excludedManufacturers: form.excludedManufacturers?.map(x => x.id),
        commission: undefined
      } as Organization)
      if (commission) {
        return organizationRepository.setCommissionProfile(customer.id, commission)
      }
      return Promise.resolve()
    }

    transaction()
      .then(() => toast.success('Customer was updated'))
      .catch((e) => tError(e))
  }

  function updateCommission(commission: CommissionProfile): void {
    setCommission(commission)
  }

  function handleCancel() {
    handleGoToCustomerList()
  }

  return {
    onCreate: handleCreateClick,
    onCancel: handleCancel,
    form: form,
    setForm: setForm,
    manufactures: manufacturers,
    isFormValid: !isCustomerUpdateFormInvalid(form),
    isLoading: isLoading,
    updateCommission: updateCommission,
    vendorId: customer.parentId!,
    logoOptions: logoOptions,
    commission: commission ?? { typeOnly: [] as Commission[], methods: [] as Commission[] } as CommissionProfile,
    commissionOptions: {
      types: logoOptions.transferLogoTypes,
      transfers: data?.transferMethods ?? []
    }
  }
}

export function isCustomerUpdateFormInvalid(form: OrganizationUpdateForm): boolean {
  return !form.name
    || !form.cvr
    || !form.phone
    || !form.address
    || !form.email
    || !form.contactEmail
    || !form.contactPerson
    || !form.contactPhone
}