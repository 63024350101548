
import { useState } from 'react'
import { InputLabel } from '../Input/InputLabel'
import { Method } from '../../../core/models/entities/TransferOptions'
import { CommissionDisplay } from './commission-display'
import { useWhiteLabelContext } from '../../../WhiteLabelContext'
import { Commission } from '../../../core/models/entities/Commission'
import { CommissionPicker } from './commission-picker.component'
import { copy } from '../../../utils/functions/ObjectUtil'
import { MethodCommissions } from '../../screens/customer-update/component/commission-editor.component'

interface CommissionPickerGroupProps {
  method: Method
  labelText: string
  disabled?: boolean
  commissions: MethodCommissions
  updateCommission: (commissions: MethodCommissions) => void
}

export function CommissionPickerGroup({ labelText, method, commissions, updateCommission: s }: CommissionPickerGroupProps) {

  const [expanded, setExpanded] = useState(false) // first element is always base elememnt

  const { whiteLabel } = useWhiteLabelContext()
  const [sCom, setSCom] = useState(copy(commissions));

  const updateCommission = (commissions: MethodCommissions) => {
    s(commissions)
  }

  function saveChangesToForm() {
    setExpanded(false)
    updateCommission(sCom);
  }

  function updateBase(commission: Commission) {
    commissions.base = commission
    for (const sized of commissions.sizeMethods) {
      sized.value = 0;
      sized.unit = '%'
    }
    setSCom(commissions)
    updateCommission(commissions)
  }

  function updateSized(commission: Commission) {
    const found = sCom.sizeMethods.find(to => to.methodKey === commission.methodKey && to.maxDimensions === commission.maxDimensions)!
    if (!found) {
      console.warn('impossible code reach')
      return
    }
    found.unit = commission.unit;
    found.value = commission.value;
    setSCom(copy(sCom))
  }

  function updateBaseAsSave(commission: Commission) {
    sCom.base.unit = commission.unit
    sCom.base.value = commission.value
    setSCom(copy(sCom))
  }

  function clear() {
    for (const sized of commissions.sizeMethods) {
      sized.value = 0;
      sized.unit = '%'
    }
    setSCom(commissions);
    updateCommission(commissions)
  }

  function toggleExpand() {
    const next = !expanded
    if (!next) {
      setSCom(copy(commissions));
    }
    setExpanded(next);
  }

  return <div>
    {!expanded
      ? (
        <div className={'relative'}>
          <button onClick={toggleExpand}
            className={`text-status-success absolute top-3 right-1 uppercase z-10 text-xxs rounded font-bold font-medium text-[${whiteLabel.colorHex}]`}>edit sizes
          </button>
          {
            commissions.sizeMethods.filter(x => x.value !== 0).length !== 0
              ? <CommissionDisplay onClick={clear} labelText={labelText}></CommissionDisplay>
              : <CommissionPicker labelText={labelText} commission={sCom.base} onChange={updateBase} />
          }
        </div>
      )
      : (
        <div className={'relative bg-gray-100 w-[180px] h-[47px] rounded-tl rounded-tr z-40'}>
          <button onClick={() => setExpanded(false)}
            className={'absolute top-1 right-1 uppercase z-30 text-white bg-success text-xxs px-1.5 py-1 rounded font-bold font-medium'}>EDITING SIZES
          </button>
          <div className={'px-1.5 pt-1'}><InputLabel>{labelText}</InputLabel></div>
          <div className={'absolute bg-gray-100 rounded-bl rounded-br space-y-1 z-20'}>
            <CommissionPicker labelText={'Base'} commission={sCom.base} onChange={updateBaseAsSave} />
            {sCom.sizeMethods.map(sc => <CommissionPicker key={sc.methodKey! + sc.maxDimensions!} commission={sc} onChange={updateSized} />)}
            <div className={'px-1 py-1'}>
              <button onClick={saveChangesToForm}
                className={'capitalize text-xs text-white bg-success text-xs h-8 py-1 rounded w-full'}>
                Save
              </button>
            </div>
          </div>
        </div>
      )
    }

  </div>
}
