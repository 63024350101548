import React, { JSX } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons'
import { PrimaryButton } from './Buttons/PrimaryButton'
import { SecondaryButton } from './Buttons/SecondaryButton'
import { useConfirmationDialog } from './Dialogs/confirmation-dialog'

interface ConfirmationGuardProps {
  anchor: JSX.Element
  onAccept?: (e?: React.MouseEvent<HTMLElement>) => void
  onCancel?: (e?: React.MouseEvent<HTMLElement>) => void
  text: string
}

export function ConfirmationGuard({ text, anchor, onCancel, onAccept }: ConfirmationGuardProps) {
  const conf = useConfirmationDialog(text, () => {
    if (onAccept) onAccept()
  }, onCancel)

  function Click(e: React.MouseEvent<HTMLElement>) {
    e.stopPropagation()
    conf.open()
  }

  return <div onClick={Click}>
    {conf.component}
    {anchor}
  </div>
}

export function ConfirmationDialog({
                                     onAccept, text, onCancel
                                   }: {
  onAccept: (e: React.MouseEvent<HTMLElement>) => void,
  onCancel: (e: React.MouseEvent<HTMLElement>) => void,
  text: string,
}) {

  return <div className={'flex flex-col space-y-4'}>
    <div className={'flex justify-center'}>
      <FontAwesomeIcon size={'3x'} icon={faTriangleExclamation} />
    </div>
    <p className={'text-center'}>{text}</p>
    <div className={'flex justify-center space-x-4'}>
      <PrimaryButton width={'sm'} onClick={onAccept}>Yes</PrimaryButton>
      <SecondaryButton width={'sm'} className={'button-secondary w-32'} onClick={onCancel}>No</SecondaryButton>
    </div>
  </div>
}