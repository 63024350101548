import { CustomerCreateForm } from '../components/customer-create-form.component'
import { Manufacturer } from '../../../../core/models/entities/Manufacturer'
import { Organization } from '../../../../core/models/entities/Organization'
import { LogoOptions } from '../../../../core/models/entities/LogoOptions'
import { useCustomerCreateFormViewModel } from '../vmHooks/useCustomerCreateFormViewModel'

interface WrapperProps {
  manufacturers: Manufacturer[]
  vendors?: Organization[]
  logoOptions: LogoOptions
}

export function CustomerCreateFormWrapper({ manufacturers, vendors, logoOptions }: WrapperProps) {
  const vm = useCustomerCreateFormViewModel(manufacturers, vendors, logoOptions)
  return <CustomerCreateForm {...vm} />
}

