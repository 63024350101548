import { JetSportBox } from '../../components/BoxView/JetSportBox'
import { BoxHeader2 } from '../../components/BoxView/BoxHeader2'
import { BoxContent } from '../../components/BoxView/BoxContent'
import { BoxFooter } from '../../components/BoxView/BoxFooter'
import { SecondaryButton } from '../../components/Buttons/SecondaryButton'
import { PrimaryButton } from '../../components/Buttons/PrimaryButton'
import { Profile } from '../../../core/models/entities/Profile'
import { EmailPreferenceForm } from './subcomponents/email-preference-editor.component'
import { EmailPreferenceEditorWrapper } from './subcomponents/email-preference-editor.wrapper'
import { WhiteLabelEditorWrapper } from './subcomponents/white-label-editor.wrapper'
import { OrderOptionsEditorWrapper } from './subcomponents/order-options-editor.wrapper'
import { OrderOptionsForm } from './subcomponents/order-options-editor.component'
import { WhiteLabelConfigurationsForm } from './subcomponents/white-label-editor.components'
import { DetailsForm } from '../customer-create/components/organization-details-form.component'
import { OrganizationDetailsEditorWrapper } from './subcomponents/organization-details-editor.wrapper'
import LoadingElement from '../../components/loading-element/loading-element'
import { CommissionFormWithVendor } from '../../components/commission/commission-form-with-vendor.component'
import { LogoOptions } from '../../../core/models/entities/LogoOptions'
import { CommissionProfile } from '../../../core/models/entities/Commission'
import { Role } from '../../../core/models/entities/Role'

export interface MyProfileViewModel {
  saveProfileChanges: () => void
  resetProfile: () => void
  updateLogoHandle: (file: File) => void
  updateWhiteLabel: (form: WhiteLabelConfigurationsForm) => void
  updateOrganizationDetails: (form: DetailsForm) => void
  updateEmailPreferences: (form: EmailPreferenceForm) => void
  updateOrderOptions: (form: OrderOptionsForm) => void
  sections: ProfileSectionConfigurations
  profile: Profile
  detailsForm: DetailsForm
  emailPreferenceForm: EmailPreferenceForm
  loading: boolean
  transition: boolean
  logoOptions?: LogoOptions
  updateCommission?: (commission: CommissionProfile) => void
  commissionProfile?: CommissionProfile
  vendorId?: number
  isVendor: boolean
  isAdmin: boolean
}

export interface ProfileSectionConfigurations {
  details?: SectionConditions
  whiteLabelling?: SectionConditions
  emailPreferences?: SectionConditions
  commissions?: SectionConditions
  orderOptions?: SectionConditions
}

export interface SectionConditions {
  show: boolean
  canEdit: boolean
}

export function MyProfile(
  {
    saveProfileChanges,
    resetProfile,
    updateLogoHandle,
    updateWhiteLabel,
    updateOrganizationDetails,
    updateEmailPreferences,
    updateOrderOptions,
    sections,
    profile,
    detailsForm,
    emailPreferenceForm,
    commissionProfile,
    logoOptions,
    updateCommission,
    vendorId,
    transition,
    isVendor,
    isAdmin
  }: MyProfileViewModel) {

  const role = profile.userOrganizationInformations.role

  return <JetSportBox>
    {transition && <LoadingElement />}
    <BoxHeader2 className='px-10'>My Profile</BoxHeader2>
    <BoxContent>
      {sections.details?.show && <div className='px-2'><OrganizationDetailsEditorWrapper
        initialForm={detailsForm!}
        onFormChange={updateOrganizationDetails}
        disabled={!sections.details?.canEdit}
      /></div>}

      {sections.whiteLabelling?.show && <WhiteLabelEditorWrapper
        profile={profile!}
        onFormChange={updateWhiteLabel}
        onLogoChange={updateLogoHandle}
        disabled={!sections.whiteLabelling?.canEdit}
        initialState={true} />}

      {sections.emailPreferences?.show && <EmailPreferenceEditorWrapper
        initialForm={emailPreferenceForm!}
        onFormChange={updateEmailPreferences}
        disabled={!sections.emailPreferences?.canEdit}
        initialState={true}
      />}

      {sections.orderOptions?.show && <OrderOptionsEditorWrapper
        profile={profile!}
        onFormChange={updateOrderOptions}
        disabled={!sections.orderOptions?.canEdit}
        initialState={true} />}
        
      {(role === Role.Vendor) && <CommissionFormWithVendor updateCommissions={updateCommission!} logoOptions={logoOptions!}
                                            commissionProfile={commissionProfile!} userType={isVendor ? 'vendor' : 'customer'}
                                            vendorId={vendorId!} />}
    </BoxContent>
    <BoxFooter className={'justify-end'}>
      <SecondaryButton onClick={resetProfile}>Cancel</SecondaryButton>
      <PrimaryButton onClick={saveProfileChanges}>Save Changes</PrimaryButton>
    </BoxFooter>
  </JetSportBox>
}
