import { PropsWithChildren, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPaperPlane } from '@fortawesome/free-regular-svg-icons'
import { BoxContent } from '../../../components/BoxView/BoxContent'
import { BoxFooter } from '../../../components/BoxView/BoxFooter'
import LoadingElement from '../../../components/loading-element/loading-element'
import { BoxHeader2 } from '../../../components/BoxView/BoxHeader2'
import { JetSportBox } from '../../../components/BoxView/JetSportBox'
import { SecondaryButton, WarnButton } from '../../../components/Buttons/SecondaryButton'
import { CustomTextArea } from '../../../components/Input/CustomInput'
import { PrimaryButton } from '../../../components/Buttons/PrimaryButton'

enum ExternalApprovalComponentState {
  Verdict,
  Rejection,
  Loading,
  End
}

interface ExternalApprovalFlowProps extends PropsWithChildren {
  placeVerdict: (approval: boolean, rejectionNote?: string) => Promise<any>
  subject: string
}

export function ExternalApprovalFlow({ placeVerdict, children, subject }: ExternalApprovalFlowProps) {
  const [state, setState] = useState<ExternalApprovalComponentState>(ExternalApprovalComponentState.Verdict)
  const [rejectionNote, setRejectionNote] = useState<string>('')

  function handleLeaveSite() {
    window.close()
  }

  function handleReject() {
    setState(ExternalApprovalComponentState.Rejection)
  }

  function handleRejectConfirm() {
    setState(ExternalApprovalComponentState.Loading)
    placeVerdict(false, rejectionNote).then(() => {
      setState(ExternalApprovalComponentState.End)
    })
  }

  function handleRejectConfirmSkip() {
    setState(ExternalApprovalComponentState.Loading)
    placeVerdict(false).then(() => {
      setState(ExternalApprovalComponentState.End)
    })
  }

  function handleApprove() {
    setState(ExternalApprovalComponentState.Loading)
    placeVerdict(true).then(() => {
      setState(ExternalApprovalComponentState.End)
    })
  }

  if (state === ExternalApprovalComponentState.Rejection) {
    return <RejectFrame onSkip={handleRejectConfirmSkip} onRejectWithNote={handleRejectConfirm}
                        rejectionNote={rejectionNote} setRejectionNote={setRejectionNote} />
  }

  if (state === ExternalApprovalComponentState.End) {
    return <ThankYouFrame onLeaveSite={handleLeaveSite} />
  }

  if (state === ExternalApprovalComponentState.Loading) {
    return <LoadingFrame />
  }

  return <ApprovalFrame subject={subject} onReject={handleReject}
                        onAccept={handleApprove}>
    {children}
  </ApprovalFrame>
}

interface RejectFrameProp {
  rejectionNote: string,
  setRejectionNote: (note: string) => void
  onSkip: () => void,
  onRejectWithNote: () => void
}

function RejectFrame({ setRejectionNote, onRejectWithNote, rejectionNote, onSkip }: RejectFrameProp) {
  return <JetSportBox>
    <BoxHeader2 />
    <BoxContent className={'items-center text-center pt-28 justify-between pb-32'}>
      <div className={'space-y-8 w-[500px]'}>
        <span className={'text-[32px]'}>Please state your reason for Rejecting</span><br />
        <CustomTextArea className={'h-[250px]'} value={rejectionNote}
                        onChange={x => setRejectionNote(x.target.value)} />
      </div>
      <div className={'flex space-x-8'}>
        <WarnButton width={'md'} onClick={onRejectWithNote}>Reject</WarnButton>
        <SecondaryButton width={'md'} onClick={onSkip}>Skip</SecondaryButton>
      </div>
    </BoxContent>
    <BoxFooter />
  </JetSportBox>
}

export function LoadingFrame() {
  return <JetSportBox>
    <BoxHeader2 />
    <BoxContent><LoadingElement className={'rounded'} /></BoxContent>
    <BoxFooter />
  </JetSportBox>
}

interface ApprovalFrameProps extends PropsWithChildren {
  onAccept: () => void
  onReject: () => void
  subject: string
}

function ApprovalFrame({ children, onReject, onAccept, subject }: ApprovalFrameProps) {
  return <JetSportBox>
    <BoxHeader2 />
    <BoxContent className={'items-center text-center pt-44 justify-between pb-32'}>
      <span className={'text-[32px]'}>Do you approve of this {subject}?</span>
      <div className={'flex-1 items-center flex justify-center'}>
        {children}
      </div>
      <div className={'flex space-x-8'}>
        <PrimaryButton width={'md'} onClick={onAccept}>Accept</PrimaryButton>
        <SecondaryButton width={'md'} onClick={onReject}>Reject</SecondaryButton>
      </div>
    </BoxContent>
    <BoxFooter />
  </JetSportBox>
}

interface ThankYouProps {
  onLeaveSite: () => void
}

function ThankYouFrame({ onLeaveSite }: ThankYouProps) {
  return <JetSportBox>
    <BoxHeader2 />
    <BoxContent className={'items-center text-center pt-44 justify-between pb-32'}>
      <div className={'flex flex-col space-y-8'}>
        <FontAwesomeIcon size={'5x'} className={'text-status-success'} icon={faPaperPlane} />
        <span>
        <span className={'text-[32px]'}>Thank you for your time!</span>
          <br />
          <span className={'text-[18px]'}>Your verdict will be sent to owner</span>
        </span>
      </div>
      {/*<PrimaryButton onClick={onLeaveSite}>
        Leave site
      </PrimaryButton>*/}
    </BoxContent>
  </JetSportBox>
}