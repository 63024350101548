import { useCartContext } from '../../../../contexts/CartContext'
import { getProductPrice } from '../../../../core/models/entities/BaseLogo'
import { PriceLine, PurePriceLine } from '../../../components/Logo/LogoPrices'
import { CartItemLogo } from '../../order-create/models/CartItem'

interface Props {
  no: string
  cartItems: CartItemLogo
}

export function CartListLogoItem({ no, cartItems: orderline }: Props): JSX.Element {

  const prices = orderline.baseLogo.product?.prices ?? []
  const { getLogoAdjustedPrice } = useCartContext()

  const quantity = orderline.toDelivery + orderline.toStorage
  const pricePerPience = getProductPrice(prices, 1)
  const qtyPrice = getLogoAdjustedPrice(orderline.baseLogo.id)
  const total = quantity * qtyPrice;

  const additionalFees = (orderline.baseLogo.product?.additionalFees ?? []).map((fee, i) => {
    return (
      <tr className='min-h-36 h-14' key={`${fee.itemNo}_${i}`}>
        {<td className='text-left'>{no}-{i + 1}</td>}
        {<td className='text-left'></td>}
        {<td className='text-left'>{fee.itemNo}</td>}
        {<td className='text-left'>{fee.name}</td>}
        {<td className='text-left'></td>}
        {<td className='text-right'>{PurePriceLine({ price: fee.unitPrice, excludeQuantity: true })}</td>}
        {<td className='text-right'>{fee.quantity}</td>}
        {<td className='text-right'>{PurePriceLine({ price: fee.unitPrice, excludeQuantity: true })}</td>}
        {<td className='text-right'>{PurePriceLine({ price: fee.price, excludeQuantity: true })}</td>}
      </tr>)
  })

  return (
    <>
      <tr>
        <td className='text-left'>{no}</td>
        <td className='text-left'>
          <img
            src={orderline.displayImage.lowResUrl}
            style={{
              maxHeight: 60,
              maxWidth: 60
            }}
          />
        </td>
        <td className='text-left'>{orderline.id}</td>
        <td className='text-left'>{orderline.title}</td>
        <td className='text-left'>{orderline.baseLogo.type}</td>
        <td className='text-right'>{PriceLine({ price: pricePerPience, excludeQuantity: true })}</td>
        <td className='text-right'>{quantity}</td>
        <td className='text-right'>{PurePriceLine({ price: qtyPrice, excludeQuantity: true })}</td>
        <td className='text-right'>{PurePriceLine({ price: total, excludeQuantity: true })}</td>
      </tr>
      {additionalFees}
    </>
  )
}
