import { JSX } from 'react'
import { useRepositoriesContext } from '../../../../contexts/RepositoriesContext'
import LoadingElement from '../../../components/loading-element/loading-element'
import { Design } from '../../../../core/models/entities/Design'
import { ErrorView } from '../../../components/error-view/ErrorView'
import { DesignDetails, DesignModalIntent } from '../components/design-details.component'
import {
  ComponentState,
  useComponentStateMachine
} from '../../../shared/hooks/useComponentStateMachine'

interface WrapperProps {
  design: Design
  onClose: () => void
  onShowRejectionNote: (design: Design) => void
  closeDialog: () => void
  intent: DesignModalIntent
}

export function DesignDetailsWrapper({
  design,
  onClose,
  onShowRejectionNote,
  closeDialog,
  intent
}: WrapperProps): JSX.Element {
  const { designRepository } = useRepositoriesContext()
  const {
    data: summary,
    isLoading: isSummaryLoading,
    isSuccess: isSummarySuccess,
    isError: isSummaryError
  } = designRepository.useDesign(design.customer.id, design.id)
  const { state, dataTransition } = useComponentStateMachine()

  dataTransition(isSummaryLoading, isSummarySuccess, isSummaryError)

  return state === ComponentState.Succeed ? (
    <DesignDetails
      onShowRejectionNote={onShowRejectionNote}
      design={summary!}
      onClose={onClose}
      closeDialog={closeDialog}
      intent={intent}
    />
  ) : state === ComponentState.Loading ? (
    <div className={'w-220 h-128 relative'}>
      <LoadingElement />
    </div>
  ) : (
    <div className={'w-220 h-128 relative'}>
      <ErrorView />
    </div>
  )
}
