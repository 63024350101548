import React, { forwardRef } from 'react'
import { BaseLogo, ImageLogo, LogoType } from '../../../core/models/entities/BaseLogo'

interface LogoImageProp extends React.ImgHTMLAttributes<HTMLImageElement> {
  logo: BaseLogo
  useHighRes?: boolean
}
export const LogoImage = forwardRef<HTMLImageElement, LogoImageProp>((props, ref) => {
  const { logo, useHighRes, ...rest } = props
  if (logo.logoType === LogoType.ImageLogo) {
    const imageLogo = logo as ImageLogo
    rest.src = imageLogo.colorChange
      ? 'data:image/jpeg;base64,' + imageLogo.colorChange.temporaryImageBase64
      : useHighRes
        ? logo.displayImage.highResUrl
        : logo.displayImage.lowResUrl
  } else {
    rest.src = useHighRes ? logo.displayImage.highResUrl : logo.displayImage.lowResUrl
  }
  rest.alt = logo.title
  return <img {...rest} />
})