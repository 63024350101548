import React from 'react'
import { BlockBase } from './ColorBlock'

function DigitalColorBlock({ size = 'md' }: { size?: 'sm' | 'md' }) {
  const background = 'conic-gradient(from 180deg at 50% 50%, #F00 14.602730423212051deg, #F90 58.12499821186066deg, #FAFF00 121.87499642372131deg, #00FF38 170.6250035762787deg, #00FFF0 230.625deg, #0500FF 288.7499928474426deg, #DB00FF 341.2500071525574deg)'

  return <BlockBase size={size} background={background} />
}


export default DigitalColorBlock