import React, { JSX, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCloudArrowUp, faFileArrowDown } from '@fortawesome/free-solid-svg-icons'
import { extensionsToMimeTypesString } from './ExtensionsToMimeTypesString'
import { LogoOptions } from '../../../../core/models/entities/LogoOptions'

interface UploadIconProps {
  onUploadImage: (image: File) => void;
  options: LogoOptions
}

export function UploadLogo({ onUploadImage, options }: UploadIconProps): JSX.Element {

  // drag state
  const [dragActive, setDragActive] = useState(false)
  const inputRef = React.useRef<HTMLInputElement>(null)


  // handle drag events
  function handleDrag(e: React.DragEvent<HTMLDivElement | HTMLFormElement>) {
    e.preventDefault()
    e.stopPropagation()
    if (e.type === 'dragenter' || e.type === 'dragover') {
      setDragActive(true)
    } else if (e.type === 'dragleave') {
      setDragActive(false)
    }
  }

  function handleDrop(e: React.DragEvent<HTMLDivElement | HTMLFormElement>) {
    e.preventDefault()
    e.stopPropagation()
    setDragActive(false)
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      // at least one file has been dropped so do something
      handleFiles(e.dataTransfer.files[0])
    }
  }

  function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    e.preventDefault()
    if (e.target.files && e.target.files[0]) {
      // at least one file has been selected so do something
      handleFiles(e.target.files[0])
    }
  }

  function onUploadClick() {
    inputRef.current?.click()
  }

  function handleFiles(file: File) {
    onUploadImage(file)
  }

  return (
    <div className={'h-full'}>
      <div className={'bg-gray-100 space-y-2 py-2 px-3 rounded-tr-md'}>
        <div className={'flex h-9 items-center'}>
          <h2 className={'flex-1 font-bold text-gray-600'}>Upload Logo</h2>
        </div>
      </div>
      <div className={`flex relative items-center justify-center h-full`} onDragEnter={handleDrag}>
        <input ref={inputRef} className={'hidden'} type='file' id='input-file-upload' onChange={handleChange}
               accept={extensionsToMimeTypesString(options.extensions)} />
        <div className={'space-y-3 flex flex-col'}>
          <FontAwesomeIcon icon={faCloudArrowUp} size={'2x'} className={'mx-auto '} />
          <p className={'italic font-light text-xs'}>Drag and drop or</p>
          <button onClick={onUploadClick}
                  className='mx-auto upload-button hover:underline italic font-light text-sm'>Upload logo
          </button>
        </div>
        {dragActive &&
          (
            <>
              <div
                className={'z-10 absolute w-full h-full top-0 bottom-0 left-0 right-0'}
                onDragEnter={handleDrag}
                onDragLeave={handleDrag}
                onDragOver={handleDrag}
                onDrop={handleDrop}>
              </div>
              <div id={'drop-overlay'}
                   className={'animate-fadeInFast bg-gray-200 absolute w-full h-full top-0 bottom-0 left-0 right-0 flex justify-center items-center'}>
                <div className={'space-y-3 flex flex-col h-5 mt-[-60px]'}>
                  <FontAwesomeIcon icon={faFileArrowDown} size={'2x'} className={'mx-auto '} />
                  <p className={'italic font-light text-xs'}>Drop image here</p>
                </div>
              </div>
            </>
          )
        }
      </div>
    </div>
  )

}