import { nameOf } from '../../../../utils/functions/ReflectionUtil'
import { Organization } from '../../../../core/models/entities/Organization'

// @ts-ignore
export const vendorColumnsConfig: ColumnField[] = [
  {
    value: nameOf<Organization>(x => x.name),
    displayValue: 'Vendor',
    weight: 1.5,
    canSortBy: true
  },
  {
    value: nameOf<Organization>(x => x.contactPerson),
    displayValue: 'CONTACT PERSON',
    weight: 1.5,
    canSortBy: false
  },
  {
    value: nameOf<Organization>(x => x.email),
    displayValue: 'EMAIL',
    weight: 1.5,
    canSortBy: true
  },

  {
    value: nameOf<Organization>(x => x.phone),
    displayValue: 'PHONE',
    weight: 0,
    canSortBy: true
  },

  {
    value: nameOf<Organization>(x => x.totalOrderValue),
    displayValue: 'TOTAL ORDER VALUE',
    weight: 1.5,
    canSortBy: false
  },

  {
    value: nameOf<Organization>(x => x.lastOrder),
    displayValue: 'Last order',
    weight: 1.5,
    canSortBy: false
  },
  {
    value: 'actions',
    displayValue: 'Action',
    weight: 1.2,
    canSortBy: false
  }
]