import { Manufacturer } from '../../../../core/models/entities/Manufacturer'
import { useDesignStyleUpdateFormViewModel } from '../vmHooks/useDesignChangeStyleFormViewModel'
import DesignChangeStyleForm from '../components/design-change-style-form.component'
import { Design } from '../../../../core/models/entities/Design'

interface WrapperProps {
  manufacturers: Manufacturer[]
  design: Design
}

export function DesignChangeStyleFormWrapper({ manufacturers, design }: WrapperProps) {
  const vm = useDesignStyleUpdateFormViewModel(manufacturers, design)
  return <DesignChangeStyleForm {...vm} />
}