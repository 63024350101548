import { DesignLogo } from '../../../../core/models/entities/Design'
import { ScaledViewInfo } from '../../design-editor/design-view/DesignView.vm'
import { Marker } from '../models/marker.model'

export function toMarker(designLogo: DesignLogo, viewSettings: ScaledViewInfo): Marker {
  return {
    x: designLogo.logoX * viewSettings.styleToViewSizeDelta,
    y: designLogo.logoY * viewSettings.styleToViewSizeDelta,
    heightPx: designLogo.heightMm * (viewSettings.ppcm / 10),
    widthPx: designLogo.widthMm * (viewSettings.ppcm / 10),
    id: designLogo.inDesignId,
    rotationRad: designLogo.angleRads,
    xRect: 0,
    yRect: 0,
    imageUrl: designLogo.logo.displayImage.highResUrl,
    viewId: designLogo.viewId,
    logo: designLogo.logo
  }
}