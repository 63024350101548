import { OrganizationDetailForm, DetailsForm } from '../components/organization-details-form.component'
import { useOrganizationDetailFormCreateViewModel } from '../vmHooks/useOrganizationDetailFormCreateViewModel'

interface WrapperProps {
  form: DetailsForm,
  onFormChange: (form: DetailsForm) => void
}

export function OrganizationDetailFormCreateWrapper({ form, onFormChange }: WrapperProps) {
  const vm = useOrganizationDetailFormCreateViewModel(form, onFormChange)
  return <OrganizationDetailForm {...vm} />
}

