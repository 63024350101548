import React from 'react'

export const Section = React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement>>((props, ref) => {
  const { className, ...rest } = props
  return <div ref={ref} className={`${className ? className : ''}`} {...rest}>{rest.children}</div>
})

export const SubSection = React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement>>((props, ref) => {
  const { className, ...rest } = props
  return <div ref={ref} className={`py-2 ${className ? className : ''}`} {...rest}>{rest.children}</div>
})

export const FlexWrapSpacer = React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement>>((props, ref) => {
  const { className, ...rest } = props
  return <div ref={ref} className={`mb-2 mr-2.5 ${className ?? ''}`} {...rest}>{rest.children}</div>
})