import { useRepositoriesContext } from '../../../contexts/RepositoriesContext'
import { useParams } from 'react-router-dom'
import { LoadingView } from '../../components/loading-view/LoadingView'
import { ComponentState, useComponentStateMachine } from '../../shared/hooks/useComponentStateMachine'
import { ErrorView } from '../../components/error-view/ErrorView'
import { CustomerFormUpdateWrapper } from './wrappers/customer-form-update.wrapper'

export function CustomerUpdateScreen() {
  const params = useParams()
  const id = params?.customerId
  return id ? <Found customerId={+id} />
    : <ErrorView />
}

function Found({ customerId }: { customerId: number }) {
  const { state, dataTransition } = useComponentStateMachine()
  const { manufacturerRepository, organizationRepository, logoRepository } = useRepositoriesContext()
  const { data: logoOptions, isLoading: isLogoOptionsLoading, isError: isLogoOptionsError, isSuccess: isLogoOptionsSuccess } = logoRepository.useOptions();
  const {
    data,
    isError: isManufacturersError,
    isLoading: isManufacturersLoading,
    isSuccess: isManufacturersSuccess
  } = manufacturerRepository.useManufacturers()

  const {
    data: organization,
    isLoading: isOrganizationLoading,
    isSuccess: isOrganizationSuccess,
    isError: isOrganizationError
  } = organizationRepository.useCustomer(customerId)

  const isLoading = isManufacturersLoading || isOrganizationLoading || isLogoOptionsLoading
  const isSuccess = isOrganizationSuccess && isManufacturersSuccess && isLogoOptionsSuccess
  const isError = isOrganizationError || isManufacturersError || isLogoOptionsError

  dataTransition(isLoading, isSuccess, isError)
  return state === ComponentState.Succeed
    ? <CustomerFormUpdateWrapper manufacturers={data!} customer={organization!} logoOptions={logoOptions!} />
    : state === ComponentState.Loading
      ? <LoadingView />
      : <ErrorView />
}