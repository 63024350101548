import { Order, OrderStatus } from "../../../../core/models/entities/Order"
import { ApprovalDisplay } from "../../../components/Approval/ApprovalDisplay"

interface Props {
  order: Order
}

export function OrderStatusElement({ order }: Props): JSX.Element {
  switch (order.status) {
    case OrderStatus.Unknown:
      return <p className={`mb-1 font-semibold text-xs text-gray-600`}>Unknown</p>
    case OrderStatus.Draft:
      return <p className={`mb-1 font-semibold text-xs text-orange-400`}>Draft</p>
    case OrderStatus.Pending:
      return <ApprovalDisplay approval={order.approval!} />
    case OrderStatus.Approved:
      return <p className={`mb-1 font-semibold text-xs text-status-success`}>Approved</p>
    case OrderStatus.Rejected:
      return <ApprovalDisplay approval={order.approval!} />
    case OrderStatus.InProduction:
      return <p className={`mb-1 font-semibold text-xs text-status-success`}>In Production</p>

  }

  return <></>
}
