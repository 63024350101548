import React from 'react'

interface StrucColProps extends React.HTMLAttributes<HTMLDivElement> {
  size?: number
  offset?: number
}

export function StrucCol(
  {
    className,
    children,
    size,
    offset,
    ...rest
  }: StrucColProps
) {
  const flex = size ? `0 0 calc(calc(${size} / 12) * 100%)` : '1'
  const width = size ? `calc(calc(${size} /  12) * 100%)` : ''
  const maxWidth = size ? `calc(calc(${size} / 12) * 100%)` : ''
  const marginLeft = offset ? `calc(calc(${offset} / var(--ion-grid-columns, 12)) * 100%)` : ''

  return (
    <div
      style={{
        flex,
        width,
        maxWidth,
        marginLeft,
        justifyContent: 'inherit'
      }}
      {...rest}
      className={`flex h-auto flex-col ${className ? className : ''}`}>
      {children}
    </div>
  )
}