import { useRepositoriesContext } from '../../../contexts/RepositoriesContext'
import { Manufacturer } from '../../../core/models/entities/Manufacturer'

export function useStyleCatalogUploadViewModel() {
  const { manufacturerRepository, styleRepository } = useRepositoriesContext()

  const {
    data: manufacturers,
    isLoading,
    isSuccess,
    refetch
  } = manufacturerRepository.useManufacturers()

  async function createManufacturer(name: string): Promise<Manufacturer> {
    const manu = await manufacturerRepository.createManufacturer(name)
    await refetch()
    return manu
  }

  return {
    manufacturers,
    isLoading,
    isSuccess,
    uploadCatalog: styleRepository.uploadCatalog,
    createManufacturer: createManufacturer
  }
}
