import { AuthenticatedTemplate } from '@azure/msal-react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import { Role } from '../../../../core/models/entities/Role'
import NavigationBarLink from './NavigationBarLink'
import { useNavigationBarViewModel } from '../view-models/useNavigationBarViewModel'
import { useProfileContext } from '../../../../contexts/ProfileContext'
import Brand from '../../../components/customer-brand/Brand'
import { LoggedInMenu } from './LoggedInMenu'
import { CartNavMenu } from '../../../components/Cart/Cart-Icon/CartNavMenu'
import { useWhiteLabelContext } from '../../../../WhiteLabelContext'
import { linkFormatter } from '../../../../utils/functions/FormatHyperLink'

export function NavigationBar(): JSX.Element {
  const { userRole, logout } = useNavigationBarViewModel()
  const { t } = useTranslation()
  const location = useLocation()

  const { profile } = useProfileContext()
  const { whiteLabel } = useWhiteLabelContext();

  var formattedLink = linkFormatter(whiteLabel?.whiteLabelWebsiteLink)

  // Render
  return (
    <nav className='w-full bg-white border-b border-gray-200 px-8'>
      <div className='max-w-[90rem] h-7 w-full mx-auto flex justify-between items-center gap-x-10 font-[700] uppercase text-xxs color-[#596675]'>
        <span className='flex-1'>{whiteLabel?.whiteLabelVendorName}</span>
        <span className='flex-0'>
          <a style={{ color: whiteLabel?.colorHex }} target="_blank" rel="noreferrer noopener" href={formattedLink}>{whiteLabel?.whiteLabelWebsiteLink}</a>
        </span>
        <span className="flex-1 text-right space-x-4">
          <span><a href={'mailto:' + whiteLabel?.whiteLabelContactEmail}>{whiteLabel?.whiteLabelContactEmail}</a></span>
          <span><a href={'tel:' + whiteLabel?.whiteLabelContactPhone}>{whiteLabel?.whiteLabelContactPhone}</a></span>
        </span>
      </div>
      <div className='max-w-[90rem] h-16 w-full mx-auto flex items-center gap-x-10'>
        <Brand profile={profile!} />
        <div className='w-full h-full flex justify-between'>
          <div className='h-full flex flex-1'>
            {userRole !== undefined && (
              <AuthenticatedTemplate>
                {(userRole === Role.Admin || userRole === Role.Vendor || userRole === Role.EndUser) && (
                  <NavigationBarLink selected={location.pathname.includes('/design')} to='/design'>
                    {t('designs')}
                  </NavigationBarLink>
                )}
                {(userRole === Role.Admin || userRole === Role.Vendor || userRole === Role.EndUser) && (
                  <NavigationBarLink selected={location.pathname.includes('/order')} to='/order'>
                    {t('orders')}
                  </NavigationBarLink>
                )}
                {(userRole === Role.Admin || userRole === Role.Vendor || userRole === Role.EndUser) && (
                  <NavigationBarLink selected={location.pathname.includes('/logo')} to='/logo'>
                    {t('logos')}
                  </NavigationBarLink>
                )}
                {userRole === Role.Admin && (
                  <NavigationBarLink
                    selected={location.pathname.includes('/vendor')}
                    to='/vendor'>
                    {t('Vendors')}
                  </NavigationBarLink>
                )}
                {(userRole === Role.Admin || userRole === Role.Vendor) && (
                  <NavigationBarLink
                    selected={location.pathname.includes('/customer')}
                    to='/customer'>
                    {t('customers')}
                  </NavigationBarLink>
                )}
                {userRole === Role.Admin && (
                  <NavigationBarLink
                    selected={location.pathname.includes('/style')}
                    to='/style'>
                    {t('styles')}
                  </NavigationBarLink>
                )}

              </AuthenticatedTemplate>
            )}
          </div>
          <CartNavMenu />
          <LoggedInMenu logout={logout} />
        </div>
      </div>
    </nav>
  )
}