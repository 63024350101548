import React from 'react'
import { SectionHeaderTypography } from '../../../components/Typographies/SectionHeaderTypography'
import { Section } from '../../../components/StructureElements/Section'
import { ExplainTypography } from '../../../components/Typographies/ExplainTypography'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faXmark } from '@fortawesome/free-solid-svg-icons'
import { Manufacturer } from '../../../../core/models/entities/Manufacturer'
import { ManufacturerAutoComplete } from '../../../components/autocompletes/manufacturer-auto.complete'

export interface BrandExclusionForm_VM {
  manufacturers: Manufacturer[]
  onExclude: (manufacturer: Manufacturer) => void
  onReinclude: (manufacturer: Manufacturer) => void,
  form: OrganizationBrandExclusionForm
}

export interface OrganizationBrandExclusionForm {
  excludedManufacturers: Manufacturer[]
}

export function BrandExclusionForm({ onExclude, manufacturers, onReinclude, form }: BrandExclusionForm_VM) {

  function handleExclude(manufacturer: Manufacturer | null) {
    if (!manufacturer) {
      return
    }
    onExclude(manufacturer)
  }

  return <Section className={'space-y-2.5'}>
    <SectionHeaderTypography>Brands</SectionHeaderTypography>
    <ExplainTypography>EXCLUDE SELECTED BRANDS FOR CUSTOMER</ExplainTypography>
    <div className={'w-72'}>
      <ManufacturerAutoComplete manufacturers={manufacturers} onSelected={handleExclude} />
    </div>
    <ExcludedBrandsList excluded={form.excludedManufacturers} onReincludeBrand={onReinclude} />
  </Section>
}


export function ExcludedBrandsList({ excluded, onReincludeBrand }: {
  excluded: Manufacturer[] | undefined,
  onReincludeBrand: (brand: Manufacturer) => void
}) {
  const excludedChips = excluded?.map(b => <ExcludedBrandChip key={b.id} brand={b} onClose={onReincludeBrand} />)
  return <div className={'flex flex-wrap'}>
    {excludedChips}
  </div>
}

export function ExcludedBrandChip({ brand, onClose }: { brand: Manufacturer, onClose: (brand: Manufacturer) => void }) {
  return <div className={'text-xs border rounded bg-gray-100 flex space-x-1 px-1.5 py-1 mr-2.5 mb-2'}>
    <button onClick={() => onClose(brand)}><FontAwesomeIcon icon={faXmark} /></button>
    <p>{brand.name}</p
    ></div>
}
