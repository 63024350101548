import { JSX, useState } from 'react'
import Column, { ColumnField } from './Column'
import SortByFieldEvent from '../Models/sortByFieldEvent'
import { PageQuery } from '../../../../utils/models/pageQuery'
import { StrucCol } from '../../StructureElements/StrucCol'
import { StrucRow } from '../../StructureElements/StrucRow'

interface ColumnHeaderProps {
  columns: ColumnField[];
  onNewSort: (sortByFiled: SortByFieldEvent) => void
  multiSelect: boolean;
  initialQuery: PageQuery;
}

function ColumnHeader({ columns, onNewSort, multiSelect, initialQuery }: ColumnHeaderProps): JSX.Element {
  const [sortByFiled, setSortByFiled] = useState<SortByFieldEvent>({
    value: initialQuery.sortBy?.field ?? '',
    sortDirection: initialQuery.sortBy?.sortDirection
  })
  const columnsElements = columns.filter(x => !x.hide).map((c, i) => <StrucCol size={c.weight} key={c.value}><Column
    sortByField={sortByFiled} column={c} setSortByField={setSortByFiled} onNewSort={onNewSort} /></StrucCol>)
  return (
    <StrucRow className={`!items-center h-10 ${!multiSelect ? 'pl-6' : ''}`}>
      {columnsElements}
    </StrucRow>
  )
}

export default ColumnHeader