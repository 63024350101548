import { OrderLinesListItem } from './OrderLinesListItem'
import { Closeable } from '../../../components/Buttons/Closeable'
import { OrderLineAdd } from './OrderLineAdd'
import { PrimaryButton } from '../../../components/Buttons/PrimaryButton'
import { CartItem } from '../models/CartItem'

interface OrderLinesListProps {
  orderLines: CartItem[]
  onAddOrderLine: () => void
  onRemoveOrderLine: (orderLine: CartItem) => void
  onSelectOrderLine: (orderLine: CartItem) => void
  handleContinue: () => void
  isValid: boolean
}

export function OrderLinesList({ orderLines, onRemoveOrderLine, onSelectOrderLine, onAddOrderLine, handleContinue, isValid }: OrderLinesListProps) {

  const orderLineList = orderLines.map((orderLine, index) => 
    <Closeable key={index} onClose={() => onRemoveOrderLine(orderLine)}>
      <OrderLinesListItem orderLine={orderLine} onClick={() => onSelectOrderLine(orderLine)} />
    </Closeable>
  )

  return <div>
    <p className={'uppercase text-sm font-semibold pb-1.5'}>CURRENT ORDER PRODUCTS</p>
    <div className={'flex'}>
      <div className={'flex space-x-1.5 overflow-x-auto overflow-y-hidden pt-1.5 mt-[-6px] pb-1 my-1 relative pr-1.5'}>
        {orderLineList}
      </div>
      <OrderLineAdd onClick={onAddOrderLine} />
      <div className={'flex-1'}></div>
      <div className={'my-auto pb-1 pl-2 flex flex-col space-y-2 mt-[-15px]'}>
        <PrimaryButton width={'md'} onClick={handleContinue} disabled={!isValid}>Continue</PrimaryButton>
      </div>
    </div>
  </div>
}