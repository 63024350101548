import { isValidHexCode } from '../../../../utils/functions/IsValidHexCode'
import React from 'react'
import { ColorPickerEditor, ColorPickerEditorViewModel } from './color-picker-editor.component'
import { CustomInputChangeEvent } from '../../../../utils/models/events/CustomInputChangeEvent'
import { WhiteLabelConfigurationsForm } from './white-label-editor.components'

interface WrapperProps {
  form: WhiteLabelConfigurationsForm
  onFormChange: (form: WhiteLabelConfigurationsForm) => void
  disabled: boolean
}

export function ColorPickerEditorWrapper({ disabled, form, onFormChange }: WrapperProps) {
  const vm = useViewModel(form, onFormChange, disabled)
  return <ColorPickerEditor {...vm} />;
}

export function useViewModel(form: WhiteLabelConfigurationsForm,
  onFormChange: (form: WhiteLabelConfigurationsForm) => void,
  disabled: boolean): ColorPickerEditorViewModel {

  function handleFormChange(event: React.ChangeEvent<HTMLInputElement> | CustomInputChangeEvent) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    //@ts-ignore
    form[name] = value;

    onFormChange(form);
  }
  
  const colorNotValid = ['Not a valid color']
  const errorMsg = form.colorHex ? (isValidHexCode(form.colorHex) ? undefined : colorNotValid) : undefined
  return {
    disabled,
    form,
    onFormChange: handleFormChange,
    errorMsg: errorMsg
  };
}