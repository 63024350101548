import { CustomerForm } from '../components/customer.form'

export function isCustomerFormInvalid(form: CustomerForm): boolean {
  return !form.name
    || !form.cvr
    || !form.phone
    || !form.address
    || !form.email
    || !form.contactEmail
    || !form.contactPerson
    || !form.contactPhone
}