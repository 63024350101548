import { useParams } from 'react-router-dom'
import { ComponentState, useComponentStateMachine } from '../../shared/hooks/useComponentStateMachine'
import { useRepositoriesContext } from '../../../contexts/RepositoriesContext'
import LoadingElement from '../../components/loading-element/loading-element'
import { ErrorView } from '../../components/error-view/ErrorView'
import { OrganizationDetailsViewCustomerWrapper } from './organization-details-view-vendor.wrapper'

export function CustomerDetailsScreen() {
  const params = useParams()
  const id = params?.customerId
  return <CustomerDetailsScreenWithParam customerId={+id!} />
}

interface Props {
  customerId: number
}

export function CustomerDetailsScreenWithParam({ customerId }: Props) {

  const { state, dataTransition } = useComponentStateMachine()
  const { organizationRepository, userRepository } = useRepositoriesContext()
  const {
    data: customer,
    isError: isCustomerError,
    isLoading: isCustomerLoading,
    isSuccess: isCustomerSuccess
  } = organizationRepository.useCustomer(customerId)

  const {
    data: users,
    isError: isUsersError,
    isLoading: isUsersLoading,
    isSuccess: isUsersSuccess
  } = userRepository.useUsersOfOrganization(customerId)

  const isLoading = isCustomerLoading || isUsersLoading
  const isSuccess = isCustomerSuccess && isUsersSuccess
  const isError = isCustomerError || isUsersError

  dataTransition(isLoading, isSuccess, isError)

  return state === ComponentState.Succeed
    ? <OrganizationDetailsViewCustomerWrapper organization={customer!} users={users!} />
    : state === ComponentState.Loading
      ? <div className={'h-128 relative'}>
        <LoadingElement />
      </div>
      : <div className={'w-220 h-128 relative'}>
        <ErrorView />
      </div>
}

