import { CartItem } from '../models/CartItem'
import { OrderLineBlock } from './OrderLineBlock'

interface OrderLinesListItemLogoProps {
  orderLine: CartItem
  onClick: (orderLine: CartItem) => void
}

export function OrderLinesListItemLogo({ orderLine: orderLine, onClick }: OrderLinesListItemLogoProps) {
  const alt = `Logo - ${orderLine.title}`
  const src = orderLine.displayImage.lowResUrl
  return <OrderLineBlock className={'flex justify-center items-center'} onClick={() => onClick(orderLine)}>
    <img className={'w-[70px] h-[45px] object-contain'} src={src} alt={alt} />
  </OrderLineBlock>
}
