import { useDesignEditorStateContext } from '../contexts/design-editor-state-provider.context'
import { DesignEditorStates } from '../models/enums/design-editor-states.enum'
import { StateBasedPanel } from './panel/state-based-panel.component'
import { StateBasedView } from './state-based-view.component'
import { JetSportBox } from '../../../components/BoxView/JetSportBox'
import LoadingElement from '../../../components/loading-element/loading-element'
import { StrucRow } from '../../../components/StructureElements/StrucRow'
import { StrucCol } from '../../../components/StructureElements/StrucCol'
import { BoxHeader2 } from '../../../components/BoxView/BoxHeader2'
import { BoxHeaderTypography } from '../../../components/Typographies/BoxHeaderTypography'
import { WhiteLabelIcon } from '../../logo-editor/componenets/WhiteLabelIcon'
import { fa3, faFolder, faFont, faPalette, faPen, faUser } from '@fortawesome/free-solid-svg-icons'
import { BoxContent } from '../../../components/BoxView/BoxContent'
import { MenuButton } from './menu-button.component'
import { PrimaryButton } from '../../../components/Buttons/PrimaryButton'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useState } from 'react'
import { Design } from '../../../../core/models/entities/Design'
import { LogoOptions } from '../../../../core/models/entities/LogoOptions'
import { useDesignContext } from '../contexts/design.context'
import { CheckboxOptions } from '../../../components/Input/CheckboxOptions'
import { MainViewStates } from '../models/enums/main-view-states.enum'
import { SecondaryButton } from '../../../components/Buttons/SecondaryButton'

export interface DesignEditorToolProps {
  design: Design
  options: LogoOptions
}

export function DesignEditorTool({ design }: DesignEditorToolProps) {
  const { sidePanelState, goToState, mainViewState, overall, isLoading } = useDesignEditorStateContext()
  const { updateDesign, approveDesign, approvalDialog, readonly, handleGoToChangeStyle } = useDesignContext()

  const [confirmApproval, setConfirmApproval] = useState<boolean>(false)
  const neutral = overall === DesignEditorStates.Normal

  const panel = <StateBasedPanel state={sidePanelState} />
  const view = <StateBasedView state={mainViewState} />
  const showSaveConfirmDesign = mainViewState === MainViewStates.LogoPlacer
  const actions = <>
    <StrucRow>
      <div className={'w-full flex justify-between px-8 pb-5 pt-7'}>
        <div className='flex gap-x-6'>
          <PrimaryButton className={'px-4'} onClick={updateDesign} disabled={readonly}>
            <FontAwesomeIcon icon={faPen} />
            <span className={'flex-1'}>Save Design</span>
          </PrimaryButton>
          <SecondaryButton className={'px-4'} onClick={() => handleGoToChangeStyle(design)} disabled={readonly}>
            <FontAwesomeIcon icon={faPen} />
            <span className={'flex-1'}>Change Style</span>
          </SecondaryButton>
        </div>
        {showSaveConfirmDesign && <div className={'space-x-4 flex'}>
          <CheckboxOptions
            header={'YES, I AM SURE I AM DONE'}
            name={"confirmApproval"}
            checked={confirmApproval}
            onChange={x => setConfirmApproval(x.target.checked)}
            explain={""}
            disabled={false || readonly}
            checkboxPosition='left'
          ></CheckboxOptions>
          <PrimaryButton width={'md'} onClick={approveDesign} disabled={!confirmApproval || readonly}>Save Changes And Approve</PrimaryButton>
        </div>}
      </div>
    </StrucRow>
  </>

  return (<>
    {approvalDialog}
    <JetSportBox>
      {isLoading && <LoadingElement transparent />}
      <StrucRow className={'border-b'}>
        <StrucCol className={'border-r'} size={4}>
          <BoxHeader2
            className={'justify-between items-center'}><BoxHeaderTypography>Design</BoxHeaderTypography><WhiteLabelIcon
              icon={faPalette} /></BoxHeader2>
          <BoxContent>
            <div className={'space-y-2'}>
              <StrucRow className={'space-x-2'}>
                <StrucCol>
                  <MenuButton icon={faUser} text={'ADD NAME'}
                    disabled={overall !== DesignEditorStates.AddName && !neutral}
                    onClick={() => goToState(DesignEditorStates.AddName)}
                  />
                </StrucCol>
                <StrucCol>
                  <MenuButton icon={faFont} text={'ADD TEXT'}
                    disabled={overall !== DesignEditorStates.AddText && !neutral}
                    onClick={() => goToState(DesignEditorStates.AddText)}
                  />
                </StrucCol>
                <StrucCol>
                  <MenuButton icon={fa3} text={'ADD NUMBER'}
                    disabled={overall !== DesignEditorStates.AddNumber && !neutral}
                    onClick={() => goToState(DesignEditorStates.AddNumber)}
                  />
                </StrucCol>
              </StrucRow>
              <StrucRow className={'space-x-2'}>
                <StrucCol>
                  <MenuButton icon={faFolder} text={'VIEW LIBRARY'}
                    disabled={overall !== DesignEditorStates.ViewLibrary && !neutral}
                    onClick={() => goToState(DesignEditorStates.ViewLibrary)}
                  />
                </StrucCol>
                <StrucCol>

                </StrucCol>
                <StrucCol>

                </StrucCol>
              </StrucRow>
            </div>
            {panel}
          </BoxContent>
        </StrucCol>
        <StrucCol size={8} className='overflow-hidden'>
          {mainViewState === MainViewStates.LogoPlacer && actions}
          {view}
        </StrucCol>
      </StrucRow>
    </JetSportBox>
  </>)
}