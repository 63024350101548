import { useDesignContext } from '../contexts/design.context'
import { useEffect, useRef } from 'react'
import { LogoMarker_VM } from '../components/logo-placer/marker.component'
import { setupInteractJs } from '../functions/setup-interactJs.function'
import { Marker } from '../models/marker.model'
import { XnY } from '../models/x-n-y.model'

export function useMakerViewModel(marker: Marker): LogoMarker_VM {
  const {
    lockedLogos,
    selectedLogo,
    selectLogo,
    logoPlacement: logoPlacement,
    parentContainerXnY,
    onLogoManipulation
  } = useDesignContext()
  const markerRef = useRef<HTMLDivElement>(null)
  const isActive = selectedLogo?.inDesignId === marker.id
  const isLocked = lockedLogos[marker.id]
  const markerInfo = useRef<Marker>(marker)


  useEffect(() => {
    if (markerRef.current) {
      manipulateLogo(marker.x, marker.y, marker.widthPx, marker.heightPx, marker.rotationRad)
    }
  }, [markerRef, marker.rotationRad])

  async function manipulateLogoCommit(x: number, y: number, width: number, height: number, angle: number, parentContainerXnY: XnY) {
    const target = markerRef.current
    if (!target) {
      return
    }

    // may not need anymore
    /*const rect = target.getBoundingClientRect()
    const parentRelativX = rect.x - parentContainerXnY.x
    const parentRelativY = rect.y - parentContainerXnY.y*/

    markerInfo.current.heightPx = height
    markerInfo.current.widthPx = width
    markerInfo.current.rotationRad = angle
    markerInfo.current.x = x
    markerInfo.current.y = y
    onLogoManipulation(markerInfo.current)
  }

  function manipulateLogo(x: number, y: number, width: number, height: number, angle: number) {
    const target = markerRef.current
    if (!target) {
      return
    }

    Object.assign(target.style, {
      width: `${width}px`,
      height: `${height}px`,
      transform: `translate(${x}px, ${y}px) rotate(${angle}rad)`
    })

    Object.assign(target.dataset, { x: x, y: y })
  }

  setupInteractJs(marker, isActive, isLocked, selectLogo, manipulateLogo, (x, y, width, height, angle) => manipulateLogoCommit(x, y, width, height, angle, parentContainerXnY!).then())

  return {
    marker,
    isActive: isActive,
    isLocked: isLocked,
    markerRef: markerRef,
    logoPlacements: logoPlacement[marker.id]?.status,
    onSelect: () => selectLogo(marker.id)
  }
}