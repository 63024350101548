import React from 'react'
import { useTranslation } from 'react-i18next'
import { MaskColor } from '../../../../../../../core/models/entities/MaskColor'
import PaletteButton from '../../../../../../components/Buttons/PaletteButton'
import StyledLabel from '../../../../../../components/StyledLabel'
import BrushSlider from './BrushSlider'
import OpacitySlider from './OpacitySlider'
import { StrucCol } from '../../../../../../components/StructureElements/StrucCol'
import { StrucRow } from '../../../../../../components/StructureElements/StrucRow'

interface SettingsContainerProps {
  penColor: MaskColor
  onPenColorChange: (color: MaskColor) => void
  colors: MaskColor[]
  brushSize: number
  onBrushSizeChange: (size: number) => void
  opacity: number
  onOpacityChange: (opacity: number) => void
}

export function SettingsContainer({
                                    penColor,
                                    colors,
                                    onBrushSizeChange,
                                    brushSize,
                                    onPenColorChange,
                                    opacity,
                                    onOpacityChange
                                  }: SettingsContainerProps): React.ReactElement {
  const { t } = useTranslation()
  // Render
  return (
    <div className='flex flex-col w-full h-full space-y-16'>
      <StrucRow className={'!justify-start'}>
        <StrucCol className={'space-y-2'}>
          <StyledLabel>Current</StyledLabel>
          <PaletteButton full color={penColor} onClick={onPenColorChange} />
        </StrucCol>
      </StrucRow>
      <StrucRow className={'!justify-start'}>
        <StrucCol className={'space-y-2'}>
          <StyledLabel>{t('change_brush_type')}</StyledLabel>
          <div className={'space-y-4'}>
            {colors.map((value) => (
              <PaletteButton key={value} color={value} onClick={onPenColorChange} />))}
          </div>
        </StrucCol>
      </StrucRow>
      <StrucRow>
        <StrucCol>
          <BrushSlider onBrushSizeChange={onBrushSizeChange} brushSize={brushSize} />
          <OpacitySlider opacity={opacity} onOpacityChange={onOpacityChange} />
        </StrucCol>
      </StrucRow>
    </div>
  )
}
