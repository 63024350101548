import { DesignEditorTool_VM, Direction, useDesignEditorToolViewModel } from './DesignEditorTool.vm'
import { JetSportBox } from '../../../components/BoxView/JetSportBox'
import { BoxHeader2 } from '../../../components/BoxView/BoxHeader2'
import { BoxContent } from '../../../components/BoxView/BoxContent'
import { BoxFooter } from '../../../components/BoxView/BoxFooter'
import { BoxHeaderTypography } from '../../../components/Typographies/BoxHeaderTypography'
import { WhiteLabelIcon } from '../../logo-editor/componenets/WhiteLabelIcon'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  fa3,
  faCircleCheck,
  faCircleXmark,
  faFile,
  faFolder,
  faFont,
  faPalette,
  faPen,
  faStar,
  faUpload,
  faUser
} from '@fortawesome/free-solid-svg-icons'
import { InvertedPanel } from '../../../components/BoxView/InvertedPanel'
import { PrimaryButton } from '../../../components/Buttons/PrimaryButton'
import { SecondaryButton } from '../../../components/Buttons/SecondaryButton'
import { IconDefinition } from '@fortawesome/free-regular-svg-icons'
import { BaseLogo, getColors, getTransferDisplayName, isDigital } from '../../../../core/models/entities/BaseLogo'
import React, { JSX, useState } from 'react'
import { DesignLogoEditorWrapper } from '../design-style-editor/DesignStyleEditor.component'
import { Design, DesignLogo } from '../../../../core/models/entities/Design'
import { v4 as uuidv4 } from 'uuid'
import DigitalColorBlock from '../../logo-editor/componenets/DigitalColorBlock'
import { IdLookUps } from '../../../../utils/models/idLookUp'
import { LogoPlacementPosition } from '../design-style-editor/DesignStyleEditor.vm'
import { StyleView } from '../../../../core/models/entities/StyleView'
import { faRotateLeft } from '@fortawesome/free-solid-svg-icons/faRotateLeft'
import { faRotateRight } from '@fortawesome/free-solid-svg-icons/faRotateRight'
import { faLockOpen } from '@fortawesome/free-solid-svg-icons/faLockOpen'
import { faLock } from '@fortawesome/free-solid-svg-icons/faLock'
import { useWhiteLabelContext } from '../../../../WhiteLabelContext'

import { NotImplemented } from '../../../shared/functions/not-implemented.function'
import { StrucCol } from '../../../components/StructureElements/StrucCol'
import { StrucRow } from '../../../components/StructureElements/StrucRow'
import { InputWithSuffix } from '../../logo-editor/componenets/InputWithSuffix'
import { LogoDimensions } from '../../../components/Logo/LogoDimensions'
import { NamePlaceholderLogoCreate } from '../../design-editor2/components/panel/name-placeholder-logo-create.component'
import { useLogoActionHandler } from '../../logo-list/hooks/useLogoActionsHandler'
import { useLogoContext } from '../../design-editor2/contexts/logo-options.context'
import { Color, Font, LogoOptions } from '../../../../core/models/entities/LogoOptions'
import { useDesignContext } from '../../design-editor2/contexts/design.context'
import { useLogoRepository } from '../../../../data/repositories/useLogoRepository'
import { FontAndColorEditor, FontAutoComplete } from '../../design-editor2/components/panel/font-and-color-editor.component'
import { CustomInput } from '../../../components/Input/CustomInput'
import { InputWrapper } from '../../../components/Input/InputWrapper'
import { InputLabel } from '../../../components/Input/InputLabel'
import { NumberPackage } from '../../../../core/models/entities/NumberPackage'
import { NumberPackageEditor } from '../../design-editor2/components/panel/number-package-editor.component'


export interface WrapperProp {
  design: Design,
  logos: BaseLogo[],
  updateDesign: (design: Design) => Promise<void>
  /*
  textLogos: TextLogo[],
  deleteLogo: (logoId: number) => Promise<void>,
  uploadLogo: (logo: File) => Promise<Logo>,
  */
}

export function DesignEditorToolWrapper({
  updateDesign,
  design, /*deleteLogo, uploadLogo, textLogos,*/
  logos
}: WrapperProp) {
  const viewModel = useDesignEditorToolViewModel(
    design,
    logos!,
    updateDesign
    /*
    textLogos!,
    deleteLogo,
    uploadLogo,
    */
  )
  return <DesignEditorTool {...viewModel} />
}

export function DesignEditorTool(
  {
    design,
    designLogos,
    logos,
    handleLogoChange,
    handleAddLogo,
    handleSaveDesign,
    handleSelectView,
    handleRemoveLogo,
    currentView,
    invalidPlacement,
    setInvalidPlacement,
    currentLogo,
    locked,
    handleRotateLogo,
    handleLockClick,
    handleSelectLogo,
    reprepareLogos
  }: DesignEditorTool_VM): React.ReactElement {


  function handleAddLogoToDesign(logo: BaseLogo) {
    const designLogo: DesignLogo = {
      id: 0,
      logoId: logo.id,
      inDesignId: uuidv4(),
      logo: logo,
      viewId: currentView!.id,
      logoX: 0,
      logoY: 0,
      angleRads: 0,
      widthMm: logo.widthMm,
      heightMm: logo.heightMm,
      viewName: currentView!.view
    }
    handleAddLogo(designLogo)
  }

  return <JetSportBox>
    <StrucRow className={'border-b'}>
      <StrucCol className={'border-r'} size={4}>
        <BoxHeader2
          className={'justify-between items-center'}><BoxHeaderTypography>Design</BoxHeaderTypography><WhiteLabelIcon
            icon={faPalette} />
        </BoxHeader2>
        <BoxContent>
          <LogoPanel allLogos={logos}
            currentlyInUseLogos={designLogos}
            currentView={currentView?.id}
            onAddLogoToDesign={handleAddLogoToDesign}
            invalidPlacements={invalidPlacement}
            locked={locked}
            onRemoveLogoFromDesign={handleRemoveLogo}
          />
        </BoxContent>
      </StrucCol>
      <StrucCol size={8}>
        <InvertedPanel className={'!mx-0 !py-0 !px-0 flex justify-center rounded-tr'}>
          <BoxHeader2 className={'!mx-0 !py-3 justify-between items-center'}>
            <HeaderBar locked={locked} onLockClick={handleLockClick} onRotate={handleRotateLogo}
              currentLogo={currentLogo} />
          </BoxHeader2>
        </InvertedPanel>
        <BoxContent className={'!px-0'}>
          <DesignLogoEditorWrapper design={design}
            designLogos={designLogos}
            onLogoChange={handleLogoChange}
            onLogoInvalid={setInvalidPlacement}
            onViewSelect={handleSelectView}
            currentLogo={currentLogo}
            onSelectLogo={handleSelectLogo}
            locked={locked}
            reprepareLogos={reprepareLogos}
            invalidPlacement={invalidPlacement}
          />
        </BoxContent>
      </StrucCol>
    </StrucRow>
    <BoxFooter>
      <PrimaryButton className={'px-4'}>
        <FontAwesomeIcon icon={faPen} />
        <span className={'flex-1'} onClick={e => handleSaveDesign()}>Save Design</span>
      </PrimaryButton>
    </BoxFooter>
  </JetSportBox>
}
function LogoCard({ logo, actions, placement, disable }: {
  logo: BaseLogo,
  actions: JSX.Element[],
  placement?: boolean
  disable?: boolean
}) {

  function StatusSome({ verdict, badOption, goodOption }: { verdict: boolean, goodOption: string, badOption: string }) {
    return <div
      className={`flex font-bold text-xxs items-center ${!verdict ? 'text-status-failed-1' : 'text-status-success'} `}>
      <div className={'mr-1'}>
        {!verdict
          ? <FontAwesomeIcon className={'text-status-failed-1'} icon={faCircleXmark} />
          : <FontAwesomeIcon className={'text-status-success'} icon={faCircleCheck} />}
      </div>
      {!verdict ? badOption : goodOption}
    </div>
  }

  const isRaster = isDigital(logo);
  const color = isRaster ? <DigitalColorBlock /> : getColors(logo)/*.map(x => <ColorBlock key={x.key}
                                                                                          colorHex={`#${x.values[0] ?? '000'}`} />)*/

  return <div className={`border rounded bg-gray-100 ${disable ? 'opacity-50' : ''}`}>
    <StrucRow className={'pl-1 pr-2 py-1 border-b'}>
      <StrucCol className={'!justify-between !flex-row items-center'}>
        <div className={'text-[#1B76DF] flex items-center space-x-2'}>
          <FontAwesomeIcon className={'text-xs px-1.5 py-1 rounded border'} icon={faFile} />
          <span className={'max-w-[200px] overflow-x-hidden overflow-ellipsis whitespace-nowrap'}>{logo.title}</span>
        </div>
        <StatusSome verdict={!isRaster} goodOption={'Vector'} badOption={'Raster'} />
      </StrucCol>
      {placement !== undefined && <StrucCol className={'!flex-row !justify-end'} size={3.5}>
        <StatusSome verdict={placement} goodOption={'Valid Placement'} badOption={'Invalid Placement'} />
      </StrucCol>}
    </StrucRow>
    <StrucRow>
      <StrucCol size={3.5} className={'bg-white p-3'}>
        <img src={logo.displayImage.lowResUrl} className={'object-contain h-16'} />
      </StrucCol>
      <StrucCol size={5} className={'pl-2 pt-1 text-xs space-y-1'}>
        <div>
          <span className={'uppercase font-bold'}>Colors</span>
          <div>
            {color}
          </div>
        </div>
        <LogoDimensions logo={logo} />
        <span
          className={'font-bold'}>{getTransferDisplayName(logo)}</span>
      </StrucCol>
      <StrucCol className={'pr-2 pt-2 space-y-2'} size={3.5}>
        {actions}
      </StrucCol>
    </StrucRow>
  </div>
}


function IconButton(props: React.ButtonHTMLAttributes<HTMLButtonElement>) {
  return <button
    className={'rounded bg-white w-7 flex justify-center items-center border disabled:!bg-[#fafafa]'} {...props}>
    {props.children}
  </button>
}

function onChange(x: React.ChangeEvent<HTMLInputElement>) {
}

function HeaderBar({ locked, onLockClick, onRotate, currentLogo }: {
  locked: boolean,
  onLockClick: (lock: boolean) => void,
  currentLogo?: DesignLogo,
  onRotate: (logo: DesignLogo, direction: Direction) => void
}) {
  const { whiteLabel: { colorHex } } = useWhiteLabelContext()
  const disabled = !currentLogo || locked
  return <div className={'flex h-7 space-x-2'}>
    <IconButton disabled={disabled} onClick={() => onRotate(currentLogo!, Direction.Right)}>
      <FontAwesomeIcon icon={faRotateRight} />
    </IconButton>
    <InputWithSuffix disabled={disabled} className={'w-32'} suffix={'MM'} value={currentLogo?.logo.widthMm ?? 0}
      onChange={onChange} />
    {locked
      ? <IconButton onClick={() => onLockClick(false)} style={{ color: colorHex }}>
        <FontAwesomeIcon icon={faLock} />
      </IconButton>
      : <IconButton onClick={() => onLockClick(true)}>
        <FontAwesomeIcon icon={faLockOpen} />
      </IconButton>
    }
    <InputWithSuffix disabled={disabled} className={'w-32'} suffix={'MM'} value={currentLogo?.logo.heightMm ?? 0}
      onChange={onChange} />
    <IconButton disabled={disabled} onClick={() => onRotate(currentLogo!, Direction.Left)}>
      <FontAwesomeIcon icon={faRotateLeft} />
    </IconButton>
  </div>
}


function LocalButton({ text, icon, onClick, disabled }: {
  text: string,
  icon: IconDefinition,
  onClick?: (x: React.MouseEvent<HTMLButtonElement>) => void,
  disabled?: boolean
}) {
  return <SecondaryButton width={'full'} className={'h-18 flex-col space-y-2'} onClick={onClick}
    disabled={disabled}>
    <FontAwesomeIcon icon={icon} />
    <span className={'uppercase'}>{text}</span>
  </SecondaryButton>
}


interface LogoPanelViewModel {
  allLogos: BaseLogo[]
  currentlyInUseLogos: DesignLogo[]
  currentView?: StyleView['id'];
  onAddLogoToDesign: (logo: BaseLogo) => void
  invalidPlacements: IdLookUps<LogoPlacementPosition>,
  onRemoveLogoFromDesign: (logo: DesignLogo) => void
  locked: boolean,
}

enum LogoPanelState {
  Normal,
  ViewLibrary
}

export function LogoPanel({
  allLogos,
  currentlyInUseLogos,
  onAddLogoToDesign,
  invalidPlacements,
  currentView,
  onRemoveLogoFromDesign,
  locked
}: LogoPanelViewModel) {
  const [logoPanelState, setLogoPanelState] = useState<LogoPanelState>(LogoPanelState.Normal)

  function Buttons() {
    return <div className={'space-y-2'}>
      <StrucRow className={'space-x-2'}>
        <StrucCol>
          <LocalButton icon={faUpload} text={'Upload file'} disabled={logoPanelState !== LogoPanelState.Normal} />
        </StrucCol>
        <StrucCol>
          <LocalButton icon={faFont} text={'ADD TEXT'} disabled={logoPanelState !== LogoPanelState.Normal} />
        </StrucCol>
      </StrucRow>
      <StrucRow className={'space-x-2'}>
        <StrucCol>
          <LocalButton icon={faUser} text={'ADD NAME'} disabled={logoPanelState !== LogoPanelState.Normal} />
        </StrucCol>
        <StrucCol>
          <LocalButton icon={fa3} text={'ADD NUMBER'} disabled={logoPanelState !== LogoPanelState.Normal} />
        </StrucCol>
        <StrucCol>
          <LocalButton icon={faFolder} text={'VIEW LIBRARY'}
            onClick={() => logoPanelState === LogoPanelState.ViewLibrary ? setLogoPanelState(LogoPanelState.Normal) : setLogoPanelState(LogoPanelState.ViewLibrary)} />
        </StrucCol>
      </StrucRow>
    </div>
  }

  let showLogos: JSX.Element[]
  switch (logoPanelState) {
    case LogoPanelState.Normal:
      showLogos = currentlyInUseLogos.sort((a, b) => a.inDesignId.localeCompare(b.inDesignId)).map(x => <InUseLogo
        placement={invalidPlacements[x.inDesignId]} key={x.inDesignId}
        logo={x} />)
      break
    case LogoPanelState.ViewLibrary:
      showLogos = allLogos.map(x => <LibLogo key={x.id} {...x} />)
      break
  }

  function LibLogo(logo: BaseLogo) {
    let buttons = [
      <PrimaryButton className={'!h-7'} width={'full'} key={'add'}
        onClick={x => onAddLogoToDesign(logo)}>Add</PrimaryButton>,
      // <PrimaryButton className={'!h-7'} width={'full'} key={'delete'}
      //                onClick={() => NotImplemented('delete Logo')}>Delete</PrimaryButton>
    ]

    return <LogoCard key={logo.id} logo={logo}
      actions={buttons} />
  }

  function InUseLogo({ logo, placement }: { logo: DesignLogo, placement: LogoPlacementPosition }) {
    function s() {
      onRemoveLogoFromDesign(logo)
    }

    let buttons = [
      <PrimaryButton className={'!h-7'} width={'full'} key={'edit'}
        onClick={() => NotImplemented('Edit Logo')}>Edit</PrimaryButton>,
      <PrimaryButton className={'!h-7'} width={'full'} key={'remove'}
        onClick={s}>Remove</PrimaryButton>
    ]

    return <div><LogoCard key={logo.id} logo={logo.logo} placement={placement === LogoPlacementPosition.VALID}
      actions={buttons} disable={currentView !== logo.viewId} /></div>
  }

  return <>
    <Buttons />
    <div className={'flex-1 my-4 max-h-[710px]'}>
      <div className={'h-full overflow-y-scroll mr-[-0.75rem] '}>
        <div className={'mr-[0.48rem] space-y-2'}>
          {showLogos.length ? <>{showLogos}</> : <p>no logos</p>}
        </div>
      </div>
    </div>
  </>
}


export function SimpleLogoPanel({ valid, options, numbers, onSubmit, renderLogoUpload }: {
  valid: boolean
  options: LogoOptions,
  numbers?: NumberPackage[],
  onSubmit: (type: string, text?: string, color?: Color, font?: Font, size?: number, numberPackage?: NumberPackage) => void,
  renderLogoUpload: () => JSX.Element
}) {
  const [state, setState] = useState('upload')
  const [text, setText] = useState<string>('')
  const [color, setColor] = useState<Color>()
  const [font, setFont] = useState<Font>()
  const [size, setSize] = useState<number>(0)
  const [numberPackage, setNumberPackage] = useState<NumberPackage>()

  const isValid = valid && (
    (state === 'number' && numberPackage)
    || color && font && size && (state !== 'text' || text)
  )

  const submit = () => {
    onSubmit(state, text, color, font, size, numberPackage)
  }

  return (
    <StrucCol>
      <StrucRow className={'space-x-2 mb-2'}>
        <StrucCol>
          <LocalButton icon={faUpload} text={'Upload file'} onClick={() => setState('upload')} />
        </StrucCol>
        <StrucCol>
          <LocalButton icon={faFont} text={'ADD TEXT'} onClick={() => setState('text')} />
        </StrucCol>
      </StrucRow>
      <StrucRow className={'space-x-2 mb-2'}>
        <StrucCol>
          <LocalButton icon={faUser} text={'ADD NAME'} onClick={() => setState('name')} />
        </StrucCol>
        <StrucCol>
          <LocalButton icon={fa3} text={'ADD NUMBER'} onClick={() => setState('number')} />
        </StrucCol>
      </StrucRow>

      {['text', 'upload'].includes(state) && <div className="h-4" />}

      {state === 'upload' && renderLogoUpload()}

      {state === 'text' &&
        <InputWrapper>
          <InputLabel>Text</InputLabel>
          <CustomInput value={text} onChange={(e) => setText(e.target.value)} />
        </InputWrapper>}

      {['text', 'name'].includes(state) &&
        <FontAndColorEditor
          options={options}
          color={color}
          onSelectColor={setColor}
          font={font}
          onSelectFont={setFont}
          fontSize={size}
          onSelectFontSize={setSize}
          showValueField={false}
        />}

      {state === 'number' && <NumberPackageEditor
        className="my-4"
        numberPackage={numberPackage}
        onNumberPackage={setNumberPackage}
        numberPackages={numbers ?? []}
      />}

      {state !== 'upload' &&
        <PrimaryButton width={'full'} onClick={submit} disabled={!isValid}>
          Create logo
        </PrimaryButton>}

    </StrucCol>
  )
}