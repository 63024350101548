import { ReactElement, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useWhiteLabelContext } from '../../../WhiteLabelContext'

interface Props<T> {
  open?: boolean
  overrideKey?: string
  selectedOption?: T
  options: T[]
  defaultValue?: boolean
  defaultTitle?: string
  width?: 'small' | 'large' | 'max'
  onOpenToggle?: (open: boolean) => void
  onOptionSelect: (option?: T) => void
  getOptionDisplay: (option: T) => string
}

export default function Dropdown<T>({
  open = false,
  selectedOption,
  options,
  defaultValue = false,
  defaultTitle,
  width = 'max',
  onOpenToggle = () => {},
  onOptionSelect,
  getOptionDisplay,
  overrideKey = ''
}: Props<T>): ReactElement {
  const [show, setShow] = useState(open)
  const [title, setTitle] = useState('')
  const [dWidth, setDWidth] = useState('full')
  const { t } = useTranslation()
  const { whiteLabel } = useWhiteLabelContext()

  useEffect(() => {
    if (defaultTitle) {
      setTitle(defaultTitle)
    } else {
      setTitle(t('default') ?? '')
    }
  }, [defaultTitle])

  useEffect(() => {
    setShow(open)
  }, [open])

  useEffect(() => {
    switch (width) {
      case 'small':
        setDWidth('button-root but-32')
        break
      case 'large':
        setDWidth('button-root but-48')
        break
      case 'max':
        setDWidth('w-full button-root')
        break
    }
  }, [width])

  function onSelect(option?: T) {
    setShow(false)
    onOptionSelect(option)
    onOpenToggle(false)
  }

  function handleOpenClick() {
    onOpenToggle(!show)
    setShow(!show)
  }

  return (
    <div key={overrideKey} className={`${dWidth} px-0 relative !text-xs`}>
      <button
        style={{ color: selectedOption || show ? whiteLabel.colorHex : undefined }}
        className={`${dWidth} z-0 dropdown-button ${show ? 'rounded-b-none' : ''}`}
        onClick={handleOpenClick}>
        <label className="max-w-full overflow-hidden truncate">
          {selectedOption !== undefined ? getOptionDisplay(selectedOption) : title}
        </label>
      </button>
      {show && (
        <div className="w-full z-10 absolute dropdown-options-container">
          {defaultValue && (
            <div
              className="border-b dropdown-option"
              onClick={() => onSelect(undefined)}
              key="styled-option-defauly">
              {title}
            </div>
          )}
          <ul className="w-full">
            {options.map((option, i) => (
              <li
                onClick={() => onSelect(option)}
                className="dropdown-option"
                key={`styled-option-${i}-${option}`}>
                {getOptionDisplay(option)}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  )
}
