import React from 'react'
import { useOrganizationDetailsViewCustomer } from './useOrganizationDetailsViewVendor'
import { OrganizationDetailsView } from '../../components/Organization/organization-details-view.component'
import { Organization } from '../../../core/models/entities/Organization'
import { User } from '../../../core/models/entities/User'

interface OrganizationDetailsViewProps {
  users: User[]
  organization: Organization,
}

export function OrganizationDetailsViewCustomerWrapper({ organization, users }: OrganizationDetailsViewProps) {
  const vm = useOrganizationDetailsViewCustomer(organization, users)
  return <OrganizationDetailsView {...vm} />
}