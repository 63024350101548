import { CartItem } from '../models/CartItem'

interface ProductPreviewLogoProps {
  orderline: CartItem
}

export function ProductPreviewLogo({ orderline }: ProductPreviewLogoProps) {

  const frontView = {
    src: orderline.displayImage.highResUrl,
    alt: orderline.title
  }

  return <div className={'space-x-2 justify-center items-center flex w-full'} style={{ height: "100%", backgroundColor: "#F8FAFD"}}>
    <img {...frontView} />
  </div>
}