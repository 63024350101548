import { SecondaryButton } from '../../../components/Buttons/SecondaryButton'
import { PrimaryButton } from '../../../components/Buttons/PrimaryButton'
import React from 'react'
import { NotImplemented } from '../../../shared/functions/not-implemented.function'

interface OrderDoneCelebrationProps {
  orderId: string
  handleNavigateToOrders: () => void
}

export function OrderDoneCelebration({ orderId, handleNavigateToOrders }: OrderDoneCelebrationProps) {
  function handleOrderDownload() {
    NotImplemented('handleOrderDownload')
  }

  function handleViewOrder() {
    handleNavigateToOrders()
  }

  return <div className={'bg-[#fafafa] h-[580px] w-[515px] flex flex-col items-center justify-between p-6 rounded'}>
    <div className={'flex flex-col items-center space-y-6'}>
      <div className={'h-[150px] flex items-center'}>
        <p className={'w-full text-[80px] text-center'}>🎉</p>
      </div>
      <p className={'text-[21px] font-bold'}>Your order is sent for approval!</p>
      <p className={'text-[40px] font-bold text-status-success'}># {orderId}</p>
      <SecondaryButton onClick={() => handleOrderDownload()} className={'uppercase w-64'}>DOWNLOAD ORDER
        CONFIRMATION</SecondaryButton>
    </div>
    <PrimaryButton onClick={() => handleViewOrder()} className={'uppercase w-64'}>VIEW ORDERS</PrimaryButton>
  </div>
}