import { CommissionProfile } from '../../../core/models/entities/Commission'
import { LogoOptions } from '../../../core/models/entities/LogoOptions'
import { useRepositoriesContext } from '../../../contexts/RepositoriesContext'
import { ComponentState, useComponentStateMachine } from '../../shared/hooks/useComponentStateMachine'
import LoadingElement from '../loading-element/loading-element'
import { CommissionEditor } from '../../screens/customer-update/component/commission-editor.component'


interface WrapperProps {
  commissionProfile: CommissionProfile
  logoOptions: LogoOptions
  vendorId: number
  updateCommissions: (commission: CommissionProfile) => void
  userType: 'vendor' | 'customer'
}

export function CommissionFormWithVendor({ commissionProfile, logoOptions, vendorId, updateCommissions, userType }: WrapperProps) {

  const { logoRepository } = useRepositoriesContext();

  const { state, dataTransition } = useComponentStateMachine()
  const { data, isError, isLoading, isSuccess } = logoRepository.useTransferOptions(vendorId)

  dataTransition(isLoading, isSuccess, isError)

  if (state === ComponentState.Loading) {
    return <LoadingElement />
  }

  return <CommissionEditor userType={userType} commissions={commissionProfile} onCommissionUpdate={updateCommissions} logoOptions={logoOptions} options={{
    types: logoOptions.transferLogoTypes,
    transfers: data?.transferMethods ?? []
  }} collapsable={true} />
}
