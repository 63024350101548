import { Manufacturer } from '../../../../core/models/entities/Manufacturer'
import { Organization } from '../../../../core/models/entities/Organization'
import { DesignChangeStyleForm_VM } from '../components/design-change-style-form.component'
import { useTranslation } from 'react-i18next'
import { useErrorMessage } from '../../../hooks/useErrorMessage'
import { useProfileContext } from '../../../../contexts/ProfileContext'
import { useRepositoriesContext } from '../../../../contexts/RepositoriesContext'
import { useDesignActionHandler } from '../../design-list/hooks/useDesignActionHandler'
import { useState } from 'react'
import { PageQuery } from '../../../../utils/models/pageQuery'
import { DesignChangeStyleForm } from '../models/DesignChangeStyleForm'
import { Style } from '../../../../core/models/entities/Style'
import { toast } from 'react-toastify'
import { Role } from '../../../../core/models/entities/Role'
import { isAllowed } from '../../../shared/functions/isAllowed'
import { Design } from '../../../../core/models/entities/Design'

export function useDesignStyleUpdateFormViewModel(manufacturers: Manufacturer[], design: Design): DesignChangeStyleForm_VM {

  const { t } = useTranslation()
  const { tError } = useErrorMessage()
  const { profile, role } = useProfileContext()
  const { styleRepository } = useRepositoriesContext()
  const { handleChangeStyle, isLoading, handleGoToUpdateDesign, handleGoToDesignList } = useDesignActionHandler()
  const [pageQuery, setPageQuery] = useState<PageQuery>({ page: { index: 1, size: 50 } } as PageQuery)
  const userRole = profile?.userOrganizationInformations?.role!;
  const [form, setForm] = useState<DesignChangeStyleForm>({
    organization: design.customer,
    style: design.style
  } as DesignChangeStyleForm)

  const { data, fetchNextPage, isLoading: isStylesLoading } = styleRepository.useAllStyleAsInfinityQuery(pageQuery)
  let styles: Style[] = []
  if (data) {
    if (!form.style) {
      setForm(f => ({ ...f, style: data.pages[0].results[0] }))
    }
    for (const datum of data.pages) {
      styles = styles.concat(datum.results)
    }
  }

  function handleNexPage() {
    fetchNextPage().then()
  }

  function handleFilterOnManufacturer(manufacturer: Manufacturer | null) {
    setPageQuery(x => ({
      ...x,
      filterBy: manufacturer ? [{
        field: 'manufacturer.id',
        value: '' + manufacturer.id
      }] : []
    }))
  }

  function handleSearchQueryChange(query: string) {
    setPageQuery(x => ({
      ...x,
      searchTerm: query
    }))
  }

  function handleSelectCustomer(customer: Organization) {
    setForm(f => ({ ...f, organization: customer }))
  }

  function handleSelectStyle(style: Style) {
    setForm(f => ({ ...f, style: style }))
  }

  function handleChangeTitle(title: string) {
    setForm(x => ({ ...x, title: title }))
  }

  function handleSubmitForm() {

    if (!form) {
      return
    }

    if (!form.style) {
      toast.warn(t('toasts.no_styles_selected'))
      return
    }

    handleChangeStyle(
      design.customerId,
      design.id,
      form.style.id
    )
      .then((design) => {
        // @ts-ignore
        handleGoToUpdateDesign(design as Design)
      })
      .catch((e) => tError(e, 'Failed to create'))
  }

  function handleCancel() {
    handleGoToDesignList()
  }

  return {
    translate: t,
    styles,
    isStylesLoading,
    onLoadMoreStyles: handleNexPage,
    manufacturers: manufacturers,
    onFilterByManufacturer: handleFilterOnManufacturer,
    searchQuery: pageQuery.searchTerm ?? '',
    onSearchQueryChange: handleSearchQueryChange,
    selectedStyle: form.style,
    onSelectStyle: handleSelectStyle,
    showCustomerInfo: isAllowed(role!, Role.Vendor),
    organization: design.customer,
    title: design.title,
    selectedCustomer: form.organization!,
    onChangeTitle: handleChangeTitle,
    onSelectCustomer: handleSelectCustomer,
    onSubmit: handleSubmitForm,
    disableSubmit: !validateForm(form),
    onCancel: handleCancel,
    isLoading: isLoading,
    role: userRole
  }
}

function validateForm(form: DesignChangeStyleForm): boolean {
  return !!form.style
}