import React from "react";
import { useCartContext } from "../../../../contexts/CartContext";
import { getProductPrice } from "../../../../core/models/entities/BaseLogo";
import { PriceLine, PurePriceLine } from "../../../components/Logo/LogoPrices";
import { CartItemDesign } from "../../order-create/models/CartItem";

interface Props {
  no: string
  orderline: CartItemDesign
}

export function CartListDesignItem({ no, orderline }: Props): JSX.Element {

  // Design row
  const designQuantity = orderline.instances.reduce((sum, current) => sum + current.toDelivery, 0)
  const { getLogoAdjustedPrice } = useCartContext()

  // Logo rows
  const logoRows = orderline.design.designLogos.map((designLogo, logoRowIndex) => {
    const logoPrices = designLogo.logo.product?.prices ?? []
    const logoPricePerPience = getProductPrice(logoPrices, 1)
    const logoQtyPrice = getLogoAdjustedPrice(designLogo.logoId)
    const logoTotal = designQuantity * logoQtyPrice!

    const additionalFees = (designLogo.logo.product?.additionalFees ?? []).map((fee, feeIndex) => {
      return (
        <tr className='min-h-36 h-14' key={`${fee.itemNo}_${feeIndex}`}>
          {<td className='text-left'>{`${no}-${logoRowIndex + 1}`}</td>}
          {<td className='text-left'></td>}
          {<td className='text-left'>{fee.itemNo}</td>}
          {<td className='text-left'>{fee.name}</td>}
          {<td className='text-left'></td>}
          {<td className='text-right'>{PurePriceLine({ price: fee.unitPrice, excludeQuantity: true })}</td>}
          {<td className='text-right'>{fee.quantity}</td>}
          {<td className='text-right'>{PurePriceLine({ price: fee.unitPrice, excludeQuantity: true })}</td>}
          {<td className='text-right'>{PurePriceLine({ price: fee.price, excludeQuantity: true })}</td>}
        </tr>)
    })

    return (
      <React.Fragment key={`${no}-${logoRowIndex + 1}`}>
        <tr key={logoRowIndex}>
          <td className='text-left'>{`${no}-${logoRowIndex + 1}`}</td>
          <td className='text-left'></td>
          <td className='text-left'>{designLogo.logo.id}</td>
          <td className='text-left'>{designLogo.logo.title}</td>
          <td className='text-left'>{designLogo.logo.type}</td>
          <td className='text-right'>{PriceLine({ price: logoPricePerPience, excludeQuantity: true })}</td>
          <td className='text-right'>{designQuantity}</td>
          <td className='text-right'>{PurePriceLine({ price: logoQtyPrice, excludeQuantity: true })}</td>
          <td className='text-right'>{PurePriceLine({ price: logoTotal, excludeQuantity: true })}</td>
        </tr>
        {additionalFees}
      </ React.Fragment>
    )
  });

  return (
    <>
      <tr>
        <td className='text-left'>{no}</td>
        <td className='text-left'>
          <img
            src={orderline.displayImage?.lowResUrl}
            style={{
              maxHeight: 60,
              maxWidth: 60
            }}
          />
        </td>
        <td className='text-left'>{orderline.id}</td>
        <td className='text-left'>{orderline.title}</td>
        <td className='text-left'>{orderline.type}</td>
        <td className='text-right'>-</td>
        <td className='text-right'>-</td>
        <td className='text-right'>-</td>
        <td className='text-right'>-</td>
      </tr>
      {logoRows}
    </>
  )
}
