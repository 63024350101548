import React, { createContext, useContext } from 'react'
import { LogoOptions } from '../core/models/entities/LogoOptions'

const LogoOptionsContext = createContext<LogoOptions | undefined>(undefined)


interface LogoOptionsProviderProps {
  options: LogoOptions
  children: React.ReactNode
}

function LogoOptionsProvider({
                                options,
                                children
                              }: LogoOptionsProviderProps): React.ReactElement {
  return (
    <LogoOptionsContext.Provider
      value={options}>
      {children}
    </LogoOptionsContext.Provider>
  )
}

function useLogoOptionsContext(): LogoOptions {
  const context = useContext(LogoOptionsContext)
  if (context === undefined) {
    throw new Error('useLogoOptionsContext must be used within a LogoOptionsProvider')
  }
  return context
}

export { LogoOptionsProvider, useLogoOptionsContext }