import { JetSportBox } from '../../components/BoxView/JetSportBox'
import { InvertedPanel } from '../../components/BoxView/InvertedPanel'
import { OrderLineList } from '../order-details/subcomponents/OrderLineList'
import { SecondaryButton } from '../../components/Buttons/SecondaryButton'
import { PrimaryButton } from '../../components/Buttons/PrimaryButton'
import { PriceTotal } from '../order-details/subcomponents/PriceTotal'
import { BoxHeader2 } from '../../components/BoxView/BoxHeader2'
import { BoxContent } from '../../components/BoxView/BoxContent'
import { BoxFooter } from '../../components/BoxView/BoxFooter'
import { OrderForm } from './subcomponents/OrderDeliveryForm'
import { DeliveryTypeOption, OrderDeliveryDetail, PackagingOption, ProductGroup } from './useOrderConfirmationEditViewModel'
import { DeliveryType, Heating, LogoPrice, OrderLine, OrderLineProduct, Packaging } from '../../../core/models/entities/Order'

export interface OrderDeliveryFormViewModel {
  orderNo: string
  orderlines: OrderLine[]
  handleBack: () => void
  handleChange: () => void
  handleConfirm: () => void
  form: OrderDeliveryDetail
  deliveryTypeGroupList: DeliveryTypeOption[]
  packagingGroupList: PackagingOption[]
  handleOrderFormUpdate: (form: OrderDeliveryDetail) => void
  handlePackagingOrDeliveryChange?: (deliveryType: DeliveryType, packaging: Packaging) => void
  productGroups: ProductGroup[]
  getQuantity: (product: OrderLineProduct) => number
  subTotal: number
  vat: number
  totalAmount: number
  readonly?: boolean
  heating: Heating[]
  logoPrices: LogoPrice[]
  isFormValid: boolean
}

export function OrderConfirmationComponent({ orderlines, orderNo, form, productGroups, handleOrderFormUpdate, handlePackagingOrDeliveryChange, handleBack, handleChange, handleConfirm, deliveryTypeGroupList, packagingGroupList, getQuantity, subTotal, vat, totalAmount, readonly, heating, logoPrices, isFormValid }: OrderDeliveryFormViewModel) {
  return <JetSportBox className={'min-h-[900px]'}>
    <BoxHeader2 className={'uppercase text-sm font-bold flex justify-between items-center'}>
      <p>Order confirmation - #{orderNo}</p>
    </BoxHeader2>
    <BoxContent>
      <InvertedPanel>
        Panel
      </InvertedPanel>
      <div className={'flex-1 flex-col my-10'}>
        <OrderLineList orderlines={orderlines} productGroups={productGroups} getQuantity={getQuantity} heating={heating} logoPrices={logoPrices} />
        <PriceTotal subTotal={subTotal} vat={vat} totalAmount={totalAmount} />
      </div>
      <OrderForm form={form} readonly={readonly} onFormChange={handleOrderFormUpdate} onDeliveryOrPackagingChange={handlePackagingOrDeliveryChange} deliveryTypeOptionList={deliveryTypeGroupList} packagingOptionList={packagingGroupList} />
    </BoxContent>
    <div className={'flex justify-between mx-8 mt-4'}>
      <SecondaryButton onClick={handleBack}>Back</SecondaryButton>
      <div className={'flex justify-between gap-x-8'}>
        <PrimaryButton disabled={readonly} onClick={handleChange}>Change</PrimaryButton>
        <PrimaryButton disabled={readonly || !isFormValid} onClick={handleConfirm}>Confirm</PrimaryButton>
      </div>
    </div>
    <BoxFooter className={'justify-between'}>
    </BoxFooter>
  </JetSportBox>
}
