import { IconDefinition } from '@fortawesome/free-regular-svg-icons'
import React from 'react'
import { SecondaryButton } from '../../../components/Buttons/SecondaryButton'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export function MenuButton({ text, icon, onClick, disabled }: {
  text: string,
  icon: IconDefinition,
  onClick?: (x: React.MouseEvent<HTMLButtonElement>) => void,
  disabled?: boolean
}) {
  return <SecondaryButton width={'full'} className={'h-18 flex-col space-y-2'} onClick={onClick}
                          disabled={disabled}>
    <FontAwesomeIcon icon={icon} />
    <span className={'uppercase'}>{text}</span>
  </SecondaryButton>
}