import React, { ReactElement } from 'react'
import { MaskEditorViewModel } from './MaskEditorViewModel'
import { ErrorView } from '../../components/error-view/ErrorView'
import { LoadingView } from '../../components/loading-view/LoadingView'
import { MaskEditorTool } from './subcomponents/mask-editor/MaskEditorTool'
import { useMaskEditorToolViewModel } from './subcomponents/mask-editor/useMaskEditorToolViewModel'

interface MaskEditorPageProps {
  viewModel: MaskEditorViewModel
}

export function MaskEditorPage({
  viewModel: { style, isLoading, isSuccess, getOriginalMask, updateMask }
}: MaskEditorPageProps): ReactElement {
  function MaskEditorComponent(): JSX.Element {
    if (isSuccess && style) {
      const viewModel = useMaskEditorToolViewModel(style, getOriginalMask, updateMask)
      return <MaskEditorTool viewModel={viewModel} />
    } else if (isLoading) {
      return <LoadingView />
    } else {
      return <ErrorView />
    }
  }

  return <MaskEditorComponent />
}
