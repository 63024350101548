import { faChevronLeft, faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { CheckboxOptions } from "../../../components/Input/CheckboxOptions";
import { ExtraLogo } from "./add-heating-fee-order-dialog";
import { Stepper } from "../../../components/Buttons/Stepper";

interface PartlyHeatingListProps {
  extraLogos: ExtraLogo[]
  setExtraLogos: (extraLogo: ExtraLogo) => void
}

export function PartlyHeatingList({ extraLogos, setExtraLogos }: PartlyHeatingListProps) {
  const ref = React.createRef<HTMLDivElement>();

  const scroll = (scrollOffset: number) => {
    ref.current!.scrollLeft += scrollOffset;
  };

  const heatingProducts = extraLogos.map(item => <HeatingItem key={item.designLogo.logoId} item={item} setItem={setExtraLogos} />)

  return <div className='flex flex-row justify-center items-center justify-between'>
    <div className='flex-1 -ml-6'>
      <button onClick={() => scroll(-250)} className='p-2'><FontAwesomeIcon icon={faChevronLeft} /></button>
    </div>
    <div className='flex-3 overflow-hidden max-w-3xl'>
      <div ref={ref} className="flex overflow-x-scroll scroll-smooth pb-2">
        <div className="flex flex-nowrap gap-x-5">
          {heatingProducts}
        </div>
      </div>
    </div>
    <div className='flex-1 -mr-8 flex justify-end'>
      <button onClick={() => scroll(250)} className='p-2'><FontAwesomeIcon icon={faChevronRight} /></button>
    </div>
  </div>
}

interface HeatingItemProps {
  item: ExtraLogo
  setItem: (HeatingPartlyOption: ExtraLogo) => void
}

function HeatingItem({ item, setItem }: HeatingItemProps) {
  return (
    <div className="inline-block">
      <div className="w-48 h-fit max-w-xs overflow-hidden rounded-lg bg-[#F8FAFD] hover:shadow-xl transition-shadow duration-300 ease-in-out">
        <div className='flex flex-col px-5 pb-3'>
          <div className="h-32 w-32 ml-auto mr-auto my-3 place-content-center">
          <img className='object-cover ' src={item.designLogo.logo.displayImage.lowResUrl} />
          </div>
          {/* <div><span className="font-bold">Quantity </span>25</div> */}
          <div className="flex flex-col justify-items-start italic">
            <div className='font-bold'>Extra no heating</div>
            <div className=''>CHOOSE AMOUNT</div>
            <Stepper onValueChange={v => setItem({...item, quantity: v })} min={0} value={item.quantity} />
          </div>
          <div className="flex flex-row justify-between justify-center items-center italic">
            <p>To Storage</p>
            <CheckboxOptions
              checkboxPosition="right"
              name="toStorage"
              checked={item.option === "toStorage"}
              onChange={x => setItem({...item, option: x.target.checked ? "toStorage" : undefined })}
            ></CheckboxOptions>
          </div>
          <div className="flex flex-row justify-between justify-center items-center italic">
            <p>To Delivery</p>
            <CheckboxOptions
              checkboxPosition="right"
              name="toDelivery"
              checked={item.option === "toDelivery"}
              onChange={x => setItem({...item, option: x.target.checked ? "toDelivery" : undefined })}
            ></CheckboxOptions>
          </div>
        </div>
      </div>
    </div>
  )
}
