import { useQuery, useQueryClient } from '@tanstack/react-query'
import { CreateDesignDto } from '../../core/models/dtos/CreateDesignDto'
import { Resource } from '../../core/models/wrappers/Resource'
import { DesignRepository } from '../../core/repositories/DesignRepository'
import { useApiClient } from '../clients/ApiClient'
import { PageQuery, toQueryParam } from '../../utils/models/pageQuery'
import { Design } from '../../core/models/entities/Design'
import { PagedResponse } from '../models/responses/pagedResponse'
import { mapToDesign } from '../models/mappers/mapDesign'
import { InitiateApproval } from '../../presentation/screens/logo-list/hooks/useLogoActionsHandler'
import { Verdict } from '../../presentation/components/ApprovalVoting/approval-voting'
import { ChangeStyleBody, ChangeTitleBody } from '../models/bodies/ChangeBody'

function useDesignRepository(): DesignRepository {
  const apiClient = useApiClient()
  const queryClient = useQueryClient()

  async function invalidateQueries() {
    await queryClient.refetchQueries({
      queryKey: ['designs'],
      type: 'all',
      refetchPage: () => true
    })
  }

  function useAllDesigns(query: PageQuery): Resource<PagedResponse<Design>> {
    return useQuery(['designs', toQueryParam(query)], () => apiClient.getAllDesigns(query), {
      select: (data) => ({
        ...data,
        results: data.results.map(mapToDesign)
      })
    })
  }

  function useDesign(organizationId: number, designId: number): Resource<Design> {
    return useQuery(['designs', designId], () => apiClient.getDesign(organizationId, designId), {
      select: (data) => mapToDesign(data)
    })
  }

  function createDesign(dto: CreateDesignDto): Promise<Design> {
    return apiClient.createDesign(dto).then((designResponse) => {
      invalidateQueries().then()
      return mapToDesign(designResponse)
    })
  }

  function updateDesign(design: Design): Promise<Design> {
    return apiClient.updateDesign(design.customerId, design.id, design).then((designResponse) => {
      invalidateQueries().then()
      return mapToDesign(designResponse)
    })
  }

  function commit(design: Design): Promise<Design> {
    return apiClient.commitDesign(design).then((designResponse) => {
      invalidateQueries().then()
      return mapToDesign(designResponse)
    })
  }

  function initiateApproval(design: Design, approval: InitiateApproval): Promise<Design> {
    return apiClient.initiateApprovalDesign(design, approval).then((designResponse) => {
      invalidateQueries().then()
      return mapToDesign(designResponse)
    })
  }

  function placeVerdict(design: Design, verdict: Verdict): Promise<Design> {
    return apiClient.placeVerdictDesign(design, verdict).then((designResponse) => {
      invalidateQueries().then()
      return mapToDesign(designResponse)
    })
  }

  function deleteDesign(orgId: number, designId: number): Promise<void> {
    return apiClient.deleteDesign(orgId, designId).then(async () => {
      await invalidateQueries()
    })
  }

  function skipApproval(design: Design): Promise<Design> {
    return apiClient.skipApprovalDesign(design).then((designResponse) => {
      invalidateQueries().then()
      return mapToDesign(designResponse)
    })
  }

  async function changeStyle(orgId: number, designId: number, styleId: number): Promise<Design> {
    const body: ChangeStyleBody = {
      styleId: styleId
    }

    const result = apiClient.changeStyle(orgId, designId, body).then(async (res) => {
      await invalidateQueries()
      return res
    })
    return result
  }

  async function changeTitle(orgId: number, designId: number, title: string): Promise<Design> {
    const body: ChangeTitleBody = {
      newName: title
    }

    const result = apiClient.changeDesignTitle(orgId, designId, body).then(async (res) => {
      await invalidateQueries()
      return res
    })
    return result
  }

  async function copyDesign(orgId: number, designId: number): Promise<Design> {
    const dto = await apiClient.copyDesign(orgId, designId)
    const result = mapToDesign(dto)
    await invalidateQueries()
    return result
  }

  /*
  *
  delete(orgId: number, designId: number): Promise<void>
  * */

  return {
    useAllDesigns,
    useDesign,
    createDesign,
    updateDesign,
    commit,
    deleteDesign,
    placeVerdict,
    initiateApproval,
    skipApproval,
    changeStyle,
    changeTitle,
    copyDesign
  }
}

export { useDesignRepository }
