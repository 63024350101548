import React, { forwardRef } from 'react'

import { StrucRow } from '../../components/StructureElements/StrucRow'

export interface SectionContent extends React.HTMLAttributes<HTMLDivElement> {
  close: boolean
  overflowYHidden?: boolean
}

export const SectionContent = forwardRef<HTMLDivElement, SectionContent>((props, ref) => {
  const { children, className, close = true, overflowYHidden = true, ...rest } = props

  const hidden = close ? 'overflow-y-hidden' : overflowYHidden ? "overflow-y-hidden" : ""

  return <StrucRow ref={ref} {...rest}
                   className={`px-2 ${hidden} transition-max-height ${close ? ' max-h-0' : ' max-h-[40rem]'} ${className ?? ''} `}>
    <StrucRow className={'py-4 w-full'}>
      {children}
    </StrucRow>
  </StrucRow>
})