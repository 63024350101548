import React from 'react'

interface RadioButtonProps {
  checked: boolean
  onClick: () => void
  label: string
}

export function RadioButton({ checked, onClick, label }: RadioButtonProps) {
  return (
    <div className="flex items-center gap-1" onClick={() => onClick()}>
      <div
        className={`rounded-full border border-[#ECEEF9] size-3 ${
          checked ? 'bg-[#596675]' : 'bg-[#EFF4FB]'
        }`}
      />
      <label className="font-medium">{label}</label>
    </div>
  )
}
