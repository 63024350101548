import { PropsWithChildren } from 'react'
import { Link } from 'react-router-dom'
import { useWhiteLabelContext } from '../../../../WhiteLabelContext'

interface Props extends PropsWithChildren {
  selected: boolean
  to: string
}

export default function NavigationBarLink({ selected, to, children }: Props): JSX.Element {
  const {whiteLabel } = useWhiteLabelContext()

  return (
    <Link
      style={{color: selected ? whiteLabel.colorHex : undefined}}
      to={to}
      className={`flex justify-center items-center h-full w-[150px] hover:bg-gray-100 text-sm font-medium ${
        selected ? 'text-primary-100' : 'text-gray-600'
      }`}>
      {children}
    </Link>
  )
}
