import { OrganizationDetailForm_VM, DetailsForm } from '../components/organization-details-form.component'
import { CustomInputChangeEvent } from '../../../../utils/models/events/CustomInputChangeEvent'
import { CountryAutoCompleteItem } from '../../../components/Input/CountryAutoComplete'

export function useOrganizationDetailFormCreateViewModel(form: DetailsForm, onFormChange: (form: DetailsForm) => void): OrganizationDetailForm_VM {

  function handleFormChange(event: React.ChangeEvent<HTMLInputElement> | CustomInputChangeEvent) {
    const target = event.target
    const value = target.type === 'checkbox' ? target.checked : target.value
    const name = target.name
    //@ts-ignore
    form[name] = value

    handleDetailsChange(form)
  }

  function handleDetailsChange(changedForm: DetailsForm) {
    onFormChange(changedForm)
  }

  function handleCountryChange(country: CountryAutoCompleteItem) {
    onFormChange({...form, country: country.code})
  }

  function handleShippingCountryChange(country: CountryAutoCompleteItem) {
    onFormChange({...form, shippingCountry: country.code})
  }

  return {
    form: form,
    onFormChange: handleFormChange,
    onCountryChange: handleCountryChange,
    onShippingCountryChange: handleShippingCountryChange,
    disabled: false
  }
}