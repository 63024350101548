import React from 'react'
import '../Styles/CheckBox.css'
import { SelectEvent } from '../Models/multiSelectEvent'
import { ListItemSelector } from './ListItemSelector'
import { StrucCol } from '../../StructureElements/StrucCol'
import { StrucRow } from '../../StructureElements/StrucRow'

interface RowFrameProps extends React.HTMLAttributes<HTMLDivElement> {
  multiSelect: boolean
  index: number
  isChecked: boolean
  rowChecked: (event: SelectEvent) => void
  bgColor?: string
}

export const ListItemFrame = React.forwardRef<HTMLDivElement, RowFrameProps>((
  { children, className, multiSelect, rowChecked, index, isChecked, bgColor, ...rest },
  ref
) => {

  const s = React.Children.toArray(children)[0] as JSX.Element
  return (
    <StrucRow
      className={`flex flex-row items-center min-h-10 pb-8 !text-xs hover:bg-gray-200 border-gray-100 ${!multiSelect ? 'pl-8' : ''} ${bgColor ?? ''}`}
      {...rest}
      >
      {multiSelect && <StrucCol size={0.53}>
        <ListItemSelector initialValue={isChecked} index={index} onRowSelectChange={(rowChecked)} />
      </StrucCol>}
      <StrucCol>
        {children}
      </StrucCol>
    </StrucRow>
  )
})