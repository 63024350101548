import React, { useState } from 'react'
import { useRepositoriesContext } from '../../../contexts/RepositoriesContext'
import { CustomerListWrapper } from './subcomponents/customer-list.wrapper'
import { usePageQuery } from '../../shared/hooks/usePageQuery'
import { PagedComponentState, usePagedComponentStateMachine } from '../../shared/hooks/usePageComponentStateMachine'
import { ErrorView } from '../../components/error-view/ErrorView'
import { LoadingView } from '../../components/loading-view/LoadingView'


export function CustomerListScreen(): React.ReactElement {
  const [query, queryChanger] = usePageQuery()
  const { state, dataTransition } = usePagedComponentStateMachine()
  const { organizationRepository: organizationRepository } = useRepositoriesContext()
  const {
    data: customerPage,
    isSuccess: isCustomersSuccess,
    isError: isCustomersError,
    isLoading: isCustomersLoading
  } = organizationRepository.useAllCustomers(query)
  const [lastPage, setLastPage] = useState<number>(1)

  if (customerPage && customerPage.pageCount !== lastPage) {
    setLastPage(customerPage.pageCount ?? 1)
  }

  const isLoading = isCustomersLoading
  const isSuccess = isCustomersSuccess
  const isError = isCustomersError
  dataTransition(isLoading, isSuccess, isError)

  return state === PagedComponentState.Succeed || state === PagedComponentState.PageLoading ? <CustomerListWrapper
      organizations={customerPage?.results}
      query={query}
      queryChanger={queryChanger}
      isPageLoading={state === PagedComponentState.PageLoading}
      lastPage={lastPage}
    />
    : state === PagedComponentState.Failed ?
      <ErrorView /> :
      <LoadingView />

}

