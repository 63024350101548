import { Organization } from '../../../core/models/entities/Organization'
import { SubSection } from '../StructureElements/Section'
import { SectionHeader } from '../../screens/my-profile/SectionHeader'
import { PrimaryButton } from '../Buttons/PrimaryButton'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPen } from '@fortawesome/free-solid-svg-icons'
import { SubSectionHeaderTypography } from '../Typographies/SubSectionHeaderTypography'
import { StrucRow } from '../StructureElements/StrucRow'
import { StrucCol } from '../StructureElements/StrucCol'
import { SecondaryButton } from '../Buttons/SecondaryButton'
import { getCountryDisplayNameOrUndefined } from '../../../utils/functions/CountryUtil'
import { useProfileContext } from '../../../contexts/ProfileContext'
import { Role } from '../../../core/models/entities/Role'

interface OrganizationDetailsProps {
  organization: Organization,
  onBranchClick: (branch: Organization) => void
  onToMainBranch: () => void
  onToCustomers: () => void,
  onToEdit?: () => void
}

export function OrganizationDetails({
  organization,
  onBranchClick,
  onToMainBranch,
  onToCustomers,
  onToEdit
}: OrganizationDetailsProps) {

  const { profile } = useProfileContext()

  const showMainBranch = organization.parent !== undefined && organization.parent?.id === organization.mainBranchId
  const showBranch = !organization.isVendor && organization.parent !== undefined && organization.parent?.isVendor
  const showCustomers = organization.isVendor

  return <SubSection className={'h-[500px]'}>
    <div className={'h-full flex flex-col'}>
      <SectionHeader className={'!border-0'} isCloseable={false}>
        <div className={'flex justify-between items-center'}>
          <SubSectionHeaderTypography>Details</SubSectionHeaderTypography>
          {
            !!organization.isVendor && <PrimaryButton width={'icon'} onClick={onToEdit}><FontAwesomeIcon icon={faPen} /></PrimaryButton>
          }

        </div>
      </SectionHeader>
      <StrucRow className={'space-y-4 px-2 flex-1'}>
        <StrucCol size={4} className={'space-y-4'}>
          <p><b>Navision ID:</b><br />{organization.navNumber}</p>

          <p><b>Name:</b><br />{organization.name}</p>

          <p><b>CVR:</b><br />{organization.cvr}</p>
        </StrucCol>
        <StrucCol size={4} className={'space-y-4'}>
          <p>
            <b>Address:</b>
            <br />{organization.address}
            <br />{organization.city} {organization.postCode}
            <br />{getCountryDisplayNameOrUndefined(organization.country) ?? 'N/A'}
          </p>
          <p>
            <b>Contact:</b>
            <br />{organization.contactPerson}
            <br />{organization.contactPhone}
            <br />{organization.contactEmail}
          </p>
        </StrucCol>
        {organization.children?.length > 0 &&
          <StrucCol size={4}>
            <p className={'leading-6'}>
              <b>Branches:</b>
              {
                organization.children.map(b => <a onClick={() => onBranchClick(b)} key={b.id}
                  className={'text-blue cursor-pointer hover:underline'}><br />{b.name}
                </a>)
              }
            </p>
          </StrucCol>
        }
      </StrucRow>
      <StrucRow>
        <div className={'flex space-x-4'}>
          <></>
          {showMainBranch && <SecondaryButton onClick={onToMainBranch}>Go to Main Branch</SecondaryButton>}
          {showBranch && <SecondaryButton onClick={onToMainBranch}>Go to Branch</SecondaryButton>}
          {showCustomers && <SecondaryButton onClick={onToCustomers}>Go to Customers</SecondaryButton>}
        </div>
      </StrucRow>
    </div>


  </SubSection>
}