
import { Color } from '../../../core/models/entities/LogoOptions'
import ColorBlock from '../../screens/logo-editor/componenets/ColorBlock'
import ColorBlockWithToolTip from '../../screens/logo-editor/componenets/ColorBlockWithToolTip'

interface Props {
  color: Color
  showName?: boolean
}

export function VectorColorElement({ color, showName }: Props) {
  return (
    <div className={'flex align-middle space-x-1'}>
      <ColorBlockWithToolTip onClick={() => { }} size={'sm'} color={color} />
      {showName && <span>{color.name}</span>}
    </div>
  )
}