import { Design } from '../../../../core/models/entities/Design'
import { PageQuery } from '../../../../utils/models/pageQuery'
import { Organization } from '../../../../core/models/entities/Organization'
import { QueryChanger } from '../../../shared/hooks/usePageQuery'
import FunctionalList from '../../../components/GenericTable/FunctionalList'
import {
  DesignClickCallback,
  useFunctionalListDesignViewModel
} from '../vmHooks/useFunctionalListDesignViewModel'
import { Manufacturer } from '../../../../core/models/entities/Manufacturer'
import { Style } from '../../../../core/models/entities/Style'
import { TransferMethod } from '../../../../core/models/entities/LogoOptions'
import { useDesignActionHandler } from '../hooks/useDesignActionHandler'
import { useApprovalDialogByRole } from '../../../components/Dialogs/useApprovalDialogByRole'
import { useProfileContext } from '../../../../contexts/ProfileContext'

interface WrapperProps {
  onSelectDesign: (design: Design) => void
  lastPage: number
  query: PageQuery
  customers: Organization[]
  isPageLoading: boolean
  designs?: Design[]
  vendors: Organization[] | undefined
  queryChanger: QueryChanger
  manufacturers: Manufacturer[]
  styles: Style[]
  methods: TransferMethod[]
  onShowRejectionNote: (design: Design) => void
  onApprove: DesignClickCallback
  onReject: DesignClickCallback
}

export function DesignListWrapper({
  onSelectDesign,
  lastPage,
  query,
  customers,
  isPageLoading,
  designs,
  vendors,
  queryChanger,
  methods,
  styles,
  manufacturers,
  onShowRejectionNote,
  onApprove,
  onReject
}: WrapperProps) {
  const { profile } = useProfileContext()
  const { handleInitiateApproval, handleApprovalSkip, handleSkipAddToCart } =
    useDesignActionHandler()
  const { component, start: openInitApproval } = useApprovalDialogByRole<Design>({
    role: profile?.userOrganizationInformations.role!,
    subject: 'Design',
    onSkip: (entity) => handleApprovalSkip(entity).then(),
    onOrder: (entity) => handleSkipAddToCart(entity, 10).then(),
    onInitApproval: (entity, addExternal, email1) =>
      handleInitiateApproval(entity, {
        external: addExternal ?? false,
        externalEmail: email1,
        vendor: true,
        customer: true
      }).then()
  })

  const vm = useFunctionalListDesignViewModel(
    onSelectDesign,
    onApprove,
    onReject,
    lastPage,
    query,
    customers,
    isPageLoading,
    vendors,
    queryChanger,
    manufacturers,
    styles,
    methods,
    onShowRejectionNote,
    // openInitApproval,
    designs
  )

  return (
    <>
      {component}
      <FunctionalList<Design> {...vm} />
    </>
  )
}
