import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { CartItemDesign } from '../models/CartItem'
import { getColorsElements, getMethodDisplay } from './ProductDetailsLogo'
import { faCartPlus, faCircleCheck, faCircleXmark, faFileImage } from '@fortawesome/free-solid-svg-icons'
import { isDigital } from '../../../../core/models/entities/BaseLogo'
import IconButton from '../../../components/LogoButton/IconButton'
import { DesignLogo } from '../../../../core/models/entities/Design'
import { useAddLogoToOrderDialog } from '../../../components/Dialogs/add-logo-to-design-order-dialog'
import { LogoSize } from '../../../components/Logo/LogoSize'

interface OrderLineViewDesignPrintProps {
  orderline: CartItemDesign
  designLogo: DesignLogo
}

export function OrderLineViewDesignPrint({ orderline, designLogo }: OrderLineViewDesignPrintProps) {
  const views = orderline.design.style.styleViews
  const colors = getColorsElements(designLogo.logo);
  const relevantViews = views.filter(v => v.id == designLogo.viewId)
  const placements = relevantViews.map(x => x.view).join("");
  const alt = `Logo - ${orderline.title}`
  const src = designLogo.logo.displayImage.lowResUrl
  const isRaster = isDigital(designLogo.logo)

  const { component, open } = useAddLogoToOrderDialog(designLogo)
  console.log(designLogo.logo.transferMethod)

  return <div className='flex flex-col bg-white'>
    {component}
    <div className='flex flex-row items-center p-1.5 font-bold gap-2 text-[#1B76DF] bg-gray-100 border-x  border-t rounded-t'>
      <div className='border rounded-sm p-1 px-2 text-[#1B76DF] text-xs bg-[#FFFFFF]'>
        <FontAwesomeIcon size={'1x'} icon={faFileImage} />
      </div>
      <div className='truncate text-[#1B76DF] text-xs font-bold'>{designLogo.logo.title}</div>
      <div className={`flex font-bold text-xxs items-center ${isRaster ? 'text-status-failed-1' : 'text-status-success'} `}>
        <div className='mr-1 text-xs text-[#7BBB58]'>
          {isRaster
            ? <FontAwesomeIcon className={'text-status-failed-1'} icon={faCircleXmark} size={'sm'} />
            : <FontAwesomeIcon className={'text-status-success'} icon={faCircleCheck} size={'sm'} />}
        </div>
        <div className='mr-1 text-xs text-[#7BBB58] font-bold'>
          {isRaster ? 'Raster' : 'Vector'}
        </div>
      </div>
    </div>
    <div className='flex flex-row border-y bg-gray-100 border-x rounded-b'>
      <div className='bg-white w-[120px] h-[140px] flex items-center justify-center'>
        <img className={'w-[70px] h-[45px] object-contain'} src={src} alt={alt} />
      </div>
      <div className={'flex flex-col gap-1 text-xs p-2'}>
        <div className='flex flex-row gap-x-1 -mb-1'>
          <span><b>COLORS</b></span>
          <div>{colors}</div>
        </div>
        <p><b>Placement</b> {placements}</p>
        <LogoSize logo={designLogo.logo} />
        {/* <p><b>B</b> {logo.logoX} x <b>H</b>{logo.logoX}</p> */}
        <p><b>{getMethodDisplay(designLogo.logo)}</b></p>
        <p><b>In Storage</b> {designLogo.logo?.inStorage ?? 0}</p>
        <IconButton key={'add-order'} onClick={open} icon={<FontAwesomeIcon icon={faCartPlus} />}>
          ADD MORE
        </IconButton>
      </div>
    </div>
  </div>
}
