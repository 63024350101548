import React from 'react'
import { LogoMarker_VM, useLogoMarkerViewModel } from './LogoMarker.vm'
import { DesignLogo } from '../../../../core/models/entities/Design'
import { Marker } from '../design-view/DesignView.vm'
import { LogoPlacementPosition } from '../models/Placement'

export interface WrapperProp {
  marker: Marker,
  isActive: boolean,
  isLocked: boolean,
  onLogoManipulation: (
    marker: Marker,
    x: number,
    y: number,
    logoWidth: number,
    logoHeight: number,
    rotation: number,
    rectX: number,
    rectY: number
  ) => void
  onSelect: (logo: DesignLogo['inDesignId']) => void,
  viewCords: { x: number, y: number }
}

export function LogoMarkerWrapper({
                                    marker,
                                    isActive,
                                    onLogoManipulation,
                                    isLocked,
                                    onSelect,
                                    viewCords
                                  }: WrapperProp) {
  const viewModel = useLogoMarkerViewModel(
    marker,
    isLocked,
    onLogoManipulation,
    isActive,
    onSelect,
    viewCords
  )

  return <LogoMarker {...viewModel} />
}

export function LogoMarker(
  {
    isLocked,
    markerRef,
    logoUrl,
    logoType,
    isActive,
    marker,
    onSelect,
    angle
  }: LogoMarker_VM): React.ReactElement {

  function getLogoBorderColor(): string {
    switch (marker.placementStatus) {
      case LogoPlacementPosition.INVALID:
        return 'border-red'
      case LogoPlacementPosition.OVERLAPPING:
        return 'border-red'
      case LogoPlacementPosition.VALID:
        return 'border-green'
      case LogoPlacementPosition.UNKNOWN:
        return 'border-gray-600'
      default:
        return 'border-gray-600'
    }
  }

  function buildLogoBorder(): string {
    let borderAttributes = 'border'
    if (isLocked) {
      if (isActive) {
        borderAttributes += ' border-solid border-1 border-blue'
      } else {
        borderAttributes += ' border-solid border-1 border-transparent'
      }
    } else {
      borderAttributes += ' ' + getLogoBorderColor()

      if (isActive) {
        borderAttributes += ' border-solid border-1'
      } else {
        borderAttributes += ' border-dashed border-1'
      }
    }

    return borderAttributes
  }

  function buildLogoBg(): string {
    return marker.placementStatus === LogoPlacementPosition.VALID ? 'bg-[#7BBB5840]' : 'bg-[#C6353540]'
  }

  return (
    <div
      id={`box-${marker.id}`}
      ref={markerRef}
      className={`box box-border rounded absolute z-[2] ${buildLogoBorder()} ${buildLogoBg()} ${isActive ? isLocked ? '' : 'cursor-move' : 'cursor-pointer'}`}
      onClick={onSelect}
    >
      <img src={logoUrl} className='w-full h-full' />
      {!isLocked && (
        <>
          {/*<div
            id={`item_rotater-${marker.id}`}
            className='item_rotater absolute w-[10px] h-[10px] bg-white border-solid border-2 rounded-md border-purple-500 left-full bottom-full -translate-x-1/3 translate-y-1/3'
          />
          {logoType === LogoType.NORMAL && (
            <div
              id={`item_resizer-${marker.id}`}
              className='absolute w-[10px] h-[10px] bg-white border-solid border-2 rounded-md left-full top-full -translate-x-1/3 -translate-y-1/3'
            />
          )}*/}
        </>
      )}
    </div>
  )
}
