import { Profile } from '../../../core/models/entities/Profile'
import { UpdateProfileDto } from '../../../core/models/entities/UpdateProfileDto'
import { ProfileResponse } from '../responses/ProfileResponse'
import { mapToVendorType } from './mapOrganizationType'
import { mapToRole } from './mapRole'

export function mapToProfile(dto: ProfileResponse): Profile {
  return {
    userOrganizationInformations: {
      b2CId: dto.b2CId,
      role: mapToRole(dto.role),
      organizationName: dto.organizationName,
      organizationId: dto.organizationId,
      vendorType: mapToVendorType(dto.vendorType)!
    },
    whiteLabelConfigurations: {
      colorHex: dto?.whiteLabelColorHex,
      logoUrl: dto?.whiteLabelLogoUrl,
      whiteLabelCopyrightName: dto.whiteLabelCopyrightName,
      whiteLabelContactEmail: dto.whiteLabelContactEmail,
      whiteLabelContactPhone: dto.whiteLabelContactPhone,
      whiteLabelVendorName: dto.whiteLabelVendorName,
      whiteLabelWebsiteLink: dto.whiteLabelWebsiteLink,
    },
    emailPreferences: {
      emailPreferenceNewDesign: dto.emailPreferenceNewDesign,
      emailPreferenceNewLogos: dto.emailPreferenceNewLogos,
      emailPreferenceNewOrder: dto.emailPreferenceNewOrder,
      emailPreferencePendingOrder: dto.emailPreferencePendingOrder,
      emailPreferenceOrder: dto.emailPreferenceOrder
    },
    orderOptions: {
      orderOptionsTermsOfDelivery: dto.orderOptionsTermsOfDelivery,
      orderOptionsTermsOfStandardPackaging: dto.orderOptionsTermsOfStandardPackaging,
      orderOptionsTermsOfSpecificPackaging: dto.orderOptionsTermsOfSpecificPackaging,
      orderOptionsDeliveryCost: dto.orderOptionsDeliveryCost
    },
    detailedConfigurations: {
      contactEmail: dto.contactEmail,
      contactPerson: dto.contactPerson,
      contactPhone: dto.contactPhone,
      organizationAddress: dto.organizationAddress,
      organizationCVR: dto.organizationCVR,
      organizationEmail: dto.organizationEmail,
      organizationPhone: dto.organizationPhone,
      organizationPostCode: dto.organizationPostCode,
      organizationCity: dto.organizationCity,
      organizationCountry: dto.organizationCountry,
      organizationLanguage: dto.organizationLanguage,
    },
    shippingPreferences: {
      shippingName: dto.shippingName,
      shippingAddress: dto.shippingAddress,
      shippingPostCode: dto.shippingPostCode,
      shippingCity: dto.shippingCity,
      shippingCountry: dto.shippingCountry,
      shippingContactPerson: dto.shippingContactPerson,
      shippingContactPhone: dto.shippingContactPhone,
      shippingContactEmail: dto.shippingContactEmail,
    }
  }
}

export function mapProfileToUpdateProfileDto(dto: Profile): UpdateProfileDto {
  return {
    whiteLabelColorHex: dto.whiteLabelConfigurations?.colorHex ?? '#000000',
    whiteLabelCopyrightName: dto.whiteLabelConfigurations?.whiteLabelCopyrightName,
    whiteLabelContactEmail: dto.whiteLabelConfigurations?.whiteLabelContactEmail,
    whiteLabelContactPhone: dto.whiteLabelConfigurations?.whiteLabelContactPhone,
    whiteLabelVendorName: dto.whiteLabelConfigurations?.whiteLabelVendorName,
    whiteLabelWebsiteLink: dto.whiteLabelConfigurations?.whiteLabelWebsiteLink,

    emailPreferenceNewDesign: dto.emailPreferences?.emailPreferenceNewDesign,
    emailPreferenceNewLogos: dto.emailPreferences?.emailPreferenceNewLogos,
    emailPreferenceNewOrder: dto.emailPreferences?.emailPreferenceNewOrder,
    emailPreferencePendingOrder: dto.emailPreferences?.emailPreferencePendingOrder,
    emailPreferenceOrder: dto.emailPreferences?.emailPreferenceOrder,

    orderOptionsTermsOfDelivery: dto.orderOptions?.orderOptionsTermsOfDelivery,
    orderOptionsTermsOfStandardPackaging: dto.orderOptions?.orderOptionsTermsOfStandardPackaging,
    orderOptionsTermsOfSpecificPackaging: dto.orderOptions?.orderOptionsTermsOfSpecificPackaging,
    orderOptionsDeliveryCost: dto.orderOptions?.orderOptionsDeliveryCost?.toString(),

    shippingName: dto.shippingPreferences.shippingName,
    shippingAddress: dto.shippingPreferences.shippingAddress,
    shippingPostCode: dto.shippingPreferences.shippingPostCode,
    shippingCity: dto.shippingPreferences.shippingCity,
    shippingCountry: dto.shippingPreferences.shippingCountry,
    shippingContactPerson: dto.shippingPreferences.shippingContactPerson,
    shippingContactPhone: dto.shippingPreferences.shippingContactPhone,
    shippingContactEmail: dto.shippingPreferences.shippingContactEmail,
  }
}
