import React, { PropsWithChildren, useState } from 'react'
import { Modal } from '@mui/material'
import { ModalBox } from './modal-box.component'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faX } from '@fortawesome/free-solid-svg-icons'
import { useWhiteLabelContext } from '../../../WhiteLabelContext'

export interface Dialog_VM {
  isOpen: boolean
  openDialog: () => void
  closeDialog: () => void
}

export function useDialog(): Dialog_VM {
  const [open, setOpen] = useState<boolean>(false)
  const openDialog = () => setOpen(true)
  const closeDialog = () => setOpen(false)

  return {
    isOpen: open,
    openDialog,
    closeDialog
  }
}

export interface DialogFrameProps extends PropsWithChildren {
  dialogOption: Dialog_VM,
  onClose?: (e: React.MouseEvent<HTMLElement>) => void
}

export function DialogFrame({ dialogOption, onClose, children }: DialogFrameProps) {
  const { whiteLabel } = useWhiteLabelContext()
  return <>
    <Modal
      aria-labelledby='transition-modal-title'
      aria-describedby='transition-modal-description'
      open={dialogOption.isOpen}
      onClose={() => {
      }}
    >
      {/* @ts-ignore */}
      <ModalBox className={'relative'} onClick={e => e.stopPropagation()} style={{ '--primary': whiteLabel.colorHex, '--primary-bg': `${whiteLabel.colorHex}10` }}>
        <div className={'absolute z-10 right-0 top-0'}>
          <button onClick={(e) => {
            dialogOption.closeDialog()
            if (onClose) onClose(e)
          }} className={'!h-8 !w-8 z-50'}>
            <FontAwesomeIcon className={'text-sm'} style={{ fontSize: 10, lineHeight: 10 }} icon={faX} />
          </button>
        </div>
        {children}
      </ModalBox>
    </Modal>
  </>
}
