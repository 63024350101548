import { JetSportBox } from '../../../components/BoxView/JetSportBox'
import { BoxHeader2 } from '../../../components/BoxView/BoxHeader2'
import { BoxHeaderTypography } from '../../../components/Typographies/BoxHeaderTypography'
import { BoxContent } from '../../../components/BoxView/BoxContent'
import { BoxFooter } from '../../../components/BoxView/BoxFooter'
import { SecondaryButton } from '../../../components/Buttons/SecondaryButton'
import { PrimaryButton } from '../../../components/Buttons/PrimaryButton'
import { LineSeparator } from '../../../components/StructureElements/LineSeparator'
import { OrganizationDetailFormCreateWrapper } from '../wrappers/organization-details-form-create.wrapper'
import { BrandExclusionFormCreateWrapper } from '../wrappers/brand-exclusion-form-create.wrapper'
import { Manufacturer } from '../../../../core/models/entities/Manufacturer'
import LoadingElement from '../../../components/loading-element/loading-element'
import { CustomerForm } from './customer.form'
import Dropdown from '../../../components/dropdown/Dropdown'
import { Organization } from '../../../../core/models/entities/Organization'
import { SectionHeaderTypography } from '../../../components/Typographies/SectionHeaderTypography'
import { LogoOptions } from '../../../../core/models/entities/LogoOptions'
import { t } from 'i18next'
import { CommissionFormWithVendor } from '../../../components/commission/commission-form-with-vendor.component'
import { CommissionProfile } from '../../../../core/models/entities/Commission'

export interface CustomerCreateForm_VM {
  manufactures: Manufacturer[]  
  onCreate: () => void
  onCancel: () => void
  form: CustomerForm
  setForm: (form: CustomerForm) => void
  isFormValid: boolean
  isLoading: boolean
  showVendorPicker: boolean
  vendors?: Organization[]
  selectedVendor?: Organization
  logoOptions: LogoOptions
  updateCommission: (commission: CommissionProfile) => void
  commissionProfile: CommissionProfile
}

export function CustomerCreateForm({ manufactures, onCreate, form, setForm, onCancel, isFormValid, isLoading, showVendorPicker, vendors, selectedVendor, logoOptions, updateCommission, commissionProfile }: CustomerCreateForm_VM) {
  return <JetSportBox>
    <BoxHeader2 className={'border-b'}>
      <BoxHeaderTypography>add new Customer</BoxHeaderTypography>
    </BoxHeader2>
    <BoxContent className={'py-4 space-y-4'}>
    {
        showVendorPicker && vendors && <>
          <SectionHeaderTypography>Create under Vendor (Admin only)</SectionHeaderTypography>
          <Dropdown
            selectedOption={selectedVendor}
            options={vendors}
            defaultTitle={t("pick-vendor")!}
            getOptionDisplay={v => v.name}
            onOptionSelect={
              o => {
                if (!o) {
                  return
                }
                form.parentId = o.id
                setForm({...form})
              }
            }
          />
          <LineSeparator />
        </>
      }
      <OrganizationDetailFormCreateWrapper form={{ ...form }} onFormChange={x => setForm({ ...form, ...x })} />
      <LineSeparator />
      {
          (showVendorPicker && form.parentId === undefined)
          ? <p>{"Select a vendor before choosing commissions"}</p>
          : <CommissionFormWithVendor updateCommissions={updateCommission} logoOptions={logoOptions} commissionProfile={commissionProfile} userType={'customer'} vendorId={form.parentId!}/>
      }
      <LineSeparator />
      <BrandExclusionFormCreateWrapper form={{ ...form }} onFormChange={x => setForm({ ...form, ...x })}
                                       manufacturers={manufactures} />
    </BoxContent>
    <BoxFooter className={'justify-end'}>
      <SecondaryButton onClick={onCancel}>Cancel</SecondaryButton>
      <PrimaryButton onClick={onCreate} disabled={!isFormValid}>Create</PrimaryButton>
    </BoxFooter>
    {isLoading && <LoadingElement className={'rounded'} transparent />}
  </JetSportBox>
}