import React from 'react'
import './styles/OrderConfirmationScreen.css'
import { OrderConfirmationWrapper } from './OrderConfirmationWrapper';
import { useOrderRepository } from '../../../data/repositories/useOrderRepository';
import { ComponentState, useComponentStateMachine } from '../../shared/hooks/useComponentStateMachine';
import { LoadingView } from '../../components/loading-view/LoadingView';
import { ErrorView } from '../../components/error-view/ErrorView';
import { useSearchParams } from 'react-router-dom';

export function OrderConfirmationScreen(): React.ReactElement {

  const { state, dataTransition } = useComponentStateMachine()
  const { useOrder } = useOrderRepository()

  const [searchParams] = useSearchParams()
  const orderId = searchParams.get('orderId')
  const organizationId = searchParams.get('orgId')

  const { data, isError, isLoading, isSuccess } = useOrder(+organizationId!, +orderId!);

  dataTransition(isLoading, isSuccess, isError)
  return state === ComponentState.Succeed
    ? <OrderConfirmationWrapper order={data!} />
    : state === ComponentState.Loading
      ? <LoadingView />
      : <ErrorView />
}
