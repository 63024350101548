import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useRepositoriesContext } from '../../../contexts/RepositoriesContext'
import { useProfileContext } from '../../../contexts/ProfileContext'
import { t } from 'i18next'
import { DeliveryType, Order, OrderDeliveryPreferences, OrderLine, OrderLineDesign, OrderLineLogo, OrderLineProduct, OrderStatus, Packaging } from '../../../core/models/entities/Order'
import { IdLookUps } from '../../../utils/models/idLookUp'
import { groupBy } from '../../../utils/functions/GroupBy'
import { dissmissToast, useErrorToast, useLoadingToast, useSuccessToast } from '../../components/Toast/Toast'
import { DeliveryTypeOption, OrderDeliveryDetail, PackagingOption, ProductGroup, getSubTotal } from '../order-confirmation/useOrderConfirmationEditViewModel'
import { OrderDetailsComponentViewModel } from './OrderDetailsComponent'
import { useOrderActionHandler } from '../order-create/hooks/useOrderActionsHandler'

export function useOrderDetailsViewModel(order: Order): OrderDetailsComponentViewModel {

  const { profile } = useProfileContext();
  const { handleReorder } = useOrderActionHandler()

  const [form, setForm] = useState<OrderDeliveryDetail>({
    shipToName: order.orderDeliveryAddress?.name ?? "",
    shipToZip: order.orderDeliveryAddress?.zip ?? "",
    shipToContactEmail: order.orderDeliveryAddress?.email ?? "",
    shipToContactPerson: order.orderDeliveryAddress?.contact ?? "",
    shipToCity: order.orderDeliveryAddress?.city ?? "",
    shipToContactPhone: order.orderDeliveryAddress?.phone ?? "",
    shipToAddress: order.orderDeliveryAddress?.address ?? "",
    shipToCountry: order.orderDeliveryAddress?.country ?? "",
    emailtrackAndTrace: "",
    latestDeliveryDate: new Date(),
    deliveryBefore12PMwithGLS: false,
    deliveryType: order.deliveryType as DeliveryType ?? DeliveryType.Standard,
    packaging: order.packagingType as Packaging ?? Packaging.NoPackaging,
    orderComment: order.orderComment ?? "",
    originOfDelivery: "",
    requisitionNumber: "",
  });

  const deliveryTypeGroupList: DeliveryTypeOption[] = [
    {
      key: "Standard",
      type: DeliveryType.Standard,
      title: "Standard",
      description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi sagittis, augue non ultrices mollis, lorem velit gravida nunc, ac aliquet orci tellus at tellus. Phasellus et ex a libero efficitur placerat. Morbi convallis vehicula ex.",
      feePercentage: 0,
      feeUnit: "%",
      label: "Standard"
    },
    {
      key: "Express24",
      type: DeliveryType.Express24,
      title: "Express 24",
      description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi sagittis, augue non ultrices mollis, lorem velit gravida nunc, ac aliquet orci tellus at tellus. Phasellus et ex a libero efficitur placerat. Morbi convallis vehicula ex.",
      feePercentage: 100,
      feeUnit: "%",
      label: "Exp. 24"
    },
    {
      key: "Express48",
      type: DeliveryType.Express48,
      title: "Express 48",
      description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi sagittis, augue non ultrices mollis, lorem velit gravida nunc, ac aliquet orci tellus at tellus. Phasellus et ex a libero efficitur placerat. Morbi convallis vehicula ex.",
      feePercentage: 50,
      feeUnit: "%",
      label: "Exp. 48"
    },
    {
      key: "Express72",
      type: DeliveryType.Express72,
      title: "Express 72",
      description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi sagittis, augue non ultrices mollis, lorem velit gravida nunc, ac aliquet orci tellus at tellus. Phasellus et ex a libero efficitur placerat. Morbi convallis vehicula ex.",
      feePercentage: 25,
      feeUnit: "%",
      label: "Exp. 72"
    }
  ]

  const packagingGroupList: PackagingOption[] = [
    {
      key: "NoPackaging",
      type: Packaging.NoPackaging,
      title: "No packaging",
      description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi sagittis, augue non ultrices mollis, lorem velit gravida nunc, ac aliquet orci tellus at tellus. Phasellus et ex a libero efficitur placerat. Morbi convallis vehicula ex.",
      feeAmount: 0,
      feeUnit: "",
      label: "No packaging"
    },
    {
      key: "StandardBagPackaging",
      type: Packaging.StandardBagPackaging,
      title: "Standard bag packaging",
      description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi sagittis, augue non ultrices mollis, lorem velit gravida nunc, ac aliquet orci tellus at tellus. Phasellus et ex a libero efficitur placerat. Morbi convallis vehicula ex.",
      feeAmount: 4.5,
      feeUnit: "per pcs",
      label: "Standard bag packaging"
    },
    {
      key: "SpecificPackaging",
      type: Packaging.SpecificPackaging,
      title: "Specific packaging",
      description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi sagittis, augue non ultrices mollis, lorem velit gravida nunc, ac aliquet orci tellus at tellus. Phasellus et ex a libero efficitur placerat. Morbi convallis vehicula ex.",
      feeAmount: 300,
      feeUnit: "per hour",
      label: "Specific packaging"
    }
  ]

  const orderlines = order.lines

  const navigate = useNavigate()

  const { orderRepository } = useRepositoriesContext()

  const { commitOrder } = orderRepository

  function handleBack() {
    navigate('/order')
  }

  function mapToCreateOrderDeliveryPreferences(form: OrderDeliveryDetail): OrderDeliveryPreferences {
    return {
      address: form.shipToAddress,
      city: form.shipToCity,
      company: form.shipToName,
      contactPerson: form.shipToContactPerson,
      contactPhone: form.shipToContactPhone,
      country: form.shipToCountry,
      deliveryBefore12PMwithGLS: form.deliveryBefore12PMwithGLS,
      deliveryType: form.deliveryType,
      email: form.shipToContactEmail,
      emailtrackAndTrace: form.emailtrackAndTrace,
      latestDeliveryDate: form.latestDeliveryDate,
      orderComment: form.orderComment,
      originOfDelivery: form.originOfDelivery,
      packaging: form.packaging,
      requisitionNumber: form.requisitionNumber,
      zipCode: form.shipToZip
    }
  }

  function handleOrderFormUpdate(form: OrderDeliveryDetail): void {
    setForm({ ...form })
  }

  function reorder() {
    const orgId = profile?.userOrganizationInformations.organizationId!;
    const orderDeliveryPreferences = mapToCreateOrderDeliveryPreferences(form)

    const toast = useLoadingToast("Committing Logo")
    commitOrder(orgId, order.id, orderDeliveryPreferences).then(() => {
      navigate(`/order/${order.id}/end`)
      useSuccessToast(t("toasts.orders_committed_success"))
    }).catch(() => {
      navigate(`/order/${order.id}/end`)
      useErrorToast(t("toasts.orders_committed_failed"))
    }).finally(() => dissmissToast(toast))
  }

  const pricePerProductByTotalQuantity = cachePriceBySkuQuantity(orderlines)

  function cachePriceBySkuQuantity(orderlines: OrderLine[]): IdLookUps<number> {
    let dict: IdLookUps<number> = {}

    orderlines.forEach(element => {
      switch (element.product.productType) {
        case 'Design': {
          const design = (element as OrderLineDesign);
          if (dict[design.product.productMul!] == undefined) {
            dict[design.product.productMul!] = design.quantity
          } else {
            dict[design.product.productMul!] += design.quantity
          }
          return;
        }
        case 'Logo': {
          const logo = (element as OrderLineLogo);
          if (dict[logo.product.productSku!] == undefined) {
            dict[logo.product.productSku!] = logo.quantity
          } else {
            dict[logo.product.productSku!] += logo.quantity
          }
          return;
        }
      }
    });

    return dict
  }

  function getQuantity(product: OrderLineProduct): number {

    switch (product.productType) {
      case 'Design': return pricePerProductByTotalQuantity[product.productMul!];
      case 'Logo': return pricePerProductByTotalQuantity[product.productSku!];
      default: return 0;
    }
  }

  const productDict = groupBy(orderlines, i => i.product.productNo);
  const productGroups = Object.values(productDict).map(orderlines => ({ orderlines } as ProductGroup))

  const subTotal = getSubTotal(order);
  const vat = 0.25 * subTotal;
  const totalAmount = subTotal + vat;

  const readonly = order.status != OrderStatus.Draft

  function onBack() {
    navigate('/order')
  }

  const onReorder = async () => {
    await handleReorder(order)
  };

  const heating = order.heating
  const logoPrices = order.logoPrices

  return {
    orderNo: order.orderNumber,
    orderlines: order.lines,
    deliveryTypeGroupList,
    form,
    handleBack,
    handleConfirm: reorder,
    handleOrderFormUpdate,
    packagingGroupList,
    productGroups,
    getQuantity,
    subTotal,
    vat,
    totalAmount,
    readonly,
    onBack,
    order,
    onReorder,
    heating,
    logoPrices
  }
}
