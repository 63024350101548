import { DesignLogo } from "../../../../core/models/entities/Design"
import { StyleView } from "../../../../core/models/entities/StyleView"

export interface LogoPlacementStatus {
  position: LogoPlacementPosition
  coverage: LogoCoverage
}

export interface LogoPlacement {
  status: LogoPlacementPosition
  viewId: DesignLogo['viewId']
}

export enum LogoPlacementPosition {
  VALID,
  INVALID,
  OVERLAPPING,
  UNKNOWN
}

export interface LogoCoverage {
  base: number | 0
  collar: number | 0
  pocket: number | 0
  sewing: number | 0
  button: number | 0
  zipper: number | 0
  background: number | 0
}