import { AdditionalFee, Heating, HeatingProduct, LogoPrice, Order, OrderDeliveryPreferences, OrderLine, OrderLineDesign, OrderLineDesignInstance, OrderLineDesignInstanceLogo, OrderLineLogo } from '../../../core/models/entities/Order'
import { AdditionalFeeDto, DesignInstanceDto, DesignInstanceLogoDto, HeatingDto, HeatingProductDto, LogoPriceDto, OrderDto, OrderLineDto } from '../responses/OrderDto'
import { CreateOrderDto, CreateOrderPreferencesDto, OrderProductDto, OrderProductLogoDto, OrderProductDesignDto } from '../../../core/models/dtos/CreateOrderDto'
import { CreateOrderLine, CreateOrderLineDesign, CreateOrderLineLogo } from '../bodies/CreateOrder'
import { mapToDesign, mapToPrice } from './mapDesign'
import { mapToLogo } from './mapLogo'
import { DesignSize } from '../../../presentation/screens/order-create/models/CartItem'
import { mapToOrganization } from './mapOrganization'
import { mapToApprovalGroup } from './ApprovalMapper'
import { DesignDto } from '../responses/DesignDto'
import { LogoDto } from '../responses/LogoDto'

export function mapToOrder(dto: OrderDto): Order {
  return {
    id: dto.id,
    createdAtUtc: new Date(dto.createdAtUtc),
    updatedAtUtc: new Date(dto.updatedAtUtc),
    placedAtUtc: new Date(dto.placedAtUtc),
    orderNumber: dto.orderNumber,
    price: dto.price,
    status: dto.status,
    lines: dto.lines?.map(mapToOrderLine) ?? [],
    additionalFees: dto.additionalFees?.map(mapToAdditionalFee) ?? [],
    heating: dto.heating?.map(mapHeating) ?? [],
    logoPrices: dto.logoPrices?.map(mapLogoPrice) ?? [],
    deliveryType: dto.deliveryType,
    orderComment: dto.orderComment,
    originOfDelivery: dto.originOfDelivery,
    requisitionNumber: dto.requisitionNumber,
    packagingType: dto.packagingType,
    organization: mapToOrganization(dto.organization),
    approval: dto.approval ? mapToApprovalGroup(dto.approval) : null,
    orderDeliveryAddress: { ...dto.orderDeliveryAddressDto }
  }
}

function mapHeating(dto: HeatingDto): Heating {
  return {
    ...dto,
    product: mapHeatingProduct(dto.product),
    quantity: dto.quantity
  }
}

export function mapLogoPrice(dto: LogoPriceDto): LogoPrice {
  return {
    ...dto
  }
}

function mapHeatingProduct(dto: HeatingProductDto): HeatingProduct {
  return {
    ...dto,
    additionalFee: dto.additionalFee ? mapToAdditionalFee(dto.additionalFee) : undefined,
    prices: dto.prices?.map(x => mapToPrice(x)) ?? []
  }
}

function mapToOrderLine(dto: OrderLineDto): OrderLine {
  switch (dto.product?.productType) {
    case 'Logo': return mapOrderLineLogo(dto);
    case 'Design': return mapOrderLineDesign(dto);
    default: return mapOrderLine(dto)
  }
}

function mapOrderLine(dto: OrderLineDto): OrderLine {
  const subject: LogoDto | DesignDto = dto.logo ?? dto.design
  return {
    displayImage: subject?.displayImage?.lowResUrl,
    id: dto.id,
    title: subject?.title,
    quantity: dto.product?.quantity,
    adjustedPrice: dto.product.adjustedPrice,
    toDelivery: dto.product.toDelivery,
    toStorage: dto.product.toStorage,
    type: dto.product.productType,
    product: {
      productNo: dto.product.productNo,
      productSku: subject?.product.sku,
      productName: dto.product.productName,
      productType: dto.product.productType,
    },
    deliveryType: dto.type.value,
    additionalFees: dto.additionalFees?.map(mapToAdditionalFee) ?? []
  }
}

function mapOrderLineLogo(dto: OrderLineDto): OrderLineLogo {
  const orderLine = mapOrderLine(dto) as OrderLineLogo
  orderLine.baseLogo = mapToLogo(dto.logo)
  return orderLine
}

function mapOrderLineDesign(dto: OrderLineDto): OrderLineDesign {
  const orderLine = mapOrderLine(dto) as OrderLineDesign
  orderLine.design = mapToDesign(dto.design)
  orderLine.instances = dto.product?.instances?.map(instance => mapToDesignInstance(instance)) ?? [];
  return orderLine
}

function mapToDesignInstance(instanceDto: DesignInstanceDto): OrderLineDesignInstance {
  return {
    logos: instanceDto.logos.map(mapToDesignInstanceLogo),
    size: instanceDto.size as DesignSize,
    label: "instanceDto.label",
    quantity: instanceDto.toDelivery
  }
}

function mapToDesignInstanceLogo(dto: DesignInstanceLogoDto): OrderLineDesignInstanceLogo {
  return {
    isNumeric: dto.isNumeric,
    logoId: dto.logoId,
    value: dto.value
  }
}

export function mapToAdditionalFee(dto: AdditionalFeeDto): AdditionalFee {
  return {
    itemNo: dto.itemNo,
    name: dto.name,
    price: dto.price,
    quantity: dto.quantity,
    unitPrice: dto.unitPrice
  }
}

export function mapToCreateOrderDto(orderLines: CreateOrderLine[]): CreateOrderDto {
  return {
    products: orderLines.map(mapToOrderProductDto),
  }
}

export function mapToCreateOrderDeliveryPreferences(orderDeliveryPreferences: OrderDeliveryPreferences): CreateOrderPreferencesDto {
  return {
    shipToName: orderDeliveryPreferences.company,
    shipToZip: orderDeliveryPreferences.zipCode,
    shipToContactPerson: orderDeliveryPreferences.contactPerson,
    shipToContactEmail: orderDeliveryPreferences.email,
    shipToContactPhone: orderDeliveryPreferences.contactPhone,
    shipToCity: orderDeliveryPreferences.city,
    shipToAddress: orderDeliveryPreferences.address,
    shipToCountry: orderDeliveryPreferences.country,
    emailtrackAndTrace: orderDeliveryPreferences.emailtrackAndTrace,
    latestDeliveryDate: orderDeliveryPreferences.latestDeliveryDate,
    deliveryBefore12PMwithGLS: orderDeliveryPreferences.deliveryBefore12PMwithGLS,
    deliveryType: orderDeliveryPreferences.deliveryType,
    packaging: orderDeliveryPreferences.packaging,
    orderComment: orderDeliveryPreferences.orderComment,
    originOfDelivery: orderDeliveryPreferences.originOfDelivery,
    requisitionNumber: orderDeliveryPreferences.requisitionNumber,
  }
}

function mapToOrderProductDto(orderLines: CreateOrderLine): OrderProductDto {
  switch (orderLines.productType) {
    case 'Logo': {
      return mapToProductDetailLogoDto(orderLines as CreateOrderLineLogo);
    }
    case 'Design': {
      return mapToProductDetailDesignDto(orderLines as CreateOrderLineDesign);
    }
  }
}

function mapToProductDetailLogoDto(orderline: CreateOrderLineLogo): OrderProductDto {
  const dto = {
    productNo: orderline.productId,
    productType: orderline.productType,
    toStorage: orderline.toStorage,
    toDelivery: orderline.toDelivery,
    value: orderline.value,
  } as OrderProductLogoDto;

  return dto;
}

function mapToProductDetailDesignDto(orderline: CreateOrderLineDesign): OrderProductDto {
  const dto = {
    productNo: orderline.productId,
    productType: orderline.productType,
    instances: orderline.instances.map(x => ({ ...x }))
  } as OrderProductDesignDto

  return dto;
}