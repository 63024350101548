import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Autocomplete, Box, createFilterOptions } from "@mui/material"

export interface KitMakerAutoCompleteItem {
  key: string
  name: string
}

export function KitMakerAutoComplete({ items, disabled, onItemChange, value, subject }: {
  subject: string,
  items: KitMakerAutoCompleteItem[],
  disabled: boolean,
  onItemChange: (item: KitMakerAutoCompleteItem) => void
  value: KitMakerAutoCompleteItem | undefined
}) {
  const filterOptions = createFilterOptions<KitMakerAutoCompleteItem>({
    limit: 200
  })

  return <Autocomplete
    className={'!text-xs'}
    disabled={disabled}
    options={items}
    value={value ?? null}
    filterOptions={filterOptions}
    getOptionLabel={(option) => option.name}
    isOptionEqualToValue={(option, value) => option.key === value.key}
    renderOption={(props, option) => {
      // @ts-ignore
      const { key, ...otherProps } = props;
      return (
        <Box
          key={key}
          component='li'
          {...otherProps}
        >
          <div className={'flex text-xs items-center space-x-2'}>
            <p>{option.name}</p>
          </div>
        </Box>
      )
    }}
    renderInput={(params) =>
    (
      <div ref={params.InputProps.ref}
        className='border rounded-md border-gray-200 border-solid h-9 flex space-x-2 items-center pl-2 bg-white focus-within:border-gray-600'>
        <span>
          <FontAwesomeIcon icon={faMagnifyingGlass} />
        </span>
        <input {...params.inputProps} className='border-none text-xs font-medium outline-none w-full'
          placeholder={`search for ${subject}...`} />
      </div>
    )}
    onChange={(x, v) => {
      if (v) {
        onItemChange(v)
      }
    }}
  />
}