import { Manufacturer } from '../../../../core/models/entities/Manufacturer'
import { BrandExclusionForm_VM, OrganizationBrandExclusionForm } from '../components/brand-exclusion-form.component'

export function useBrandExclusionFormCreateViewModel(form: OrganizationBrandExclusionForm, onFormChange: (form: OrganizationBrandExclusionForm) => void, manufacturers: Manufacturer[]): BrandExclusionForm_VM {

  function handleReincludeBrand(manufacturer: Manufacturer) {
    form.excludedManufacturers = form.excludedManufacturers.filter(m => m.id !== manufacturer.id)

    handleDetailsChange(form)
  }

  function handleExcludeBrand(manufacturers: Manufacturer) {
    form.excludedManufacturers.push(manufacturers)

    handleDetailsChange(form)
  }

  function handleDetailsChange(changedForm: OrganizationBrandExclusionForm) {
    onFormChange(changedForm)
  }

  return {
    form: form,
    onExclude: handleExcludeBrand,
    onReinclude: handleReincludeBrand,
    manufacturers: manufacturers
  }
}