import { Marker } from '../models/marker.model'
import { LogoCoverage } from '../../design-editor/design-style-editor/DesignStyleEditor.vm'
import { LogoPlacementPosition } from '../../design-editor/models/Placement'
import { isLogoOverlappingAnotherLogo } from './isLogoOverlappingAnotherLogo'
import { isMarkerPlacementOnMaskValid } from './isMarkerPlacementOnMaskValid'

export function getLogoPlacementPosition(
  marker: Marker,
  overlappingMarkers: Marker[],
  coverage: LogoCoverage
): LogoPlacementPosition {

  if (marker.heightPx == 0 || marker.widthPx == 0) {
    return LogoPlacementPosition.UNKNOWN
  }
  if (isLogoOverlappingAnotherLogo(marker, overlappingMarkers)) {
    return LogoPlacementPosition.OVERLAPPING
  }

  if (!isMarkerPlacementOnMaskValid(coverage)) {
    return LogoPlacementPosition.INVALID
  }

  return LogoPlacementPosition.VALID
}