import { OrderLineViewLogo } from './OrderLineViewLogo'
import { OrderLineViewDesign } from './OrderLineViewDesign'
import { CartItem, CartItemDesign, CartItemLogo } from '../models/CartItem'

interface OrderLineViewProps { 
  orderline: CartItem
}

export function OrderLineView({ orderline }: OrderLineViewProps) {
  switch (orderline.type) {
    case "Logo":
      return <OrderLineViewLogo orderline={orderline as CartItemLogo} />
    case "Design":
      return <OrderLineViewDesign orderline={orderline as CartItemDesign} />
  }
}