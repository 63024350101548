import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons'
import '../../GenericTable.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { SearchQueryEvent } from '../../Models/searchQueryEvent'
import { ChangeEvent, useEffect, useMemo, useState } from 'react'
import debounce from 'lodash.debounce'

export interface ListSearchBarProps {
  onSearchQueryChange: (nextSearchQuery: SearchQueryEvent) => void | undefined
  searchQuery: string
}

function TableSearchBar({ onSearchQueryChange, searchQuery }: ListSearchBarProps) {
  const [value, setValue] = useState<string>(searchQuery ?? '')

  // onChange is not debounced anymore, it just calls debounced function
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value)
  }

  useEffect(() => {
    onSearchQueryChange({ searchTerm: value })
  }, [value])

  // now send request is debounced
  const debouncedResults = useMemo(() => {
    return debounce(handleChange, 500)
  }, [])

  useEffect(() => {
    return () => {
      debouncedResults.cancel()
    }
  })

  return (
    <div className="text-xs rounded-md h-9 flex space-x-2 items-center pl-2">
      <span>
        <FontAwesomeIcon icon={faMagnifyingGlass} />
      </span>
      <input
        placeholder="Search"
        className="w-full border-none text-gray-600 text-xs font-medium outline-none"
        onChange={debouncedResults}
      />
    </div>
  )
}

export default TableSearchBar
