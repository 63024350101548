import React, { ChangeEvent, useEffect, useState } from 'react'
import { InputWrapper } from '../../../components/Input/InputWrapper'
import { InputLabel } from '../../../components/Input/InputLabel'
import { SecondaryButton } from '../../../components/Buttons/SecondaryButton'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileArrowDown } from '@fortawesome/free-solid-svg-icons'
import { toBase64 } from '../../../../utils/functions/ImageUtil'

interface OrganizationLogoPickerProps {
  value: string,
  onLogoChange: (file: File) => void
  disabled: boolean
}

export function OrganizationLogoPicker({ value, onLogoChange, disabled }: OrganizationLogoPickerProps) {

  const [isDragging, setIsDragging] = useState(false)
  const [uploadedLogoBase64, setUploadedLogoBase64] = useState<string>()

  useEffect(( ) => {
    setUploadedLogoBase64(value);
  }, [value])

  function handleDropFile(e: React.DragEvent<HTMLDivElement | HTMLFormElement>) {
    e.preventDefault()
    e.stopPropagation()
    setIsDragging(false)
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      // at least one file has been dropped so do something
      const file = e.dataTransfer.files[0]
      toBase64(file).then(setUploadedLogoBase64)
      if (onLogoChange) onLogoChange(file)
    }
  }

  function handleUploadLogo(event: ChangeEvent<HTMLInputElement>) {
    event.stopPropagation()
    event.preventDefault()

    const files = event.target.files

    if (files && files.length > 0) {
      const file = files[0]
      toBase64(file).then(base64 => {
        setUploadedLogoBase64(base64)
        if (onLogoChange) onLogoChange(file)
      })
    }
  }

  function handleDragCatalog(e: React.DragEvent<HTMLDivElement | HTMLFormElement>) {
    e.preventDefault()
    e.stopPropagation()
    if (e.type === 'dragenter' || e.type === 'dragover') {
      setIsDragging(true)
    } else if (e.type === 'dragleave') {
      setIsDragging(false)
    }
  }

  function handelUploadClick() {
    inputRef.current?.click()
  }

  const src = uploadedLogoBase64 ? `${uploadedLogoBase64}` : value
  const inputRef = React.useRef<HTMLInputElement>(null)
  return <InputWrapper>
    <InputLabel>Logo</InputLabel>
    <div className={'relative'}>

      <div className={`relative flex-1 border rounded`} onDragEnter={handleDragCatalog}>
        <input ref={inputRef} className={'hidden'} type='file' id='input-file-upload'
               onChange={handleUploadLogo}
               accept='image/png' />
        <div className={'bg-gray-100 border rounded-tl rounded-tr flex justify-center items-center h-30'}>
          {src && <img className={'object-cover h-32 max-h-32'} src={src} alt={'company-logo'} />}
        </div>
        <SecondaryButton onClick={!disabled ? handelUploadClick : () => {}} className={'!rounded-tl-none !rounded-tr-none border-t-0'} disabled={disabled}
                         width={'full'}>Upload a File</SecondaryButton>
        {isDragging &&
          (
            <>
              <div
                className={'z-10 absolute w-full h-full top-0 bottom-0 left-0 right-0'}
                onDragEnter={handleDragCatalog}
                onDragLeave={handleDragCatalog}
                onDragOver={handleDragCatalog}
                onDrop={handleDropFile}>
              </div>
              <div id={'drop-overlay'}
                   className={'animate-fadeInFast bg-gray-200 absolute w-full h-full top-0 bottom-0 left-0 right-0 flex justify-center items-center'}>
                <div className={'space-y-3 flex flex-col h-5 mt-[-60px]'}>
                  <FontAwesomeIcon icon={faFileArrowDown} size={'2x'} className={'mx-auto '} />
                  <p className={'italic font-light text-xs'}>Drop image here</p>
                </div>
              </div>
            </>
          )
        }</div>
    </div>
  </InputWrapper>
}