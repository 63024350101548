import { NamePlaceholderLogoCreate_VM } from '../components/panel/name-placeholder-logo-create.component'
import { useLogoContext } from '../contexts/logo-options.context'
import { useDesignEditorStateContext } from '../contexts/design-editor-state-provider.context'
import { useState } from 'react'
import { Color, Font } from '../../../../core/models/entities/LogoOptions'
import { DesignEditorStates } from '../models/enums/design-editor-states.enum'
import { useLogoActionHandler } from '../../logo-list/hooks/useLogoActionsHandler'
import { useDesignContext } from '../contexts/design.context'
import { useLogoRepository } from '../../../../data/repositories/useLogoRepository'

export function useNamePlaceholderLogoCreateViewModel(): NamePlaceholderLogoCreate_VM {
  const { handleCreatePlaceholderNameLogo, isLoading } = useLogoActionHandler()
  const { options } = useLogoContext()
  const { goToState } = useDesignEditorStateContext()
  const [color, setColor] = useState<Color>()
  const [font, setFont] = useState<Font>()
  const [heightMM, setHeightMM] = useState<number>(0)
  const { design, addToDesign } = useDesignContext()
  const { generateTextLogoPreview } = useLogoRepository()
  
  function handleCancel() {
    // todo: confirmation dialog when leaving
    goToState(DesignEditorStates.Normal)
  }

  function handleAddLogo() {
    handleCreatePlaceholderNameLogo(color?.id!, heightMM, font?.key!, design.customerId).then(logo => {
      addToDesign(logo)
      goToState(DesignEditorStates.Normal)
    });
  }

  function isValid(): boolean {
    return !!color && !!font && !!heightMM && !isLoading
  }

  function getPreview(value: string, color: Color, heightMM: number, font: Font): Promise<string> {
    return generateTextLogoPreview(value, color.id, heightMM, font.key, design.customerId)
  }

  return {
    font: font,
    onFontChange: setFont,
    heightMM: heightMM,
    onHeightMMChange: setHeightMM,
    color: color,
    onColorChange: setColor,
    options: options,
    onCancel: handleCancel,
    onAddLogo: handleAddLogo,
    canAdd: isValid(),
    getPreview: getPreview,
    isLoading: isLoading
  }
}