import { DesignSize, CartItemDesignInstance } from '../models/CartItem';
import { DesignInstanceViewModelProps } from './DesignInstance.Component';

interface Props {
  index: number
  instance: CartItemDesignInstance
  updateInstance: (index: number, instances: CartItemDesignInstance) => void
}

export function DesignInstanceVM({ index, instance, updateInstance }: Props): DesignInstanceViewModelProps {

  function updateLabel(value: string) {
    updateInstance(index, {
      ...instance,
      label: value
    });
  }

  function updateInstanceSize(designSize: DesignSize) {
    updateInstance(index, {
      ...instance,
      size: designSize
    });
  }

  function updateInstanceQuantity(quantity: number) {
    updateInstance(index, {
      ...instance,
      toDelivery: quantity
    });
  }

  function handleLogoText(logoId: number, value: string | number): void {
    const logos = instance.logos.map(logo => logo.logoId === logoId ? { ...logo, value: value } : logo);

    updateInstance(index, {
      ...instance,
      logos: logos
    });
  }

  return {
    orderlineDesign: instance,
    index,
    updateLabel: updateLabel,
    handleInstanceSizeChange: updateInstanceSize,
    handleInstanceQuantity: updateInstanceQuantity,
    handleLogoText: handleLogoText,
  }
}