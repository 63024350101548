import React, { JSX, RefObject, useImperativeHandle, useRef } from 'react'
import { CircularProgress } from '@mui/material'

interface Props<T> {
  currentItems: T[],
  onLoadMore?: () => void,
  renderItem: (data: T) => JSX.Element,
  ref?: RefObject<any>,
  isLoading?: boolean
  className?: string
}
export function InfinityListComponent<T>({ currentItems, onLoadMore, ref, renderItem, isLoading, className }: Props<T>) {
  function onScroll(e: React.UIEvent<HTMLDivElement>) {

    // @ts-ignore
    const bottom = Math.ceil(e.target.scrollHeight - e.target.scrollTop - 3) <= (e.target.clientHeight)
    if (!isLoading && bottom && onLoadMore) {
      onLoadMore()
    }
  }

  useImperativeHandle(ref, () => ({
    reset() {
      if (scrollRef.current) {
        scrollRef.current.scrollTop = 0
      }
    }
  }))

  const scrollRef = useRef<HTMLDivElement>(null)

  const listitems = currentItems.map(renderItem)

  return <div ref={ref} className={`my-4 h-[inherit] relative ${className ?? ''}`}>
    <div ref={scrollRef} className={'h-[inherit] overflow-y-scroll mr-[-0.75rem]'} onScroll={onScroll}>
      <div className={'mr-[0.48rem] space-y-2'}>{listitems}</div>
    </div>
    {
      currentItems.length > 0 && isLoading &&
      <div className={'flex absolute w-full bottom-2 justify-center animate-fadeInFast'}>
        <CircularProgress />
      </div>
    }
    {
      !currentItems.length && isLoading && <div
        className={'flex bg-loadingTransparent absolute w-full bottom-0 h-full items-center justify-center animate-fadeInFast'}>
        <CircularProgress />
      </div>
    }
  </div>
}