import { LogoPrice, OrderLineDesign } from '../../../../core/models/entities/Order'
import { OrderLineHideColumns } from './OrderLineList'

interface Props {
  no: string
  orderline: OrderLineDesign
  hideColumns?: OrderLineHideColumns
  logoPrices: LogoPrice[]
}

export function DesignOrderLine({ no, orderline, hideColumns }: Props): JSX.Element {

  const designQuantity = orderline.quantity

  return (
    <>
      <tr className='min-h-36 h-14'>
        {(hideColumns?.no) ?? <td className='text-left'>{no}</td>}
        {(hideColumns?.image) ?? <td className='text-left'>
          <img
            src={orderline.displayImage}
            style={{ maxHeight: 60, maxWidth: 60 }}
          />
        </td>}
        {(hideColumns?.productId) ?? <td className='text-left'>{orderline.product?.productNo}</td>}
        {(hideColumns?.title) ?? <td className='text-left'>{orderline.title}</td>}
        {(hideColumns?.type) ?? <td className='text-left'>{orderline.type === "Design" ? 'Multivare (Delivery)' : ''}</td>}
        {(hideColumns?.pricePerPiece) ?? <td className='text-right'>DKK {orderline.adjustedPrice}</td>}
        {(hideColumns?.qty) ?? <td className='text-right'>{designQuantity}</td>}
        {(hideColumns?.qtyPrice) ?? <td className='text-right'>DKK {orderline.adjustedPrice}</td>}
        {(hideColumns?.totalPrice) ?? <td className='text-right'>DKK {orderline.adjustedPrice * designQuantity}</td>}
      </tr>
    </>
  )
}