import { Method, TransferOptions } from "../../../core/models/entities/TransferOptions"
import { TransferDto } from "../responses/transferOptionsDto"

export function mapTransferOptions(dto: TransferDto[]): TransferOptions {
  return {
    transferMethods: dto.map(mapToTransfer),
  }
}

function mapToTransfer(dto: TransferDto): Method {
  return {
    methodKey: dto.key,
    priceType: dto.priceType,
    options: dto.sizes,
    type: dto.type
  }
}
