import React, { useEffect, useState } from 'react'
import { LoadingView } from '../../components/loading-view/LoadingView'
import { ErrorView } from '../../components/error-view/ErrorView'
import { usePageQuery } from '../../shared/hooks/usePageQuery'
import { PagedComponentState, usePagedComponentStateMachine } from '../../shared/hooks/usePageComponentStateMachine'
import { useRepositoriesContext } from '../../../contexts/RepositoriesContext'
import { useCustomerAndVendors } from '../../shared/hooks/useCustomerAndVendors'
import { OrderListWrapper } from './wrappers/order-list.wrapper'
import { Order } from '../../../core/models/entities/Order'
import { useDialog } from '../../components/Dialogs/dialog-base'
import { RejectionNoteDialog } from '../../components/Dialogs/rejection-note-popup.component'
import { OrderTitleHeader } from '../../components/Logo/LogoTitleHeader'
import { ContentModal } from '../../components/content-modal/ContentModal'
import { OrderDetailsWrapper } from './wrappers/order-details.wrapper'
import { useProfileContext } from '../../../contexts/ProfileContext'
import { useOpenOrder } from '../logo-list/vmHooks/useOpenOrder'

export function OrderListScreen(): React.ReactElement {
  const [query, queryChanger] = usePageQuery()
  const { state, dataTransition } = usePagedComponentStateMachine()
  const { orderRepository, organizationRepository } = useRepositoriesContext()
  const [lastPage, setLastPage] = useState<number>(1)
  const [orderSelected, setOrderSelected] = useState<Order | null>(null)
  const [orderRejection, setOrderRejection] = useState<Order | null>(null)
  const { profile, role } = useProfileContext()
  const rejectionNoteDialog = useDialog()
  const { result: openResult, clearOpen } = useOpenOrder()

  function showRejectionNote(order: Order): void {
    setOrderRejection(order)
    rejectionNoteDialog.openDialog();
  }

  const {
    data: ordersPage,
    isSuccess: isOrdersSuccess,
    isLoading: isOrdersLoading,
    isError: isOrdersError
  } = orderRepository.useAllOrders(query)

  const {
    customers,
    vendors,
    isSuccess: isOrganizationSuccess,
    isError: isOrganizationError,
    isLoading: isOrganizationLoading
  } = useCustomerAndVendors(organizationRepository)

  if (ordersPage && ordersPage.pageCount !== lastPage) {
    setLastPage(ordersPage.pageCount)
  }

  useEffect(() => {
    if (openResult) {
      setOrderSelected(openResult)
    }
  }, [openResult])

  const isLoading = isOrdersLoading || isOrganizationLoading
  const isSuccess = isOrdersSuccess && isOrganizationSuccess
  const isError = isOrdersError || isOrganizationError
  dataTransition(isLoading, isSuccess, isError)


  return state === PagedComponentState.Succeed || state === PagedComponentState.PageLoading ? <>
    <OrderListWrapper
      profile={profile!}
      query={query}
      queryChanger={queryChanger}
      isPageLoading={state === PagedComponentState.PageLoading}
      lastPage={lastPage}
      orders={ordersPage?.results}
      customers={customers!}
      vendors={vendors}
      onSelectOrder={setOrderSelected}
      onRejectionNote={showRejectionNote}
    />
    <ContentModal open={!!orderSelected} onClose={() => setOrderSelected(null)}>
      <OrderDetailsWrapper
        order={orderSelected!}
        onClose={() => {
          setOrderSelected(null)
          clearOpen()
        }}
        onShowRejectionNote={showRejectionNote}
      />
    </ContentModal>
    {orderRejection && <RejectionNoteDialog
      title={
        <OrderTitleHeader order={orderRejection!} />
      }
      dialogOptions={rejectionNoteDialog}
      approval={orderRejection.approval!}
      exclude={role!}
    />}
  </>
    : state === PagedComponentState.Failed ?
      <ErrorView /> :
      <LoadingView />
}
