import { JSX, useState } from 'react'
import { Dialog_VM, DialogFrame, useDialog } from '../../../components/Dialogs/dialog-base'
import { PrimaryButton } from '../../../components/Buttons/PrimaryButton'
import { SecondaryButton } from '../../../components/Buttons/SecondaryButton'
import LoadingElement from '../../../components/loading-element/loading-element'
import { DeliveryTypeOption } from '../useOrderConfirmationEditViewModel'
import { CheckboxOptions } from '../../../components/Input/CheckboxOptions'
import { useProfileContext } from '../../../../contexts/ProfileContext'

interface Props {
  component: JSX.Element
  open: () => void
}

export function useDeliveryTypeOrderDialog({
  onContinue,
  onCancel,
  type
}: {onContinue: () => Promise<void>, onCancel?: () => void, type: DeliveryTypeOption}): Props {

  const [loading, setLoading] = useState(false)
  const [toggle, setToggle] = useState<boolean>(false); 

  const dialog = useDialog()
  const { profile } = useProfileContext()
  
  function handleSubmit() {
    setLoading(true);
    onContinue().finally(() => {
      setLoading(false)
      setToggle(false);
      dialog.closeDialog()
    })
  }

  function handleToggle() {
    setToggle(!toggle)
  }

  function handleCancel() {
    if (onCancel) onCancel()
    dialog.closeDialog()
  }

  return {
    component: <>
      <DeliveryTypeOrderDialog 
        dialogVm={dialog}
        onToggle={handleToggle}
        onSubmit={handleSubmit}
        loading={loading}
        onCancel={handleCancel}
        toggle={toggle}
        type={type}
        orderOptionsTermsOfSpecificPackaging={profile!.orderOptions.orderOptionsTermsOfSpecificPackaging}
      />
    </>,
    open: dialog.openDialog
  }
}

interface DeliveryTypeOrderDialogProps {
  dialogVm: Dialog_VM
  toggle: boolean
  onSubmit: () => void
  onToggle: () => void
  onCancel?: () => void
  loading: boolean
  type: DeliveryTypeOption
  orderOptionsTermsOfSpecificPackaging: string
}

export function DeliveryTypeOrderDialog(
  {
    onSubmit,
    toggle,
    onToggle,
    onCancel,
    loading,
    dialogVm,
    type,
    orderOptionsTermsOfSpecificPackaging
  }: DeliveryTypeOrderDialogProps) {
    
  return <DialogFrame dialogOption={dialogVm}>
    {loading && <LoadingElement transparent />}
    <div className={'flex flex-col space-y-4 p-4 max-w-xl'}>
      <p className={'text-left text-base font-bold'}>{type.title}</p>
      <p className={'text-left'}>{orderOptionsTermsOfSpecificPackaging}</p>
      <p className={'text-left'}>The current fee for specific packaging is <span className='font-bold'>{type.feePercentage}{type.feeUnit}.</span></p>
      <div className={'flex flex-col justify-center space-y-4'}>
      <CheckboxOptions
          header={''}
          name={"checkbox"}
          checked={toggle}
          onChange={onToggle}
          explain={"I agree to the terms and wish to include the additional charge to my order."}
          disabled={false}
          checkboxPosition='left'
        ></CheckboxOptions>
      </div>
      <div className={'flex flex-col justify-center space-y-4'}>
        <div className="flex flex-row justify-between space-x-10">
          <SecondaryButton className={'rounded-l-none font-bold w-60'} onClick={onCancel}>Close</SecondaryButton>
          <PrimaryButton className="font-bold w-60" onClick={onSubmit} disabled={!toggle}>Accept</PrimaryButton>
        </div>
      </div>
    </div>
  </DialogFrame>
}
