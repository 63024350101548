import React, { forwardRef } from 'react'

export const StrucRow = forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement>>((props, ref) => {
  const {
    className,
    children,
    ...rest
  } = props
  return  <div ref={ref} {...rest} className={`flex flex-row flex-wrap w-full ${className ?? ''}`}>
      {children}
    </div>
})