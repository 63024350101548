import { useOrderDetailsViewModel } from './useOrderDetailsViewModel'
import { Order } from '../../../core/models/entities/Order'
import { OrderDetailsComponent } from './OrderDetailsComponent'

interface Props {
  order: Order
}

export function OrderDetailsWrapper({ order }: Props) {
  const vm = useOrderDetailsViewModel(order);
  return <OrderDetailsComponent {...vm} />
}


