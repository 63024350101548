import { JSX } from 'react'
import { useRepositoriesContext } from '../../../../contexts/RepositoriesContext'
import LoadingElement from '../../../components/loading-element/loading-element'
import { Order } from '../../../../core/models/entities/Order'
import { ErrorView } from '../../../components/error-view/ErrorView'
import { ComponentState, useComponentStateMachine } from '../../../shared/hooks/useComponentStateMachine'
import { OrderDetails } from '../components/order-details.component'

interface WrapperProps {
  order: Order
  onClose: () => void,
  onShowRejectionNote: (Order: Order) => void
}

export function OrderDetailsWrapper({ order, onClose, onShowRejectionNote }: WrapperProps): JSX.Element {
  const { orderRepository } = useRepositoriesContext()

  const {
    data: summary,
    isLoading: isSummaryLoading,
    isSuccess: isSummarySuccess,
    isError: isSummaryError
  } = orderRepository.useOrder(order.organization.id, order.id)
  const { state, dataTransition } = useComponentStateMachine()

  dataTransition(isSummaryLoading, isSummarySuccess, isSummaryError)

  return state === ComponentState.Succeed
    ? <OrderDetails onShowRejectionNote={onShowRejectionNote} order={summary!} onClose={onClose} />
    : state === ComponentState.Loading
      ? <div className={'w-220 h-128 relative'}>
        <LoadingElement />
      </div>
      : <div className={'w-220 h-128 relative'}>
        <ErrorView />
      </div>
}




