import { useState } from 'react'
import { IdLookUps } from '../../../utils/models/idLookUp'
import { MultiSelectEvent } from '../../components/GenericTable/Models/multiSelectEvent'

export interface MultiSelectStateMachine<T extends Identifiable> {
  isMultiSelect: boolean
  toggleMultiSelect: () => void
  selectedData: IdLookUps<T>
  onRowChecked: (event: MultiSelectEvent<T>) => void
  onMultiSelectRow: (data: T) => void
}

interface Identifiable {
  id: number
}

interface Props<T extends Identifiable> {
  onSelect: (item: T) => void
  enabled?: boolean
}

export function useMultiSelectStateMachine<T extends Identifiable>({ onSelect, enabled }: Props<T>): MultiSelectStateMachine<T> {
  const [isMultiSelect, setIsMultiSelect] = useState<boolean>(enabled ?? false)
  const [selectedData, setSelectedData] = useState<IdLookUps<T>>({})

  function toggleMultiSelect() {
    setIsMultiSelect(b => !b)
  }

  function onMultiSelectRow(data: T) {
    if (isMultiSelect) {
      if (data.id in selectedData) {
        setSelectedData(selected => {
          delete selected[data.id];
          return { ...selected };
        })
      } else {
        setSelectedData(selected => {
          selected[data.id] = data;
          return { ...selected };
        })
      }
    } else {
      return onSelect(data)
    }
  }

  function onRowChecked(event: MultiSelectEvent<T>) {
    if (!event.checked) {
      setSelectedData(selected => {
        delete selected[event.data.id];
        return { ...selected };
      })
    } else {
      setSelectedData(selected => {
        selected[event.data.id] = event.data;
        return { ...selected };
      })
    }
  }

  return {
    isMultiSelect,
    toggleMultiSelect,
    selectedData,
    onRowChecked,
    onMultiSelectRow
  }
}