import Paginator from './Paginator'
import '../../GenericTable.css'
import { PageChangeEvent } from '../../Models/pageChangeEvent'
import PageSizeSelector, { PageSizeEvent } from './PageSizeSelector'
import { PageQuery } from '../../../../../utils/models/pageQuery'

interface ListFooterProps {
  lastPage: number
  onNewPage: (nextPageEvent: PageChangeEvent) => void;
  onPageSizeChange: (nextPageEvent: PageSizeEvent) => void;
  initialQuery: PageQuery;
}

function TableFooter({ onNewPage, onPageSizeChange, lastPage, initialQuery }: ListFooterProps) {
  return (
    <div className='flex items-center justify-end'>
      <PageSizeSelector initialSize={initialQuery.page.size} pageSizes={[25, 50, 100]}
                        onNewPageSize={onPageSizeChange} />
      <Paginator lastPage={lastPage} initialIndex={initialQuery.page.index} onNewPage={onNewPage} />
    </div>
  )
}


export default TableFooter