import React, { useState } from 'react'
import { Profile } from '../../../../core/models/entities/Profile'
import { CustomInputChangeEvent } from '../../../../utils/models/events/CustomInputChangeEvent'
import { OrderOptionsEditor, OrderOptionsEditorViewModel, OrderOptionsForm } from './order-options-editor.component'

interface WrapperProps {
  profile: Profile
  onFormChange: (form: OrderOptionsForm) => void
  disabled: boolean
  initialState: boolean
}

export function OrderOptionsEditorWrapper({ disabled, onFormChange, initialState, profile }: WrapperProps) { 
  const vm = useViewModel(profile, onFormChange, disabled, initialState)
  return <OrderOptionsEditor {...vm} />;
}

function useViewModel(
  profile: Profile,
  onFormChange: (form: OrderOptionsForm) => void,
  disabled: boolean,
  initialState: boolean): OrderOptionsEditorViewModel {

  const [toggleState, setToggleState] = useState(initialState);

  const form = {
    orderOptionsTermsOfDelivery: profile.orderOptions?.orderOptionsTermsOfDelivery ?? '',
    orderOptionsTermsOfStandardPackaging: profile.orderOptions?.orderOptionsTermsOfStandardPackaging ?? '',
    orderOptionsTermsOfSpecificPackaging: profile.orderOptions?.orderOptionsTermsOfSpecificPackaging ?? '',
    orderOptionsDeliveryCost: profile.orderOptions?.orderOptionsDeliveryCost ?? 0,
  }

  function handleFormChange(event: React.ChangeEvent<HTMLInputElement> | CustomInputChangeEvent) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    //@ts-ignore
    form[name] = value;

    onFormChange(form);
  }

  function handleDeliveryCostChange (v: number) {
    form.orderOptionsDeliveryCost = v;
    onFormChange(form);
  }

  const toggle = () => { setToggleState(!toggleState) }

  return {
    disabled,
    form,
    onDeliveryCostChange: handleDeliveryCostChange,
    onFormChange: handleFormChange,
    initialState: toggleState,
    onToggle: toggle
  };
}
