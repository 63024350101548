import { useLogoOptionsContext } from '../../../../contexts/LogoOptionsContext';
import { BaseLogo, GetProductMinQuantity, getColors, isDigital } from '../../../../core/models/entities/BaseLogo';
import { Stepper } from '../../../components/Buttons/Stepper';
import { DigitalColorElement } from '../../../components/Logo/DigitalColorElement';
import { PriceLine } from '../../../components/Logo/LogoPrices';
import { VectorColorElement } from '../../../components/Logo/VectorColorElement';
import { CartItemLogo } from '../models/CartItem'

interface ProductDetailsLogoProps {
  orderLine: CartItemLogo
  handleLogosToDelivery: (v: number) => void
  handleLogosToStorage: (v: number) => void
}

export function ProductDetailsLogo({ orderLine, handleLogosToDelivery, handleLogosToStorage }: ProductDetailsLogoProps) {

  const logo = orderLine.baseLogo

  const logosToDelivery = orderLine.toDelivery
  const logosToStorage = orderLine.toStorage

  let colorsElements = getColorsElements(logo);

  const size = logo.transferMethod.size;
  const type = logo.logoType;
  const method = logo.transferMethod.key;
  const storage = logo.inStorage
  const prices = logo.product?.prices.sort(x => x.minQuantity) ?? []

  let highlightedMinQuantity: number | undefined = GetProductMinQuantity(prices, logosToDelivery + logosToStorage)

  const productPrices = prices.map(p => {
    const key = crypto.randomUUID()
    return (
      <span key={key} className={`pt-1 text-xs capitalize inline pr-1 ${highlightedMinQuantity == p.minQuantity ? "font-bold" : ""}`}>
        {PriceLine({ price: p })}
      </span>
    )
  })

  const additionalFees = <>
    <ol>
      {(logo.product?.additionalFees ?? []).map((fee, i) =>
        <li key={`${fee.itemNo}_${i}`} >{fee.name} ({fee.unitPrice} DKK x {fee.quantity})</li>
      )}
    </ol>
  </>

  return <>
    <div className={"grid grid-cols-2 gap-4"}>
      <div>
        <div className={'block text-sm font-semibold uppercase'}>Details</div>
        <span className='block pt-1 text-xs'>
          <p className={'font-bold capitalize inline pr-1'}>Logo Name</p>
          <p className={'uppercase inline'}>{orderLine.title}</p>
        </span>
        <span className='block pt-1 text-xs'>
          <p className={'font-bold capitalize inline pr-1'}>Size</p>
          <p className={'uppercase inline'}>{size}</p>
        </span>
        <span className='block pt-1 text-xs'>
          <p className={'font-bold capitalize inline pr-1'}>Type</p>
          <p className={'uppercase inline'}>{type}</p>
        </span>
        <span className='block pt-1 text-xs'>
          <p className={'font-bold capitalize inline pr-1'}>Method</p>
          <p className={'uppercase inline'}>{method}</p>
        </span>
        <span className='block pt-1 text-xs flex flex-row'>
          <p className={'font-bold capitalize inline pr-1'}>Colors</p>
          {colorsElements}
        </span>
        <span className='block pt-1 text-xs'>
          <p className={'font-bold capitalize inline pr-1'}>In Storage</p>
          <p className={'uppercase inline'}>{storage}</p>
        </span>
      </div>
      <div>
        <div className={'block text-sm font-semibold uppercase'}>Prices</div>
        <div className={"grid grid-rows-5 grid-flow-col gap-0"}>
          {productPrices}
        </div>
      </div>
      <div>
        <div className={'block text-sm font-semibold uppercase'}>QTY</div>
        <span className='block pt-1 text-xs'>
          <p className={'uppercase inline pr-1'}>Logos to storage</p>
        </span>
        <Stepper onValueChange={handleLogosToStorage} min={0} value={logosToStorage} />
        <span className='block pt-1 text-xs'>
          <p className={'uppercase inline pr-1'}>Logos to delivery</p>
        </span>
        <Stepper onValueChange={handleLogosToDelivery} min={0} value={logosToDelivery} />
      </div>
      <div>
        <div className={'block text-sm font-semibold uppercase'}>EXTRA FEES</div>
        {additionalFees}
      </div>
    </div>
  </>;
}

export function getColorsElements(logo: BaseLogo) {
  const { colors: colorOptions } = useLogoOptionsContext()
  const colors = getColors(logo)
  const pantoneColors = colorOptions.filter(co => colors.find(c => c === co.id))
  const colorsElements = isDigital(logo) ? <DigitalColorElement /> :
    pantoneColors.map(co => <VectorColorElement key={co.id} showName={false} color={co} />)

  return (
    <div className='flex flex-row'>
      {colorsElements}
    </div>
  )
}

export function getMethodDisplay(logo: BaseLogo) {
  const transferMethods = [(logo.transferMethod.method1.toLocaleLowerCase() ?? 'N/A'), logo.transferMethod.method2.toLocaleLowerCase(), logo.transferMethod.method3.toLocaleLowerCase()].filter(x => x?.length > 0)
  return <p className="capitalize">{transferMethods.join(", ")}</p>
} 