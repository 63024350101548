import { ScaledViewInfo, ViewInfo } from '../../design-editor/design-view/DesignView.vm'
import { calculateActualPpcm } from '../../../../utils/functions/SizeUtil'

export function getScaledViewInfo(
  view: ViewInfo,
  containerHeight: number,
  containerWidth: number
): ScaledViewInfo {


  const maskImage = view.maskImage
  const maskCanvas = view.maskCanvas

  let newCanvasWidth = 0
  let newCanvasHeight = 0
  const viewAspectRatio = view.width / view.height

  newCanvasHeight = containerHeight
  newCanvasWidth = newCanvasHeight * viewAspectRatio
  const newPpcm = calculateActualPpcm(view.width, newCanvasWidth, view.ppcm)

  maskCanvas.width = newCanvasWidth
  maskCanvas.height = newCanvasHeight

  return {
    viewId: view.viewId,
    width: newCanvasWidth,
    height: newCanvasHeight,
    ppcm: newPpcm,
    maskImage: maskImage,
    maskCanvas: maskCanvas,
    styleToViewSizeDelta: newCanvasWidth / view.width
  }
}