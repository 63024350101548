import { useState } from 'react'


export enum PagedComponentState {
  Loading,
  PageLoading,
  Succeed,
  Failed
}

export function usePagedComponentStateMachine() {
  const [current, setState] = useState<PagedComponentState>(PagedComponentState.Loading)

  function canTransition(from: PagedComponentState, to: PagedComponentState) {
    switch (from) {
      case PagedComponentState.Loading:
        return to === PagedComponentState.Succeed || to === PagedComponentState.Failed
      case PagedComponentState.PageLoading:
        return to === PagedComponentState.Succeed || to === PagedComponentState.Failed
      case PagedComponentState.Succeed:
        return to === PagedComponentState.PageLoading
      case PagedComponentState.Failed:
        return to === PagedComponentState.PageLoading
    }
  }

  function transitionTo(to: PagedComponentState) {
    if (to === current) {
      return
    }

    if (!canTransition(current, to)) {
      throw new Error('illegal transition')
    }

    setState(to)
  }

  function transitionOrStaySucceeded() {
    transitionTo(PagedComponentState.Succeed)
  }

  function transitionOrStayFailed() {
    transitionTo(PagedComponentState.Failed)
  }

  function transitionOrStayLoading() {
    if (canTransition(current, PagedComponentState.Loading)) {
      transitionTo(PagedComponentState.Loading)
      return
    }
    if (canTransition(current, PagedComponentState.PageLoading)) {
      transitionTo(PagedComponentState.PageLoading)
      return
    }
  }

  function dataTransition(isLoading: boolean, isSucceeded: boolean, isFailed: boolean) {
    if (isSucceeded) {
      transitionOrStaySucceeded()
    } else if (isFailed) {
      transitionOrStayFailed()
    } else if (isLoading) {
      transitionOrStayLoading()
    }
  }

  return {
    state: current,
    transition: transitionTo,
    dataTransition: dataTransition
  }
}