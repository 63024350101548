import { NamePlaceholderLogoCreate } from '../components/panel/name-placeholder-logo-create.component'
import { useNamePlaceholderLogoCreateViewModel } from '../vmHooks/useNamePlaceholderLogoCreateViewModel'

export function NamePlaceholderLogoCreateWrapper() {
  const vm = useNamePlaceholderLogoCreateViewModel()
  return <NamePlaceholderLogoCreate {...vm} />
}



