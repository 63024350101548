import { Design } from '../../../core/models/entities/Design'
import { DesignStatus } from '../../../core/models/entities/enums/DesignStatus'
import { ApprovalDisplay } from '../Approval/ApprovalDisplay'

export function DesignStatusElement({ design }: { design: Design }) {
  switch (design.status) {
    case DesignStatus.Unknown:
      return <p className={`mb-1 font-semibold text-xs text-gray-600`}>UNKNOWN</p>
    case DesignStatus.Draft:
      return <p className={`mb-1 font-semibold text-xs text-orange-400`}>DRAFT</p>
    case DesignStatus.Rejected:
      return <ApprovalDisplay approval={design.approval!} />
    case DesignStatus.Approval:
      return <ApprovalDisplay approval={design.approval!} />
    case DesignStatus.Done:
      return <p className={`mb-1 font-semibold text-xs text-status-success`}>APPROVED</p>
  }
}