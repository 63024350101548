import { JSX } from 'react'
import { Dialog_VM, DialogFrame, useDialog } from './dialog-base'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFolder, faFolderPlus } from '@fortawesome/free-solid-svg-icons'
import { useNavigate } from 'react-router'
import { SecondaryButton } from '../Buttons/SecondaryButton'

interface useAddToOrderDialogProps {
  component: JSX.Element
  open: () => void
}

export function useAddToOrderDialog(
  onCancel?: () => void
): useAddToOrderDialogProps {
  const dialog = useDialog()

  const navigate = useNavigate()

  function handleCreateNewLogo() {
    navigate("/logo/create")
    dialog.closeDialog()
  }

  function handleCreateNewDesign() {
    navigate("/design/create")
    dialog.closeDialog()
  }

  function handleChooseExistingLogo() {
    navigate("/logo?pageSize=25&pageIndex=1")
    dialog.closeDialog()
  }

  function handleChooseExistingDesign() {
    navigate("/design?pageSize=25&pageIndex=1")
    dialog.closeDialog()
  }

  function handleCancel() {
    if (onCancel) onCancel()
    dialog.closeDialog()
  }

  return {
    component: <AddToOrderDialog dialogVm={dialog} 
    onCreateNewLogo={handleCreateNewLogo}
    onCreateNewDesign={handleCreateNewDesign}
    onChooseExistingLogo={handleChooseExistingLogo}
    onChooseExistingDesign={handleChooseExistingDesign}
    onCancel={handleCancel} />,
    open: dialog.openDialog
  }
}

interface AddToOrderDialogProps {
  dialogVm: Dialog_VM
  onCreateNewLogo: () => void
  onCreateNewDesign: () => void
  onChooseExistingLogo: () => void
  onChooseExistingDesign: () => void
  onCancel?: () => void
}

export function AddToOrderDialog(
  {
    onCreateNewLogo,
    onCreateNewDesign,
    onChooseExistingLogo,
    onChooseExistingDesign,
    onCancel,
    dialogVm
  }: AddToOrderDialogProps) {

  return <DialogFrame dialogOption={dialogVm}>
    <div className={'flex flex-col space-y-4 p-4'}>
      <p className={'text-left text-base font-bold'}>Add to Order</p>
      <p className={'text-left'}>Click on an option to continue.</p>
      <div className={'flex justify-center space-x-4'}>
      <div className={"grid grid-cols-2 gap-4"}>
          <SecondaryButton width={'full'} className={'gap-2 p-8 font-bold bg-[#F8FAFD]'} onClick={onCreateNewLogo}>
            <FontAwesomeIcon size={'2x'} icon={faFolderPlus} />
            <span className={'flex-1'}>Create New Logo</span>
          </SecondaryButton>
          <SecondaryButton width={'full'} className={'gap-2 p-8 font-bold bg-[#F8FAFD]'} onClick={onCreateNewDesign}>
            <FontAwesomeIcon size={'2x'} icon={faFolderPlus} />
            <span className={'flex-1'}>Create New Design</span>
          </SecondaryButton>
          <SecondaryButton width={'full'} className={'gap-2 p-8 font-bold bg-[#F8FAFD]'} onClick={onChooseExistingLogo}>
            <FontAwesomeIcon size={'2x'} icon={faFolder} />
            <span className={'flex-1'}>Choose Existing Logo</span>
          </SecondaryButton>
          <SecondaryButton width={'full'} className={'gap-2 p-8 font-bold bg-[#F8FAFD]'} onClick={onChooseExistingDesign}>
            <FontAwesomeIcon size={'2x'} icon={faFolder} />
            <span className={'flex-1'}>Choose Existing Design</span>
          </SecondaryButton>
      </div>
      </div>
    </div>
  </DialogFrame>
}
