import { useWhiteLabelContext } from '../../../../../WhiteLabelContext'

export interface PageIndexButtonProps {
  pageIndex: number;
  goToPage: (pageIndex: number) => void
  active: boolean
}

function PageIndexButton({ pageIndex, goToPage, active }: PageIndexButtonProps) {

  const { whiteLabel } = useWhiteLabelContext()
  return (
    <div key={pageIndex}
         style={{backgroundColor: active ? whiteLabel.colorHex : undefined}}
         className={'rounded-md text-xs flex items-center justify-center h-8 w-8 cursor-pointer bg-transparent text-gray-600 font-semibold ' + (active ? 'text-white border-none' : 'hover:bg-gray-200')}
         onClick={() => goToPage(pageIndex)}>
      <span>{pageIndex}</span></div>)
}

export default PageIndexButton
