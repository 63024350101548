import { useRepositoriesContext } from '../../../contexts/RepositoriesContext'
import { StyleEditViewModel } from './StyleEditViewModel'

export function useStyleEditViewModel(styleId: number): StyleEditViewModel {
  const { styleRepository } = useRepositoriesContext()
  const {
    data: style,
    isLoading: isStyleLoading,
    isSuccess: isStyleSuccess
  } = styleRepository.useStyle(styleId)

  return {
    style,
    isStyleLoading,
    isStyleSuccess,
    updateStyle: styleRepository.updateStyle
  }
}
