import { ReactElement, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { UpdateStyleDto } from '../../../../../core/models/dtos/UpdateStyleDto'
import { Style } from '../../../../../core/models/entities/Style'
import { useStyleEditFormViewModel } from './useStyleEditFormViewModel'
import { JetSportBox } from '../../../../components/BoxView/JetSportBox'
import { BoxHeader2 } from '../../../../components/BoxView/BoxHeader2'
import { BoxHeaderTypography } from '../../../../components/Typographies/BoxHeaderTypography'
import { BoxContent } from '../../../../components/BoxView/BoxContent'
import { BoxFooter } from '../../../../components/BoxView/BoxFooter'
import LoadingElement from '../../../../components/loading-element/loading-element'
import { PrimaryButton } from '../../../../components/Buttons/PrimaryButton'
import { SecondaryButton } from '../../../../components/Buttons/SecondaryButton'
import { InputWrapper } from '../../../../components/Input/InputWrapper'
import { InputLabel } from '../../../../components/Input/InputLabel'
import { CustomInput } from '../../../../components/Input/CustomInput'
import { LineSeparator } from '../../../../components/StructureElements/LineSeparator'
import { SectionHeaderTypography } from '../../../../components/Typographies/SectionHeaderTypography'
import { StyleView } from '../../../../../core/models/entities/StyleView'
import { ViewSelector } from './ViewSelector'
import { faPenToSquare } from '@fortawesome/free-regular-svg-icons'
import { useNavigate } from 'react-router-dom'
import { WhiteLabelIcon } from '../../../logo-editor/componenets/WhiteLabelIcon'
import { StrucCol } from '../../../../components/StructureElements/StrucCol'
import { StrucRow } from '../../../../components/StructureElements/StrucRow'

interface Props {
  style: Style
  updateStyle: (dto: UpdateStyleDto) => Promise<Style>
}

export function StyleEditForm({ style, updateStyle }: Props): ReactElement {
  const viewModel = useStyleEditFormViewModel(style, updateStyle)
  const [currentView, setCurrentView] = useState<StyleView>()
  const navigate = useNavigate()

  const currentViewForm = currentView ? viewModel.views.find(v => v.id === currentView.id) : viewModel.views[0];

  const { t } = useTranslation()

  function handleCancel() {
    navigate('/style')
  }

  return (
    <JetSportBox>
      <StrucRow>
        <StrucCol size={3} className={'border-r'}>
          <BoxHeader2 className={'items-center justify-between'}>
            <BoxHeaderTypography>Edit Style</BoxHeaderTypography>
            <WhiteLabelIcon className={'text-primary-100'} icon={faPenToSquare} />
          </BoxHeader2>
          <BoxContent className={'space-y-4'}>
            <StrucRow>
              <StrucCol>
                <InputWrapper>
                  <InputLabel>{t('name')}</InputLabel>
                  <CustomInput value={viewModel.name} onChange={x => viewModel.onNameChange(x.target.value)} />
                </InputWrapper>
              </StrucCol>
            </StrucRow>
            <StrucRow>
              <StrucCol>
                <InputWrapper>
                  <InputLabel>{t('model')}</InputLabel>
                  <CustomInput value={viewModel.model} onChange={x => viewModel.onModelChange(x.target.value)} />
                </InputWrapper>
              </StrucCol>
            </StrucRow>
            {currentViewForm && <>
              <LineSeparator />
              <SectionHeaderTypography>{currentViewForm.view} View Details</SectionHeaderTypography>
              <StrucRow className={'h-full flex-1 !justify-start'}>
                <StrucCol>
                  <InputWrapper>
                    <InputLabel>{t('ppcm')}</InputLabel>
                    <CustomInput onChange={x => viewModel.onPpcmChange(currentViewForm!.id, Number(x.target.value))}
                                 type={'number'} value={currentViewForm.ppcm}></CustomInput>
                  </InputWrapper>
                </StrucCol>
              </StrucRow></>}
            {viewModel.submitting && <LoadingElement transparent className={'rounded'} />}
          </BoxContent>
          <BoxFooter className={'justify-end flex-col !space-x-0 space-y-4'}>
            <StrucRow className={'!justify-start'}>
              <StrucCol size={5.5} offset={6.5}>
                <SecondaryButton width={'full'} onClick={viewModel.handleAnnotate}>Annotate</SecondaryButton>
              </StrucCol>
            </StrucRow>
            <StrucRow className={'!justify-start'}>
              <StrucCol size={5.5}>
                <SecondaryButton width={'full'} onClick={handleCancel}>Cancel</SecondaryButton>
              </StrucCol>
              <StrucCol size={5.5} offset={1}>
                <PrimaryButton width={'full'} onClick={viewModel.submit}>
                  {t('submit')}
                </PrimaryButton>
              </StrucCol>
            </StrucRow>
          </BoxFooter>
        </StrucCol>
        <StrucCol size={9}>
          <BoxHeader2 className={'bg-gray-100 rounded-tr'}></BoxHeader2>
          {!!viewModel.views.length &&
            <StyleViewSelector onSelectView={v => setCurrentView(v)} styleViews={style.styleViews} />}
        </StrucCol>
      </StrucRow>
    </JetSportBox>
  )
}

function StyleViewSelector({ styleViews, onSelectView }: {
  styleViews: StyleView[],
  onSelectView: (view: StyleView) => void
}) {
  const [currentView, setCurrentView] = useState<StyleView>()
  if (!currentView) {
    setCurrentView(styleViews[0])
  }

  function handleSelectView(view: StyleView) {
    setCurrentView(view)
    onSelectView(view)
  }

  return <div className={'h-full w-full flex flex-col '}>
    <StrucRow className={'flex-1 justify-center'}>
      <StrucCol>
        <img className={'object-contain h-128'} src={currentView?.imageUrl} alt={'' + currentView?.id} />
      </StrucCol>
    </StrucRow>
    <ViewSelector views={styleViews} onSelectView={v => handleSelectView(v)} currentView={currentView} />
  </div>
}



