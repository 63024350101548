import { Marker } from '../models/marker.model'
import interact from 'interactjs'
import { ActionName, ActionProps, Interactable, InteractEvent } from '@interactjs/types'
import { parseDataset } from './parse-dataset.function'
import { capRadianToFullRotation } from './capRadianToFullRotation'
import { degreesToRadians } from '../../../../utils/functions/MathUtil'

export function setupInteractJs(
  marker: Marker,
  isActive: boolean,
  isLocked: boolean,
  selectLogo: (markerId: Marker['id']) => void,
  onManipulate: (x: number, y: number, width: number, height: number, angle: number) => void,
  onManipulateEnd: (x: number, y: number, width: number, height: number, angle: number) => void
) {
  const interactor = interact(`#box-${marker.id}`)

  const enabled = !isLocked
  interactor.draggable({
    inertia: false,
    modifiers: [
      interact.modifiers.restrictRect({
        restriction: 'parent',
        enabled: true
      })
    ],
    listeners: {
      move: (event: InteractEvent) => {
        if (!isActive) {
          selectLogo(marker.id)
          return
        }
        onDragListener(event)
      },
      end: (event: InteractEvent) => {
        onLogoManipulationEnd(event)
      }
    },
    enabled
  })

  function onLogoManipulationEnd(event: InteractEvent) {
    const { x, y } = parseDataset(event.target.dataset)
    onManipulateEnd(x + event.dx, y + event.dy, marker.widthPx, marker.heightPx, marker.rotationRad)
  }

  function onDragListener(event: InteractEvent) {
    const { x, y } = parseDataset(event.target.dataset)
    onManipulate(x + event.dx, y + event.dy, marker.widthPx, marker.heightPx, marker.rotationRad)
  }

  /*if (logoComp.designLogo.logoType === LogoType.NORMAL) {
    interactor.resizable({
      edges: {
        bottom: `#item_resizer-${logoComp.designLogo.inDesignId}`,
        right: `#item_resizer-${logoComp.designLogo.inDesignId}`
      },
      listeners: {
        move: function(event: ResizeEvent) {
          onResizeListener(event)
          onLogoManipulationEnd()
        }
      },
      modifiers: [
        interact.modifiers.aspectRatio({
          ratio: width / height,
          // also restrict the size by nesting another modifier
          modifiers: [interact.modifiers.restrictSize({ max: 'parent' })]
        })
      ]
    })
  }*/

  function onRotateStart(event: InteractEvent) {
    const parent = event.target.parentElement
    if (!parent) {
      return
    }

    const rect = parent.getBoundingClientRect()

    // store the center as the element has css `transform-origin: center center`
    parent.setAttribute('data-center-x', `${rect.left + rect.width / 2}`)
    parent.setAttribute('data-center-y', `${rect.top + rect.height / 2}`)

    // get the angle of the element when the drag starts
    parent.setAttribute('data-angle', `${marker.rotationRad}`)
    parent.setAttribute('data-angle', `${getDragAngle(event)}`)
    // get the angle of the element when the drag starts
    /*const angle = getDragAngle(event)
    console.warn(angle)
    /!*const startAngel = capRadianToFullRotation(angle ?? 0)
    console.warn(startAngel)*!/
    if (!parent.hasAttribute('data-angle')) {
      console.log('has no angle')
      parent.setAttribute('data-angle', `${0}`)
    } else if (parent.hasAttribute('data-angle') && parent.getAttribute('data-angle') == 'null') {
      console.log('has angle but is null')
      parent.setAttribute('data-angle', `${angle}`)
    }*/
  }

  function onRotateListener(event: InteractEvent) {
    const parent = event.target.parentElement
    if (!parent) {
      return
    }

    const { x, y } = parseDataset(parent.dataset)

    const angle = getDragAngle(event)

    if (angle) {
      const fixedAngle =angle + marker.rotationRad
       /* Math.round(() / degreesToRadians(7.5)) * degreesToRadians(7.5)*/
      const caped = capRadianToFullRotation(fixedAngle)
      onManipulate(x, y, marker.widthPx, marker.heightPx, caped)
    }
  }

  function onRotateListenerEnd(event: InteractEvent) {
    const parent = event.target.parentElement
    if (!parent) {
      return
    }

    const { x, y } = parseDataset(parent.dataset)

    const angle = getDragAngle(event)
    if (angle) {
      const fixedAngle =
        Math.round((angle + marker.rotationRad) / degreesToRadians(7.5)) * degreesToRadians(7.5)
      const caped = capRadianToFullRotation(fixedAngle)
      onManipulateEnd(marker.x, marker.y, marker.widthPx, marker.heightPx, caped)
    }
    //onRotateStart(event)
  }


  interact(`#item_rotater-${marker.id}`).draggable({
    cursorChecker: (
      action: ActionProps<ActionName>,
      interactable: Interactable,
      element: Element,
      interacting: boolean
    ) => {
      if (interacting) {
        return 'grabbing'
      } else {
        return 'grab'
      }
    },
    /*onstart: (event: InteractEvent) => onRotateStart(event),
    onmove: (event: InteractEvent) => onRotateListener(event)*/
    listeners: {
      start: (event: InteractEvent) => onRotateStart(event),
      move: (event: InteractEvent) => onRotateListener(event),
      end: onRotateListenerEnd
      /*end: (event: InteractEvent) => {
        onLogoManipulationEnd(event)
      }*/
    },
    enabled
  })
}


function getDragAngle(event: InteractEvent): number | null {
  var parent = event.target.parentElement
  if (!parent) {
    return null
  }

  const dataAngle = parent.getAttribute('data-angle')
  const dataCenterX = parent.getAttribute('data-center-x')
  const dataCenterY = parent.getAttribute('data-center-y')

  if (!dataAngle || !dataCenterX || !dataCenterY) {
    return null
  }
  const startAngleRad = parseFloat(dataAngle) || 0
  const centerX = parseFloat(dataCenterX) || 0
  const centerY = parseFloat(dataCenterY) || 0
  var angleRad = Math.atan2(centerY - event.clientY, centerX - event.clientX)
  return capRadianToFullRotation(angleRad - startAngleRad)
}

