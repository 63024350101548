import { CreateCustomerBody } from '../models/bodies/CreateCustomerBody'
import { SaveDesignSnapshotBody } from '../models/bodies/SaveDesignSnapshotBody'
import { UpdateCustomerBody } from '../models/bodies/UpdateCustomerBody'
import { DesignSnapshotResponse } from '../models/responses/DesignSnapshotResponse'
import { LogoPriceDto, OrderDto } from '../models/responses/OrderDto'
import { ProfileResponse } from '../models/responses/ProfileResponse'
import { UrlResponse } from '../models/responses/UrlResponse'
import { useAuthorizedFetch } from './AuthorizedFetch'
import { UpdateDesignBody } from '../models/bodies/UpdateDesignBody'
import { TextLogoResponse } from '../models/responses/TextLogoResponse'
import { CreateTextLogoBody } from '../models/bodies/CreateTextLogoBody'
import { ManufacturerDto } from '../models/responses/ManufacturerDto'
import { DesignSummaryResponse } from '../models/responses/DesignSummaryResponse'
import { CreateManufacturerBody } from '../models/bodies/CreateManufacturerBody'
import { CreateUserBody } from '../models/bodies/CreateUserBody'
import { CreateMaskQueueCount } from '../models/responses/CreateMaskQueueCount'
import { UpdateStyleBody } from '../models/bodies/UpdateStyleBody'
import { PageQuery, toQueryParam } from '../../utils/models/pageQuery'
import { BaseLogo, ImageLogo } from '../../core/models/entities/BaseLogo'
import { Organization } from '../../core/models/entities/Organization'
import { enviroment } from '../../enviroment'
import { CreateDesignDto } from '../../core/models/dtos/CreateDesignDto'
import { DesignDto } from '../models/responses/DesignDto'
import { PagedResponse } from '../models/responses/pagedResponse'
import { LogoDto } from '../models/responses/LogoDto'
import { OrganizationDto } from '../models/responses/OrganizationDto'
import { UpdateProfileDto } from '../../core/models/entities/UpdateProfileDto'
import { StyleDto } from '../models/responses/StyleDto'
import { ApprovalSubmission } from '../models/bodies/ApprovalSubmission'
import { LogoOptionsDto } from '../models/responses/logoOptionsDto'
import { TransferDto } from '../models/responses/transferOptionsDto'
import { ChangeLogoColors, InitiateApproval } from '../../presentation/screens/logo-list/hooks/useLogoActionsHandler'
import { LogoCommit } from '../models/bodies/LogoCommit'
import { Design } from '../../core/models/entities/Design'
import { User } from '../../core/models/entities/User'
import { CommissionProfile } from '../../core/models/entities/Commission'
import { CommissionProfileDto } from '../models/responses/CommissionDto'
import { CreateOrderDto, CreateOrderPreferencesDto, UpdateOrderDto } from '../../core/models/dtos/CreateOrderDto'
import { DeliveryType, Order, Packaging } from '../../core/models/entities/Order'
import { Verdict } from '../../presentation/components/ApprovalVoting/approval-voting'
import { CountryDto } from '../models/responses/CountryDto'
import { CreateImageLogoBody } from '../models/bodies/CreateImageLogoBody'
import { CartItemDesign } from '../../presentation/screens/order-create/models/CartItem'
import { CreateDesignTemplateDto } from '../../core/models/dtos/CreateDesignTemplateDto'
import { DesignTemplateDto } from '../models/responses/DesignTemplateDto'
import { CreateNameLogoBody } from '../models/bodies/CreateNameLogoBody'
import { CreateNumberLogoBody } from '../models/bodies/CreateNumberLogoBody'
import { NumberPackageDto } from '../models/responses/NumberPackageDto'
import { ChangeStyleBody, ChangeTitleBody } from '../models/bodies/ChangeBody'

export function useApiClient() {
    const API_URL = enviroment.apiUrl

    const { authFetch } = useAuthorizedFetch()

    async function getAllStyles(query: PageQuery): Promise<PagedResponse<StyleDto>> {

        const queryString = toQueryParam(query);
        const response = await authFetch(

            `${API_URL}/styles${queryString}`
        )

        return await response.json()
    }

    async function getAllDesigns(query: PageQuery): Promise<PagedResponse<DesignDto>> {
        const queryString = toQueryParam(query);
        const response = await authFetch(
            `${API_URL}/organizations/designs${queryString}`
        )
        return await response.json()
    }

    async function getAllOrders(query: PageQuery): Promise<PagedResponse<OrderDto>> {
        const response = await authFetch(
            `${API_URL}/organizations/orders${toQueryParam(query)}`
        )
        return await response.json()
    }

    async function getAllManufacturers(orgId?: number): Promise<PagedResponse<ManufacturerDto>> {
        let orgIdQuery = ''

        if (orgId) {
            orgIdQuery = `orgId=${orgId}&`
        }

        const response = await authFetch(
            `${API_URL}/manufacturers?${orgIdQuery}`
        )
        return await response.json()
    }

    async function getDesign(organizationId: number, designId: number): Promise<DesignDto> {
        const response = await authFetch(
            `${API_URL}/organizations/${organizationId}/designs/${designId}`
        )
        return await response.json()
    }

    async function createDesign(body: CreateDesignDto): Promise<DesignDto> {
        const response = await authFetch(
            `${API_URL}/organizations/${body.customerId}/designs`,
            {
                method: 'POST',
                body: JSON.stringify(body)
            }
        )
        return await response.json()
    }

    async function updateDesign(
        organizationId: number,
        designId: number,
        body: UpdateDesignBody
    ): Promise<DesignDto> {
        const response = await authFetch(
            `${API_URL}/organizations/${organizationId}/designs/${designId}`,
            {
                method: 'PUT',
                body: JSON.stringify(body)
            }
        )
        return await response.json()
    }


    async function createImageLogo(organizationId: number, body: CreateImageLogoBody): Promise<LogoDto> {
        const response = await authFetch(
            `${API_URL}/organizations/${organizationId}/image-logo`,
            {
                method: 'POST',
                body: JSON.stringify(body)
            }
        )

        return response.json()
    }

    async function uploadFileImageLogo(logo: File, customerId: number, logoId: number): Promise<LogoDto> {
        const formData = new FormData()
        formData.append('file', logo)


        const response = await authFetch(
            `${API_URL}/organizations/${customerId}/image-logo/${logoId}`,
            {
                method: 'PUT',
                body: formData
            }
        )

        return response.json()
    }

    async function createAndUploadImageLogo(logo: File, orgId: number): Promise<LogoDto> {
        const formData = new FormData()
        formData.append('file', logo)

        const response = await authFetch(
            `${API_URL}/organizations/${orgId}/image-logo`,
            {
                method: 'POST',
                body: formData
            }
        )

        return response.json()
    }

    async function createOrder(orgId: number, createOrder: CreateOrderDto): Promise<OrderDto> {
        const response = await authFetch(
            `${API_URL}/organizations/${orgId}/orders`,
            {
                method: 'POST',
                body: JSON.stringify(createOrder)
            }
        )

        return response.json()
    }

    async function updateOrder(orgId: number, orderId: number, updateOrder: UpdateOrderDto): Promise<OrderDto> {
        const response = await authFetch(
            `${API_URL}/organizations/${orgId}/orders/${orderId}`,
            {
                method: 'PUT',
                body: JSON.stringify(updateOrder)
            }
        )

        return response.json()
    }

    async function getOrderPrices(orgId: number | undefined, createOrder: CreateOrderDto): Promise<LogoPriceDto[]> {
        if (orgId === undefined) {
            return []
        }

        const response = await authFetch(
            `${API_URL}/organizations/${orgId}/orderprices`,
            {
                method: 'POST',
                body: JSON.stringify(createOrder)
            }
        )

        return response.json()
    }

    async function commitOrder(orgId: number, orderId: number, createOrder: CreateOrderPreferencesDto): Promise<OrderDto> {
        const response = await authFetch(
            `${API_URL}/organizations/${orgId}/orders/${orderId}/commit`,
            {
                method: 'POST',
                body: JSON.stringify(createOrder)
            }
        )

        return response.json()
    }

    async function createTextLogo(orgId: number, body: CreateTextLogoBody): Promise<LogoDto> {
        const response = await authFetch(
            `${API_URL}/organizations/${orgId}/text-logo`,
            {
                method: 'POST',
                body: JSON.stringify(body)
            }
        )
        return response.json()
    }

    async function createPlaceholderNameLogo(orgId: number, body: CreateNameLogoBody): Promise<LogoDto> {
        const response = await authFetch(
            `${API_URL}/organizations/${orgId}/name-logo`,
            {
                method: 'POST',
                body: JSON.stringify(body)
            }
        )
        return response.json()
    }

    async function createPlaceholderNumberLogo(orgId: number, body: CreateNumberLogoBody): Promise<LogoDto> {
        const response = await authFetch(
            `${API_URL}/organizations/${orgId}/number-logo?orgId=${orgId}`,
            {
                method: 'POST',
                body: JSON.stringify(body)
            }
        )
        return response.json()
    }

    async function generateTextLogoPreview(orgId: number, body: CreateTextLogoBody): Promise<string> {
        const response = await authFetch(
            `${API_URL}/organizations/${orgId}/text-logo-preview`,
            {
                method: 'POST',
                body: JSON.stringify(body)
            }
        )

        return await response.text();
    }

    async function generateNumberLogoPreview(orgId: number, body: CreateNumberLogoBody): Promise<string> {
        const response = await authFetch(
            `${API_URL}/organizations/${orgId}/number-logo-preview?orgId=${orgId}`,
            {
                method: 'POST',
                body: JSON.stringify(body)
            }
        )

        return await response.text();
    }

    async function updateLogo(orgId: number, logo: BaseLogo): Promise<BaseLogo> {
        const response = await authFetch(
            `${API_URL}/organizations/${orgId}/logos/${logo.id}/update`,
            {
                method: 'PUT',
                body: JSON.stringify(logo)
            }
        )

        return response.json()
    }

    async function resizeLogo(orgId: number, logo: BaseLogo): Promise<BaseLogo> {
        const response = await authFetch(
            `${API_URL}/organizations/${orgId}/logos/${logo.id}/resize`,
            {
                method: 'PUT',
                body: JSON.stringify(logo)
            }
        )

        return response.json()
    }

    async function copyLogo(orgId: number, logoId: number): Promise<BaseLogo> {
        const response = await authFetch(
            `${API_URL}/organizations/${orgId}/logos/${logoId}/copy`,
            {
                method: 'POST',
            }
        )

        return response.json()
    }

    async function commitLogo(logo: BaseLogo, logoCommit: LogoCommit): Promise<BaseLogo> {
        const logoId = logo.id;
        const orgId = logo.customer.id;

        let url = `${API_URL}/organizations/${orgId}/logos/${logoId}/commit`

        const response = await authFetch(
            url,
            {
                method: 'PUT',
                body: JSON.stringify(logoCommit)
            }
        )

        return response.json()
    }

    async function commitDesign(design: Design): Promise<DesignDto> {
        const designId = design.id;
        const orgId = design.customer.id;

        let url = `${API_URL}/organizations/${orgId}/designs/${designId}/commit`

        const response = await authFetch(
            url,
            {
                method: 'PUT'
            }
        )

        return response.json()
    }

    async function skipApprovalDesign(design: Design): Promise<DesignDto> {
        const designId = design.id;
        const orgId = design.customer.id;

        let url = `${API_URL}/organizations/${orgId}/designs/${designId}/approval-skip`

        const response = await authFetch(
            url,
            {
                method: 'POST'
            }
        )

        return response.json()
    }

    async function copyDesign(orgId: number, designId: number): Promise<DesignDto> {
        const response = await authFetch(
            `${API_URL}/organizations/${orgId}/designs/${designId}/copy`,
            {
                method: 'POST',
            }
        )

        return response.json()
    }

    async function getAllTextLogos(): Promise<TextLogoResponse[]> {
        return []
        /*const response = await authFetch(
            `${API_URL}/text/logos`
        )
        return response.json()*/
    }

    async function getDesignSnapshot(
        organizationId: number,
        designId: number
    ): Promise<DesignSnapshotResponse> {
        const response = await authFetch(
            `${API_URL}/organizations/${organizationId}/designs/${designId}/snapshot`
        )
        return await response.json()
    }

    async function getDesignPriceSummary(
        organizationId: number,
        designId: number
    ): Promise<DesignSummaryResponse> {
        const response = await authFetch(
            `${API_URL}/organizations/${organizationId}/designs/${designId}/summary`
        )
        return await response.json()
    }

    async function getOrder(
        organizationId: number,
        orderId: number
    ): Promise<OrderDto> {
        const response = await authFetch(
            `${API_URL}/organizations/${organizationId}/orders/${orderId}`
        )

        return await response.json()
    }

    async function saveDesignSnapshot(
        organizationId: number,
        designId: number,
        body: SaveDesignSnapshotBody
    ): Promise<void> {
        await authFetch(
            `${API_URL}/organizations/${organizationId}/designs/${designId}/snapshot`,
            {
                method: 'PUT',
                body: JSON.stringify(body)
            }
        )
    }

    async function submitDesign(organizationId: number, designId: number, submission: ApprovalSubmission): Promise<void> {
        await authFetch(
            `${API_URL}/organizations/${organizationId}/designs/${designId}/submit`,
            {
                method: 'PUT',
                body: JSON.stringify(submission)
            }
        )
    }

    async function getVendor(id: number): Promise<OrganizationDto> {
        const response = await authFetch(
            `${API_URL}/organizations/vendor/${id}`
        )
        return await response.json()
    }

    async function getOrganization(id: number): Promise<OrganizationDto> {
        const response = await authFetch(
            `${API_URL}/organizations/${id}`
        )
        return await response.json()
    }

    async function getCustomer(id: number): Promise<OrganizationDto> {
        const response = await authFetch(
            `${API_URL}/organizations/customer/${id}`
        )
        return await response.json()
    }

    async function getAllVendors(query: PageQuery): Promise<PagedResponse<OrganizationDto>> {
        const queryString = toQueryParam(query);
        const response = await authFetch(
            `${API_URL}/organizations/Vendors${queryString}`
        )
        return await response.json()
    }

    async function getAllCustomers(query: PageQuery): Promise<PagedResponse<OrganizationDto>> {
        const queryString = toQueryParam(query);
        const response = await authFetch(
            `${API_URL}/organizations/Customers${queryString}`
        )
        return await response.json()
    }

    async function createOrganization(body: CreateCustomerBody): Promise<OrganizationDto> {
        const response = await authFetch(
            `${API_URL}/organizations`,
            {
                method: 'POST',
                body: JSON.stringify(body)
            }
        )

        return await response.json()
    }

    async function updateOrganization(
        customerId: number,
        body: UpdateCustomerBody
    ): Promise<OrganizationDto> {
        const response = await authFetch(
            `${API_URL}/organizations/${customerId}`,
            {
                body: JSON.stringify(body),
                method: 'PUT'
            }
        )

        return await response.json()
    }

    async function deleteOrganization(id: number): Promise<void> {
        await authFetch(
            `${API_URL}/organizations/${id}`,
            {
                method: 'DELETE'
            }
        )
    }

    async function createCustomer(customer: Organization): Promise<OrganizationDto> {
        const response = await authFetch(
            `${API_URL}/organizations/customers`,
            {
                method: 'POST',
                body: JSON.stringify(customer)
            }
        )

        return await response.json()
    }

    async function updateCustomer(customer: Organization): Promise<OrganizationDto> {
        const response = await authFetch(
            `${API_URL}/organizations/${customer.id}`,
            {
                body: JSON.stringify(customer),
                method: 'PUT'
            }
        )

        return await response.json()
    }

    async function deleteCustomer(customer: Organization): Promise<void> {
        await authFetch(
            `${API_URL}/organizations/${customer.id}`,
            {
                method: 'DELETE'
            }
        )
    }

    async function getLogos(orgId: number, query: PageQuery): Promise<PagedResponse<LogoDto>> {

        const queryString = toQueryParam(query);
        const response = await authFetch(
            `${API_URL}/logos/data`
        )
        return await response.json()
    }

    async function getAllLogos(query: PageQuery, type?: string): Promise<PagedResponse<LogoDto>> {
        const typeParameter = type ? '/' + type : ''
        const queryString = toQueryParam(query);
        const response = await authFetch(
            `${API_URL}/logos${typeParameter}${queryString}`
        )
        return await response.json()
    }

    async function getAllLogosForDesign(customerId: number, query: PageQuery): Promise<PagedResponse<LogoDto>> {
        const queryString = toQueryParam(query);
        const response = await authFetch(
            `${API_URL}/logos/design/${customerId}${queryString}`
        )
        return await response.json()
    }

    async function getAllCountries(query: PageQuery): Promise<PagedResponse<CountryDto>> {
        const queryString = toQueryParam(query);
        const response = await authFetch(
            `${API_URL}/countries${queryString}`
        )
        return await response.json()
    }

    async function getLogo(orgId: number, logoId: number): Promise<LogoDto> {
        const response = await authFetch(
            `${API_URL}/organizations/${orgId}/logos/${logoId}`
        )
        return await response.json()
    }

    async function getLogoOptions(): Promise<LogoOptionsDto> {
        const response = await authFetch(
            `${API_URL}/logos/options/all`
        )
        return await response.json()
    }

    async function getTransferOptions(vendorId?: number): Promise<TransferDto[]> {
        const vendorIdQueryParameter = vendorId ? `?vendorId=${vendorId}` : ""

        const response = await authFetch(
            `${API_URL}/logos/options/transfersizes${vendorIdQueryParameter}`
        )
        return await response.json()
    }

    async function getNumberPackages(orgId: number): Promise<NumberPackageDto[]> {
        const response = await authFetch(
            `${API_URL}/organizations/${orgId}/numberpackages?orgId=${orgId}`
        )
        return await response.json()
    }

    async function deleteLogoById(orgId: number, logoId: number): Promise<void> {
        await authFetch(
            `${API_URL}/organizations/${orgId}/logos/${logoId}`,
            { method: 'DELETE' }
        )
    }

    async function deleteDesign(orgId: number, designId: number): Promise<void> {
        await authFetch(
            `${API_URL}/organizations/${orgId}/designs/${designId}`,
            { method: 'DELETE' }
        )
    }

    async function getStyleById(id: number): Promise<StyleDto> {
        const response = await authFetch(
            `${API_URL}/styles/${id}`
        )
        return await response.json()
    }

    async function getOriginalMask(styleId: number, styleViewId: number): Promise<UrlResponse> {
        const response = await authFetch(
            `${API_URL}/styles/${styleId}/views/${styleViewId}/mask?original=true`
        )
        return await response.json()
    }

    async function getProfile(orgId?: number): Promise<ProfileResponse> {
        const path = orgId ? `/${orgId}` : ""

        const response = await authFetch(
            `${API_URL}/profile${path}`
        )

        return await response.json()
    }

    async function updateProfile(profile: UpdateProfileDto, orgId?: number): Promise<ProfileResponse> {
        const body = profile
        const path = orgId ? `/${orgId}` : ""

        const response = await authFetch(
            `${API_URL}/profile${path}`,
            {
                method: 'PUT',
                body: JSON.stringify(body)
            }
        )
        return response.json();
    }

    async function updateMask(styleId: number, styleViewId: number, mask: Blob): Promise<void> {
        const file = new File([mask], 'some_file')
        const formData = new FormData()
        formData.append('file', file)

        await authFetch(
            `${API_URL}/styles/${styleId}/views/${styleViewId}/mask`,
            {
                method: 'PUT',
                body: formData
            }
        )
    }

    async function uploadCompanyLogo(orgId: number, logo: File): Promise<void> {
        const formData = new FormData()
        formData.append('file', logo)

        await authFetch(
            `${API_URL}/organizations/${orgId}/logo`,
            {
                method: 'POST',
                body: formData
            }
        )
    }

    async function uploadProviderLogo(logo: File): Promise<void> {
        const formData = new FormData()
        formData.append('file', logo)

        await authFetch(
            `${API_URL}/provider/logo`,
            {
                method: 'POST',
                body: formData
            }
        )
    }

    async function uploadCatalog(manufacturerId: string, catalog: File): Promise<void> {
        const formData = new FormData()
        formData.append('File', catalog)

        await authFetch(
            `${API_URL}/styles/upload/${manufacturerId}`,
            {
                method: 'POST',
                body: formData
            }
        )
    }

    async function createManufacturer(body: CreateManufacturerBody): Promise<ManufacturerDto> {
        const response = await authFetch(
            `${API_URL}/manufacturers`,
            {
                method: 'POST',
                body: JSON.stringify(body)
            }
        )

        return response.json()
    }

    async function createUser(body: CreateUserBody): Promise<void> {
        await authFetch(
            `${API_URL}/users/create`,
            {
                method: 'POST',
                body: JSON.stringify(body)
            }
        )
    }

    async function changeStyle(orgId: number, designId: number, body: ChangeStyleBody): Promise<Design> {
        const response = await authFetch(
            `${API_URL}/organizations/${orgId}/designs/${designId}/style`,
            {
                method: 'PUT',
                body: JSON.stringify(body)
            }
        )

        return response.json()
    }

    async function changeDesignTitle(orgId: number, designId: number, body: ChangeTitleBody): Promise<Design> {
        const response = await authFetch(
            `${API_URL}/organizations/${orgId}/designs/${designId}/rename`,
            {
                method: 'PUT',
                body: JSON.stringify(body)
            }
        )

        return response.json()
    }

    async function getUsersOfOrganization(orgId: number): Promise<User[]> {
        const response = await authFetch(
            `${API_URL}/users?orgId=${orgId}`,
            {
                method: 'Get',
            }
        )
        return response.json();
    }

    async function getCreateMaskQueueCount(): Promise<CreateMaskQueueCount> {
        const response = await authFetch(
            `${API_URL}/styles/view/pending`
        )

        return response.json()
    }

    async function updateStyle(styleId: number, body: UpdateStyleBody): Promise<StyleDto> {
        const response = await authFetch(
            `${API_URL}/styles/${styleId}`,
            {
                method: 'PUT',
                body: JSON.stringify(body)
            }
        )

        return response.json()
    }

    async function changeColors(orgId: Organization['id'], logoId: BaseLogo['id'], colors: ChangeLogoColors): Promise<BaseLogo> {
        const body = colors

        const response = await authFetch(
            `${API_URL}/organizations/${orgId}/logos/${logoId}/changecolors`,
            {
                method: 'PUT',
                body: JSON.stringify(body)
            }
        )
        return response.json();
    }

    async function changeLogoTitle(orgId: number, logoId: number, title: string): Promise<BaseLogo> {
        const response = await authFetch(
            `${API_URL}/organizations/${orgId}/logos/${logoId}/rename`,
            {
                method: 'PUT',
                body: JSON.stringify({ newName: title })
            }
        )

        return response.json()
    }

    async function placeVerdictLogo(logo: BaseLogo, verdict: Verdict): Promise<BaseLogo> {
        const orgId = logo.customer.id
        const logoId = logo.id
        const response = await authFetch(
            `${API_URL}/organizations/${orgId}/logos/${logoId}/approval`,
            {
                method: 'PUT',
                body: JSON.stringify(verdict)
            }
        )
        return response.json();
    }

    async function placeVerdictDesign(design: Design, verdict: Verdict): Promise<DesignDto> {
        const orgId = design.customer.id
        const designId = design.id
        const response = await authFetch(
            `${API_URL}/organizations/${orgId}/designs/${designId}/approval`,
            {
                method: 'PUT',
                body: JSON.stringify(verdict)
            }
        )
        return response.json();
    }

    async function placeVerdictOrder(order: Order, verdict: Verdict): Promise<Order> {
        const orgId = order.organization.id
        const logoId = order.id
        const response = await authFetch(
            `${API_URL}/organizations/${orgId}/orders/${logoId}/approval`,
            {
                method: 'PUT',
                body: JSON.stringify(verdict)
            }
        )
        return response.json();
    }

    async function setPackagingAndDelivery(order: Order, deliveryType: DeliveryType, packaging: Packaging): Promise<Order> {
        const orgId = order.organization.id
        const orderId = order.id
        const response = await authFetch(
            `${API_URL}/organizations/${orgId}/orders/${orderId}/packagingAndDelivery`,
            {
                method: 'PUT',
                body: JSON.stringify({
                    deliveryType: deliveryType.toString(),
                    packagingType: packaging.toString(),
                })
            }
        )
        return response.json();
    }


    async function approveLogoExternal(logo: ImageLogo): Promise<BaseLogo> {
        const logoPublicKey = logo.approval!.publicKey
        const response = await authFetch(
            `${API_URL}/logos/${logoPublicKey}/approveExternal`,
            {
                method: 'PUT',
            }
        )
        return response.json();
    }

    async function rejectLogoExternal(logo: BaseLogo): Promise<BaseLogo> {
        const orgId = logo.customer.id
        const logoId = logo.id
        const response = await authFetch(
            `${API_URL}/organizations/${orgId}/logos/${logoId}/rejectExternal`,
            {
                method: 'PUT',
            }
        )
        return response.json();
    }

    async function initiateApprovalLogo(logo: BaseLogo, approval: InitiateApproval): Promise<BaseLogo> {
        const orgId = logo.customer.id
        const logoId = logo.id
        const response = await authFetch(
            `${API_URL}/organizations/${orgId}/logos/${logoId}/approval-start`,
            {
                method: 'POST',
                body: JSON.stringify(approval)
            }
        )
        return response.json();
    }

    async function initiateApprovalDesign(design: Design, approval: InitiateApproval): Promise<DesignDto> {
        const orgId = design.customer.id
        const designId = design.id
        const response = await authFetch(
            `${API_URL}/organizations/${orgId}/designs/${designId}/approval-start`,
            {
                method: 'POST',
                body: JSON.stringify(approval)
            }
        )
        return response.json();
    }

    async function setCommissionProfile(orgId: number, commissionProfile: CommissionProfile): Promise<CommissionProfileDto> {
        const response = await authFetch(
            `${API_URL}/organization/${orgId}/commission`,
            {
                method: 'PUT',
                body: JSON.stringify(commissionProfile)
            }
        )
        return response.json();
    }

    async function deleteCommissionProfile(orgId: number): Promise<void> {
        await authFetch(
            `${API_URL}/organization/${orgId}/commission`,
            {
                method: 'DELETE'
            }
        )
    }

    async function downloadTemplate(orgId: number, designs: CartItemDesign[]): Promise<Blob> {
        const body: CreateDesignTemplateDto = {
            designs: designs.map(design => ({
                designId: Number(design.id), // Ensure designId is a number
                instances: design.instances.map(instance => ({
                    label: instance.label.toString(),
                    size: instance.size ?? "",
                    quantity: instance.toDelivery,
                    logos: instance.logos.map(logo => ({
                        logoId: logo.logoId,
                        view: logo.locationIdentifier,
                        value: logo.value.toString()
                    }))
                }))
            }))
        };

        const response = await authFetch(
            `${API_URL}/organizations/${orgId}/orders/download-template`,
            {
                method: 'post',
                body: JSON.stringify(body),
            }
        )
        return await response.blob();
    }

    async function uploadTemplate(orgId: number, spreadsheet: File): Promise<DesignTemplateDto> {
        const file = new File([spreadsheet], 'some_file')
        const formData = new FormData()
        formData.append('file', file)

        const response = await authFetch(
            `${API_URL}/organizations/${orgId}/orders/upload-template`,
            {
                method: 'POST',
                body: formData
            }
        )

        return response.json()
    }

    async function reorder(orgId: number, orderId: number): Promise<OrderDto> {
        const response = await authFetch(
            `${API_URL}/organizations/${orgId}/orders/${orderId}/reorder`,
            {
                method: 'POST'
            }
        )

        return response.json()
    }

    return {
        changeColors,
        changeLogoTitle,
        getAllStyles,
        getAllDesigns,
        getAllManufacturers,
        createDesign,
        updateDesign,
        createTextLogo,
        createPlaceholderNameLogo,
        createPlaceholderNumberLogo,
        generateTextLogoPreview,
        generateNumberLogoPreview,
        getAllTextLogos,
        getDesignSnapshot,
        saveDesignSnapshot: saveDesignSnapshot,
        getDesignPriceSummary,
        getOrder: getOrder,
        submitDesign: submitDesign,
        getAllVendors: getAllVendors,
        getAllCustomers: getAllCustomers,
        getAllOrders,
        getOrganization,
        getVendor,
        getCustomer,
        getAllLogos,
        getAllLogosForDesign,
        getAllCountries,
        getLogo,
        getLogoOptions,
        getNumberPackages,
        getTransferOptions,
        getLogos,
        deleteLogoById,
        getStyleById,
        getOriginalMask,
        getProfile,
        updateProfile,
        createAndUploadImageLogo,
        createImageLogo,
        uploadFileImageLogo,
        updateMask,
        updateOrganization: updateOrganization,
        createOrganization: createOrganization,
        deleteOrganization: deleteOrganization,
        uploadCompanyLogo,
        uploadProviderLogo,
        uploadCatalog,
        createManufacturer,
        createUser,
        getCreateMaskQueueCount,
        updateStyle,
        changeStyle,
        changeDesignTitle,
        createOrder,
        updateOrder,
        getOrderPrices,
        commitOrder,
        getDesign,
        updateLogo,
        resizeLogo,
        copyLogo,
        copyDesign,
        commitLogo,
        placeVerdictLogo,
        placeVerdictDesign,
        placeVerdictOrder,
        setPackagingAndDelivery,
        approveLogoExternal,
        rejectLogoExternal,
        createCustomer,
        updateCustomer,
        deleteCustomer,
        initiateApprovalLogo,
        commitDesign,
        skipApprovalDesign,
        initiateApprovalDesign,
        deleteDesign,
        getUsersOfOrganization,
        setCommissionProfile,
        deleteCommissionProfile,
        downloadTemplate,
        uploadTemplate,
        reorder
    }
}
