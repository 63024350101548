import { Style } from '../../../../core/models/entities/Style'
import React, { useEffect, useRef, useState } from 'react'
import { StyleView } from '../../../../core/models/entities/StyleView'
import { IdLookUps } from '../../../../utils/models/idLookUp'
import { ScaledViewInfo, ViewInfo } from '../../design-editor/design-view/DesignView.vm'
import { XnY } from '../models/x-n-y.model'
import { loadImageAsync } from '../../../../utils/functions/ImageUtil'
import { getScaledViewInfo } from '../functions/getScaledViewInfo'

interface StyleViewOperation {
  onImageRefSet: () => void
  changeStyle: (style: Style) => void
  selectView: (view: StyleView) => void
  views: StyleView[]
  style: Style
  selectedView: StyleView | undefined
  imageRef: React.RefObject<HTMLDivElement>
  containerRef: React.RefObject<HTMLDivElement>
  scaledViewInfoPerView: IdLookUps<ScaledViewInfo>
  fullsizeViewInfoPerView: IdLookUps<ScaledViewInfo>
  parentContainerXnY?: XnY,
}

export function useStyleViewOperations(initial: Style): StyleViewOperation {
  const [style, setStyle] = useState<Style>(initial)
  const [views, setViews] = useState<StyleView[]>(initial.styleViews)
  const [selectedView, setSelectedView] = useState<StyleView['id']>(initial.styleViews[0].id)
  const [fullsizeViewInfoPerView, setFullsizeViewInfoPerView] = useState<IdLookUps<ViewInfo>>({})
  const [scaledViewInfoPerView, setScaledViewInfoPerView] = useState<IdLookUps<ScaledViewInfo>>({})
  const [imageRefXnY, setImageRefXnY] = useState<XnY>()

  const imageRef = useRef<HTMLDivElement>(null)
  const containerRef = useRef<HTMLDivElement>(null)

  const [initialPrepare, setInitialPrepare] = useState<boolean>(false)
  if (!initialPrepare) {
    setInitialPrepare(true)
    prepareViews(style.styleViews).then(vs => {
      vs.forEach(v => setFullsizeViewInfoPerView(x => ({ ...x, [v.viewId]: v })))
    })
  }

  useEffect(() => {
    if (imageRef.current) {
      const rect = imageRef.current.getBoundingClientRect()
      setImageRefXnY({ x: rect.x, y: rect.y })

    }
  }, [imageRef.current])

  useEffect(() => {
    views.forEach(view => {
      if (containerRef.current && fullsizeViewInfoPerView[view.id]) {
        // already generated no
        if (scaledViewInfoPerView[view.id]) {
          return
        }
        const currentContainerWidth = containerRef.current.offsetWidth
        const currentContainerHeight = containerRef.current.offsetHeight
        const settings = getScaledViewInfo(fullsizeViewInfoPerView[view.id], currentContainerHeight, currentContainerWidth)
        setScaledViewInfoPerView(x => ({
          ...x,
          [settings.viewId]: settings
        }))
      }
    })
  }, [containerRef, fullsizeViewInfoPerView[selectedView]])

  function onImageRefSet() {
    if (imageRef.current) {
      const rect = imageRef.current.getBoundingClientRect()
      setImageRefXnY({ x: rect.x, y: rect.y })
    }
  }

  function changeStyle(style: Style) {
    setStyle(style)
    setViews(style.styleViews)
    prepareViews(style.styleViews).then(vs => {
      vs.forEach(v => setFullsizeViewInfoPerView(x => ({ ...x, [v.viewId]: v })))
    })
  }

  return {
    onImageRefSet,
    changeStyle,
    selectView: (view: StyleView) => setSelectedView(view.id),
    views,
    style,
    selectedView: views.find(x => x.id === selectedView),
    imageRef,
    containerRef,
    scaledViewInfoPerView,
    fullsizeViewInfoPerView,
    parentContainerXnY: imageRefXnY
  }
}

async function prepareViews(views: StyleView[]) {
  const preparedView = []
  for (const view of views) {
    const pView = await prepareView(view)
    preparedView.push(pView)
  }
  return preparedView
}


async function prepareView(view: StyleView): Promise<ScaledViewInfo> {
  const vImage = await loadImageAsync(view.imageUrl)
  const maskImage = await loadImageAsync(view.maskUrl)

  const maskCanvas = document.createElement('canvas')
  maskCanvas.width = vImage.width
  maskCanvas.height = vImage.height
  /*
    const maskCanvasCtx = maskCanvas.getContext('2d', {
      willReadFrequently: true
    })*/

  return {
    viewId: view.id,
    height: vImage.height,
    width: vImage.width,
    maskImage,
    maskCanvas: maskCanvas,
    ppcm: view.ppcm,
    styleToViewSizeDelta: 1
  }
}