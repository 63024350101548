import { NumberPackage } from "../../../core/models/entities/NumberPackage";
import { NumberPackageDto } from "../responses/NumberPackageDto";

export function mapToNumberPackages(dto: NumberPackageDto[]): NumberPackage[] {
  return dto.map(mapToNumberPackage)
}

export function mapToNumberPackage(dto: NumberPackageDto): NumberPackage {
  return { ...dto }
}

