import { StyleView } from '../../../../../core/models/entities/StyleView'
import { StyleViewForm } from './StyleEditFormViewModel'
import { InvertedPanel } from '../../../../components/BoxView/InvertedPanel'
import { StrucCol } from '../../../../components/StructureElements/StrucCol'
import { StrucRow } from '../../../../components/StructureElements/StrucRow'

interface ViewSelectorProps {
  views: StyleView[];
  onSelectView: (view: StyleView) => void,
  currentView?: StyleView | StyleViewForm;
}

export function ViewSelector({ views, onSelectView, currentView }: ViewSelectorProps) {
  const viewsComponents = views.map((x, index) => <SmallView styleView={x} key={index}
                                                             isSelected={x.id === currentView?.id}
                                                             onSelect={() => Select(index)} />)

  function Select(index: number) {
    onSelectView(views[index])
  }

  return <StrucRow>
    <StrucCol>
      <InvertedPanel className={'flex h-40 items-center justify-center space-x-6 mx-0 rounded-br mx-[0rem]'}>
        {viewsComponents}
      </InvertedPanel>
    </StrucCol>
  </StrucRow>
}

function SmallView({ styleView, isSelected, onSelect }: {
  styleView: StyleView | StyleViewForm,
  isSelected: boolean,
  onSelect: (view: StyleView | StyleViewForm) => void
}) {
  return <div
    className={`h-24 w-20 flex flex-col justify-center transition-all ease-in-out duration-500 scale-100 ${isSelected ? '!scale-150' : ''}`}
    onClick={() => onSelect(styleView)}>
    <div className={`w-20 h-24 bg-white flex justify-center items-center `}>
      <img className={'object-contain h-16'} src={styleView.imageUrl} alt={'' + styleView.id} />
    </div>
    <span className={'text-center'}>{styleView.view}</span>
  </div>
}