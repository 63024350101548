import { useEffect, useState } from "react";
import { nameOf } from "../../../../utils/functions/ReflectionUtil";
import { PrimaryButton } from "../../../components/Buttons/PrimaryButton";
import { ImageLogoForm } from "../models/ImageLogoForm";
import { InputWithSuffix } from "./InputWithSuffix";

interface Props {
  width: number
  height: number
  onSizeChange: (width: number, height: number) => void
  onResize: () => void
  disabled?: boolean
}

export function LogoResize({ width, height, onSizeChange, onResize, disabled = false }: Props) {

  const [originalSize, setOriginalSize] = useState<{ width: number, height: number }>({
    width: width,
    height: height
  })

  useEffect(() => {
    setOriginalSize({ width, height })
  }, [width, height])

  function handleWidthChange(event: React.ChangeEvent<HTMLInputElement>) {
    if (event.target?.value?.trim().length === 0 || event.target?.value === "0") {
      onSizeChange(width, height)
      return;
    }

    const newWidth = +event.target.value ?? originalSize.width
    const newHeight = newWidth / width * height
    onSizeChange(newWidth, newHeight)
  }

  function handleHeightChange(event: React.ChangeEvent<HTMLInputElement>) {
    if (event.target?.value?.trim().length === 0 || event.target?.value === "0") {
      onSizeChange(width, height)
      return;
    }

    const newHeight = +event.target.value ?? originalSize.height
    const newWidth = newHeight / height * width
    onSizeChange(newWidth, newHeight)
  }

  return (<div className={'flex items-center space-x-2'}>
    <label className={'uppercase'}>Size</label>
    <InputWithSuffix
      disabled={disabled}
      className={'!w-20'}
      suffix={'MM'}
      name={nameOf<ImageLogoForm>(lf => lf.widthMm)}
      type='number'
      value={width}
      onChange={handleWidthChange}
    />
    <label>x</label>
    <InputWithSuffix
      disabled={disabled}
      className={'!w-20'}
      suffix={'MM'}
      name={nameOf<ImageLogoForm>(lf => lf.heightMm)}
      type='number'
      value={height}
      onChange={handleHeightChange}
    />
    <PrimaryButton width={'sm'} onClick={onResize}>Update Size</PrimaryButton>
  </div>)
}