import { MaskColor } from '../../../core/models/entities/MaskColor'
import { ButtonRoot } from './SecondaryButton'

interface Props {
  color: MaskColor
  onClick: (color: MaskColor) => void
  full?: boolean
}

export default function PaletteButton({ color, onClick, full }: Props): JSX.Element {
  return (
    <ButtonRoot width={'full'}
      className="text-white [text-shadow: 1px_0_0_#000,0_-1px_0_#000,0_1px_0_#000,-1px_0_0_#000]"
      onClick={() => onClick(color)}
      style={{ backgroundColor: `rgb(${MaskColor.toRGBString(color)}, ${full ? '1' : '0.80'})` }}>
      {MaskColor.toString(color)}
    </ButtonRoot>
  )
}
