import { useDesignCreateFormViewModel } from '../vmHooks/useDesignCreateFormViewModel'
import DesignCreateForm from '../components/design-create-form.component'
import React from 'react'
import { Organization } from '../../../../core/models/entities/Organization'
import { Manufacturer } from '../../../../core/models/entities/Manufacturer'

interface WrapperProps {
  customers: Organization[]
  manufacturers: Manufacturer[]
}

export function CreateDesignFormWrapper({ customers, manufacturers }: WrapperProps) {
  const vm = useDesignCreateFormViewModel(manufacturers, customers)
  return <DesignCreateForm {...vm} />
}