import { ProductPreviewLogo } from './ProductPreviewLogo'
import { ProductDetailsLogo } from './ProductDetailsLogo'
import { CartItemLogo } from '../models/CartItem'
import { useOrderActionHandler } from '../hooks/useOrderActionsHandler'

interface OrderLineViewLogoProps {
  orderline: CartItemLogo
}

export function OrderLineViewLogo({ orderline }: OrderLineViewLogoProps) {

  const orderlineDetails = orderline as CartItemLogo

  const logosToDelivery = orderlineDetails.toDelivery
  const logosToStorage = orderlineDetails.toStorage
  
  const { handleLogoSetQuantities } = useOrderActionHandler();

  function handleLogosToDelivery(v: number) {
    handleLogoSetQuantities(orderline.baseLogo, v, logosToStorage)
  }

  function handleLogosToStorage(v: number) {
    handleLogoSetQuantities(orderline.baseLogo, logosToDelivery, v)
  }
  
  return (
    <div id={'main-box'} className={'flex-1 flex space-x-10 p-8'}>
      <div id={'left-box'} className={'space-y-2 flex-1'}>
        <div className={'flex-1'} style={{ height: "100%"}}>
          <ProductPreviewLogo orderline={orderline} />
        </div>
      </div>
      <div id={'right-box'} className={'flex-1 flex flex-col'}>
        <div className={'flex-1'}>
          <ProductDetailsLogo orderLine={orderlineDetails} handleLogosToDelivery={handleLogosToDelivery} handleLogosToStorage={handleLogosToStorage} />
        </div>
      </div>
    </div>
  )
}