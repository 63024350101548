import React from 'react'
import IconButton from '../../../components/LogoButton/IconButton'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPenToSquare } from '@fortawesome/free-regular-svg-icons'
import { Organization } from '../../../../core/models/entities/Organization'
import { ColumnSettings } from '../../../components/GenericTable/SubComponents/ListFrame'
import { nameOf } from '../../../../utils/functions/ReflectionUtil'
import { formatDate } from '../../../../utils/functions/DateUtil'
import { formatPhoneNumberIntl } from 'react-phone-number-input'
import { StrucCol } from '../../../components/StructureElements/StrucCol'
import { StrucRow } from '../../../components/StructureElements/StrucRow'
import { Tooltip } from '@mui/material'

export interface CustomerListItemProps {
  organization: Organization,
  columns: ColumnSettings;
  onEditOrganization: (organization: Organization) => void;
}

export function CustomerListItemComponent({ organization, columns, onEditOrganization }: CustomerListItemProps) {
  function handleEditClick(x: React.MouseEvent<HTMLButtonElement>) {
    x.stopPropagation()
    onEditOrganization(organization)
  }

  return <StrucRow className={'py-4'}>
    <StrucCol size={columns[nameOf<Organization>(org => org.name)].weight}>
      <Tooltip title={organization.name} placement={'top-start'}>
        <span className={'whitespace-nowrap font-bold overflow-x-hidden overflow-ellipsis'}>{organization.name}</span>
      </Tooltip>
    </StrucCol>
    <StrucCol size={columns[nameOf<Organization>(org => org.contactPerson)].weight}>
      <Tooltip title={organization.contactPerson} placement={'top-start'}>
        <span className={'whitespace-nowrap overflow-x-hidden overflow-ellipsis'}>{organization.contactPerson}</span>
      </Tooltip>
    </StrucCol>
    <StrucCol size={columns[nameOf<Organization>(org => org.email)].weight}>
      <Tooltip title={organization.email} placement={'top-start'}>
        <span className={'whitespace-nowrap overflow-x-hidden overflow-ellipsis'}>{organization.email}</span>
      </Tooltip>
    </StrucCol>
    <StrucCol size={columns[nameOf<Organization>(org => org.phone)].weight}>
      {// @ts-ignore
        formatPhoneNumberIntl(organization.phone)
      }
    </StrucCol>
    <StrucCol size={columns[nameOf<Organization>(org => org.totalOrderValue)].weight}>
      DKK {organization.totalOrderValue ? organization.totalOrderValue.toFixed(2) : '0.00'}
    </StrucCol>
    <StrucCol size={columns[nameOf<Organization>(org => org.lastOrder)].weight}>
      {organization.lastOrder ? formatDate(organization.lastOrder) : '-'}
    </StrucCol>
    <StrucCol size={columns['actions'].weight} className={'my-[-0.5rem]'}>
      <IconButton icon={<FontAwesomeIcon icon={faPenToSquare} />} onClick={x => handleEditClick(x)}>Edit</IconButton>
    </StrucCol>
  </StrucRow>
}