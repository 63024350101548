import { Autocomplete, Box, createFilterOptions } from '@mui/material'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons'

export function CustomAutoComplete<T extends object>({ options, onChange, displayOption, placeholder, getOptionKey, value, disabled }: {
  options: T[],
  onChange: (method: T | null) => void
  displayOption: (option: T) => string
  getOptionKey: (option: T) => string | number
  placeholder?: string
  value: T | null
  disabled?: boolean
}) {
  const filterOptions = createFilterOptions<T>({
    limit: 200
  })

  const existingValue = value ? options.filter((x) => !!x).find(x => getOptionKey(x) === getOptionKey(value)) : null

  return <Autocomplete
    disabled={disabled ?? false}
    className={'!text-xs'}
    disablePortal
    options={options}
    value={existingValue ?? null}
    filterOptions={filterOptions}
    getOptionLabel={x => !!x ? '' + displayOption(x) : ''}
    renderOption={(props, option, state) => {
      // @ts-ignore
      const {key, ...noKeyPropsWhyEvenKey } = props
      return (
        <Box
          key={state.index}
          component='li'
          {...noKeyPropsWhyEvenKey}
        >
          <div key={getOptionKey(option)}
            className={'flex text-xs items-center space-x-2'}>
            <p>{displayOption(option)}</p>
          </div>
        </Box>
      )
    }}
    renderInput={(params) =>
    (
      <div ref={params.InputProps.ref}
        className='border rounded-md border-gray-200 border-solid h-9 flex space-x-2 items-center pl-2 bg-white focus-within:border-gray-600'>
        <span>
          <FontAwesomeIcon icon={faMagnifyingGlass} />
        </span>
        <input {...params.inputProps} className='border-none text-xs font-medium outline-none w-full'
          placeholder={placeholder} />
      </div>
    )}
    onChange={(x, v) => onChange(v)}
  />
}